
const search = {
  namespaced: true,

  state: {
    keyword: '',
    guestResults: [],
    showResults: [],
  },

  getters: {
    keyword: state => state.keyword,
    guestResults: state => state.guestResults,
    showResults: state => state.showResults,
  },

  mutations: {
    setKeyword(state, data) {
      state.keyword = data;
    },
    setGuestResults(state, data) {
      state.guestResults = data;
    },
    setShowResults(state, data) {
      state.showResults = data;
    },
    setPagination(state, data) {
      if (data.pagination) {
        state.pagination = data.pagination;
      }
    },
  },
}

export default search
