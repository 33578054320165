<template>
  <div class="flex flex-col flex-start p-6 pt-5 bg-white rounded-lg">
    <span class="font-bold mb-5">Inquire user</span>
    <ValidationObserver ref="inquireForm" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit(submit)" method="post">
        <div class="grid grid-cols-1 row-gap-6 md:w-96">
          <div>
            <label for="name" class="register-label">Name</label>
            <ValidationProvider rules="required|max:100" name="Name" vid="name" v-slot="{ errors }">
              <TextInput v-model="form.name" :errors="errors"/>
            </ValidationProvider>
          </div>

          <div>
            <label for="email" class="register-label">Email</label>
            <ValidationProvider rules="required|email" name="Email" vid="email" v-slot="{ errors }">
              <TextInput v-model="form.email" :errors="errors"/>
            </ValidationProvider>
          </div>

          <div>
            <label class="register-label">Phone</label>
            <ValidationProvider rules="required" vid="phone" name="Phone" v-slot="{ errors }">
              <div>
                <the-mask type="tel" v-model="form.phone" :mask="'(###) ###-####'" class="guestio-form-input" />
              </div>
              <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <div>
            <label class="register-label">Message</label>
            <ValidationProvider mode="lazy" rules="required" name="Message" vid="message" v-slot="{ errors }">
              <div>
                <textarea-autosize class="shadow-sm guestio-form-textarea w-full" name="message" id="message" v-model="form.message" :min-height="100" />
              </div>
              <p class="text-red-500 text-sm font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
        </div>

        <div class="flex items-center justify-between mt-8">
          <button @click="close" type="button" class="text-pink-500 font-bold text-sm px-12">
            Cancel
          </button>

          <button type="submit" class="ml-12 w-full bg-indigo-gradiant text-sm font-bold text-white rounded-full px-12 h-11 flex items-center justify-center disabled:opacity-60 relative" :disabled="loading">
            <span v-if="loading" class="absolute">
              <loading-icon class="text-white h-2" />
            </span>
            <span :class="{'text-transparent': loading}">
              Send
            </span>
          </button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import TextInput from "@/components/shared/TextInput";
  import { TheMask } from 'vue-the-mask'
  import api from '@/api'

  export default {
    name: 'InquireUserModal',
    components: { TextInput, TheMask },
    props: {
      profileId: Number,
      profileType: String
    },

    data() {
      return {
        loading: false,
        form: {
          name: '',
          email: '',
          phone: '',
          profile_id: '',
          profile_type: '',
        }
      }
    },

    methods: {
      submit() {
        this.loading = true;

        this.form.profile_id = this.profileId;
        this.form.profile_type = this.profileType;

        api.post(`/guestio-talent/inquire-profile`, this.form)
          .then(() => {
            const success = { message: 'Your request was successfully submitted.'}
            window.Event.$emit('toast', success)
            this.close();
          }).catch(() => {
            const error = { message: 'Something went wrong. Try again in a few minutes.', type: 'error'}
            if (error.response) {
              return this.$refs.inquireForm.setErrors(error.response.data.errors)
            }
            window.Event.$emit('toast', error)
          }).finally(() => this.loading = false)
      },
      
      close() {
        this.$emit('close');
      }
    },
  }
</script>
