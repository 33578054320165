<template>
  <div class="h-16">
    <div class="flex bg-gray-50 rounded-r-full rounded-l-full justify-between">
      <router-link
        :to="{name:'MiniviewQuestions'}"
        class="flex-1 text-center rounded-full py-3 lg:py-4 transition-colors duration-300 ease-out text-sm lg:text-base"
        :class="{ 'bg-lightBlack text-white': $route.name == 'MiniviewQuestions' }"
      >
        Questions
      </router-link>

      <router-link
        :to="{name:'MiniviewAnswerGroups'}"
        class="flex-1 text-center py-3 lg:py-4 rounded-full transition-colors duration-300 ease-out text-sm lg:text-base"
        :class="{ 'bg-lightBlack text-white': $route.name.includes('MiniviewAnswer') }"
      >
        Answers ({{ miniview.answer_groups.length }})
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    name: "MiniviewTabs",

    computed: {
       ...mapGetters({
        miniview: 'miniviews/current',
      }),
    },
  }
</script>
