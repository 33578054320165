<template>
  <div class="dashboard-card border border-gray-50">
    <div class="px-4">
      <template v-if="loading">
        <div class="h-40 flex items-center justify-center">
          <loading-icon class="w-5 h-5 text-pink-500" />
        </div>
      </template>

      <template v-else-if="! booking.is_completed">
        <div class="my-12 max-w-sm w-full mx-auto flex items-center justify-center text-center">
          <span class=" text-xl font-semibold">Reviews will be available after the interview takes place.</span>
        </div>
      </template>

      <template v-else>
        <template v-if="userOwnsBookingGuest">
          <template v-if="! editMode">
            <review-content v-if="showReview" @edit="editMode = true" :entity="booking.show" :review="showReview" />

            <div v-if="showReview && guestReview">
              <hr class="pt-12 mt-12">
              <review-content :entity="booking.guest" :review="guestReview" :with-edit="false" />
            </div>
          </template>

          <template v-else>
            <review-form @saved="fetchReviews(); editMode = false" type="show" :review="showReview"/>
          </template>
        </template>

        <template v-if="userOwnsBookingShow">
          <template v-if="! editMode">
            <review-content v-if="guestReview" @edit="editMode = true" :entity="booking.guest" :review="guestReview" />

            <div v-if="showReview && guestReview">
              <hr class="pt-12 mt-12">
              <review-content :entity="booking.show" :review="showReview" :with-edit="false" />
            </div>
          </template>

          <template v-else>
            <review-form @saved="fetchReviews(); editMode = false" type="guest" :review="guestReview" />
          </template>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import ReviewForm from '@/components/bookings/individual/reviews/ReviewForm'
  import ReviewContent from '@/components/bookings/individual/reviews/ReviewContent'
  export default {
    name: "ReviewCard",

    components: {
      ReviewContent,
      ReviewForm,
    },

    data() {
      return {
        loading: true,
        editMode: false,
      }
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      showReview() {
        return this.$store.getters['dashboard/bookings/showReview']
      },

      guestReview() {
        return this.$store.getters['dashboard/bookings/guestReview']
      },

      userOwnsBookingGuest() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingGuest']
      },

      userOwnsBookingShow() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
      },
    },

    methods: {
      fetchReviews() {
        this.loading = true

        this.$store.dispatch('dashboard/bookings/fetchReviews', {
          id: this.$route.params.bookingId,
        })
        .then(() => {
          if (this.userOwnsBookingGuest && ! this.showReview) {
            this.editMode = true
          } else if (this.userOwnsBookingShow && ! this.guestReview) {
            this.editMode = true
          }
        })
        .catch(error => console.log(error))
        .finally(() => this.loading = false)
      },

      moment(date) {
        return moment.utc(date).local()
      }
    },

    created() {
      this.fetchReviews()
    },

    destroyed() {
      this.$store.commit('dashboard/bookings/clearRatings')
    }
  }
</script>
