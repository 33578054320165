<template>
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
      <div class="flex justify-between items-center">
        <h3 class="font-bold font-poppins text-blue-800">
          This is a Pro feature
        </h3>
        <div>
          <button @click="$emit('close')" type="button" class="text-gray-500 p-1 rounded focus:shadow-outline">
            <XIcon class="w-6 h-6" />
          </button>
        </div>
      </div>

      <div class="text-sm text-blue-800 font-medium mt-6">
        <slot />
      </div>

      <div class="flex items-center justify-between mt-8">
        <button @click="showProSubscription" type="button" class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-8 py-2.5">
          Unlock Pro
        </button>

        <button @click="showProSubscription" type="button" class="text-pink-500 font-bold text-sm">
          Read more about Pro
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api';
  import XIcon from '@/components/svgs/XIcon';

  export default {
    name: 'ProFeatureModal',

    components: {
      XIcon,
    },

    data() {
      return {
        processing: false,
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser'];
      }
    },

    methods: {
      showProSubscription() {
        this.$router.push({name: 'Settings', hash: '#pro-subscription'})
      },

      joinTheWaitlist() {
        this.processing = true;

        api.post('/join-waitlist', {
          email: this.user.email,
          source: 'app',
        }).then(() => {
          this.$toast.success("You've beed added to the waitlist.");
        }).catch(() => {
          // if (error.response) {
          //   this.$toast.error(error.response.data.message)
          //   return
          // }
          this.$toast.error('Something went wrong')
        }).finally(() => {
          this.$emit('close');
          this.processing = false;
        });
      }
    }
  }
</script>
