// import api from '@/api'

const messages = {
  namespaced: true,

  state: {
    activeBooking: -1
  },

  actions: {
    //
  },

  mutations: {
    setActiveBooking(state, data) {
      state.activeBooking = data
    },
  },

}

export default messages;
