<template>
  <div v-if="userProp && userProp.id" class="flex flex-col md:flex-row md:space-x-6">
    <div class="flex-1">
      <h4 class="font-medium text-lg text-blue-800">
        Pause Account
      </h4>
      <p class="text-sm text-gray-500 mt-2">
        <template v-if="! userIsPaused">
          When pausing the account, your profiles will be set to private and removed from search results. No one will be able to book you. {{ userIsProText }}
        </template>

        <template v-else>
          When resuming the account, your profiles will be set to public and will start showing in search results. People will be able to book you. {{ userIsProText }}
        </template>
      </p>
    </div>

    <div class="flex-1 flex items-start justify-end mt-4 sm:mt-0">
      <div class="flex items-center space-x-4">
        <span>
          <span v-if="userIsPaused" class="text-orange-500 font-medium">
            Paused
          </span>
          <span v-else class="text-green-500 font-medium">
            Active
          </span>
        </span>

        <ConfirmDialog
          v-if="userIsPaused"
          @confirm="resumeAccount"
          confirm-action="Yes, Resume"
          dialog-title="Resume Account?"
          dialog-text="Are you sure you want to resume your account?"
          class="bg-white border border-pink-500 text-pink-500 px-4 py-2 rounded-full text-white text-sm hover:bg-gray-50 transition duration-150 ease-in-out hover:shadow-3xl focus:shadow-outline"
        >
          Resume Account
        </ConfirmDialog>

        <ConfirmDialog
          v-else
          @confirm="pauseAccount"
          confirm-action="Yes, Pause"
          dialog-title="Pause Account?"
          dialog-text="Are you sure you want to pause your account?"
          class="bg-white border border-pink-500 text-pink-500 px-4 py-2 rounded-full text-white text-sm hover:bg-gray-50 transition duration-150 ease-in-out hover:shadow-3xl focus:shadow-outline"
        >
          Pause Account
        </ConfirmDialog>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import ConfirmDialog from '@/components/shared/ConfirmDialog'

  export default {
    name: 'UserPauseAccount',

    props: {
      userProp: Object,
    },

    components: {
      ConfirmDialog
    },

    computed: {
      userIsPaused() {
        return this.userProp.status === 'paused'
      },

      userIsProText() {
        if (this.userProp.account_type !== 'pro') {
          return
        }

        if (this.userIsPaused) {
          return `If your PRO subscription did not expire, we'll attempt to resume it.`
        }

        return `Your PRO subscription will also be cancelled.`
      }
    },

    methods: {
      async resumeAccount(callback) {
        let confirmed = await this.confirmLogin()

        if (! confirmed) {
          callback()
          this.$toast.error('Login not confirmed.')
          return
        }

        api.patch('/users/me/account/resume')
          .then(() => this.$store.dispatch('auth/getUser'))
          .then(() => {
            this.$toast.success('Account resumed!')
            callback()
          })
          .catch(() => {
            this.$toast.error('Error! Something went wrong.')
          })
      },

      async pauseAccount(callback) {
        let confirmed = await this.confirmLogin()

        if (! confirmed) {
          callback()
          this.$toast.error('Login not confirmed.')
          return
        }

        api.patch('/users/me/account/pause')
          .then(() => this.$store.dispatch('auth/getUser'))
          .then(() => {
            this.$toast.success('Account paused!')
            callback()
          })
          .catch(() => {
            this.$toast.error('Error! Something went wrong.')
          })
      },
    }
  }
</script>
