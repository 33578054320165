<template>
  <div class="main-container grid grid-cols-12 row-gap-10 md:col-gap-10">
    <template v-if="! miniview">
      <div class="h-40 col-span-12 flex justify-center items-center">
        <loading-icon class="w-10 h-10 text-pink-500" />
      </div>
    </template>
    <template v-else>
      <!--Left col-->
      <div class="col-span-12 lg:col-span-5">
        <MiniviewsNavigation class="md:mb-12 py-4"/>
        <DetailsCard/>
        <div class="flex justify-center mt-10">
          <ConfirmDialog
            @confirm="deleteMiniview"
            confirm-action="Yes, Delete"
            dialog-title="Delete Miniview"
            dialog-text="Are you sure you want to delete this MINIVIEW™?"
            class="text-sm text-red-500"
          >
            Delete MINIVIEW™
          </ConfirmDialog>
        </div>
        
      </div>
      <!--Right col-->
      <div class="col-span-12 lg:col-span-7">
        <MiniviewTabs class="mb-14"/>
        <transition :css="false" @before-enter="beforeEnter" @enter="enter">
          <router-view></router-view>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
import api from '@/api';
import anime from "animejs";
import { mapActions, mapGetters } from 'vuex';
import DetailsCard from "@/components/miniviews/individual/DetailsCard";
import MiniviewsNavigation from "@/components/miniviews/individual/MiniviewsNavigation";
import MiniviewTabs from "@/components/miniviews/individual/MiniviewTabs";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "MiniviewDetail",

  components: { MiniviewTabs, DetailsCard, MiniviewsNavigation, ConfirmDialog },

  computed: {
    ...mapGetters({
      miniview: 'miniviews/current',
    }),
  },

  created() {
    this.fetchMiniview()
  },

  mounted() {
    window.scrollTo({top: 0, behaviour: 'smooth'})
  },
  methods: {
    ...mapActions({
      getMiniview: 'miniviews/get'
    }),

    fetchMiniview() {
      this.getMiniview(this.$route.params.miniviewId);
    },

    async deleteMiniview(callback) {
      try {
        this.loading = true;
        const { data } = await api.delete(`/miniviews/${this.miniview.uuid}`);
        if (data.success) {
          callback.call();
          this.$router.push({ name: 'Miniviews' })
        }
      } catch (error) {
       console.log(error); 
      } finally {
        this.loading = false;
      }
    },

    beforeEnter(el) {
      el.style.transform = 'translateX(30rem)';
    },
    
    enter(el, done) {
      anime({
        targets: el,
        translateX: 0,
        duration: 200,
        easing: 'cubicBezier(0.230, 1.000, 0.320, 1.000)',
        complete: done
      });
    },
  }
}
</script>
