<template>
  <div>
    <label v-if="withTitle" class="register-label capitalize">{{title}}s</label>
    <p v-if="items.length == 0" class="h-12 text-sm text-gray-500">
      No {{title}}s added.
    </p>
    <div v-for="(item, index) in items" :key="`interval-${index}`">
      <ValidationProvider :rules="shouldValidate" :name="title" :vid="items[index]" v-slot="{ errors }">
        <div class="flex items-end mb-2">
          <div class="w-full mr-3">
            <input type="text" class="guestio-form-input" placeholder="Type or paste URL..." v-model="items[index]">
          </div>
          <div class="w-1/12 flex justify-end my-auto">
            <button class="rounded-full bg-gray-50 flex justify items-center p-2 hover:bg-softGray" @click.prevent="removeItem(index)">
              <CrossIcon/>
            </button>
          </div>
        </div>
        <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
      </ValidationProvider>
    </div>
    <button type="button" class="text-pink-500 underline text-sm" @click="addItem">Add {{title}} +</button>
  </div>
</template>

<script>
import CrossIcon from "@/components/svgs/CrossIcon";
export default {
  name: 'TextInputMultiple',
  components: {CrossIcon},
  props: {
    items: { type: Array, required: false, default: () => [] },
    withTitle: { type: Boolean, required: false, default: true } ,
    title: { type: String, required: false, default: 'link'} ,
    shouldValidate: { type: String, required: false, default: ''} ,
  },
  methods: {
    addItem() {
      this.items.push('')
    },
    removeItem(index) {
      this.items.splice(index, 1)
    },
  },
}
</script>
