<template>
  <div style="background-color: rgba(181, 3, 208, .1)" class="rounded-xl border border-pink-500 py-8 px-5 text-sm lg:text-base">
    <p class="mb-5"><b>Have questions?</b> We have the answers.
      Check out the FAQs.</p>
    <p><b>Have any issues with your order?</b> Visit the Resolution Center.</p>
  </div>
</template>

<script>
  export default {
    name: "Questions"
  }
</script>

<style scoped>

</style>
