<template>
  <transition
    appear
    enter-active-class="transition ease-out duration-1000"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class=""
    leave-class="opacity-100"
    leave-to-class="opacity-0"
    mode="out-in"
  >
    <div class="mt-10 mb-14">
      <ValidationObserver ref="itemForm" v-slot="{ handleSubmit }">
        <form method="post" @submit.prevent="handleSubmit(save)">
          <div class="grid grid-cols-1 sm:grid-cols-2 xl:col-gap-56 lg:col-gap-40">
            <div class="grid grid-cols-1 row-gap-8">
              <div>
                <label for="name" class="register-label">Name</label>
                <ValidationProvider rules="required|max:255" name="Name" vid="name" v-slot="{ errors }">
                  <TextInput v-model="form.name" :errors="errors"/>
                </ValidationProvider>
              </div>

              <div>
                <label for="domain" class="register-label">Domain</label>
                <ValidationProvider rules="max:255" name="Domain" vid="domain" v-slot="{ errors }">
                  <TextInput v-model="form.domain" :errors="errors"/>
                  <button type="button" @click="getEmails" class="text-violet flex items-center text-sm">
                    Get contact info
                    <RightArrow v-if="!emailWorking" class="ml-3"/>
                    <loading-icon v-else class="ml-3 h-5 w-5"/>
                  </button>
                </ValidationProvider>
              </div>

              <div>
                <TextInputMultiple :items="form.emails" shouldValidate="email" title="email"/>
              </div>

              <div>
                <label for="phone" class="register-label">Phone</label>
                <ValidationProvider rules="max:255" name="Phone" vid="phone" v-slot="{ errors }">
                  <TextInput v-model="form.phone" :errors="errors"/>
                </ValidationProvider>
              </div>

              <div v-if="!item">
                <label class="register-label">Notes</label>
                <ValidationProvider name="Notes" vid="notes" v-slot="{ errors }">
                  <textarea v-model="note"
                            rows="6"
                            class="guestio-form-textarea resize-none"
                            :class="{'border-red-500': errors.length}"/>
                  <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <div class="flex flex-col md:flex-row space-x-0 md:space-x-4  md:space-y-0 space-y-4 items-center justify-start">
                <button type="submit" class="h-12 btn disabled:opacity-50 flex items-center" :disabled="working">
                  <loading-icon v-if="working" class="h-2" />
                  <span class=" text-center" v-else>{{item ? 'Update' : 'Add'}} {{type}}</span>
                </button>
                <button type="button" @click="cancel" class="text-pink-500 hover:text-pink-700 font-bold">Cancel</button>
              </div>
            </div>

            <div v-if="emails.length">
              <div class="rounded-3xl border shadow-2xl p-6">
                <p class="font-bold mb-2">{{emails.length}} email addresses found for <span class="text-violet">{{form.domain}}</span></p>
                <p class="text-gray-500 text-sm mb-3">Tap on the ones you would like to add</p>
                <div
                  v-for="(item, index) in emails" :key="index"
                  @click="selectEmail(item)"
                  class="p-3 my-1 font-semibold hover:bg-violet hover:opacity-75 hover:text-white rounded-lg cursor-pointer flex justify-between"
                  :class="[{'bg-violet' : form.emails.includes(item)}, form.emails.includes(item) ? 'text-white' : 'text-blue-800']"
                >
                  {{item}}
                  <CrossIcon v-if="form.emails.includes(item)" class="w-5" color="white"/>
                </div>
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </transition>
</template>

<script>
import api from '@/api'
import moment from 'moment'
import {mapState, mapMutations} from "vuex";
import TextInput from "@/components/shared/TextInput";
import RightArrow from "@/components/svgs/RightArrow";
import TextInputMultiple from "@/components/shared/TextInputMultiple";
import CrossIcon from "@/components/svgs/CrossIcon";

export default {
  name: 'ItemForm',
  components: {TextInput, RightArrow, TextInputMultiple, CrossIcon},
  props: {
    type: { type: String, default: 'guest'}
  },

  data() {
    return {
      working: false,
      emailWorking: false,
      form: {
        emails: []
      },
      emails: [],
      note: ''
    }
  },

  mounted() {
    this.form = {
      type: this.type,
      emails: []
    }
    if (this.item) {
      this.form = this.item;
      if (!this.form.emails) {
        this.form.emails = [];
      }
    }
  },

  methods: {
    ...mapMutations({
      setItem: "dashboard/smartlist/setItem",
    }),

    cancel() {
      this.$emit('cancel');
    },

    getEmails() {
      if (this.form.domain) {
        this.emailWorking = true
        api
          .get(`/email-addresses/${this.form.domain}`)
          .then(({ data }) => {
            this.emails = []
            for (let index in data.data) {
              this.emails.push(data.data[index])
            }
          })
          .catch(() => {
            this.$toast.error('Something went wrong.', {
              position: 'bottom',
            });
          })
          .finally(() => this.emailWorking = false);
      }
    },

    selectEmail(email) {
      if (this.form.emails.includes(email)) {
        const index = this.form.emails.findIndex(e => e == email)
        this.form.emails.splice(index, 1)
      } else {
        this.form.emails.push(email)
      }
    },

    save() {
      if (this.note) {
        this.form.notes = [{
          text: this.note,
          date: moment().format()
        }]

      }
      if (this.item) {
        this.updateItem();
      } else {
        this.createItem();
      }
    },

    createItem() {
      this.working = true
      const item = Object.assign({}, this.form);
      return api.post(`/users/${this.user.id}/smartlist`, item)
        .then(() => {
          this.$emit('updated')
        })
        .catch(error => {
          console.log(error)
          return Promise.reject(error)
        })
        .finally(() => this.working = false)
    },

    updateItem() {
      this.working = true
      const item = Object.assign({}, this.form);
      return api.put(`/users/${this.user.id}/smartlist/${this.form.id}`, item)
        .then(() => {
          this.$emit('updated')
        })
        .catch(error => {
          console.log(error)
          return Promise.reject(error)
        })
        .finally(() => this.working = false)

    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      item: state => state.dashboard.smartlist.item,
    }),
  },

  watch: {
    item(item) {
      this.form = item
      if (!this.form.emails) {
        this.form.emails = [];
      }
    },
  },

  destroyed() {
    this.setItem(null)
  },
}
</script>
