<template>
  <modal @click.prevent class="sm:ml-8 flex items-center text-pink-500" ref="modal">
    <template #default>
      <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1" />
      </svg>
      <span class="ml-2 font-light">Connect</span>
    </template>
    <template #content>
      <div class="max-w-xl w-full bg-white rounded-xl overflow-hidden py-6 px-6 md:py-12 md:px-16" @click.stop>
        <div class="">
          <div class="flex items-center justify-center space-x-6">
            <div class="rounded-full w-20 h-20 sm:w-24 sm:h-24 bg-gray-50 p-4">
              <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 150 150"><path fill-rule="evenodd" clip-rule="evenodd" d="M114.068 57.29c0 20.594-17.06 37.289-38.103 37.289-21.044 0-38.103-16.695-38.103-37.29 0-19.629 15.498-35.716 35.172-37.18V20h41.034v37.29zm-25.687.418c0-6.942-5.75-12.57-12.843-12.57-7.094 0-12.844 5.628-12.844 12.57s5.75 12.57 12.844 12.57c7.093 0 12.843-5.628 12.843-12.57z" fill="url(#paint0_linear)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M47.724 100.757c-2.965-3.72-8.447-4.41-12.28-1.534-3.854 2.892-4.583 8.294-1.629 12.065l6.979-5.235c-6.979 5.235-6.976 5.239-6.973 5.242l.005.007.012.015.026.034.067.083a26.417 26.417 0 00.775.906 38.55 38.55 0 002.045 2.105c1.748 1.674 4.3 3.838 7.666 5.987C51.182 124.75 61.252 129 74.5 129c13.248 0 23.318-4.25 30.083-8.568 3.366-2.149 5.918-4.313 7.666-5.987a38.426 38.426 0 002.044-2.105c.244-.273.44-.501.589-.679.074-.088.136-.165.187-.227l.067-.083.026-.034.012-.015.006-.007c.002-.003.005-.007-6.973-5.242l6.978 5.235c2.954-3.771 2.225-9.173-1.629-12.064-3.833-2.877-9.315-2.186-12.28 1.533a20.92 20.92 0 01-1.325 1.385c-1.06 1.016-2.722 2.436-4.974 3.874-4.47 2.853-11.254 5.774-20.477 5.774s-16.007-2.921-20.477-5.774c-2.252-1.438-3.913-2.858-4.974-3.874a20.85 20.85 0 01-1.115-1.144 8.774 8.774 0 01-.21-.241z" fill="url(#paint1_linear)"/><defs><linearGradient id="paint0_linear" x1="64.412" y1="-4.222" x2="142.924" y2="58.272" gradientUnits="userSpaceOnUse"><stop stop-color="#6203D0"/><stop offset="1" stop-color="#DC3CF6"/></linearGradient><linearGradient id="paint1_linear" x1="64.412" y1="-4.222" x2="142.924" y2="58.272" gradientUnits="userSpaceOnUse"><stop stop-color="#6203D0"/><stop offset="1" stop-color="#DC3CF6"/></linearGradient></defs></svg>
            </div>
            <div>
              <svg class="h-6 w-6 text-pink-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 7h12m0 0l-4-4m4 4l-4 4m0 6H4m0 0l4 4m-4-4l4-4" />
              </svg>
            </div>
            <div class="rounded-full w-20 h-20 sm:w-24 sm:h-24 bg-gray-50 p-6">
              <svg class="text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.676 35.676"><path d="M32.295 26.202c-.096.271-.189.562-.296.848-.886 2.399-2.231 4.529-3.863 6.481-.315.38-.682.724-1.061 1.043-.749.634-1.611 1.017-2.608 1.052a5.229 5.229 0 01-2.161-.394c-.502-.205-.996-.434-1.505-.619-1.652-.6-3.295-.521-4.92.121-.586.232-1.164.488-1.761.689-.692.232-1.41.326-2.141.188-.638-.119-1.196-.416-1.714-.799-.643-.476-1.183-1.056-1.688-1.67-2.391-2.916-3.996-6.213-4.771-9.906-.334-1.588-.494-3.189-.396-4.812.115-1.946.567-3.799 1.607-5.469 1.305-2.099 3.146-3.474 5.568-4.041 1.457-.343 2.874-.203 4.263.332.731.28 1.464.557 2.198.832a2.763 2.763 0 002.026-.005c.749-.286 1.499-.571 2.251-.85.771-.281 1.555-.511 2.373-.576a8.913 8.913 0 013.58.449c1.647.551 2.987 1.526 3.999 2.946l.077.113c-2.552 1.779-4.005 4.129-3.794 7.311.213 3.184 1.927 5.338 4.737 6.736zM17.98 8.253a5.448 5.448 0 002.174-.402c3.179-1.262 4.841-4.625 4.791-7.197-.004-.207-.018-.414-.027-.654-.327.049-.625.072-.911.144-2.321.569-4.107 1.864-5.281 3.961-.687 1.228-1.069 2.532-.952 3.957.008.151.063.189.206.191z" fill="currentColor"/></svg>
            </div>
          </div>
          <div class="mt-4 text-center max-w-sm w-full mx-auto">
            <span class="text-sm text-gray-500">Guestio needs to authenticate with iCloud to connect to your calendar.</span>
          </div>
        </div>
        <div class="mt-12">
          <ValidationObserver ref="icloudForm" v-slot="{ handleSubmit }">
            <form method="post" @submit.prevent="handleSubmit(connect)">
              <ValidationProvider mode="lazy" rules="required|email|max:255" vid="email" name="iCloud Email" v-slot="{ errors }">
                <div>
                  <div class="rounded-full shadow-sm">
                    <label for="icloud_email" class="register-label">iCloud Email</label>
                    <input name="icloud_email" id="icloud_email" type="email" class="guestio-form-input" v-model="form.credentials.email" placeholder="iCloud Email" />
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>

              <ValidationProvider mode="lazy" rules="required|max:255" vid="password" name="iCloud Password" v-slot="{ errors }">
                <div class="mt-6">
                  <div class="rounded-full shadow-sm">
                    <div class="flex items-center justify-between">
                      <label for="icloud_password" class="register-label">iCloud App Password</label>
                    </div>
                    <input name="icloud_password" id="icloud_password" type="password" class="guestio-form-input" v-model="form.credentials.password" placeholder="iCloud Email" />
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                  <div class="rounded-md bg-blue-100 p-4 mt-4">
                    <div class="flex">
                      <div class="flex-shrink-0">
                        <!-- Heroicon name: information-circle -->
                        <svg class="h-5 w-5 text-blue-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                        </svg>
                      </div>
                      <div class="ml-3 flex-1">
                        <p class="text-sm leading-5 text-blue-700">
                          To connect your iCloud calendar to Guestio, you must enable two-factor authentication and <span class="font-bold">create an app-specific password</span>. Your default iCloud account password will not work.
                        </p>
                        <p class="mt-1 font-light">
                          <a href="https://appleid.apple.com/account/manage" target="_blank" rel="noopener" class="text-sm text-pink-500">
                            Create iCloud App password
                          </a>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="">

                  </div>
                </div>
              </ValidationProvider>

              <div class="mt-12 flex flex-col items-center">
                <button type="submit" class="btn disabled:opacity-50" :disabled="working">Connect</button>

                <template v-if="! working">
                  <button @click.prevent="closeModal()" type="button" class="mt-4 flex items-center text-pink-500 hover:text-pink-600">Cancel</button>
                </template>

                <template v-else>
                  <span class="mt-4 text-blue-500">Connecting...</span>
                </template>

                <template v-if="connectionError">
                  <span class="mt-4 text-red-600">{{ connectionError }}</span>
                </template>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import api from '@/api'
  import Modal from '@/components/shared/Modal'
  export default {
    components: {
      Modal,
    },

    data() {
      return {
        working: false,
        connectionError: null,
        form: {
          name: 'iCloud Calendar',
          slug: 'icloud-calendar',
          credentials: {
            email: '',
            password: '',
          }
        }
      }
    },

    methods: {
      closeModal() {
        this.form.credentials.email = ''
        this.form.credentials.password = ''
        this.connectionError = null

        this.$refs.modal.close()
      },

      async connect() {
        this.working = true
        this.connectionError = null

        const { data } = await api.post('/integrations', {
          ...this.form,
          account: this.form.credentials.email,
        })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
              this.$refs.icloudForm.setErrors(error.response.data.errors)
            }
            this.working = false
            this.connectionError = 'iCloud connection error'
          })

        api.get(`/integrations/${data.data.id}/check`)
          .then(() => {
            this.closeModal()
            this.$emit('refresh')
          })
          .catch(() => {
            this.connectionError = 'iCloud connection error'
          })
          .finally(() => {
            this.working = false
          })
      }
    }
  }
</script>
