<template>
  <div v-on-clickaway="close" class="relative inline-block text-left z-50">
    <div class="cursor-pointer" @click="toggle">
      <slot name="button"></slot>
    </div>
    <transition @enter="enter">
      <slot v-if="isOpen" name="content" v-bind:close="close"></slot>
    </transition>
  </div>

</template>

<script>
import anime from "animejs";
import {mixin as clickaway} from 'vue-clickaway';

export default {
  name: "Dropdown",
  mixins: [clickaway],
  props: {
    initStatus: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.isOpen = this.initStatus
  },
  data() {
    return {
      isOpen: null
    }
  },
  methods: {
    enter(el) {
      anime({
        targets: el,
        translateY: [0, 10],
        duration: 800
      })
    },
    toggle() {
      this.isOpen = !this.isOpen;
      this.$emit('isOpen', this.isOpen);
    },
    close() {
      this.isOpen = false;
    }
  }
}
</script>

<style scoped>

</style>
