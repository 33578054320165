<template>
  <div class="w-full lg:max-w-lg">
    <div class="flex flex-col md:flex-row">
      <template v-if="activity.properties.sender_id == user.id">
        You sent a pitch.
      </template>
      <template v-else>
        {{ activity.properties.sender_first_name }} sent a pitch.
      </template>
    </div>
    <div v-if="activity.properties.message" class="mt-2 mb-3">
      <div class="rounded-b-lg bg-white shadow-lg py-6 px-6">
        <div v-html="activity.properties.message" class="whitespace-pre-wrap" v-linkified:options="{ className: 'hover:text-violet underline' }" />
      </div>
    </div>
    <span class="text-gray-400 italic">{{ date }}</span>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'AudioPitch',

    props: {
      activity: Object,
      user: Object,
      booking: Object,
      userTimezone: {
        type: String,
        default: ''
      }
    },

    computed: {
      date() {
        return `${moment(this.activity.created_at).format('MMM D, h:mm A')}${this.userTimezone !== '' ? ` (${this.userTimezone})` : ''}`
      }
    }
  }
</script>
