<template>
  <div v-if="profile.user_id !== user.id">
    <template v-if="authenticated && profile.option_prices.length > 0">
      <router-link
        class="bg-indigo-gradiant text-white rounded-full h-12 font-bold px-8 uppercase text-normal hover:opacity-90 transition-opacity duration-150 ease-in-out flex items-center"
        :class="[classes, padding, fontSize, {'bg-indigo-gradiant': profile.color == null}]"
        :style="backgroundColor"
        :to="{ name: routeName, params: { showId: profile.id } }"
      >
        <span class="uppercase">{{ text }}</span>
      </router-link>
    </template>
    <template v-else-if="authenticated">
      <BookingAvailabilityModal
        class="bg-indigo-gradiant text-white rounded-full h-14 font-bold px-8 uppercase text-normal hover:opacity-90 transition-opacity duration-150 ease-in-out flex items-center"
        :class="[classes, padding, fontSize, {'bg-indigo-gradiant': profile.color == null}]"
        :style="backgroundColor"
        :profileId="this.profile.id"
        profileType="show"
      >
        <span class="uppercase">{{ text }}</span>
        <template #content="{ close }">
          <div class="max-w-4xl w-full sm:gap-16 bg-white rounded-xl overflow-hidden text-center" >
            <h1 class="m-10 text-2xl">
              This show does not currently have booking options available. We'll send them a message and let them know you're interested, and we'll notify you when their availability opens up!
            </h1>

            <div class="mb-6 flex items-center justify-center">
              <button
                @click="close"
                type="button"
                class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-8 py-2.5"
              >
                OK
              </button>
            </div>
          </div>
        </template>
      </BookingAvailabilityModal>
    </template>
    <template v-else>
      <modal
        class="bg-indigo-gradiant text-white rounded-full h-14 font-bold px-8 uppercase text-normal hover:opacity-90 transition-opacity duration-150 ease-in-out"
        :class="[classes, padding, fontSize, {'bg-indigo-gradiant': profile.color == null}]"
        :style="backgroundColor"
        key="loginModal"
      >
        <span class="uppercase">{{ text }}</span>
        <div slot="content">
          <LoginModal :redirect="redirectAfter"/>
        </div>
      </modal>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Modal from "@/components/shared/Modal"
import BookingAvailabilityModal from "@/components/shared/BookingAvailabilityModal";
import LoginModal from "@/components/shared/LoginModal"

export default {
  name: "RequestToBookButton",
  components: {Modal,LoginModal, BookingAvailabilityModal},
  props: {
    profile: Object,
    showPrice: {type: Boolean, required: false, default: false},
    classes: {
      type: String,
      required: false,
      default: "text-center font-semibold lg:mb-0 min-w-48 lg:w-auto"
    },
    padding: {
      type: String,
      required: false,
      default: "px-8 py-2.5"
    },
    fontSize: {
      type: String,
      required: false,
      default: "text-base"
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authenticated: state => state.auth.authenticated
    }),

    redirectAfter() {
      return `/shows/${this.profile.id}/book`;
    },

    text() {
      return `Request To Book`;
    },

    backgroundColor() {
      return this.profile.color ? `background-color: ${this.profile.color}` : ''
    },

    routeName() {
      return 'ShowBookingOptions';
    }
  },
};
</script>
