import api from '@/api'

const tags = {
  namespaced: true,

  state: {
    allGuestTags: {},
    allShowTags: {},
    popularShowTags: {},
    popularGuestTags: {},
  },

  getters: {
    allGuestTags: state => state.allGuestTags,
    allShowTags: state => state.allShowTags,
    popularShowTags: state => state.popularShowTags,
    popularGuestTags: state => state.popularGuestTags,
  },

  actions: {
    fetchGuestTags({ commit }) {
      return api.get(`/tags/guests`)
        .then(({ data }) => {
          commit('setGuestTags', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchShowTags({ commit }) {
      return api.get(`/tags/shows`)
        .then(({ data }) => {
          commit('setShowTags', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchPopularTags({ commit }) {
      return api.get(`/tags/popular`)
        .then(({ data }) => {
          commit('setPopularTags', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setGuestTags(state, data) {
      state.allGuestTags = data;
    },

    setShowTags(state, data) {
      state.allShowTags = data;
    },

    setPopularTags(state, data) {
      state.popularShowTags = data.popular_show_tags;
      state.popularGuestTags = data.popular_guest_tags;
    },
  },
}

export default tags
