import axios from 'axios';
import Vue from '@/main'

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
  // baseURL:"http://localhost:8000/api/v1",
  withCredentials: true,
});

instance.interceptors.response.use((response) => {
  // const { message } = response.data;
  // if (message) {
  //   Vue.$toast.success(message, {
  //     duration: 5000,
  //   });
  // }

  return response
}, function (error) {
  // const { message } = error.response.data;
  // Vue.$toast.error(message, {
  //   duration: 5000,
  // });

  if (error.response.status == 401) {
    Vue.$store.commit('auth/logout')
    Vue.$router.push({name: 'Home'})
  }

  if (error.response.status == 403) {
    Vue.$router.push({name: 'Forbidden'})
  }

  return Promise.reject(error);
});

export default instance;
