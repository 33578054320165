import Vue from 'vue'
import api from '@/api'

const filters = {
  namespaced: true,

  state: {
    activeFilters: {},
    activeCategories: [],
    activePrices: [],
    activeTags: [],
    activeRating: '',
    activeBookingOptions: [],

    bookings: {
      activeStatuses: [],
      activeAmounts: [0, 1000],
    },

    query: {},
    showing: 'guests',

    bookingOptions: [],
  },

  getters: {
    activeFilters: state => state.activeFilters,
    activeCategories: state => state.activeCategories,
    activePrices: state => state.activePrices,
    activeStatuses: state => state.bookings.activeStatuses,
    activeAmounts: state => state.bookings.activeAmounts,
    activeBookingOptions: state => state.activeBookingOptions,
  },

  mutations: {
    addActiveFilter(state, data) {
      state.activeFilters = {
        ...state.activeFilters,
        ...data
      }
    },

    setActivePrices(state, data) {
      state.activePrices = data.map(range => range);
    },

    setActiveBookingOptions(state, data) {
      state.activeBookingOptions = data
    },

    toggleActivePriceRange(state, data) {
      const priceRange = data == '401' ? '401-99999' : data
      const index = state.activePrices.findIndex(range => range == priceRange);
      if (index >= 0) {
        state.activePrices.splice(index, 1);
      } else {
        state.activePrices.push(priceRange);
      }

      if (state.activePrices.length) {
        state.query.prices = state.activePrices.join()
      } else {
        delete state.query.prices
      }
    },

    toggleActiveCategory(state, data) {
      const index = state.activeCategories.findIndex(cat => cat.id == data.id);
      if (index >= 0) {
        state.activeCategories.splice(index, 1);
      } else {
        state.activeCategories.push(data);
      }

      if (state.activeCategories.length) {
        state.query.categories = state.activeCategories.map(cat => cat.id).join()
      } else {
        delete state.query.categories
      }
    },

    toggleActiveBookingOptions(state, slug) {
      const index = state.activeBookingOptions.findIndex(option => option == slug);
      if (index >= 0) {
        state.activeBookingOptions.splice(index, 1);
      } else {
        state.activeBookingOptions.push(slug);
      }

      if (state.activeBookingOptions.length) {
        state.query['options'] = state.activeBookingOptions.join()
      } else {
        delete state.query.options
      }
    },

    setActiveAmounts(state, data) {
      state.bookings.activeAmounts = data;
    },

    setActiveStatuses(state, data) {
      state.bookings.activeStatuses = data;
    },

    setSortBy(state, sortBy) {
      Vue.set(state.query, 'sortBy', sortBy)
    },

    setRating(state, rating) {
      state.query.rating = rating
      state.activeRating = rating
    },

    setShowing(state, showing) {
      state.query.showing = showing
      state.showing = showing
    },

    setPriceRange(state, prices) {
      state.query.prices = prices;
    },

    setBookingOptions(state, options) {
      state.query.options = options;
    },

    setSearch(state, search) {
      state.query.search = search;
    },

    removeSearch(state) {
      delete state.query.search;
      delete state.activeFilters.search;
    },

    setCategories(state, categories) {
      state.query.categories = categories;
    },

    setTags(state, tags) {
      state.query.tags = tags;
    },

    setFeatured(state, value) {
      state.query.featured = value;
    },

    setIsGuestioTalent(state, value) {
      state.query.isGuestioTalent = value;
    },

    setPremium(state, value) {
      state.query.premium = value;
    },

    toggleTag(state, tag) {
      let tags = []
      if (state.query.tags) {
        tags = state.query.tags.split(',');
      }
      const index = tags.findIndex(t => t == tag);
      if (index >= 0) {
        tags.splice(index, 1);
        delete state.activeFilters[`tag-${tag}`]
      } else {
        tags.push(tag);
      }
      if (tags.length) {
        state.query.tags = tags.join()
      } else {
        delete state.query.tags
      }
      state.activeTags = tags
    },

    removeTag(state, tag) {
      let tags = []
      if (state.query.tags) {
        tags = state.query.tags.split(',');
      }
      tags.splice(tags.findIndex(t => t == tag), 1);
      state.query.tags = tags.join();
      delete state.activeFilters[`tag-${tag}`]
    },

    clearActiveFilters(state) {
      state.query = {}
      state.activeFilters = {}
      state.activeCategories = []
      state.activePrices = []
      state.activeTags = []
      state.activeRating = ''
      state.activeBookingOptions = []
      state.showing = 'guests'
    },

    SET_BOOKING_OPTIONS(state, options) {
      state.bookingOptions = options;
    },

    CLEAR_BOOKING_OPTIONS(state) {
      state.bookingOptions = [];
    },
  },

  actions: {
    async fetchBookingOptions({ commit, state }) {
      if (state.bookingOptions.length) {
        return Promise.resolve(state.bookingOptions);
      }

      try {
        const { data } = await api.get('/booking-options');

        commit('SET_BOOKING_OPTIONS', data.data);

        return Promise.resolve(data.data);
      } catch {
        //
      }
    }
  }
}

export default filters
