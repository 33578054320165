<template>
  <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-10">
    <Stats title="Confirmed Bookings" :amount="stats.confirmed"/>
    <Stats title="Unconfirmed Bookings" :amount="stats.pending"/>
    <Stats title="Canceled Bookings" :amount="stats.completed"/>
    <!-- TODO: Why are completed bookings labeled as canceled? -->
  </div>
</template>

<script>
  import Stats from '@/components/shared/Stats'
  export default {
    name: "BookingStats",
    components: { Stats },
    props: {
      stats: Object,
    },
  }
</script>
