<template>
  <div class="flex flex-col justify-center items-center">
    <template v-if="activity.properties.scheduling_link">
      <span>
        <template v-if="(sharedBy === 'invitee' && userIsInvitee) || (sharedBy === 'initiator' && userIsInitiator)">
          You've shared a scheduling link:
        </template>

        <template v-else>
          <em>{{ sharedBy === 'invitee' ? initiator.name : invitee.name }}</em> shared a scheduling link:
        </template>
      </span>

      <div class="mt-4 bg-guestio-gray-100 rounded-lg border-2 border-pink-500 px-6 py-4 w-full">
        <a :href="activity.properties.scheduling_link" class="text-sm font-semibold text-purple-50 hover:underline" target="_blank" rel="noopener">
          {{ activity.properties.scheduling_link }}
        </a>
      </div>

      <div class="mt-4">
        <p class="text-sm">
          If you scheduled a time for your inverview using the scheduling link, please add it to the booking.
        </p>
        <modal class="mt-2 bg-purple-50 rounded-full text-white text-sm font-bold px-5 py-2">
          <span>
            Schedule Date/Time
          </span>
          <template v-slot:content="{ close }">
            <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden self-start my-4">
              <calendar @close="close" :single="true" @save="changeBookingDate($event, close)" />
            </div>
          </template>
        </modal>
      </div>
    </template>

    <template v-else>
      <template v-if="(proposedBy === 'invitee' && userIsInvitee) || (proposedBy === 'initiator' && userIsInitiator)">
        <div v-if="!bookingHasProposedDates">
          <span class=" text-sm text-grayDark">
            You need to choose 1-3 dates:
          </span>
        </div>

        <div class="mt-4 flex justify-center items-center w-full">
          <modal class="text-sm w-full" :class="bookingHasProposedDates ? 'text-violet' : 'bg-indigo-gradiant rounded-full text-white font-bold px-5 py-4'">
            {{bookingHasProposedDates ? 'Re-Schedule Booking' : 'Choose'}} dates
            <template v-slot:content="{ close }">
              <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden self-start my-4">
                <Calendar @close="close" @save="updateBooking($event, close)" />
              </div>
            </template>
          </modal>
        </div>
      </template>

      <template v-else>
        <div v-if="bookingHasProposedDates" class="flex flex-col justify-center items-center">
          <span class=" text-sm text-grayDark">
            {{ bookingHasProposedDates > 1 ? 'Choose one from the following:' : 'Confirm proposed date:'}}
          </span>
          <ConfirmBooking @close="reRenderComponent" :proposed-dates="activity.properties.dates"/>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import Modal from '@/components/shared/Modal';
  import Calendar from '@/components/bookings/individual/Calendar';
  import ConfirmBooking from '@/components/bookings/individual/activity/ConfirmBooking';

  export default {
    props: {
      activity: Object,
    },

    components: {
      Modal,
      Calendar,
      ConfirmBooking,
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },
      userOwnsBookingShow() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
      },
      userOwnsBookingGuest() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingGuest']
      },
      userIsInvitee() {
        return this.$store.getters['dashboard/bookings/userIsInvitee']
      },
      userIsInitiator() {
        return this.$store.getters['dashboard/bookings/userIsInitiator']
      },
      invitee() {
        return this.$store.getters['dashboard/bookings/invitee']
      },
      initiator() {
        return this.$store.getters['dashboard/bookings/initiator']
      },
      bookingHasProposedDates() {
        return this.$store.getters['dashboard/bookings/bookingHasProposedDates']
      },
      proposedBy() {
        return this.activity.properties?.proposed_by || 'invitee';
      },
      sharedBy() {
        return this.activity.properties?.shared_by || 'invitee';
      },
    },

    methods: {
      updateBooking(dates, callback) {
        this.loading = true

        this.$store.dispatch('dashboard/bookings/setBookingProposedDates', {
          id: this.booking.id,
          proposed_dates: dates.map(slot => slot.clone().utc().format('YYYY-MM-DD HH:mm:ss')),
          proposed_by: this.userIsInvitee ? 'invitee' : 'initiator',
        })
        .then(() => {
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
          callback.call()
          window.Event.$emit('refreshActivity')
          window.Event.$emit('refreshBooking')
        })
        .catch(() => {
          this.loading = false
          this.slotsError = false
        })
      },

      changeBookingDate(date, callback) {
        this.$store.dispatch('dashboard/bookings/changeBookingDate', {
          id: this.$route.params.bookingId,
          confirmed_date: date.toISOString(),
        }).then(() => {
          callback.call()
          window.Event.$emit('refreshActivity')
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
        })
      },
      reRenderComponent(){
      window.Event.$emit('refreshActivity')
      window.Event.$emit('refreshBooking')
    }
    }
  }
</script>
