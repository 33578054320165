<template>
  <div class="dashboard-card">
    <h3 class="flex">
      New Messages
      <span v-if="messages.length" class="bg-red-500 rounded-full px-3 py-1 text-white text-xs ml-3 m-auto">
        {{ messages.length }}
      </span>
    </h3>
    <!-- Messages-->
    <p class="text-lg mb-10 font-light" v-if="!messages.length">You don't have new messages</p>
    <div class="flex mb-8" v-for="(message, index) in messages" :key="index">
      <template v-if="user.imageUrl">
        <img v-if="!isVideo" class="w-10 h-10 rounded-full flex-shrink-0 mr-4" :src="user.imageUrl" alt="User image" />
        <video v-else class="w-10 h-10 rounded-full flex-shrink-0 mr-4" :src="user.imageUrl" alt="User image"></video>
      </template>
      <template v-else>
        <DefaultProfileImage class="w-10 h-10" />
      </template>
      <div class="border-b border-gray-300 w-full overflow-hidden break-words break-all">
        <div>
          <span class="font-semibold mb-1 block capitalize">{{ message.sender.name }}</span>
          <p class="text-sm mb-4">{{ message.body }}</p>
        </div>
        <div class="flex justify-between items-center uppercase font-semibold text-sm mb-6">
          <span class="text-pink-500">{{ messageSenderType(message) }}</span>
          <span class="text-gray-500">{{ messageTime(message) }}</span>
        </div>
      </div>
    </div>
    <router-link :to="{ name: 'GeneralMessages' }"
      class="flex justify-center rounded-full bg-guestio-gray-100 w-full py-2">
      View All Messages
    </router-link>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from 'moment'
import DefaultProfileImage from "@/components/svgs/DefaultProfileImage";

export default {
  name: "MessagesCards",
  components: { DefaultProfileImage },

  created() {
    this.$store.dispatch('user/fetchUnreadMessages', this.user.id)
  },

  methods: {
    messageTime(message) {
      const today = moment();
      const daysDiff = today.diff(message.created_at, 'days');
      if (daysDiff < 7) {
        if (daysDiff == 0) {
          return moment(message.created_at).format('h:mm A')
        }
        return moment(message.created_at).fromNow();
      } else {
        return moment(message.created_at).format('MMM D, YYYY')
      }
    },

    messageSenderType(message) {
      return message.sender.type == 'show' ? 'Show' : 'Guest'
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      messages: state => state.user.unreadMessages,
    }),
    isVideo() {
      const videoTypes = ['mp4', 'ogg', 'mov', 'qt']

      let extension = this.user.imageUrl.split(".").pop();
      return videoTypes.includes(extension);
    },
  }
}
</script>

<style scoped></style>
