import * as api from '@/api/auth'

const auth = {
  namespaced: true,

  state: {
    authenticated: localStorage.getItem('authenticated') || false,

    user: {},

    adminUser: {},

    accountType: null,
  },

  getters: {
    authenticated: state => state.authenticated,

    getUser: state => state.user,

    getAdminUser: state => state.adminUser,

    profileType: state => state.user.type || null
  },

  actions: {
    login({ commit }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.login(payload).then(({ data }) => {
          if (data.admin_user) {
            commit('setAdminUser', data.admin_user)
          }
          if (data.user) {
            commit('setUser', data.user)
            // commit('guest/setGuest', data.guest)
            commit('setAuthenticated', true)
            return Promise.resolve(true)
          }
          return Promise.resolve(false)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },

    socialLogin({ commit }, user) {
      commit('setUser', user)
      commit('setAuthenticated', true)

      return Promise.resolve()
    },

    register({ commit }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.register(payload).then(({ data }) => {
          if (data.user) {
            commit('setUser', data.user)
            commit('setAuthenticated', true)
            return Promise.resolve(true)
          }

          return Promise.resolve(false)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },

    logout({commit}) {
      return api.logout().then(() => {
        commit('logout')
        return Promise.resolve(true)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    getUser({commit, state}) {
      if (! state.authenticated) {
        return Promise.resolve(false)
      }

      return api.getUser().then(({ data }) => {
        if (data.admin_user) {
          commit('setAdminUser', data.admin_user)
        }

        commit('setUser', data.data)

        // if (data.data.guest) {
        //   commit('guest/setGuest', data.data.guest, { root: true })
        // }

        if (data.data.shows && data.data.shows.length) {
          commit('shows/setShows', data.data.shows, { root: true })
        }

        return Promise.resolve(data.data)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    updateUser({commit, state}, payload) {
      return api.updateUser(state.user.id, payload).then(({ data }) => {
        if (data.admin_user) {
          commit('setAdminUser', data.admin_user)
        }

        commit('setUser', data.data)
        return Promise.resolve(true)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    updateAdminUser({commit, state}, payload) {
      return api
        .updateUser(state.adminUser.id, payload)
        .then(({ data }) => {
          commit('setAdminUser', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    requestResetPassword({ commit }, payload) {
      return api.requestResetPassword(payload).then(({ data }) => {
        if (data.success) {
          console.log(commit);
          return Promise.resolve(true)
        }
        return Promise.resolve(false)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    async resetPassword({ commit }, payload) {
      await api.setCSRFCookie();

      return api.resetPassword(payload).then(({ data }) => {
        if (data.success) {
          console.log(commit);
          return Promise.resolve(true)
        }
        return Promise.resolve(false)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    impersonate({ commit }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.impersonate(payload).then(({ data }) => {
          if (data.admin_user) {
            commit('setAdminUser', data.admin_user)
          }

          if (data.user) {
            commit('setUser', data.user)
            // commit('setGuest', data.data.user.guest)
            commit('setAuthenticated', true)
            return Promise.resolve(true)
          }
          return Promise.resolve(false)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },

    verifyEmail({ commit }, payload) {
      return api.setCSRFCookie().then(() => {
        return api.verifyEmail(payload).then(({ data }) => {
          if (data.success) {
            commit('emailVerified', true)
            return Promise.resolve(true)
          }
          return Promise.resolve(false)
        }).catch(error => {
          return Promise.reject(error)
        })
      })
    },

    storePaymentMethod(context, payload) {
      return api.storePaymentMethod(payload)
        .then(() => {
          return Promise.resolve(true)
        }).catch(error => {
          return Promise.reject(error)
        })
    },

    deleteMedia(context, payload) {
      return api.deleteMedia(payload)
        .then(() => {
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    acceptTerms({ commit }) {
      return api.acceptTerms()
        .then(({ data }) => {
          commit('setUser', data.data.user)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    startImpersonation(context, teamId) {
      return api.impersonateTeam(teamId)
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    // eslint-disable-next-line
    stopImpersonation(context) {
      return api.stopTeamImpersonation()
        .then(({ data }) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
  },

  mutations: {
    setAuthenticated(state, payload) {
      localStorage.setItem('authenticated', payload)
      state.authenticated = payload
    },

    setUser(state, data) {
      state.user = data
    },

    setAdminUser(state, data) {
      state.adminUser = data
    },

    setAccountType(state, type) {
      state.accountType = type
    },

    logout(state) {
      localStorage.removeItem('authenticated')
      state.authenticated = false
      state.user = {}
      state.adminUser = {}
    },
  },
}

export default auth
