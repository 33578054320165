<template>
  <span v-if="working">
    <loading-icon class="h-5 w-5 mr-4" />
  </span>
  <span v-else class="flex flex-row w-full justify-between">
    <button type="button" class="cancel" @click.prevent="$emit('cancel')">
      Cancel
    </button>
    <button type="submit" class="bg-indigo-gradiant text-white h-14 rounded-full md:px-20 px-10 text-lg font-semibold disabled:opacity-75">Save</button>
  </span>
</template>

<script>
export default {
  name: "SaveOrCancel",
  props: {
    working: { type: Boolean, required: false, default: false},
  },
};
</script>

<style scoped>
  .cancel {
    color: #B503D0;
    font-weight: 600;
    margin-right: 60px;
  }
</style>