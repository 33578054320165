<template>
  <div class="dashboard-card">
    <div>
      <h3>
        {{ user.teams && user.teams.length ? 'Your Profiles' : 'Your Profile' }}
      </h3>
      <div class="flex mb-6">
        <template v-if="user.imageUrl">
          <img v-if="!mediaIsVideo(user.imageUrl)"
            class="w-10 h-10 rounded-full flex-shrink-0 mr-4"
            :src="user.imageUrl"
            alt="User image"
          />
          <video v-else
            loop="loop"
            muted="true"
            autoplay="true"
            class="object-cover w-10 h-10 rounded-full flex-shrink-0 mr-4"
            :src="user.imageUrl"
            alt="User image"
          ></video>
        </template>
        <template v-else>
          <DefaultProfileImage class="w-10 h-10" />
        </template>
        <div>
          <h4 class="font-bold capitalize">
            {{ user.first_name }} {{ user.last_name }}
          </h4>
          <span class="text-pink-500 text-sm">{{ user.title }}</span>
        </div>
      </div>
      <router-link :to="{ name: 'Settings' }"
        ><button class="rounded-full bg-guestio-gray-100 w-full py-2">
          Edit Profile
        </button></router-link
      >
    </div>

    <TeamSection />
  </div>
</template>

<script>
import DefaultProfileImage from "@/components/svgs/DefaultProfileImage"
import TeamSection from '@/components/dashboard/TeamSection'

export default {
  name: "AccountCard",
  components: {
    DefaultProfileImage,
    TeamSection,
  },

  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
    isVideo() {
      const videoTypes = ['mp4', 'ogg', 'mov', 'qt']

      let extension = this.user.imageUrl.split(".").pop();
      return videoTypes.includes(extension);
    },
  },

  methods: {
    mediaIsVideo(url) {
      const videoTypes = ['mp4', 'ogg', 'mov', 'qt']
      const extension = url.split('.').pop();

      return videoTypes.includes(extension);
    },
  }
};
</script>

<style scoped></style>
