<template>
  <div class="flex flex-col md:flex-row items-center mt-8">
    <div class="flex items-center w-full" :class="{'opacity-75': sending}">

      <!--Input-->
      <div id="bodyWrapper" class="rounded-xl round px-6 py-4 bg-gray-50 flex items-center flex-grow" :class="`${sendingError ? 'bg-red-100' : ''}`">
        <textarea
          type="text"
          class="bg-transparent w-10 flex-grow text-sm"
          ref="message"
          style="resize: none; overflow:hidden; word-wrap: break-word; overflow-wrap: break-word;"
          :maxlength="maxChars"
          :placeholder="placeholderText"
          :value="body"
          @keydown.enter="sendMessage"  
          @input="onBodyInputInput"
        ></textarea>
        <span class="text-gray-500 ml-2 block flex-shrink-0 text-xs">{{ charCount }}/{{ maxChars }}</span>
      </div>

      <!--Button-->
      <button v-if="shouldShowSendButton" @click="sendMessage" type="button" class="bg-indigo-gradiant p-4 rounded-full flex items-center justify-center ml-4 hover:opacity-90">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.2673 8.42992C15.424 8.23071 15.4041 7.94515 15.2208 7.76985C15.0389 7.59454 14.7533 7.58524 14.5594 7.74993L5.71941 15.2711L1.05121 13.4648C0.636817 13.3068 0.352611 12.9203 0.324731 12.4767C0.295512 12.0344 0.527935 11.6147 0.919731 11.4049L20.2997 0.548727C20.6092 0.381383 20.9864 0.398648 21.2799 0.592557C21.5747 0.787792 21.7368 1.12911 21.7036 1.47975L20.0036 19.2009C19.969 19.5688 19.7618 19.8995 19.4471 20.0921C19.131 20.2846 18.7419 20.3178 18.3992 20.1797L12.7175 17.9803L9.15018 21.3219C8.8819 21.5729 8.48878 21.6406 8.15143 21.4932C7.81409 21.3445 7.5976 21.0098 7.60026 20.6419V18.0175L15.2673 8.42992Z"
            fill="white"/>
        </svg>
      </button>
    </div>

    <div v-if="! shouldShowSendButton" class="flex items-center mt-4 md:mt-0 space-x-4 md:ml-4">
      <template v-if="userIsPro">
        <button @click="startVideoPitch" type="button" class="icon-cont bg-indigo-gradiant" :disabled="!isValidBrowserForRecording">
          <VideoIcon class="h-6 w-6 text-white" v-tooltip="isValidBrowserForRecording ? null : 'Recording is not supported on this browser. Try with a different browser'" />
        </button>
        <button @click="startAudioPitch" type="button" class="icon-cont bg-indigo-gradiant" :disabled="!isValidBrowserForRecording">
          <MicIcon class="h-6 w-6 text-white" v-tooltip="isValidBrowserForRecording ? null : 'Recording is not supported on this browser. Try with a different browser'" />
        </button>
      </template>

      <template v-else>
        <modal
          :with-close-button="false"
          class="bg-indigo-gradiant p-4 rounded-full flex items-center justify-center ml-2 lg:ml-4 hover:opacity-90"
        >
          <VideoIcon class="h-6 w-6 text-white" />
          <template v-slot:content="{ close }">
            <ProFeatureModal @close="close">
              To record videos and audio messages, you need a PRO subscription.
            </ProFeatureModal>
          </template>
        </modal>

        <modal
          :with-close-button="false"
          class="bg-indigo-gradiant p-4 rounded-full flex items-center justify-center ml-2 lg:ml-4 hover:opacity-90"
        >
          <MicIcon class="h-6 w-6 text-white" />
          <template v-slot:content="{ close }">
            <ProFeatureModal @close="close">
              To record videos and audio messages, you need a PRO subscription.
            </ProFeatureModal>
          </template>
        </modal>
      </template>
    </div>
  </div>
</template>
<script>
  import Bowser from 'bowser'
  import VideoIcon from '@/components/svgs/VideoIcon'
  import MicIcon from '@/components/svgs/MicIcon'
  import Modal from '@/components/shared/Modal'
  import ProFeatureModal from '@/components/shared/ProFeatureModal'

  export default {
    name: 'SendInput',

    components: {
      Modal,
      MicIcon,
      VideoIcon,
      ProFeatureModal,
    },

    data() {
      return {
        sending: false,
        sendingError: false,
        maxChars: 300,
        body: '',
        videoPitchStarted: false,
        audioPitchStarted: false,
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      userIsPro() {
        return this.user.account_type == 'pro'
      },

      charCount() {
        return this.body.length
      },

      phoneNumber() {
        if (! this.booking) {
          return ''
        }

        let number = this.booking.vonage_msisdn || ''

        if (number.length < 11) {
          return ''
        }

        return number.substr(1, 10).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')
      },

      placeholderText() {
        if (this.booking && this.booking.vonage_msisdn) {
          return 'Type a Message or send an SMS to ' + this.phoneNumber
        }

        return 'Type a Message...'
      },

      shouldShowSendButton() {
        return this.body.length || this.videoPitchStarted || this.audioPitchStarted
      },

      isValidBrowserForRecording() {
        const browser = Bowser.getParser(window.navigator.userAgent);

        const validBrowser = browser.satisfies({
          mobile: {
            chrome: '>=88',
            samsung_internet: '>=13'
          },

          desktop: {
            chrome: '>=86',
            firefox: '>=84',
            edge: '>=79',
            opera: '>=71'
          }
        })

        return validBrowser
      }
    },

    // watch: {
    //   body(oldValue, newValue) {
    //     if(newValue !== '') {
    //       localStorage.setItem('booking_chat_input_value', newValue);
    //     }
    //   }
    // },

    methods: {
      onBodyInputInput(element) {
        this.body = element.target.value;
        localStorage.setItem('booking_chat_input_value', this.body);

        element.target.style.height = '22px';
        element.target.style.height = element.target.scrollHeight + 'px';

        const cardViewport = document.getElementById('dashboard-activity-card');
        cardViewport.scroll({ top: cardViewport.scrollHeight, behavior: 'auto' });
      },

      sendMessage() {
        if (this.charCount == 0) {
          this.$refs.message.focus()
          return
        }

        this.sending = true
        this.sendingError = false
        this.$store.dispatch('dashboard/bookings/sendMessage', {
          body: this.body,
          id: this.$route.params.bookingId
        }).then(() => {
          this.body = ''
          localStorage.setItem('booking_chat_input_value', '')

          setTimeout(() => {
            const textarea = this.$refs.message;

            textarea.style.height = '22px';
            textarea.style.height = textarea.scrollHeight + 'px';

            const cardViewport = document.getElementById('dashboard-activity-card');
            cardViewport.scroll({ top: cardViewport.scrollHeight, behavior: 'smooth' });
          }, 500);

          this.$emit('sent')
        }).catch(() => {
          this.sendingError = true
        }).finally(() => this.sending = false)
      },

      startVideoPitch() {
        this.videoPitchStarted = true
        window.Event.$emit('start-video-pitch')
      },

      startAudioPitch() {
        this.audioPitchStarted = true
        window.Event.$emit('start-audio-pitch')
      },
    },

    mounted() {
      const bookingChatInputValue = localStorage.getItem('booking_chat_input_value');
      if(bookingChatInputValue && bookingChatInputValue !== '') {
        this.body = bookingChatInputValue;
      }

      setTimeout(() => {
        const textarea = this.$refs.message;

        if(this.body !== '') {
          textarea.style.height = '22px';
          textarea.style.height = textarea.scrollHeight + 'px';
        } else {
          textarea.style.height = '22px';
        }
      }, 500)
    }
  }
</script>

<style scoped>
.icon-cont {
@apply p-4 rounded-full flex items-center justify-center hover:opacity-90 disabled:opacity-40;
}
</style>