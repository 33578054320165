<template>
  <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
    <div class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
      <div class="flex justify-between items-center">
        <h3 class="font-bold font-poppins text-blue-800">
          Managed Account
        </h3>
      </div>

      <div class="text-sm text-blue-800 font-medium mt-6">
        <slot />
      </div>

      <div class="flex items-center justify-center mt-8">
        <loading-icon class="h-2" />
      </div>
    </div>
  </div>
</template>

<script>
  // import XIcon from '@/components/svgs/XIcon'

  export default {
    name: 'ProFeatureModal',

    components: {
      // XIcon,
    },

    methods: {
      // showProSubscription() {
      //   this.$router.push({name: 'Settings', hash: '#pro-subscription'})
      // }
    }
  }
</script>
