<template>
  <div class="w-full bg-white px-5 py-6 flex items-center justify-between rounded-t-xl" v-if="booking">
    <div class="flex items-center">
      <img class="rounded-full h-12 w-12 mr-4 object-cover" :src="senderImage()" alt="">
      <h2 class="font-bold text-xl">{{messageFrom.name | truncate(45)}}</h2>
    </div>

    <router-link class="text-pink-500 flex items-center" :to="`/bookings/${booking.id}`">
      <span class="mr-1">Go to Booking</span>
      <RightArrow/>
    </router-link>
  </div>
</template>
<script>
import RightArrow from "@/components/svgs/RightArrow"

export default {
  name: 'ConversationHeader',
  components: {RightArrow},
  props:{
    user: Object,
    booking: Object,
  },

  computed: {
    messageFrom() {
      if (this.user.guest && this.user.guest.id == this.booking.guest.id) {
        return this.booking.show;
      }
      return this.booking.guest;
    },
  },

  methods: {
    senderImage() {
      const defaultImage = require('@/assets/images/test_image.png');
      return this.messageFrom.imageUrl ? this.messageFrom.imageUrl : defaultImage
    },
  },
}
</script>