<template>
  <div class="flex flex-col md:flex-row">
    <div class="w-64 flex-shrink-0">
      <label class="text-gray-600" for="email">Payment Methods</label>
    </div>
    <div class="md:ml-8 flex-1 mt-1 md:mt-0 flex flex-col">
      <div class="flex flex-col w-full" :class="paymentMethods.length ? 'mb-2 order-3' : 'items-center order-1'">
        <div v-if="!paymentMethods.length" class="h-12 w-full mb-2 flex justify-center items-center text-sm text-gray-500 bg-gray-50 rounded-lg border-2 border-dashed opacity-75">
          No payment methods were saved.
        </div>

        <div class="mt-4">
          <modal
            class="text-pink-500 underline"
          >
            <span>+ Add payment method</span>
            <template v-slot:content="{ close }">
              <PaymentMethodModal :user="userProp" @saved="saved(close)"/>
            </template>
          </modal>
        </div>
      </div>
      <div v-if="paymentMethods.length" class="flex flex-col order-2 space-y-2">
        <UseExistingPaymentMethodButton
          v-for="(paymentMethod, index) in paymentMethods"
          :key="`payment-methods-${index}`"
          :paymentMethod="paymentMethod"
        >
          <template #button>
            <template v-if="userProp.account_type === 'pro' && paymentMethods.length == 1">
              <modal class="text-pink-500 underline">
                <span>Update</span>
                <template v-slot:content="{ close }">
                  <PaymentMethodModal :user="userProp" @saved="saved(close)"/>
                </template>
              </modal>
            </template>

            <div v-else :class="{'flex flex-col items-end': !paymentMethod.default}">
              <ConfirmDialog
                @confirm="deletePaymentMethod(index, $event)"
                confirm-action="Yes, Delete"
                dialog-title="Delete Payment Method?"
                dialog-text="Are you sure you want to delete this payment method?"
                class="text-pink-500 underline"
              >
                Delete
              </ConfirmDialog>
              <button v-if="!paymentMethod.default" class="text-pink-500 underline" @click="setPaymentMethodAsDefault(index)">
                <template v-if="updatingDefaultPM">
                  <loading-icon class="w-6 py-2 text-pink-500"/>
                </template>
                <span v-else>Set as default</span>
                </button>
            </div>
          </template>
        </UseExistingPaymentMethodButton>
      </div>

    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import ConfirmDialog from '@/components/shared/ConfirmDialog'
  import PaymentMethodModal from './PaymentMethodModal'
  import Modal from '@/components/shared/Modal'
  import UseExistingPaymentMethodButton from '@/components/payment-methods/UseExistingPaymentMethodButton';

  export default {
    props: {
      userProp: Object,
    },

    components: {
      ConfirmDialog,
      PaymentMethodModal,
      Modal,
      UseExistingPaymentMethodButton,
    },

    data() {
      return {
        paymentMethods: [],
        updatingDefaultPM: false,
      }
    },

    methods: {
      deletePaymentMethod(index, callback) {
        api
          .delete(`/user/payment/methods/${this.paymentMethods[index].id}`)
          .catch(() => {
            this.$toast.error('The payment method was not deleted.')
          })
          .finally(() => {
            callback.call()
            this.fetchPaymentMethods();
          })
      },

      setPaymentMethodAsDefault(index) {
        this.updatingDefaultPM = true;
        api
          .post(`/user/payment/methods/${this.paymentMethods[index].id}/default`)
          .catch(() => {
            this.$toast.error('There was an error. Try again later.')
          })
          .finally(async () => {
            await this.fetchPaymentMethods();
            this.updatingDefaultPM = false;
          })
      },

      async fetchPaymentMethods() {
        await api
          .get('/user/payment/methods')
          .then(({ data }) => {
            this.paymentMethods = data
          })
      },

      saved(callback) {
        callback.call()

        this.fetchPaymentMethods()
      },
    },

    mounted() {
      this.fetchPaymentMethods()
    }
  }
</script>
