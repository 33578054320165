<template>
  <div>
    <div class="flex flex-col sm:flex-row items-start sm:items-center justify-between" id="smartlist-title">
      <div class="flex mb-4 sm:mb-0">
        <button
          @click.prevent="active = 'guest'"
          class="text-base sm:text-2xl lg:text-3xl transition duration-300 text-blue-800 text-center"
          :class="active === 'guest' ? 'font-bold text-blue-800' : null">
          Guest wishlist
        </button>
        <span class="text-base sm:text-2xl lg:text-3xl mx-4 text-gray-400 font-light"> / </span>
        <button
          @click.prevent="active = 'show'"
          class="text-base sm:text-2xl lg:text-3xl transition duration-300 text-blue-800 text-center"
          :class="active === 'show' ? 'font-bold text-blue-800' : null">
          Show wishlist
        </button>
      </div>
      <AddItemButton :type="active"/>
    </div>

    <ItemForm v-if="showForm" :type="active" @cancel="closeForm()" @updated="itemUpdated()"/>

    <div :class="ungroupedItems.length ? 'mb-14' : 'mb-4'">
      <draggable :list="ungroupedItems" group="smartlist" :move="checkMove" :component-data="getComponentData(null)" ghost-class="ghost" class="wishlist-container">
        <WishlistItem class="col-span-1" :item="item" v-for="(item, index) in ungroupedItems" :key="index" @updated="fetchData()"/>
      </draggable>
    </div>
    <div class="mt-4" v-if="noItems">
      <span class="text-pink-500">You don't have favorites yet</span>
    </div>

    <div v-for="(group, index) in activeSmartlistGroups" :key="index">
      <div class="flex justify-between">
        <EditGroupName :name="group.name" :id="group.id" @updated="fetchGroups()"/>
        <WishlistGroupDropdown :group="group" @updated="fetchGroups()"/>
      </div>
      <draggable :list="group.items" group="smartlist" :move="checkMove" :component-data="getComponentData(group)" ghost-class="ghost" class="wishlist-container">
        <WishlistItem class="col-span-1 cursor-pointer border-2 border-white" :item="item" v-for="(item, index) in group.items" :key="index" @updated="fetchData()"/>
      </draggable>
      <AddGroup :type="active" @groupCreated="fetchGroups()" :dontShow="index == activeSmartlistGroups.length -1"/>
    </div>

    <AddGroup :showOnHover="false" :type="groupType" @groupCreated="fetchGroups()"/>

  </div>
</template>

<script>
import api from '@/api'
import {mapState, mapMutations} from "vuex";
import draggable from 'vuedraggable'
import { isEmpty } from 'lodash'
import WishlistItem from "../components/wishlist/WishlistItem";
import WishlistGroupDropdown from "@/components/wishlist/partials/WishlistGroupDropdown";
import EditGroupName from "@/components/wishlist/partials/EditGroupName";
import AddGroup from "@/components/wishlist/partials/AddGroup";
import AddItemButton from "@/components/wishlist/partials/AddItemButton";
import ItemForm from "@/components/wishlist/ItemForm";

export default {
  name: "Wishlist",
  components: {
    WishlistItem,
    WishlistGroupDropdown,
    ItemForm,
    draggable,
    AddGroup,
    EditGroupName,
    AddItemButton,
  },

  methods: {
    ...mapMutations({
      setShowForm: "dashboard/smartlist/setShowForm",
      setPreviousPage: "dashboard/bookings/setPreviousPage",
    }),

    moveItemToGroup() {
      return api
              .put(`/users/${this.user.id}/smartlist/${this.moveItemForm.itemId}`, {group_id: this.moveItemForm.toGroupId})
              .finally(this.moveItemForm = {})
    },

    fetchData() {
      this.fetchUngrouped();
      this.fetchGroups();
    },

    fetchUngrouped() {
      this.$store.dispatch('dashboard/smartlist/fetchSmartlist', this.user.id)
    },

    fetchGroups() {
      this.$store.dispatch('dashboard/smartlist/fetchSmartlistGroups', this.user.id)
    },

    closeForm() {
      this.setShowForm(false);
    },

    checkMove(evt) {
      const group = evt.relatedContext.component.componentData.props.group
      this.moveItemForm.itemId = evt.draggedContext.element.id
      this.moveItemForm.toGroupId = group ? group.id : null
    },

    getComponentData(group) {
      return {
        props: {
          group: group
        }
      };
    },

    itemUpdated() {
      this.fetchUngrouped();
      this.setShowForm(false);
    },
  },

  computed: {
    ...mapState({
      authenticated: state => state.auth.authenticated,
      user: state => state.auth.user,
      smartlist: state => state.dashboard.smartlist.smartlist,
      smartlistGroups: state => state.dashboard.smartlist.smartlistGroups,
      showForm: state => state.dashboard.smartlist.showForm,
      group: state => state.dashboard.smartlist.group,
    }),

    ungroupedItems() {
      return this.smartlist.filter(item => item.group == null && item.type == this.active);
    },

    activeSmartlistGroups() {
      return this.smartlistGroups.filter(group => group.type == this.active);
    },

    noItems() {
      if (this.activeSmartlistGroups.length) {
        return this.ungroupedItems.length == 0 && this.activeSmartlistGroups.filter(group => group.items.length == 0).length > 0
      }
      return this.ungroupedItems.length == 0
    },

    groupType() {
      return this.active;
    }
  },

  data() {
    return {
      active: 'guest',
      moveItemForm: {}
    }
  },

  async mounted() {
    await this.$store.dispatch('auth/getUser')
    if (this.$route.params.tab == 'show') {
      this.active = 'show';
    }
    this.fetchData();
    this.setPreviousPage('wishlist');
  },

  watch: {
    active() {
      this.setShowForm(false);
    },

    'smartlistGroups': {
      handler: function () {
        if (!isEmpty(this.moveItemForm)) {
          this.moveItemToGroup()
        }
      },
      deep: true
    },
  },
}
</script>

<style scoped>
  .ghost {
    border: 2px dashed #B503D0;
    border-radius: 24px;
  }
  .wishlist-container {
    @apply mt-6 grid grid-cols-1 row-gap-8 md:grid-cols-2 md:col-gap-8 lg:grid-cols-3;
  }
</style>

