<template>
  <label
    :class="['form-input border-dashed border-2 border-gray-300 h-40 rounded-xl flex flex-col items-center justify-center text-gray-500 cursor-pointer hover-border transition-all duration-300 group', highlight ? 'border-indigo-500' : '']"
    @dragover.prevent="highlight=true"
    @drop.prevent="highlight=false"
    @dragleave="highlight=false"
  >
    <ImagePlusIcon class="group-hover:text-indigo-500 transition-all duration-300 w-12 h-12"/>
    <div v-show="!uploading" class="flex flex-col items-center mt-4">
      <label
        class="cursor-pointer text-indigo-500 font-semibold"
      >
        <input
          ref="upload"
          type="file"
          class="hidden"
          @change="change($event)"
        />
      </label>
      <span class="text-sm text-gray-500">PNG, JPG, GIF</span>
      <span class="text-sm text-gray-500">up to {{allowedSize}}</span>
      <span v-if="suggestedDimensions !== ''" class="text-sm text-gray-500">{{suggestedDimensions}}</span>
    </div>
    <div v-if="uploading" class="text-sm text-gray-700 mt-2">Uploading...</div>
    <div v-if="!uploading && uploadingError" class="text-sm text-red-500 mt-2">
      The image could not be uploaded
    </div>
  </label>
</template>

<script>
import ImagePlusIcon from '@/components/svgs/ImagePlusIcon';

export default {
  name: 'EmptyImageUpload',

  components: { ImagePlusIcon },

  props: {
    uploading: Boolean,
    uploadingError: Boolean,
    allowedSize: {type: String, required: false, default: '20MB'},
    suggestedDimensions: {type: String, required: false, default: ''}
  },

  data() {
    return {
      highlight: false,
    }
  },

  methods: {
    change(event) {
      this.$emit('change', event)
    }
  },
}
</script>

<style scoped>
  .hover-border:hover {
    border-color: #B503D0;
    border-style: dashed;
    border-width: 2px;
  }
</style>