<template>
  <svg
    v-if="isEmpty"
    width="16"
    height="4"
    viewBox="0 0 16 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8 0C9.10457 0 10 0.89543 10 2C10 3.10457 9.10457 4 8 4C6.89543 4 6 3.10457 6 2C6 0.89543 6.89543 0 8 0ZM2 0C3.10457 0 4 0.89543 4 2C4 3.10457 3.10457 4 2 4C0.89543 4 0 3.10457 0 2C0 0.89543 0.89543 0 2 0ZM14 0C15.1046 0 16 0.89543 16 2C16 3.10457 15.1046 4 14 4C12.8954 4 12 3.10457 12 2C12 0.89543 12.8954 0 14 0Z"
      :fill="fill"
    />
  </svg>
  <svg
    v-else
    width="26"
    height="8"
    viewBox="0 0 26 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13 1.75C14.2426 1.75 15.25 2.75736 15.25 4H16.75C16.75 1.92893 15.0711 0.25 13 0.25V1.75ZM15.25 4C15.25 5.24264 14.2426 6.25 13 6.25V7.75C15.0711 7.75 16.75 6.07107 16.75 4H15.25ZM13 6.25C11.7574 6.25 10.75 5.24264 10.75 4H9.25C9.25 6.07107 10.9289 7.75 13 7.75V6.25ZM10.75 4C10.75 2.75736 11.7574 1.75 13 1.75V0.25C10.9289 0.25 9.25 1.92893 9.25 4H10.75ZM4 1.75C5.24264 1.75 6.25 2.75736 6.25 4H7.75C7.75 1.92893 6.07107 0.25 4 0.25V1.75ZM6.25 4C6.25 5.24264 5.24264 6.25 4 6.25V7.75C6.07107 7.75 7.75 6.07107 7.75 4H6.25ZM4 6.25C2.75736 6.25 1.75 5.24264 1.75 4H0.25C0.25 6.07107 1.92893 7.75 4 7.75V6.25ZM1.75 4C1.75 2.75736 2.75736 1.75 4 1.75V0.25C1.92893 0.25 0.25 1.92893 0.25 4H1.75ZM22 1.75C23.2426 1.75 24.25 2.75736 24.25 4H25.75C25.75 1.92893 24.0711 0.25 22 0.25V1.75ZM24.25 4C24.25 5.24264 23.2426 6.25 22 6.25V7.75C24.0711 7.75 25.75 6.07107 25.75 4H24.25ZM22 6.25C20.7574 6.25 19.75 5.24264 19.75 4H18.25C18.25 6.07107 19.9289 7.75 22 7.75V6.25ZM19.75 4C19.75 2.75736 20.7574 1.75 22 1.75V0.25C19.9289 0.25 18.25 1.92893 18.25 4H19.75Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: "ActionsIcon",
  props: {
    isEmpty: {
      type: Boolean,
      default: true
    },
    fill: {
      type: String,
      default: "#817E9C"
    }
  }
};
</script>
