<template>
  <div class="flex flex-col mt-1" :class="own ? 'items-end ml-auto' : 'items-start ml-4 sm:ml-6'">
    <div
      v-if="messageContainsUrl && !hideMessage"
      class="flex"
    >
      <div class="flex items-center space-x-2 border border-violet rounded-xl px-2 py-1">
        <span class="text-sm">Are you trying to update the booking date?</span>
        <modal class="rounded-xl py-1 px-2 bg-gray-50 hover:bg-indigo-100">
            Yes
            <template v-slot:content="{ close }">
              <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden self-start my-4">
                <Calendar :single="true" @close="close" @save="updateBooking($event, close)" />
              </div>
            </template>
          </modal>
        <button @click="notTryingToUpdateDate" class="rounded-xl py-1 px-2 bg-gray-50 hover:bg-indigo-100">No</button>
      </div>
    </div>
  </div>
</template>
<script>

import api from '@/api';
import Modal from '@/components/shared/Modal';
import Calendar from '@/components/bookings/individual/Calendar';

export default {
  name: 'UpdateDateFromExternalLink',
  components: {Modal , Calendar },
  props: {
    own: { type: Boolean, default: false },
    message: Object,
    booking: Object,
  },

  data() {
    return {
      hideMessage: false,
    }
  },

  computed: {
    messageContainsUrl() {
      if (!this.message || this.message.contains_schedule_link != null) {
        return false;
      }
      return new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(this.message.body);
    },

    userIsInvitee() {
      return this.$store.getters['dashboard/bookings/userIsInvitee']
    },
  },

  methods: {
    notTryingToUpdateDate() {
      api.put(`/bookings/${this.booking.id}/messages/${this.message.id}`, {
        contains_schedule_link: false
      })
      .then(() => this.hideMessage = true)
      .catch(error => {
        console.error(error);
        this.$toast.error('Sorry. An error ocured.')
      })
    },

    async updateBooking(date, callback) {
      if (this.booking.status == 'scheduled') {
        await this.$store.dispatch('dashboard/bookings/changeBookingDate', {
          id: this.booking.id,
          confirmed_date: date.clone().utc().format('YYYY-MM-DD HH:mm:ss'),
        })
      } else {
        await this.$store.dispatch('dashboard/bookings/setBookingProposedDates', {
          id: this.booking.id,
          proposed_dates: [date.clone().utc().format('YYYY-MM-DD HH:mm:ss')],
          proposed_by: this.userIsInvitee ? 'invitee' : 'initiator',
        })
      }

      await this.$store.dispatch('dashboard/bookings/fetchBooking', {
        id: this.$route.params.bookingId,
      })
      await api.put(`/bookings/${this.booking.id}/messages/${this.message.id}`, {
        contains_schedule_link: true
      })
      this.hideMessage = true;
      callback.call()
      window.Event.$emit('refreshActivity')
      window.Event.$emit('refreshBooking')

    },
  },
}
</script>
