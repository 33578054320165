<template>
  <div class="rounded-xl bg-indigo-gradiant flex flex-col mb-12">
    <div class="py-5 px-8">
      <p class="text-white font-semibold text-lg mb-1.5 w-full lg:w-6/12 xl:w-full">Now you can send MINIVIEWs™ to guests without a Guestio account</p>
      <p class="text-white text-sm mb-5">Just record your questions and send them the link</p>
      <template v-if="user.account_type == 'standard'">
        <modal class="bg-white text-violet font-semibold px-10 py-2 rounded-full">
          <span>Start now</span>
          <template v-slot:content="{ close }">
            <SubscribeModal :user="user" :plans="plans" @subscribed="subscribed($event, close)" />
          </template>
        </modal>
      </template>
      <router-link v-else class="bg-white text-violet font-semibold px-10 py-2 rounded-full" :to="{ name: 'MiniviewCreate1' }" >
        Start now
      </router-link>
    </div>
    <div class="relative">
      <img src="/images/miniviews_people1.png" class="absolute hidden lg:block bottom-0 -mb-3 z-10" style="width: 170px; right: 240px">
      <img src="/images/miniviews_people2.png" class="absolute hidden lg:block bottom-0 -mb-3" style="width: 200px; right: 110px">
      <img src="/images/miniviews_people3.png" class="absolute hidden lg:block bottom-0 -mb-3" style="width: 200px; right: 0px">
    </div>
  </div>
</template>

<script>

import SubscribeModal from '@/components/settings/SubscribeModal'
import Modal from '@/components/shared/Modal'

export default {
  name: "MiniviewsBanner",
  components: { Modal, SubscribeModal },
  data() {
    return {
      plans: {
        monthly: process.env.VUE_APP_PRO_MONTHLY_PRICE || 97,
        yearly: process.env.VUE_APP_PRO_YEARLY_PRICE || 997,
      },
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },
  },

  methods: {
    subscribed(subscription, callback) {
      callback.call()
      this.$router.push({ name: 'MiniviewCreate1' })
    },
  },

}
</script>
