<template>
  <div
    class=" rounded-3xl bg-black w-full p-4 lg:p-0 lg:w-6/12 relative z-20 text-white grid grid-cols-1 lg:grid-cols-12 gap-y-10 shadow-xl">
    <!--Search input-->
    <div class="relative flex items-center ml-6 col-span-1 lg:col-span-7 py-5">
      <SearchIcon class="absolute left-0 top-0 bottom-0 h-full w-4" />
      <input type="text" class="bg-transparent pl-8 overflow-hidden w-full mr-1 font-light opacity-50"
        placeholder="Search by name" v-model="searchInput" @keyup.enter="search">
      <div class="border-r opacity-25 py-5"></div>
    </div>
    <!--Categories dropdown-->
    <div class="flex items-center lg:justify-center col-span-1 pl-0 lg:col-span-4">
      <div class="relative w-full">
        <button @click="open" class="flex justify-between items-center w-full px-5">
          <template v-if="!activeCategories.length">
            <span class="lg:mr-3 font-light opacity-50">Set categories</span>
          </template>
          <template v-else>
            <div>
              <span v-for="(cat, index) in activeCategories" :key="index" class="font-light">
                <span v-if="index < 2">{{ index > 0 ? `, ${cat.name}` : cat.name }}</span>
              </span>
              <span v-if="activeCategories.length > 2">(+{{ activeCategories.length - 2 }})</span>
            </div>
          </template>
          <ChevronLeftIcon class="transform -rotate-90" />
        </button>
        <transition @enter="enter">
          <div v-show="isOpen" class="lg:absolute lg:left-0 lg:top-0 flex lg:justify-center w-full">
            <Categories @filter-categories="updateQuery" :activeCategories="activeCategories" />
          </div>
        </transition>
      </div>
    </div>
    <!--Search button-->
    <button @click.prevent="search"
      class="flex justify-center items-center bg-indigo-gradiant rounded-full lg:rounded-l-none lg:rounded-r-3xl py-2 lg:py-0">
      <SearchIcon stroke-width="3" class="w-6 h-6 hidden lg:block" />
      <span class="font-bold lg:hidden">Search</span>
    </button>
  </div>
</template>
<script>
import SearchIcon from "@/components/svgs/SearchIcon";
import ChevronLeftIcon from "@/components/svgs/ChevronLeftIcon";
import Categories from '@/components/home/partials/Categories';
import anime from "animejs";
import isMobile from "is-mobile";

export default {
  name: 'Search',
  props: {
    query: Object
  },
  components: { ChevronLeftIcon, SearchIcon, Categories },
  data() {
    return {
      queryPush: {},
      activeCategories: [],
      isOpen: false,
      searchInput:null
    }
  },

  mounted() {
    //
  },

  methods: {
    open() {
      this.isOpen = !this.isOpen;
    },

    enter(el) {
      anime({
        targets: el,
        translateY: [isMobile() ? -10 : 20, isMobile() ? 0 : 45],
        duration: 800
      });
    },
    search() {
      this.queryPush.showing = this.query.showing
      this.queryPush.search = this.searchInput
      this.$router.push({ name: 'Browse', query: this.queryPush })
    },

    updateQuery(categories) {
      const catIds = categories.map(cat => cat.id);
      this.activeCategories = categories
      this.queryPush.categories = catIds.join()
    }
  },
}
</script>
