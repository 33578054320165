<template>
  <div v-show="notifications && notifications.length" class="space-y-6 mb-12 relative z-20">
    <div
      v-for="notification in notifications"
      :key="notification.id"
      class="bg-violet bg-opacity-5 px-6 py-4 rounded-3xl border border-violet flex flex-col md:flex-row md:justify-between md:items-center"
    >
      <template v-if="notification.data.type == 'confirm_completed_booking'">
        <div class="notification-details text-sm">
          You recently had an <router-link class="font-bold" :to="{name: 'Activity', params: {bookingId: notification.data.booking_id}}">interview</router-link> scheduled {{ notification.data.is_guest ? 'on' : 'with' }} <router-link class="font-bold text-violet" :to="notification.data.is_guest ? `/shows/${notification.data.show.id}` : `/guests/${notification.data.guest.id}`">{{ notification.data.is_guest ? notification.data.show.name : notification.data.guest.name }}</router-link>, please let us know about it.
        </div>
        <div class="notification-actions flex flex-col md:flex-row md:space-x-6 mt-4 md:mt-0">
          <button type="button" @click.prevent="confirmCompletedBooking(notification)" class="px-6 bg-indigo-gradiant whitespace-no-wrap rounded-full text-white py-2 font-semibold hover:opacity-75 w-full">
            It Happened!
          </button>
          
          <div class="w-full space-x-4 flex justify-between md:px-0 px-2">
            <button type="button" @click.prevent="rescheduleCompletedBooking(notification)" class="text-violet text-sm font-bold hover:opacity-50 uppercase">
              Reschedule
            </button>

            <button type="button" @click.prevent="cancelCompletedBooking(notification)" class="text-violet text-sm font-bold hover:opacity-50 uppercase">
              Cancel Booking
            </button>
          </div>
        </div>
      </template>
      <template v-else-if="notification.data.type == 'confirm_scheduled_booking'">
        <div class="notification-details text-sm">
          {{ notification.data.is_initiator ? 'Your ' : 'The ' }} <router-link class="font-bold" :to="{name: 'Activity', params: {bookingId: notification.data.booking_id}}">interview booking request</router-link> {{ notification.data.is_show ? 'with' : 'on' }} <router-link class="font-bold text-violet" :to="notification.data.is_show ? `/guests/${notification.data.guest.id}` : `/shows/${notification.data.show.id}`">{{ notification.data.is_show ? notification.data.guest.name : notification.data.show.name }}</router-link> doesn't have an interview date yet. Has your interview been scheduled?
        </div>
        <div class="notification-actions flex flex-col md:flex-row md:space-x-6 mt-4 md:mt-0">
          <button type="button" @click.prevent="confirmScheduledBooking(notification)" class="px-6 bg-indigo-gradiant whitespace-no-wrap rounded-full text-white py-2 font-semibold hover:opacity-75 w-full">
            Yes
          </button>
          
          <button type="button" @click.prevent="confirmNotScheduledBooking(notification)" class="text-violet text-sm font-bold hover:opacity-50 uppercase">
            No
          </button>
        </div>
      </template>
      <template v-else>
        <div class="notification-details text-sm">
          {{ notification.data.message }}
        </div>
        <div class="notification-actions">
          <button type="button" @click.prevent="dismissNotification(notification.id)" class="text-violet text-sm font-medium hover:opacity-50">
            Dismiss
          </button>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  export default {
    data() {
      return {
        interval: null,
      }
    },

    computed: {
      notifications() {
        return this.$store.getters['user/unreadNotifications']
      }
    },

    methods: {
      dismissNotification(notificationId) {
        this.$store.dispatch('user/readNotification', notificationId)
      },

      async confirmCompletedBooking(notification) {
        this.dismissNotification(notification.id)
        if (notification.type.includes('HasBookingHappenedReminder')) {
          await this.$store.dispatch("dashboard/bookings/confirmHappened", notification.data.booking_id);
        } else {
          this.$store.dispatch('dashboard/bookings/confirmCompletedBooking', {
            id: notification.data.booking_id
          })
        }
        this.$toast.success('Thanks for your feedback.')
      },

      rescheduleCompletedBooking(notification) {
        this.dismissNotification(notification.id)
        this.$store.dispatch('dashboard/bookings/rescheduleCompletedBooking', {
          id: notification.data.booking_id
        }).then(() => {
          this.$router.push({name: 'Activity', params: { bookingId: notification.data.booking_id }})
        })
        this.$toast.info('Booking marked for rescheduling.')
      },

      cancelCompletedBooking(notification) {
        this.dismissNotification(notification.id)
        this.$store.dispatch('dashboard/bookings/cancelCompletedBooking', {
          id: notification.data.booking_id
        }).then(() => {
          this.$router.push({name: 'Activity', params: { bookingId: notification.data.booking_id }})
        })
        this.$toast.info('Booking marked for cancellation.')
      },

      async confirmScheduledBooking(notification) {
        try {
          await this.$store.dispatch("dashboard/bookings/confirmScheduled", notification.data.booking_id);
          this.dismissNotification(notification.id)
          this.$toast.success('Thanks for your feedback.')
        } catch (error) {
          this.$toast.error('There was an error. Please try again later.')
        }
      },

      async confirmNotScheduledBooking(notification) {
        console.log('entra');
        await api.post(`/bookings/${notification.data.booking_id}/confirm-not-scheduled-yet`)
        this.dismissNotification(notification.id)
      },

      fetchNotifications() {
        this.$store.dispatch('user/fetchNotifications')
      }
    },

    created() {
      this.fetchNotifications();

      this.interval = setInterval(() => {
        this.fetchNotifications()
      }, 60000)
    },

    destroyed() {
      clearInterval(this.interval)
    }
  }
</script>
