import Vue from 'vue'
import Vuex from 'vuex'
import api from '@/api'

Vue.use(Vuex)

import * as modules from './modules'

export default new Vuex.Store({
  modules,
  state: {
    isOpen: false,
  },

  mutations: {
    SET_SIDEBAR(state, payload) {
      state.isOpen = payload.isOpen;
    }
  },

  actions: {
    fetchPage(context, payload) {
      return api.get(`/pages/${payload.slug}`)
        .then(( { data } ) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
  }
})
