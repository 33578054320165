<template>
  <div class="bg-gray-50 flex flex-col p-10 rounded-lg">
    <div v-if="profile" class="flex items-start mb-6">
      <img v-if="profile.imageUrl" :src="profile.imageUrl" :alt="profile.name" class="w-28 h-28 rounded-full object-cover flex-shrink-0 mr-4">
      <div class="flex flex-col w-full">
        <h2 class="text-2xl font-bold">{{profile.name}}</h2>
        <p class="text-pink-500 text-sm font-medium truncate mb-4">{{ profile.type == 'guest' ? title : profile.description }}</p>
        <div class="flex items-center space-x-3">
          <template v-if="profile.inquiries_only">
            <div class="flex justify-center -mt-3 relative z-10 mb-4">
              <InquireButton :profile="profile" :profileType="item.type" data-html2canvas-ignore />
            </div>
          </template>
          <template v-else>
            <BookNowButton v-if="item.type == 'guest'" :profile="profile" fontSize="text-sm"/>
            <div v-else class="flex">
              <RequestToBookButton :profile="profile" />
            </div>
            <PitchButton
              :profile="profile"
              border="border border-violet"
              :type="item.type"
            />
          </template>
        </div>
      </div>
    </div>
    <div v-else class="flex">
      <div class="rounded-full h-24 w-24 bg-white flex items-center justify-center uppercase text-5xl font-extrabold text-blue-800 text-opacity-25 mr-4">{{item.name.charAt(0)}}</div>
      <div class="flex flex-col items-start">
        <h2 class="text-2xl text-blue-800 font-bold">{{item.name}}</h2>
        <span v-if="item.domain" class="text-lg font-bold text-blue-800">{{item.domain}}</span>
        <p v-for="email in item.emails" :key="email" class="text-lg font-medium text-blue-800">{{email}}</p>
        <span v-if="item.phone" class="text-lg font-medium text-blue-800">{{item.phone}}</span>
      </div>
    </div>

    <hr class="w-full mt-8 mb-6">

    <div class="overflow-scroll max-h-60 mb-6">
      <div v-for="(note, index) in sortedNotes" :key="index" class="py-2">
        <span class="font-bold mr-2">{{date(note.date)}}</span><span class="italic text-gray-500">{{note.text}}</span>
      </div>
    </div>

    <div class="flex">
      <div class="bg-white rounded-3xl px-6 py-3 flex items-center w-full mr-4" :class="{'bg-red-100': error}">
        <textarea-autosize
          v-model="note"
          :min-height="25"
          :max-height="300"
          rows="1"
          style="min-height:25px"
          class="w-full bg-transparent"
          name="text"
          id="text"
          placeholder="Add a note"
          />
      </div>
      <div>
        <button
          @click="addNote"
          type="button"
          class="text-white p-4 flex justify-center items-center rounded-full"
          :disabled="disabled"
          :class="disabled ? 'bg-gray-700 cursor-not-allowed' : 'bg-indigo-gradiant'"
        >
          <PlusIcon class="flex-shrink-0 w-5 h-5"/>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import {mapState} from "vuex";
import api from '@/api'
import { orderBy } from 'lodash'
import PlusIcon from "@/components/svgs/PlusIcon";
import BookNowButton from "@/components/profile/BookNowButton";
import RequestToBookButton from '@/components/profile/RequestToBookButton';
import PitchButton from '@/components/profile/PitchButton';
import InquireButton from '@/components/profile/InquireButton';

export default {
  name: 'ShowItemModal',
  components: { PlusIcon, BookNowButton, RequestToBookButton, PitchButton, InquireButton },

  props: {
    item: Object
  },

  data() {
    return {
      note: '',
      error: false,
      sending: false,
      notes: []
    }
  },

  mounted() {
    if (this.item.notes) {
      this.notes = this.item.notes
    }
  },

  methods: {
    date(date) {
      if (date) {
        return moment(date).format('DD/MM')
      }
      return null
    },

    addNote() {
      this.sending = true
      this.error = false
      const newNote = {
        text: this.note,
        date: moment().format()
      }
      this.notes.push(newNote);
      let self = this;
      return api.put(`/users/${this.user.id}/smartlist/${this.item.id}`, {notes: this.notes})
        .then(() => {
          self.$emit('noteAdded')
          self.note = '';
        })
        .catch(() => {
          this.error = true
        })
        .finally(() => this.sending = false)
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),

    charCount() {
      return this.note.length
    },
    disabled() {
      return this.charCount == 0 || this.sending
    },

    sortedNotes() {
      return orderBy(
        this.item.notes,
        ({ date }) => date || moment().subtract(2, 'years').format(),
        ['desc']
      )
    },

    profile() {
      return this.item.profile
    },

    title() {
      return this.profile.title ? this.profile.title : this.profile.bio
    },
  },
}
</script>