<template>
    <div class="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle mx-10">
            <div class="bg-lightBlack px-4 pt-5 pb-4 sm:p-6 sm:pb-4 max-w-60">
                <div class="sm:flex sm:items-start">
                    <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <div class="w-full flex justify-between">
                            <h3 class="text-2xl leading-6 font-medium text-grayHome" id="modal-title">
                                Terms and conditions
                            </h3>
                            <div class="cursor-pointer" @click="$emit('hide')">
                                <CrossIcon color="#909090"/>
                            </div>
                        </div>
                        <div class="mt-2">
                            <p class="text-sm text-grayHome">
                                <TermsAndConditions :dark="true"/>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="w-full bg-black px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button class="w-full flex justify-center items-center bg-indigo-gradiant rounded-full py-2 text-white" @click="onSubmit"><span class="font-bold">Accept</span></button>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import TermsAndConditions from '@/views/TermsAndConditions';
import CrossIcon from '@/components/svgs/CrossIcon';
import { mapActions } from 'vuex';

export default {    
    components: {
        TermsAndConditions,
        CrossIcon
    },

    methods: {
        ...mapActions({
            acceptTerms: `auth/acceptTerms`
        }),

        onSubmit: async function() {
            await this.acceptTerms();
            this.$emit('hide');
        }
    }
}
</script>

<style scoped>
.max-w-60 {
    max-width: 60rem;
}
</style>