<template>
  <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.125 8.75C13.125 9.05942 12.9933 9.35616 12.7589 9.57496C12.5245 9.79375 12.2065 9.91667 11.875 9.91667H4.375L1.875 12.25V2.91667C1.875 2.60725 2.0067 2.3105 2.24112 2.09171C2.47554 1.87292 2.79348 1.75 3.125 1.75H11.875C12.2065 1.75 12.5245 1.87292 12.7589 2.09171C12.9933 2.3105 13.125 2.60725 13.125 2.91667V8.75Z"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "ChatIcon"
}
</script>

<style scoped>

</style>
