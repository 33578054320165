<template>
  <div>
    <template v-if="userIsInvitee">
      <span>
        You have chosen these possible dates, now let's wait for {{ userOwnsBookingShow ? booking.guest.name : booking.show.name }} to choose one
      </span>
      <div class="grid grid-cols-5 my-3 col-gap-4">
        <label v-for="date in booking.proposed_dates" :key="date" class="h-full rounded-xl bg-gray-50">
          <div class="flex flex-col p-5 rounded-xl items-center justify-center">
            <span class="font-roboto font-medium text-gray-500">{{$moment.utc(date).local().format('MMMM D')}}</span>
            <span class="font-bold font-roboto">{{$moment.utc(date).local().format('h:mm A')}}</span>
          </div>
        </label>
      </div>
    </template>

    <template v-else>

    </template>
  </div>
</template>

<script>
  export default {
    props: {
      activity: Object,
    },
    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },
      userOwnsBookingShow() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
      },
      userIsInvitee() {
        return this.$store.getters['dashboard/bookings/userIsInvitee']
      },
    }
  }
</script>
