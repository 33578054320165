<template>
  <svg width="61" height="72" viewBox="0 0 61 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="36.6724" cy="45.8026" r="19" transform="rotate(-17.8531 36.6724 45.8026)" fill="url(#paint0_linear)"/>
    <circle cx="7.55054" cy="17.3609" r="6" transform="rotate(-17.8531 7.55054 17.3609)" fill="#292546"/>
    <defs>
      <linearGradient id="paint0_linear" x1="21.267" y1="31.7925" x2="55.6365" y2="35.4373"
                      gradientUnits="userSpaceOnUse">
        <stop stop-color="#6203D0"/>
        <stop offset="1" stop-color="#DC3CF6"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "Bubble"
}
</script>

<style scoped>

</style>
