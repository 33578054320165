import api from '@/api'

const user = {
  namespaced: true,

  state: {
    messages: [],
    unreadMessages: [],
    notifications: [],
  },

  getters: {
    messages: state => state.messages,
    unreadNotifications: state => state.notifications.filter(notification => ! notification.read_at)
  },

  actions: {
    fetchUnreadMessages({ commit }, userId) {
      return api.get(`/users/${userId}/unread-messages`)
        .then(({ data }) => {
          commit('setUnreadMessages', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchAllMessages({ commit }, userId) {
      return api.get(`/users/${userId}/all-messages`)
        .then(({ data }) => {
          commit('setMessages', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    deleteMedia(context, payload) {
      return api.delete(`/users/${payload.model_id}/media/${payload.uuid}`)
        .then(() => {
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchNotifications({ commit }) {
      return api.get('notifications')
        .then(response => {
          commit('setNotifications', response.data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    readNotification({ commit }, notificationId) {
      commit('readNotification', notificationId)

      return api.patch(`notifications/${notificationId}`)
        .then(() => {
          return Promise.resolve(true)
        })
        .catch(error => {
          commit('unreadNotification', notificationId)
          return Promise.reject(error)
        })
    }
  },

  mutations: {
    setMessages(state, data) {
      state.messages = data;
    },
    setUnreadMessages(state, data) {
      state.unreadMessages = data;
    },
    setNotifications(state, data) {
      state.notifications = data
    },

    readNotification(state, id) {
      let index = state.notifications.findIndex(notification => notification.id == id)

      if (index === -1) {
        return
      }

      state.notifications[index].read_at = new Date
    },

    unreadNotification(state, id) {
      let index = state.notifications.findIndex(notification => notification.id == id)

      if (index === -1) {
        return
      }

      state.notifications[index].read_at = null
    }
  },
}

export default user
