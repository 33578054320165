<template>
  <div class="overflow-hidden relative pb-16/9 rounded-lg group">
    <div class="absolute inset-0 z-10 bg-gray-200">
      <div v-if="loading" class="w-full h-full flex justify-center items-center">
        <loading-icon class="h-2 text-pink-500"/>
      </div>
      <video
        ref="videoPlayer"
        class="w-full h-full object-cover"
        @play="playing = true"
        @pause="playing = false"
        @durationchange="fixDuration"
        @loadeddata="onLoad"
        playsinline
        looping="false"
      ></video>
    </div>

    <div class="group-hover:opacity-100 transition-opacity ease-in-out duration-300 bg-blue-800 bg-opacity-50 h-14 rounded-lg flex items-center px-6 space-x-5 absolute inset-x-0 bottom-0 z-20 mx-4 mb-4" :class="playing ? 'opacity-0' : 'opacity-100'">
      <div class="flex items-center">
        <button v-if="! playing" @click.prevent="play()" type="button" class="p-2 focus:outline-none rounded focus:shadow-outline">
          <PlayAltIcon class="h-4 w-4 text-white" />
        </button>
        <button v-else @click.prevent="stop()" type="button" class="p-2 focus:outline-none rounded focus:shadow-outline">
          <StopIcon class="h-4 w-4 text-white" />
        </button>
      </div>
      <div @click="seek" class="progress relative flex-1 h-2 bg-white rounded-full flex items-center">
        <div
          :style="{ width: this.playProgress + '%' }"
          class="absolute inset-y-0 rounded-full bg-pink-500 pointer-events-none">
        </div>
        <div
          :style="{ left: this.playProgress + '%' }"
          class="absolute w-5 h-5 rounded-full bg-white shadow -ml-2.5 pointer-events-none"></div>
      </div>
      <div class="text-sm font-poppins font-bold text-white whitespace-nowrap">
        {{ videoLength | time }}
      </div>
    </div>
  </div>
</template>

<script>
  import PlayAltIcon from '@/components/svgs/PlayAltIcon'
  import StopIcon from '@/components/svgs/StopIcon'

  export default {
    name: 'VideoPreview',

    props: {
      mediaSrc: String,
    },

    components: {
      PlayAltIcon,
      StopIcon,
    },

    data() {
      return {
        loading: true,
        playing: false,
        videoLength: 0,
        interval: null,
        currentSeconds: 0,
      }
    },

    watch: {
      playing(playing) {
        if (playing) {
          this.interval = setInterval(() => {
            this.currentSeconds = this.$refs.videoPlayer.currentTime
          }, 100)
        } else {
          if (this.interval) {
            clearInterval(this.interval)
          }
        }
      },

      mediaSrc(mediaSrc) {
        this.playing = false
        this.currentSeconds = 0
        this.videoLength = 0
        this.$refs.videoPlayer.src = mediaSrc
      }
    },

    computed: {
      playProgress() {
        return this.currentSeconds / this.videoLength * 100;
      },
    },

    filters: {
      time(value) {
        value = Math.round(value)

        if (value < 60) {
          let seconds = value.toString().padStart(2, '0')

          return `0:${seconds}`
        }

        let minutes = Math.floor(value / 60)
        let seconds = Number(value % 60).toString().padStart(2, '0')

        return `${minutes}:${seconds}`
      }
    },

    methods: {
      play() {
        this.$refs.videoPlayer.play()
      },

      stop() {
        this.$refs.videoPlayer.pause();
        this.$refs.videoPlayer.currentTime = 0
      },

      seek(e) {
        const el = e.target.getBoundingClientRect();
        const seekPos = (e.clientX - el.left) / el.width;

        this.currentSeconds = this.videoLength * seekPos;
        this.$refs.videoPlayer.currentTime = this.currentSeconds

        if (! this.playing) {
          this.$refs.videoPlayer.play()
        }
      },

      fixDuration(event) {
        if (event.target.duration != Infinity) {
          this.$refs.videoPlayer.currentTime = 0
          this.videoLength = event.target.duration
          return
        }

        this.$refs.videoPlayer.currentTime = 999999
      },

      onLoad() {
        this.loading = false;
      }
    },

    mounted() {
      this.$refs.videoPlayer.src = this.mediaSrc
    }
  }
</script>
