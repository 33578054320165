<template>
  <div  class="inline-block w-full px-5 bg-black py-3 overflow-y-scroll text-left">
    <div v-for="(category, index) in filledCategories" :key="index">
      <Checkbox
        v-if="index < 5 || showAllCategories"
        textColor="text-white"
        fontWeight="font-normal"
        hover="hover:text-violet"
        backgroundColor="bg-black"
        borderWidth="border"
        :value="category.id"
        :title="category.name"
        name="category"
        :checked="isItemChecked(category.id)"
        @on-toggle="checkActiveCategory"
      />
    </div>
    <button v-if="filledCategories.length > 5" class="text-sm text-violet" @click.stop="showAllCategories = !showAllCategories">
        {{ showAllCategories ? 'Show less' : `[+] ${filledCategories.length - 5} more` }}
      </button>
  </div>
</template>

<script>
import api from '@/api';
import Checkbox from '@/components/shared/Checkbox';
import { mapState} from "vuex";

export default {
  name: 'Categories',
  components: {Checkbox},

  props: {
    activeCategories: Array
  },

  data() {
    return {
      allCategories: [],
      showAllCategories: false,
      fullCategories: [],
    }
  },

  computed: {
    ...mapState({
      activeCategories: state => state.filters.activeCategories,
      query: state => state.filters.query,
      showing: state => state.filters.showing,
    }),

    filledCategories() {
      return this.allCategories.filter(category => {
        return category.counts[this.showing] > 0
      })
    }
  },

  mounted() {
    api.get(`/categories`).then(({data}) => {
      if (data.success) {
        this.allCategories = data.data
      }
    })
    api.get(`/categories?exclude=subcategories`).then(({data}) => {
      if (data.success) {
        this.fullCategories = data.data
      }
    })
  },

  methods: {
    toggleActiveCategory(category) {
      const index = this.activeCategories.findIndex(cat => cat.id == category.id);
      if (index >= 0) {
        this.activeCategories.splice(index, 1);
      } else {
        this.activeCategories.push(category);
      }
    },

    checkActiveCategory(catId) {
      const category = this.fullCategories.find(cat => cat.id == catId);
      this.toggleActiveCategory(category);
    },

    isItemChecked(id) {
      return this.activeCategories.findIndex(item => item.id == id) >= 0
    },
  },

  watch: {
    activeCategories(activeCategories) {
      this.$emit('filter-categories', activeCategories)
    },
  },
}
</script>
