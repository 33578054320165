<template>
  <div class="flex">
    <label class="rounded-l-md bg-guestio-gray-100 text-sm text-blue-800 py-3 px-4 w-full overflow-auto" for="shareable_link">
      <span class="whitespace-no-wrap">{{ url }}</span>
    </label>
    <button
      ref="btnRef"
      v-on:mouseenter="toggleTooltip()"
      v-on:mouseleave="toggleTooltip()"
      style="transition:all .15s ease"
      v-clipboard:copy="url"
      v-clipboard:success="onCopy"
      v-clipboard:error="onError"
      class="rounded-r-md bg-softGray p-3"
    >
      <CopyIcon/>
      <div ref="tooltipRef" v-bind:class="{'hidden': !tooltipShow, 'block': tooltipShow}" class="ml-3 block z-50 max-w-xs text-left no-underline break-words rounded-full">
        <div class="bg-indigo-500 text-white text-xs py-2 px-3 mb-0 rounded-full">
          {{copyMessage}}
        </div>
      </div>
    </button>
  </div>
</template>

<script>
import Popper from "popper.js";
import CopyIcon from "@/components/svgs/CopyIcon";

export default {
  name: 'ShareableLink',
  components: { CopyIcon },

  props: {
    url: { type: String, required: true }
  },

  data() {
    return {
      copyMessage: 'Copy to clipboard',
      tooltipShow: false
    }
  },

  methods: {
    onCopy() {
      this.copyMessage = 'Copied!'
    },

    onError() {
      alert(`Failed to copy. Shareable link is: ${this.shareableLink}`)
    },

    toggleTooltip(){
      if(this.tooltipShow){
        this.tooltipShow = false;
      } else {
        this.tooltipShow = true;
        new Popper(this.$refs.btnRef, this.$refs.tooltipRef, {
          placement: "right"
        });
      }
    },
  },
}
</script>
