<template>
  <div>
    <Invitation class="mb-20" />
    <div class="grid grid-cols-12 row-gap-10 md:col-gap-10">
      <!--Left col-->
      <div class="col-span-12 md:col-span-12 lg:col-span-4">
        <div class="grid grid-cols-1 row-gap-10">
          <AccountCard />
          <PublicProfiles />
          <BalanceCard />
          <MessagesCards />
        </div>
      </div>
      <!--Right col-->
      <div class="right-col">
        <GuestioConnectCard v-if="user.account_type === 'pro'" />
        <ShowsCard />
        <YourPromotionalUpsells />
        <UnlockPro v-if="user.account_type === 'standard'" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Invitation from "../components/dashboard/Invitation";
import AccountCard from "../components/dashboard/AccountCard";
import PublicProfiles from "../components/dashboard/PublicProfiles";
import BalanceCard from "../components/dashboard/BalanceCard";
import MessagesCards from "../components/dashboard/MessagesCards";
import GuestioConnectCard from "../components/dashboard/GuestioConnectCard";
import ShowsCard from "../components/dashboard/ShowsCard";
import UnlockPro from "../components/dashboard/UnlockPro";
import YourPromotionalUpsells from "../components/dashboard/YourPromotionalUpsells";

export default {
  name: "Dashboard",
  components: {
    ShowsCard,
    MessagesCards,
    GuestioConnectCard,
    BalanceCard,
    AccountCard,
    Invitation,
    PublicProfiles,
    YourPromotionalUpsells,
    UnlockPro
  },

  created() {
    this.$store.dispatch("dashboard/smartlist/fetchSmartlist", this.user.id);
    this.$store.dispatch("auth/getUser").then(() => {
      this.$store.dispatch("user/fetchUnreadMessages", this.user.id);
    });
  },

  computed: {
    ...mapState({
      smartlist: state => state.dashboard.smartlist.smartlist
    }),

    user() {
      return this.$store.getters["auth/getUser"];
    },

    hasGuestProfile() {
      return !!(this.user && this.user.guest);
    }
  }
};
</script>
<style scoped>
.containerCard {
  height: min-content;
}

.right-col {
  @apply col-span-12 lg:col-span-8 grid row-gap-10 grid-cols-1 containerCard;
}
</style>
