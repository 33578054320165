<template>
  <div class="flex flex-col md:flex-row">
    <div class="w-64 flex-shrink-0">
      <label class="text-gray-600" for="">Password</label>
    </div>
    <div class="md:ml-8 flex-1 mt-1 md:mt-0">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span class="font-semibold">********</span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else>
        <ValidationObserver ref="passwordForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <div>
                <label for="current_password" class="sr-only">Current Password</label>
                <ValidationProvider rules="required" vid="current_password" name="Current password" v-slot="{ errors }">
                  <div>
                    <div class="rounded-lg shadow-sm">
                      <input id="current_password" name="current_password" type="password" class="guestio-form-input" v-model="passwordForm.current_password" placeholder="Current password" autocomplete="off"/>
                    </div>
                    <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
              <div class="mt-4">
                <label for="password" class="sr-only">New Password</label>
                <ValidationProvider mode="lazy" rules="required|min:8" vid="new_password" name="New password" v-slot="{ errors }">
                  <div>
                    <div class="rounded-lg shadow-sm">
                      <input id="password" name="password" type="password" class="guestio-form-input" v-model="passwordForm.new_password" placeholder="New password" />
                    </div>
                    <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
              <div class="mt-4">
                <label for="password_confirmation" class="sr-only">Confirm New Password</label>
                <ValidationProvider mode="lazy" rules="required|min:8|confirmed:new_password" vid="new_password_confirmation" name="New password confirmation" v-slot="{ errors }">
                  <div>
                    <div class="rounded-lg shadow-sm">
                      <input id="password_confirmation" name="password_confirmation" type="password" class="guestio-form-input" v-model="passwordForm.new_password_confirmation" placeholder="Confirm new password" />
                    </div>
                    <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <div class="flex items-center justify-end mt-4">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel},

    props: {
      userProp: Object,
      admin: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        working: false,

        edit: false,

        user: this.userProp,

        passwordForm: {
          current_password: null,
          new_password: null,
          new_password_confirmation: null,
        }
      }
    },

    watch: {
      edit(edit) {
        if (! edit) {
          this.resetForm()
        }
      }
    },

    computed: {
      updateAction() {
        if (this.admin) {
          return `auth/updateAdminUser`
        }

        return `auth/updateUser`
      }
    },

    methods: {
      resetForm() {
        this.passwordForm.current_password = null
        this.passwordForm.new_password = null
        this.passwordForm.new_password_confirmation = null
      },

      update() {
        this.working = true

        this.$store.dispatch(this.updateAction, this.passwordForm)
          .then(() => {
            this.working = false
            this.edit = false
            this.resetForm()
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$toast.error('Error! Something went wrong.')
              this.$refs.passwordForm.setErrors(error.response.data.errors)
            }
          })
      }
    }
  }
</script>
