<template
  ><svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0)">
      <path
        d="M2.30859 5.32353H3.80859H15.8086"
        stroke="white"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M6.05859 5.32353V3.82353C6.05859 3.42571 6.21663 3.04418 6.49793 2.76287C6.77924 2.48157 7.16077 2.32353 7.55859 2.32353H10.5586C10.9564 2.32353 11.3379 2.48157 11.6193 2.76287C11.9006 3.04418 12.0586 3.42571 12.0586 3.82353V5.32353M14.3086 5.32353V15.8235C14.3086 16.2214 14.1506 16.6029 13.8693 16.8842C13.5879 17.1655 13.2064 17.3235 12.8086 17.3235H5.30859C4.91077 17.3235 4.52924 17.1655 4.24793 16.8842C3.96663 16.6029 3.80859 16.2214 3.80859 15.8235V5.32353H14.3086Z"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="18"
          height="18"
          fill="currentColor"
          transform="translate(0.0585938 0.823532)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "TrashIcon"
};
</script>
