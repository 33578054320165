<template>
  <svg
    width="42"
    height="42"
    viewBox="0 0 42 42"
    :fill="backgroundColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M21 38.5C30.665 38.5 38.5 30.665 38.5 21C38.5 11.335 30.665 3.5 21 3.5C11.335 3.5 3.5 11.335 3.5 21C3.5 30.665 11.335 38.5 21 38.5Z"
      :stroke="strokeColor"
      :stroke-width="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21 14V28"
      :stroke="fill"
      :stroke-width="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14 21H28"
      :stroke="fill"
      :stroke-width="stroke"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CirclePlusIcon",
  props: {
    backgroundColor: {
      type: String,
      default: "none",
      required: false
    },
    fill: {
      type: String,
      default: "black",
      required: false
    },
    strokeColor: {
      type: String,
      default: "black",
      required: false
    },
    stroke: {
      type: String,
      default: "3",
      required: false
    }
  }
};
</script>

<style lang="css" scoped></style>
