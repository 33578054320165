<template>
  <button type="button" @click="toggle">
    <slot/>
    <portal to="modal">
      <div
        class="modal-container fixed bottom-0 inset-x-0 px-4 inset-0 pb-6 xl:p-0 sm:flex sm:items-center sm:justify-center pointer-events-none z-50"
        @click="close">
        <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in duration-100"
          leave-class="opacity-100"
          leave-to-class="opacity-0">
          <div v-if="modalOpened" class="fixed inset-0 transition-opacity pointer-events-auto">
            <div class="absolute inset-0" :class="overlay"></div>
          </div>
        </transition>

        <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-100"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
          <div
            v-if="modalOpened" @click.stop
            class="transition-all transform flex items-center justify-center w-full h-full pointer-events-auto overflow-y-auto"
            role="dialog" aria-modal="true" aria-labelledby="modal-headline">

            <slot name="content" v-bind:close="close"/>

            <div v-if="withCloseButton" class="absolute top-0 right-0 mt-4 mr-4 z-50">
              <button @click.prevent="close" type="button"
                      class="p-2 rounded-full text-white opacity-75 hover:opacity-100 focus:bg-gray-500 bg-gray-600 bg-opacity-50">
                <svg class="h-4 sm:h-8 lg:h-12 xl:h-14" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                     viewBox="0 0 24 24" stroke="currentColor">
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </div>
        </transition>
      </div>
    </portal>
  </button>

</template>

<script>
export default {
  name: 'Modal',
  props: {
    withCloseButton: {
      type: Boolean,
      default: true
    },
    overlay: {
      type: String,
      default: 'bg-gray-900 opacity-75'
    }

  },

  data() {
    return {
      modalOpened: false
    }
  },

  watch: {
    modalOpened(opened) {
      document.body.classList.toggle('overflow-hidden', opened)

      this.$nextTick(() => this.$emit(opened ? 'opened' : 'closed'))
    }
  },

  methods: {
    open() {
      this.modalOpened = true
    },

    toggle() {
      this.modalOpened = !this.modalOpened
    },

    close() {
      this.modalOpened = false
    },

    handleEscape(e) {
      if (e.keyCode === 27) {
        this.close()
      }
    }
  },

  mounted() {
    document.addEventListener('keyup', this.handleEscape)
  },

  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEscape)
  }
}
</script>
