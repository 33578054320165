<template>
  <div>
    <div>
      <h1 class="text-3xl font-bold text-black">Account Settings</h1>
      <p v-if="adminUser.id" class="text-gray-500 mt-2">
        You are editing the information for the main account. If you want to edit your personal account settings, <router-link :to="{name: 'AdminSettings'}" class="text-pink-500 hover:underline">click here</router-link>.
      </p>
    </div>

    <div v-if="! user.id" class="mt-12 max-w-md mx-auto flex justify-center">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>
    <div v-else class="mt-12 max-w-3xl w-full">
      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-first-and-last-name :user-prop="user" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-email :user-prop="user" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-phone :user-prop="user" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-password :user-prop="user" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-title :user-prop="user" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-image :user-prop="user" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-timezone :user-prop="user" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-notifications :user-prop="user" />
      </div>

      <div class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8">
        <user-payment-methods :user-prop="user" />
      </div>

      <div v-if="canView" class="md:py-6 border-b last:border-b-0 border-gray-300 mb-8" ref="stripe-connect">
        <user-stripe-connect :user-prop="user" />
      </div>

      <div class="mt-20" id="pro-subscription" ref="pro-subscription">
        <h3 class="text-3xl text-black font-bold" id="your-plan">
          <a href="#your-plan">Your Plan</a>
        </h3>

        <div class="mt-12">
          <user-subscription :user-prop="user" />
        </div>
      </div>

      <div class="mt-20">
        <h3 class="text-3xl text-black font-bold">Calendars integration</h3>
        <p class="text-gray-500 mt-2">Connect your calendar to let Guestio know when you're available and update your calendar as interviews are scheduled.</p>

        <div class="mt-12">
          <user-calendars :user-prop="user" />
        </div>
      </div>

      <div v-if="user.account_type === 'pro' && canView" class="mt-20">
        <h3 class="text-3xl text-black font-bold">Team Members</h3>
        <p class="text-gray-500 mt-2">Manage your team members.</p>

        <div class="mt-12">
          <team-members :user-prop="user" />
        </div>
      </div>

      <div class="mt-20" id="manage-account" ref="manage-account">
        <h3 class="text-3xl text-black font-bold">
          <a href="#manage-account" >Manage Your Account</a>
        </h3>
        <p class="text-gray-500 mt-2">You can take a break by pausing your account or you can completely delete your account.</p>
        <div class="mt-6 sm:mt-12">
          <div class="py-6 border-b last:border-b-0 border-gray-300">
            <UserPauseAccount :user-prop="user" />
          </div>

          <div class="py-6 border-b last:border-b-0 border-gray-300">
            <UserDeleteAccount :user-prop="user" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import * as SettingsComponents from '@/components/settings'
  export default {
    name: "Settings",

    components: {
      ...SettingsComponents,
    },

    data() {
      return {
        //
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      adminUser() {
        return this.$store.getters['auth/getAdminUser']
      },

      hash() {
        return this.$route.hash.replace('#', '')
      },

      canView() {
        return ! this.adminUser.id || this.adminUser.team_role === 'agency'
      },
    },

    methods: {
      fetchUser() {
        this.$store.dispatch("auth/getUser")
          .then(() => {
            if (! this.$route.hash.length) {
              return
            }

            this.$nextTick(() => {
              let $el = this.$refs[this.hash]

              if ($el) {
                $el.scrollIntoView({behavior: 'smooth'})
              }
            })
          });
      }
    },

    created() {
      this.fetchUser();
    },

    mounted() {
      window.Event.$on('fetchUser', () => this.fetchUser())
    },

    beforeDestroy() {
      window.Event.$off('fetchUser')
    }
  }
</script>

