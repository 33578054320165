<template>
  <div class="max-w-lg w-full max-h-screen bg-white rounded-xl overflow-y-scroll" @click.stop>
    <div class="px-6 py-8 sm:px-12 sm:py-12">
      <ValidationObserver v-slot="{ handleSubmit }" tag="div">
        <form method="post" @submit.prevent="handleSubmit(savePaymentMethod)">
          <div>
            <h4 class="text-3xl font-bold text-blue-800 leading-none">
              New Payment Method
            </h4>

            <template>
              <CreditCard class="mt-8" :saveCardButton="false" @cardComplete="setCardComplete"/>
            </template>

            <div v-if="cardSetupError" class="mt-6">
              <p class="text-red-500">There was an error while attempting to save your payment method.</p>
              <p v-if="errorDetails" class="mt-2 text-red-500">{{ errorDetails }}</p>
            </div>

            <div class="mt-12">
              <button
                type="submit"
                class="font-semibold rounded-full h-16 sm:h-16 bg-indigo-gradiant text-white text-xl w-full flex justify-center items-center"
                :class="{'opacity-50': loading || !cardComplete}"
                :disabled="loading || !cardComplete"
              >
                <loading-icon v-if="loading" class="h-6 w-6"/>
                <template v-else>
                  Save Payment Method
                </template>
              </button>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import { confirmCardSetup } from 'vue-stripe-elements-plus';
  import CreditCard from '@/components/payment-methods/CreditCard';

  export default {
    name: 'PaymentMethodModal',

    props: {
      user: Object,
    },

    components: { CreditCard },

    data() {
      return {
        loading: false,
        intent: null,
        card: null,
        cardComplete: false,
        cardSetupError: false,
        errorDetails: null,
      }
    },

    methods: {
      async savePaymentMethod() {
        this.loading = true
        this.cardSetupError = false
        this.errorDetails = null

        const { setupIntent, error } = await confirmCardSetup(this.intent.client_secret, {
          payment_method: {
            card: this.card
          }
        })

        if (error) {
          this.loading = false
          this.cardSetupError = true

          if (error.message) {
            this.errorDetails = error.message
          }

          return
        }

        try {
          await this.$store.dispatch('auth/storePaymentMethod', {
            payment_method: setupIntent.payment_method,
            save: true,
          })

          this.$toast.success('Your payment method was saved')
          this.$emit('saved')
        } catch(error) {
          this.loading = false

          if (error.response) {
            this.errorDetails = error.response.data.message
            return
          }

          this.$toast.error('Something went wrong')
        }
      },

      generatePaymentIntent() {
        api.get(`/user/payment/intent?usage=off_session`).then(response => {
          this.intent = response.data.intent
        })
      },

      setCardComplete(data) {
        this.cardComplete = data.complete;
        this.card = data.card;
      },
    },

    mounted() {
      this.generatePaymentIntent()
    },
  }
</script>
