<template>
  <div v-if="userProp && userProp.id" class="flex flex-col md:flex-row md:space-x-6">
    <div class="flex-1">
      <h4 class="font-medium text-lg text-blue-800">
        Delete Account
      </h4>
      <p class="text-sm text-gray-500 mt-2">
        All your account data will be deleted effective immediately. {{ userIsProText }}
      </p>
    </div>

    <div class="flex-1 flex items-start justify-end space-x-4 mt-4 sm:mt-0">
      <ConfirmDialog
        @confirm="deleteAccount"
        confirm-action="Yes, Delete"
        dialog-title="Delete Account?"
        dialog-text="Are you sure you want to delete your account? This action is irreversible!"
        class="bg-white border border-red-700 text-white px-4 py-2 rounded-full bg-red-600 text-white text-sm hover:opacity-90 transition duration-150 ease-in-out hover:shadow-3xl focus:shadow-outline"
      >
        Delete Account
      </ConfirmDialog>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import ConfirmDialog from '@/components/shared/ConfirmDialog'

  export default {
    name: 'UserDeleteAccount',

    props: {
      userProp: Object,
    },

    components: {
      ConfirmDialog
    },

    computed: {
      userIsProText() {
        if (this.userProp.account_type !== 'pro') {
          return
        }

        return `Your Pro subscription will also be cancelled.`
      }
    },

    methods: {
      async deleteAccount(callback) {
        let confirmed = await this.confirmLogin()

        if (! confirmed) {
          callback()
          this.$toast.error('Login not confirmed.')
          return
        }

        api.delete('/users/me/account/delete')
          .then(() => {
            this.$store.commit('auth/logout')
            this.$router.push({name: 'Home'})
          })
          .catch(error => {
            if (error.response) {
              return this.$toast.error(error.response.data.message)
            }

            this.$toast.error('Error! Something went wrong.')
          })
          .finally(() => callback())
      },
    }
  }
</script>
