<template>
  <div class="dashboard-card">
    <h3>Your Shows</h3>
    <p class="text-lg mb-10 font-light">{{ text }}</p>
    <button
      @click.prevent="createShow"
      v-if="user.shows && !user.shows.length"
      class="rounded-full px-10 py-3 bg-indigo-gradiant text-white"
    >
      Create your Show
    </button>
    <div v-else class="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <div class="col-span-1" v-for="(show, index) in user.shows" :key="index">
        <template v-if="show.imageUrl">
          <div  :style="'background-image: url('+ show.imageUrl +');'" class="rounded-md h-56 w-full mb-6 object-contain bg-cover bg-no-repeat" > 
          </div>
          <h2 class=" text-blue-800 font-medium text-lg mb-1">{{show.name}}</h2>
          <div class="flex items-center space-x-3 font-light text-violet text-sm mb-3">
            <span v-for="category in show.categories" :key="category.id">{{category.name}} </span>
          </div>
        </template>
        <template v-else>
          <div  style="'background-image: url('@/assets/images/test_image.png');'" class="rounded-md h-56 w-full mb-6 object-contain bg-cover bg-no-repeat" > 
          </div>
          <h2 class=" text-blue-800 font-medium text-lg mb-1">{{show.name}}</h2>
          <div class="flex items-center space-x-3 font-light text-violet text-sm mb-3">
            <span v-for="category in show.categories" :key="category.id">{{category.name}} </span>
          </div>
        </template>
        <div class="flex">
          <router-link
            :to="{ name: 'ShowDetails', params: { showId: show.id } }"
            class="rounded-full py-3 bg-guestio-gray-100 w-full mr-5 text-center"
          >
            Edit
          </router-link>
          <router-link
            :to="{ name: 'Show', params: { showId: show.id } }"
            class="rounded-full py-3 bg-guestio-gray-100 w-full text-center"
          >
            View
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ShowsCard",

  methods: {
    createShow() {
      this.$router.push({ name: 'ShowCreate' })
    }  
  },

  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },

    text() {
      return this.user.shows && this.user.shows.length
        ? "View shows or edit their details."
        : "You don´t have any shows yet";
    }
  }
};
</script>
