<template>
  <div>
    <div class="grid grid-cols-12 lg:grid-cols-8 gap-5 lg:gap-8 justify-center">
      <!--Messages-->
      <MessagesList :bookingsMessages="groupedMessages"/>
      <!--Message detail-->
      <Conversation :user="user"/>
    </div>
  </div>
</template>

<script>
import MessagesList from "@/components/messages/MessagesList/index";
import Conversation from "@/components/messages/Conversation/index";
import {mapState} from "vuex";
import _ from 'lodash'

export default {
  name: "Messages",
  components: {Conversation, MessagesList},

  created() {
    window.Event.$on("markedAsRead", () => {
      this.fetchAllMessages();
    });

    this.fetchAllMessages();
  },

  methods: {
    fetchAllMessages() {
      this.$store.dispatch("auth/getUser").then(() => {
        this.$store.dispatch('user/fetchAllMessages', this.user.id)
      })
    }
  },

  computed: {
    ...mapState({
      messages: state => state.user.messages,
      user: state => state.auth.user
    }),

    groupedMessages() {
      let grouped =  _.chain(this.messages)
                      .groupBy('booking.id')
                      .map((messages) => ({
                        lastMessage: messages[messages.length - 1],
                        newMessagesCount: messages.filter(message => message.read_at == null && message.user_id != this.user.id).length
                      }))
                      .value()
      return _.orderBy(grouped, 'lastMessage.created_at', ['desc'])
    },
  }
}
</script>

