<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.3858 7.99854H19.4472C20.3076 7.99854 21.0039 8.69483 21.0039 9.55518V19.4483C21.0039 20.3077 20.3076 21.004 19.4472 21.004H8.5547C7.69434 21.004 6.99805 20.3077 6.99805 19.4473V18.0027"
      stroke="#1FD76C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.9976 18.0027H15.3143C15.9836 18.0027 16.6079 17.6685 16.979 17.1113L17.7133 16.0088C18.1515 15.3516 18.3856 14.5792 18.3856 13.7889V5.99766C18.3856 4.8922 17.4902 3.99683 16.3848 3.99683H6.3806C5.27514 3.99683 4.37976 4.8922 4.37976 5.99766V13.0566C4.37976 13.6779 4.2347 14.2901 3.95759 14.8463L3.10323 16.5551C2.77009 17.2203 3.25429 18.0027 3.9976 18.0027Z"
      stroke="#1FD76C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.6513 9.59204L10.8371 12.4062L9.14844 10.7185"
      stroke="#1FD76C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M8.37814 2.99634V4.99717"
      stroke="#1FD76C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.3811 2.99634V4.99717"
      stroke="#1FD76C"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarCheckIcon"
};
</script>

<style lang="css" scoped></style>
