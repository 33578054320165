import bookings from './bookings'
import payments from './payments'
import smartlist from './smartlist'
import messages from './messages'

const dashboard = {
  namespaced: true,

  state: {

  },

  getters: {
    //
  },

  actions: {
    //
  },

  mutations: {
    //
  },

  modules: {
    bookings,
    payments,
    smartlist,
    messages
  }
}

export default dashboard
