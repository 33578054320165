<template>
  <div v-if="upsellPackages.length" class="dashboard-card">
    <h3>Your Promotional Upsells</h3>
    <div class="space-y-6">
      <template v-for="(upsellPackage, index) in upsellPackages">
        <PromotionalUpsellDetails
          :upsell-package="upsellPackage"
          :key="`package-${index}`"
          @delete-package="deletePackage(index)"
          @update-package="updatePackage($event, index)"
        />
      </template>
    </div>
  </div>
</template>
<script>
import api from '@/api'
import PromotionalUpsellDetails from './PromotionalUpsellDetails'

export default {
  name: "YourPromotionalUpsells",
  components: {
    PromotionalUpsellDetails,
  },
  data() {
    return {
      upsellPackages: [],
    }
  },
  methods: {
    fetchUser() {
      // delay api call until the user state is available
      if (! this.user.id) {
        setTimeout(() => {
          this.fetchUser()
        }, 500)

        return
      }

      api.get(`/users/${this.user.id}?include=shows.upsellPackages,guest.upsellPackages`)
        .then(({ data }) => {
          const guestPackages = data.data.guest.upsellPackages.map(upsellPackage => {
            return {
              profile_type: 'guest',
              profile_name: data.data.guest.name,
              profile_id: data.data.guest.id,
              ...upsellPackage,
            }
          })

          const showPackages = data.data.shows
            .flatMap(show => {
              return show.upsellPackages.map(upsellPackage => {
                return{
                  profile_type: 'show',
                  profile_name: show.name,
                  profile_id: show.id,
                  ...upsellPackage,
                }
              })
            })

          this.upsellPackages = [
            ...guestPackages,
            ...showPackages,
          ]
        })
        .catch(() => {
          //
        })
    },
    updatePackage(upsellPackage, index) {
      this.upsellPackages.splice(index, 1, upsellPackage)
    },
    deletePackage(index) {
      this.upsellPackages.splice(index, 1)
    }
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
  },
  mounted() {
    this.fetchUser()
  }
};
</script>
