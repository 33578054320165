<template>
  <div>
    <div v-if="working" class="max-w-md mx-auto flex justify-center">
      <loading-icon class="h-10 w-10 text-pink-500"/>
    </div>

    <div v-else class="">
      <!-- Month selector-->
      <div class="flex justify-between items-center shadow-xs bg-white border border-guestio-gray-300 rounded-full lg:mr-6 h-12 month-selector">
        <button @click.prevent="prevMonth" class="flex-1 px-6 h-full hover:bg-gray-200 transition duration-150 ease-in-out rounded-l-full">
          <ChevronLeftIcon />
        </button>
        <span class="flex-shrink-0 md:px-0 lg:px-5 font-semibold whitespace-no-wrap select-none month">{{ filters.month }}</span>
        <button @click.prevent="nextMonth" class="flex-1 px-6 h-full hover:bg-gray-200 flex justify-end items-center transition duration-150 ease-in-out rounded-r-full">
          <ChevronRightIcon />
        </button>
      </div>

      <div class="mt-4 grid grid-cols-7 w-full rounded-3xl border p-4 sm:p-6 md:p-8 lg:p-16 shadow-md">
        <!--Calendar header-->
        <span class="uppercase font-semibold mb-12 text-center">Sun</span>
        <span class="uppercase font-semibold mb-12 text-center">Mon</span>
        <span class="uppercase font-semibold mb-12 text-center">Tue</span>
        <span class="uppercase font-semibold mb-12 text-center">Wed</span>
        <span class="uppercase font-semibold mb-12 text-center">Thu</span>
        <span class="uppercase font-semibold mb-12 text-center">Fri</span>
        <span class="uppercase font-semibold mb-12 text-center">Sat</span>
        <!--Days-->
        <Day
          v-for="(day, index) in monthRange.by('day')"
          :key="`day-${day.format('YYYY-MM-DD')}`"
          :bookings="calendarBookings[day.format('YYYY-MM-DD')] || []"
          :index="index"
          :day="day"
          :current-month="currentMonth"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import api from '@/api'
  import Moment from 'moment'
  import { extendMoment } from 'moment-range'
  import Day from "./partials/Day"
  import anime from "animejs";
  import ChevronLeftIcon from "@/components/svgs/ChevronLeftIcon";
  import ChevronRightIcon from "@/components/svgs/ChevronRightIcon";
  const moment = extendMoment(Moment)

  export default {
    name: 'index',

    components: {
      Day,
      ChevronLeftIcon,
      ChevronRightIcon,
    },

    data() {
      return {
        working: true,

        calendarBookings: {},
      }
    },

    computed: {
      month() {
        return this.$store.getters['dashboard/bookings/monthFilter']
      },

      bookedBy() {
        return this.$store.getters['dashboard/bookings/bookedByFilter']
      },

      currentMonth() {
        return moment(this.month, 'MMMM YYYY')
      },

      monthRange() {
        let start = moment(this.month, 'MMMM YYYY').startOf('month').startOf('week')
        let end = moment(this.month, 'MMMM YYYY').endOf('month').endOf('week')

        return moment.range(start, end)
      },

      filters() {
        return this.$store.getters["dashboard/bookings/filters"];
      },
    },

    watch: {
      month() {
        this.fetchCalendarBookings()
      },

      bookedBy() {
        this.fetchCalendarBookings()
      }
    },

    methods: {
      hasBookingsFor(day) {
        let dayString = day.format('YYYY-MM-DD')

        if (! this.calendarBookings[dayString]) {
          return false;
        }

        return !! Object.values(this.calendarBookings[dayString]).length
      },

      fetchCalendarBookings() {
        this.working = true
        this.calendarBookings = {}

        api.get('/bookings/calendar-view', {
          params: {
            from: moment(this.month, 'MMMM YYYY').startOf('month').startOf('week').format('YYYY-MM-DD'),
            to: moment(this.month, 'MMMM YYYY').endOf('month').endOf('week').format('YYYY-MM-DD'),
            booked_by: this.bookedBy,
          }
        }).then(({ data }) => {
          if (Object.values(data.data).length) {
            this.calendarBookings = data.data
          }
        }).catch(error => {
          alert('There was an error while trying to fetch calendar bookings')
          console.log(error)
        }).finally(() => this.working = false)
      },

      prevMonth() {
        anime({
          targets: ".month",
          translateX: [-100, 0],
          duration: 600,
        });
        this.filters.month = moment(this.filters.month, "MMMM YYYY")
          .date(15)
          .subtract(1, "month")
          .format("MMMM YYYY");
        // this.$emit("filterBookings");
      },

      nextMonth() {
        anime({
          targets: ".month",
          translateX: [100, 0],
          duration: 600,
        });
        this.filters.month = moment(this.filters.month, "MMMM YYYY")
          .date(15)
          .add(1, "month")
          .format("MMMM YYYY");
        // this.$emit("filterBookings");
      },
    },

    created() {
      this.fetchCalendarBookings()
    },
  }
</script>

<style scoped>
button {
  transition: all ease 0.2s;
}

.month-selector {
  width: 100%;
}

@media (min-width: 1024px) {
  .month-selector {
    width: 300px;
  }
}
</style>
