<template>
  <div>
    <ValidationObserver ref="reviewForm" v-slot="{ handleSubmit }">
      <form action="" @submit.prevent="handleSubmit(saveReview)">
        <div class="flex items-center justify-between">
          <span class="text-3xl font-bold text-black">
            {{ entity.name }}
          </span>
        </div>
        <div class="mt-6">
          <div>
            <ValidationProvider name="Rating" vid="number" v-slot="{ errors }">
              <label class="font-medium text-black">Your Rate</label>
              <div class="flex items-end">
                <ProfileRating editable :rating="form.number" activeColor="#B503D0" inactiveColor="#413F51" :starSize="25" @ratingSelected="ratingSelected"/>
                <span class="ml-2 font-bold text-xs">{{ form.number }}/5 stars</span>
              </div>
              <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>

          <div class="mt-8">
            <div class="flex items-center justify-between">
              <label class="font-medium text-black">Your Review</label>
              <span class="text-gray-500">{{ form.comment.length }} / 300</span>
            </div>
            <ValidationProvider rules="required" name="Review" vid="comment" v-slot="{ errors }">
              <div class="mt-2">
                <textarea v-model="form.comment" maxlength="300"
                        class="w-full bg-gray-50 rounded-xl resize-none p-5 text-black text-lg focus:border focus:border-pink-500 focus:shadow-3xl focus:bg-white"
                        :class="{'border-red-500': errors.length}"/>
              </div>
              <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
            </ValidationProvider>
          </div>
        </div>
        <div class="mt-8 flex justify-end">
          <button :disabled="working" type="submit" class="btn" :class="{'opacity-50': working}">Save Review</button>
        </div>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
  import ProfileRating from "@/components/shared/ProfileRating";

  export default {
    props: {
      type: {
        type: String,
        default: 'guest'
      },

      review: {
        type: Object,
        default: () => ({})
      },
    },

    components: {
      ProfileRating
    },

    data() {
      return {
        working: false,
        form: {
          number: 5,
          comment: '',
        }
      }
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      entity() {
        return this.booking[this.type]
      },
    },

    methods: {
      saveReview() {
        this.working = true

        if (this.type == 'guest') {
          return this.saveGuestReview()
        }

        return this.saveShowReview()
      },

      saveGuestReview() {
        if (this.review && this.review.id) {
          return this.updateGuestReview()
        }

        return this.createGuestReview()
      },

      saveShowReview() {
        if (this.review && this.review.id) {
          return this.updateShowReview()
        }

        return this.createShowReview()
      },

      createGuestReview() {
        this.$store.dispatch('dashboard/bookings/storeGuestReview', {
          guest_id: this.entity.id,
          booking_id: this.booking.id,
          ...this.form
        }).then(() => {
          this.$emit('saved')
        }).catch(() => this.working = false)
      },

      updateGuestReview() {
        this.$store.dispatch('dashboard/bookings/updateGuestReview', {
          guest_id: this.entity.id,
          review_id: this.review.id,
          booking_id: this.booking.id,
          ...this.form
        }).then(() => {
          this.$emit('saved')
        }).catch(() => this.working = false)
      },

      createShowReview() {
        this.$store.dispatch('dashboard/bookings/storeShowReview', {
          show_id: this.entity.id,
          booking_id: this.booking.id,
          ...this.form
        }).then(() => {
          this.$emit('saved')
        }).catch(() => this.working = false)
      },

      updateShowReview() {
        this.$store.dispatch('dashboard/bookings/updateShowReview', {
          show_id: this.entity.id,
          review_id: this.review.id,
          booking_id: this.booking.id,
          ...this.form
        }).then(() => {
          this.$emit('saved')
        }).catch(() => this.working = false)
      },

      ratingSelected(rating) {
        this.form.number= rating;
      }
    },

    mounted() {
      if (this.review && this.review.id) {
        this.form.comment = this.review.comment
        this.form.number = this.review.number
      }
    }
  }
</script>
