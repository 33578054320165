import api from '@/api'

const miniviews = {
  namespaced: true,

  state: {
    steps: ['MiniviewCreate1', 'MiniviewCreate2', 'MiniviewCreated'],
    currentStep: 1,
    form: {
      title: ''
    },
    answerForm: {
      name: '',
      answers: [],
    },
    current: null,
    items: [],
    pagination: null,
  },

  getters: {
    steps: state => state.steps,
    title: state => state.form.title,
    current: state => state.current,
    items: state => state.items,
    answerForm: state => state.answerForm,
  },

  actions: {
    async create({ commit }, payload) {
      const response = await api.post('/miniviews', { title: payload.title, questions: payload.questions});
      const { success, data, message } = response.data;
  
      if (success) {
        commit('setCurrent', data);
      }
      return { message, success, data };
    },

    async getAll({ commit }, payload) {
      let url = `/miniviews?page=${payload.page}`;
      if (payload.title) {
        url += `&filter[title]=${payload.title}`
      }
      if (payload.limit) {
        url += `&limit=${payload.limit}`
      }
      const response = await api.get(url);
      const { success, data, meta } = response.data;
  
      if (success) {
        commit('setItems', data);
        commit('setPagination', meta.pagination);
      }
      return { success, data };
    },

    async get({ commit }, id) {
      const response = await api.get(`/miniviews/${id}`);
      const { success, data } = response.data;
  
      if (success) {
        commit('setCurrent', data);
      }
      return { success, data };
    },
  },

  mutations: {
    setCurrentStep(state, step) {
      state.currentStep = step;
    },

    incrementStep(state) {
      if (state.steps.length == state.currentStep) {
        return;
      }
      state.currentStep++;
    },

    setTitle(state, title) {
      state.form.title = title;
    },

    setCurrent(state, miniview) {
      state.current = miniview;
    },

    setItems(state, items) {
      state.items = items;
    },

    setPagination(state, pagination) {
      state.pagination = pagination;
    },

    setAnswerName(state, name) {
      state.answerForm.name = name;
    },

    updateAnswer(state, answer) {
      let index = state.answerForm.answers.findIndex(a => a.question_id == answer.questionId)

      if (index === -1) {
        state.answerForm.answers.push({ question_id: answer.questionId, url: answer.url, type: answer.type })
      } else {
        state.answerForm.answers[index] = { question_id: answer.questionId, url: answer.url, type: answer.type }
      }
    }
  },
}

export default miniviews
