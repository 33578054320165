import api from '@/api'

const booking = {
  namespaced: true,

  state: {
    type: null,
    availableSteps: [
      { name: 'profile', enabled: true, route: 'GuestBook' },
      { name: 'options', enabled: false, route: 'GuestBookingOptions' },
      { name: 'pitch', enabled: true, route: 'GuestBookingPitch' },
      { name: 'miniview-questions', enabled: false, route: 'GuestBookingMiniviewQuestions' },
      { name: 'questions', enabled: false, route: 'GuestBookingQuestions' },
      { name: 'upsells', enabled: false, route: 'GuestBookingUpsells' },
      { name: 'payment', enabled: true, route: 'GuestBookingPayment' },
    ],
    steps: [],
    currentStep: 1,
    selectedSlots: [],
    show: null,
    selectedInterviewDuration: null,
    booking: null,
    code: {},
    guestioCash: 0,
    questions: [],
    availableUpsellPackages: [],
    upsells: [],
    paymentMethod: null,
    bookingOptions: [],
    selectedBookingOption: null,
    miniviewQuestions: [],
    pitch: null,
    isPitch: false,
    wantsGuaranteedResponse: false,
  },

  getters: {
    selectedSlots(state) {
      return state.selectedSlots
    },

    show(state) {
      return state.show
    },

    booking(state) {
      return state.booking
    },

    code(state) {
      return state.code
    },

    type(state) {
      return state.type
    },

    availableUpsellPackages: state => state.availableUpsellPackages,
    upsells: state => state.upsells,
    bookingOptions: state => state.bookingOptions,
    selectedBookingOption: state => state.selectedBookingOption,
    availableSteps: state => state.availableSteps,
    enabledSteps: state => state.availableSteps.filter(step => step.enabled),
    miniviewQuestions: state => state.miniviewQuestions,
    pitch: state => state.pitch,
    isPitch: state => state.isPitch,
    wantsGuaranteedResponse: state => state.wantsGuaranteedResponse,
  },

  actions: {
    create({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        return api.post(`/guests/${rootState.guest.guest.id}/book`, {
          show_profile_id: state.show.id,
          proposed_dates: state.selectedSlots.map(slot => slot.utc),
          interview_duration: state.selectedBookingOption.price.interview_duration,
          code: state.code.code || null,
          guestio_cash: state.guestioCash,
          questions: state.questions,
          upsells: state.upsells,
          payment_method: state.paymentMethod,
          booking_options: {
            booking_option_id: state.selectedBookingOption.id,
            price_id: state.selectedBookingOption.price.id,
            amount: state.selectedBookingOption.amount || 1,
          },
          miniview: state.miniviewQuestions,
          pitch: state.pitch,
          source: 'web',
          is_pitch: state.isPitch,
          wants_guaranteed_response: state.wantsGuaranteedResponse,
        }).then(response => {
          commit('setBooking', response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },

    fetchCode({commit}, payload) {
      return new Promise((resolve, reject) => {
        return api.get(`/guests/${payload.id}/booking-codes/${payload.code}`)
          .then(({data}) => {
            commit('setCode', data.data)
            resolve(data)
          })
          .catch(error => {
            if (error.response.status == 404) {
              commit('setCode', {
                code: payload.code,
                isValid: false,
              })
            }
            reject(error)
          })
        })
    },

    fetchBookingType({commit}) {
      return new Promise((resolve, reject) => {
        return api.get('/booking-type')
          .then(({data}) => {
            commit('setType', data.type)
            resolve(data)
          })
          .catch(error => {
            reject(error)
          })
        })
    },

    fetchBookingOptions({commit}) {
      return new Promise((resolve, reject) => {
        return api.get('/booking-options')
          .then(({ data }) => {
            commit('setBookingOptions', data.data)
            resolve(data.data)
          })
          .catch(error => {
            reject(error)
          })
        })
    }
  },

  mutations: {
    nextStep(state) {
      if (state.enabledSteps.length == state.currentStep) {
        return
      }
      state.currentStep++
    },

    prevStep(state) {
      if (state.currentStep == 1) {
        return
      }
      state.currentStep--
    },

    setCurrentStep(state, step) {
      state.currentStep = step
    },

    setSteps(state, data) {
      state.steps = data
    },

    setSelectedSlots(state, data) {
      state.selectedSlots.length = 0
      state.selectedSlots.push(...data)
    },

    addSelectedSlot(state, data) {
      state.selectedSlots.push(data)
    },

    removeSelectedSlot(state, index) {
      state.selectedSlots.splice(index, 1)
    },

    shiftSelectedSlots(state) {
      state.selectedSlots.shift()
    },

    setShow(state, show) {
      state.show = show
    },

    setSelectedInterviewDuration(state, duration) {
      state.selectedInterviewDuration = duration
    },

    setBooking(state, data) {
      state.booking = data
    },

    setQuestions(state, data) {
      state.questions = data
    },

    setAvailableUpsellPackages(state, data) {
      state.availableUpsellPackages = data
    },

    setUpsells(state, data) {
      state.upsells = data
    },

    clearBooking(state) {
      state.currentStep = 1
      state.selectedSlots = []
      state.show = null
      state.selectedInterviewDuration = null
      state.booking = null
      state.type = null
      state.steps = []
      state.code = {}
      state.guestioCash = 0
      state.questions = []
      state.availableUpsellPackages = []
      state.upsells = []
      state.selectedBookingOption = null
      state.bookingOptions = []
      state.miniviewQuestions = []
      state.pitch = null
      state.isPitch = false
      state.wantsGuaranteedResponse = false
    },

    setCode(state, data) {
      state.code = data
    },

    setType(state, data) {
      state.type = data
    },

    setGuestioCash(state, data) {
      state.guestioCash = data
    },

    setPaymentMethod(state, data) {
      state.paymentMethod = data
    },

    setBookingOptions(state, data) {
      state.bookingOptions = data
    },

    enableStep(state, stepName) {
      let stepIndex = state.availableSteps.findIndex(step => step.name == stepName)

      if (stepIndex === -1) {
        return
      }

      state.availableSteps[stepIndex].enabled = true
    },

    disableStep(state, stepName) {
      let stepIndex = state.availableSteps.findIndex(step => step.name == stepName)

      if (stepIndex === -1) {
        return
      }

      state.availableSteps[stepIndex].enabled = false
    },

    setSelectedBookingOption(state, option) {
      state.selectedBookingOption = option
    },

    setMiniviewQuestions(state, questions) {
      state.miniviewQuestions = questions
    },

    setPitch(state, pitch) {
      state.pitch = pitch
    },

    setIsPitch(state, isPitch) {
      state.isPitch = isPitch;
    },

    setWantsGuaranteedResponse(state, wantsGuaranteedResponse) {
      state.wantsGuaranteedResponse = wantsGuaranteedResponse;
    },
  },
}

export default booking
