<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.99805 17.0022L11.9051 17.0392"
      stroke="#B503D0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M12 13.0005H17.0021"
      stroke="#B503D0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M21.0036 8.99874H2.99609"
      stroke="#B503D0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.99826 2.49609V5.49734"
      stroke="#B503D0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M16.0012 2.49609V5.49734"
      stroke="#B503D0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0023 21.0039H5.99734C4.33965 21.0039 2.99609 19.6604 2.99609 18.0027V6.99808C2.99609 5.34039 4.33965 3.99683 5.99734 3.99683H18.0023C19.66 3.99683 21.0036 5.34039 21.0036 6.99808V18.0027C21.0036 19.6604 19.66 21.0039 18.0023 21.0039Z"
      stroke="#B503D0"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "CalendarDateIcon"
};
</script>

<style lang="css" scoped></style>
