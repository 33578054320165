<template>
  <div class="connect-card">
    <div class="topbar">
      <div class="icon-container">
        <AttachmentIcon />
      </div>

      <h3 class="text-xl" v-if="user.account_type != 'pro'">
        <span class="font-medium"> Make </span>
        <span class="font-light">a Booking </span>
      </h3>

      <h3 class="text-xl" v-if="user.account_type == 'pro'">
        <span class="font-medium"> Guestio </span>
        <span class="font-light"> Connect </span>
      </h3>

    </div>

    <div class="body px-8 pt-4">
      <p class="text-sm text-blue-800 mb-4">
        Explore recommended {{ candidatesType }} specially selected for you.
      </p>

      <div class="flex flex-col items-center">
        <div class="mb-4">
          <img src="@/assets/images/connect-illustration.png" alt="" />
        </div>

        <div class="flex flex-col md:flex-row w-10/12 space-y-4 md:space-y-0 space-x-0 md:space-x-4">
          <button v-if="user.shows && user.shows.length" class="guestio-connect-btn bg-indigo-gradiant"
            @click="openGuestioConnect('guest')">
            Explore Guests
          </button>
          <button v-if="user.guest" class="guestio-connect-btn bg-indigo-gradiant" @click="openGuestioConnect('show')">
            Explore Shows
          </button>
        </div>
      </div>
    </div>

    <GuestioConnect ref="guestioConnect" />
  </div>
</template>

<script>
import GuestioConnect from "@/components/guestio-connect/GuestioConnect";
import AttachmentIcon from "@/components/svgs/AttachmentIcon";
import { mapGetters } from 'vuex';
export default {
  name: "GuestioConnectCard",
  components: { GuestioConnect, AttachmentIcon },
  computed: {
    ...mapGetters({
      previousPage: 'dashboard/bookings/previousPage',
      guestioConnectType: "guestioConnect/guestioConnectType",
    }),

    user() {
      return this.$store.getters["auth/getUser"];
    },
    candidatesType() {
      if (this.user.guest && this.user.shows && this.user.shows.length) {
        return "guests and shows";
      }
      else return this.user.guest ? 'shows' : 'guests';
    }
  },
  mounted() {
    if (this.previousPage == 'guestio_connect') {
      this.openGuestioConnect(this.guestioConnectType);
    }
  },
  methods: {
    openGuestioConnect(type) {

      if (this.user.account_type == 'pro') {
        this.$refs.guestioConnect.init(type);
      } else {
        let pushData = {
          showing: type
        }
        this.$router.push({ name: 'Browse', query: pushData })
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.connect-card {
  @apply w-full shadow-lg rounded-3xl bg-white;
}

.topbar {
  @apply flex items-center gap-3 px-8 pt-4 pb-2 text-white bg-customPurple rounded-tl-3xl rounded-tr-3xl;

  .icon-container {
    @apply p-3 rounded-full bg-white bg-opacity-25 flex justify-center items-center;
  }
}

.guestio-connect-btn {
  @apply rounded-full w-full py-4 text-lg flex items-center justify-center text-white;
}
</style>
