import api from '@/api'

const home = {
  namespaced: true,

  state: {
    featured: {
      guests: [],
      shows: [],
    },
    guestioTalent: {
      guests: [],
      shows: [],
    },
    free: {
      guests: [],
      shows: [],
    },
    allProfiles: {
      guests: [],
      shows: [],
    },
    premium: {
      guests: [],
      shows: [],
    },
    categories: [],
    incremeted: false,
  },

  getters: {
    filledCategories(state) {
      return state.categories.filter(category => {
        return category.counts.shows > 0 || category.counts.guests > 0
      })
    }
  },

  actions: {
    fetchData({commit}, payload) {
      let ref = ''
      if (payload.ref) {
        ref = `?ref=${payload.ref}`
      }
      return api.get(`/referral-cookie${ref}`).then(() => {
        return api.get('/home')
          .then(({data}) => {
            commit('setHomeData', data.data)
            return Promise.resolve(data)
          })
          .catch(error => {
              return Promise.reject(error)
            }
          )
      })
    },

    fetchPremium({commit}, payload) {
      let ref = ''
      if (payload.ref) {
        ref = `?ref=${payload.ref}`
      }
      return api.get(`/referral-cookie${ref}`).then(() => {
        return api.get('/home?filter=premium')
          .then(({data}) => {
            commit('setPremium', data.data)
            return Promise.resolve(data)
          })
          .catch(error => {
              return Promise.reject(error)
            }
          )
      })
    },

    fetchFeatured({commit}, payload) {
      let ref = ''
      if (payload.ref) {
        ref = `?ref=${payload.ref}`
      }
      return api.get(`/referral-cookie${ref}`).then(() => {
        return api.get('/home?filter=featured')
          .then(({data}) => {
            commit('setFeatured', data.data)
            return Promise.resolve(data)
          })
          .catch(error => {
              return Promise.reject(error)
            }
          )
      })
    },

    fetchGuestioTalent({commit}, payload) {
      let ref = ''
      if (payload.ref) {
        ref = `?ref=${payload.ref}`
      }
      return api.get(`/referral-cookie${ref}`).then(() => {
        return api.get('/home?filter=is_guestio_talent')
          .then(({data}) => {
            commit('setGuestioTalent', data.data)
            return Promise.resolve(data)
          })
          .catch(error => {
              return Promise.reject(error)
            }
          )
      })
    },

    fetchFree({commit}, payload) {
      let ref = ''
      if (payload.ref) {
        ref = `?ref=${payload.ref}`
      }
      return api.get(`/referral-cookie${ref}`).then(() => {
        return api.get('/home?filter=free')
          .then(({data}) => {
            commit('setFreeProfiles', data.data)
            return Promise.resolve(data)
          })
          .catch(error => {
              return Promise.reject(error)
            }
          )
      })
    },

    fetchCategories({commit}, payload) {
      let ref = ''
      if (payload.ref) {
        ref = `?ref=${payload.ref}`
      }
      return api.get(`/referral-cookie${ref}`).then(() => {
        return api.get('/categories')
          .then(({data}) => {
            commit('setHomeCategories', data.data)
            return Promise.resolve(data)
          })
          .catch(error => {
              return Promise.reject(error)
            }
          )
      })
    },

    incrementReferralViews({commit}, payload) {

      return api.put(`/increment-views/${payload}`).then(() => {
        commit('setIncremented', true);
      })
          .catch(error => {
              commit('setIncremented', false);
              return Promise.reject(error)
            }
          )
    },
  },

  mutations: {
    setHomeData(state, data) {
      state.featured = data.featured
      state.guestioTalent = data.is_guestio_talent
      state.free = data.free
      state.categories = data.categories
    },
    setFeatured(state, data) {
      state.featured = data
    },
    setGuestioTalent(state, data) {
      state.guestioTalent = data
    },
    setPremium(state, data) {
      state.premium = data
    },
    setAllProfiles(state, data) {
      state.allProfiles = data
    },
    setFreeProfiles(state, data) {
      state.free = data
    },
    setHomeCategories(state, data) {
      state.categories = data
    },
    setIncremented(state, data) {
      state.incremeted = data
    },
  },
}

export default home
