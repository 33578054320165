<template>
  <div
    class="col-span-12 lg:col-span-5 rounded-3xl bg-white shadow-xl overflow-hidden relative conversation-card border"
  >
    <template v-if="show">
      <!--Header-->
      <ConversationHeader :booking="booking" :user="user"/>
      <!--Content-->
      <div id="messages" ref="messages" class="w-full h-full overflow-y-auto p-4 lg:px-10 lg:pb-10 messages">
        <div v-for="(message, index) in messages" :key="`message-${message.id}`">
          <!--Separator-->
          <Separator
            :date="message.created_at"
            :prev-date="index > 0 ? messages[index - 1].created_at : null"/>
          <!--Message-->
          <Message :message="message" :own="message.sender.user_id === user.id" background="bg-white"/>
        </div>
      </div>
      <!--Footer-->
      <ConversationFooter :booking="booking" @sent="scrollMessages"/>
    </template>
    <template v-else>
      <div v-if="loading" class="flex justify-center items-center h-full">
        <loading-icon class="h-10 w-10 text-pink-500"/>
      </div>
      <div v-else class="w-full h-full overflow-y-auto p-10 flex justify-center items-center flex-col">
        <span class="font-semibold text-2xl text-gray-800 mb-2">
         Welcome to Messages!
        </span>
        <span class="text-gray-500">
          Here you'll find your booking messages
        </span>
      </div>
    </template>
  </div>
</template>

<script>
import ConversationHeader from "@/components/messages/Conversation/partials/ConversationHeader";
import Separator from "@/components/messages/Conversation/partials/Separator";
import Message from "@/components/bookings/individual/activity/Message";
import ConversationFooter from "@/components/messages/Conversation/partials/ConversationFooter";

export default {
  name: "Conversation",
  components: {ConversationFooter, Message, Separator, ConversationHeader},

  props: {
    user: Object
  },

  data() {
    return {
      show: false,
      loading: false,
      bookingId: null
    };
  },

  mounted() {
    window.Event.$on("goToConversation", bookingId => {
      this.fetchMessages(bookingId);
    });
  },

  methods: {
    fetchMessages(bookingId) {
      this.loading = true;
      this.messageError = false;

      this.$store
        .dispatch("dashboard/bookings/fetchBookingMessages", {
          id: bookingId
        })
        .then(() => {
          this.show = true;
          this.bookingId = bookingId;
          this.$store.dispatch('dashboard/bookings/fetchBooking', {id: bookingId})
          this.scrollMessages();

          if (this.unreadMessages.length) {
            this.markMessagesAsRead().then(() => {
              this.$store.dispatch('user/fetchUnreadMessages', this.user.id)
              window.Event.$emit('markedAsRead')
            });
          }
        })
        .catch(() => (this.messageError = true))
        .finally(() => (this.loading = false));
    },

    scrollMessages() {
      

      this.$nextTick().then(() => {
        if (!this.$refs.messages) {
        return;
      }
        setTimeout(() => {
          this.$refs.messages.scrollTo({top: 99999999, behavior: "smooth"});
        }, 200);
      });
    },

    markMessagesAsRead() {
      return this.$store.dispatch("dashboard/bookings/markMessagesAsRead", {
        id: this.bookingId
      });
    }
  },

  computed: {
    messages() {
      return this.$store.getters["dashboard/bookings/bookingMessages"];
    },
    booking() {
      return this.$store.getters['dashboard/bookings/booking']
    },

    unreadMessages() {
      return this.messages.filter(message => {
        return !message.read_at && message.sender.user_id != this.user.id
      })
    },
  },

  destroyed() {
    window.Event.$off('goToConversation')
  }
};
</script>

<style scoped>
@media (min-width: 1024px) {
  .conversation-card {
    height: 672px;
  }
}

@media (min-width: 1024px) {
  .messages > *:last-child {
    margin-bottom: 15rem !important;
  }

}

.messages > *:last-child {
  margin-bottom: 6rem;
}


</style>
