<template>
  <div v-on-clickaway="close" :class="{ 'w-full lg:w-3/12': isOpen }"
    class="w-0 inline-block sidebar bg-black text-white flex-shrink-0 transition-all duration-150 ease-out">
    <div class="px-6 py-8">
      <!-- Navigation -->
      <ul class="mb-12">
        <li @click="close">
          <router-link :to="{ name: 'Browse' }" class="text-4xl flex items-center ">

            Browse
            <SearchIcon class="h-full w-7 ml-4" />
          </router-link>
        </li>
      </ul>
      <!-- Others -->
      <ul class="mb-12">
        <li @click="close" v-if="authenticated" class="mb-4">
          <router-link :to="{ name: 'Dashboard' }" class="flex items-center">
            <span class="mr-5">Dashboard</span>
          </router-link>
        </li>
        <li @click="close" v-if="authenticated" class="mb-4">
          <router-link :to="{ name: 'Bookings' }" class="flex items-center">
            <span class="mr-5">Bookings</span>
            <span v-if="bookingNotificationCount" class="rounded-full bg-pink-500 text-white px-3">
              {{ bookingNotificationCount }}
            </span>
          </router-link>
        </li>
        <li @click="close" v-if="authenticated" class="mb-4">
          <router-link :to="{ name: 'Settings' }" class="flex items-center">
            <span class="mr-5">Settings</span>
          </router-link>
        </li>
        <li @click="close" v-if="authenticated" class="mb-4">
          <router-link :to="{ name: 'GeneralMessages' }" class="flex items-center">
            <span class="mr-5">Messages</span>
            <span v-if="messages.length" class="rounded-full bg-pink-500 text-white px-3">
              {{ messages.length }}
            </span>
          </router-link>
        </li>
        <li @click="close" v-if="!authenticated" class="mb-4">
          <router-link :to="{ name: 'Login' }" class="flex items-center">
            <span class="mr-5">Log in</span>
          </router-link>
        </li>
        <li v-else class="mb-4">
          <button @click="logout" class="flex items-center">
            <span class="mr-5">Log out</span>
          </button>
        </li>
      </ul>
      <hr class="mb-12 border border-lightGray" />
      <!-- More -->
      <ul class="mb-12">
        <li @click="close" class="mb-2">
          <router-link :to="{ name: 'About' }">About Us</router-link>
        </li>
        <li @click="close" class="mb-2">
          <router-link :to="{ name: 'Contact' }">Contact Us</router-link>
        </li>
        <li @click="close" class="mb-2">
          <router-link :to="{ name: 'PrivacyPolicy' }" title="Privacy Policy ">Privacy Policy</router-link>
        </li>
        <li @click="close" class="mb-2">
          <a href="https://www.iubenda.com/privacy-policy/52449323/cookie-policy" title="Cookie Policy ">Cookie
            Policy</a>
        </li>
        <li @click="close" class="mb-2">
          <router-link :to="{ name: 'TermsAndConditions' }">Terms And Conditions</router-link>
        </li>
      </ul>
      <!-- Social media -->
      <!--Facebook-->
      <div class="flex items-center">
        <a href="https://www.facebook.com/travis.chappell15">
          <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23 11.5C23 5.14805 17.852 0 11.5 0C5.14805 0 0 5.14805 0 11.5C0 17.852 5.14805 23 11.5 23C11.5674 23 11.6348 23 11.7021 22.9955V14.0471H9.23145V11.1676H11.7021V9.04727C11.7021 6.59004 13.2025 5.25137 15.3947 5.25137C16.4459 5.25137 17.3488 5.32773 17.6094 5.36367V7.9332H16.1C14.9096 7.9332 14.676 8.49922 14.676 9.33027V11.1631H17.5285L17.1557 14.0426H14.676V22.5553C19.4826 21.1762 23 16.7514 23 11.5Z"
              fill="white" />
          </svg>
        </a>
        <!-- Instagram -->
        <a href="https://www.instagram.com/travischappell/" class="mx-5">
          <svg width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.6436 0H4.39488C3.38341 0 2.41394 0.40208 1.69917 1.11689C0.984401 1.83171 0.582275 2.80118 0.582275 3.8126V19.0614C0.582275 20.0728 0.984356 21.0423 1.69917 21.7571C2.41398 22.4718 3.38346 22.874 4.39488 22.874H19.6436C20.6551 22.874 21.6246 22.4719 22.3393 21.7571C23.0541 21.0423 23.4562 20.0728 23.4562 19.0614V3.8126C23.4562 2.80113 23.0542 1.83166 22.3393 1.11689C21.6245 0.402126 20.6551 0 19.6436 0ZM21.5495 19.0614H21.5504C21.5504 19.5671 21.3494 20.0523 20.992 20.4097C20.6345 20.7671 20.1494 20.9682 19.6436 20.9682H4.39488C3.34141 20.9682 2.48812 20.1149 2.48812 19.0614V3.81265C2.48812 2.75918 3.34144 1.90589 4.39488 1.90589H19.6436C20.1494 1.90589 20.6345 2.10693 20.992 2.46434C21.3494 2.82174 21.5504 3.30693 21.5504 3.81265L21.5495 19.0614Z"
              fill="white" />
            <path
              d="M12.0191 5.71851C10.5028 5.71851 9.04821 6.32073 7.97588 7.39385C6.90277 8.46607 6.30054 9.92074 6.30054 11.4371C6.30054 12.9534 6.90277 14.4079 7.97588 15.4803C9.0481 16.5534 10.5028 17.1556 12.0191 17.1556C13.5354 17.1556 14.99 16.5534 16.0623 15.4803C17.1354 14.4081 17.7377 12.9534 17.7377 11.4371C17.7377 9.92074 17.1354 8.46618 16.0623 7.39385C14.9901 6.32073 13.5354 5.71851 12.0191 5.71851ZM12.0191 15.2497C11.0076 15.2497 10.0382 14.8476 9.32339 14.1328C8.60862 13.418 8.20649 12.4485 8.20649 11.4371C8.20649 10.4256 8.60858 9.45613 9.32339 8.74136C10.0382 8.02659 11.0077 7.62446 12.0191 7.62446C13.0305 7.62446 14 8.02654 14.7148 8.74136C15.4296 9.45617 15.8317 10.4256 15.8317 11.4371C15.8317 12.4485 15.4296 13.418 14.7148 14.1328C14 14.8475 13.0305 15.2497 12.0191 15.2497Z"
              fill="white" />
            <path
              d="M19.6434 5.24237C19.6434 6.03134 19.0037 6.672 18.2138 6.672C17.4248 6.672 16.7842 6.03134 16.7842 5.24237C16.7842 4.45251 17.4248 3.81274 18.2138 3.81274C19.0037 3.81274 19.6434 4.45251 19.6434 5.24237Z"
              fill="white" />
          </svg>
        </a>
        <!-- Youtube -->
        <a href="https://www.youtube.com/channel/UC0ex2Vz6Jj9cTSiHy7KTICA">
          <svg width="34" height="23" viewBox="0 0 34 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M28.2462 0H5.75385C2.57657 0 0 2.51763 0 5.62222V17.3778C0 20.4824 2.57657 23 5.75385 23H28.2462C31.4234 23 34 20.4824 34 17.3778V5.62222C34 2.51763 31.4234 0 28.2462 0ZM18.4385 14.0556L13.3385 16.6111V6.38889L23.5385 11.5L18.4385 14.0556Z"
              fill="white" />
          </svg>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import SearchIcon from "@/components/svgs/SearchIcon";
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: "Sidebar",
  components: { SearchIcon },
  mixins: [clickaway],

  methods: {
    ...mapMutations({
      closeSideBar: 'SET_SIDEBAR'
    }),
    logout() {
      this.$store.dispatch('auth/logout').then(response => {
        console.log(response);
        this.close();
        this.$router.push({ name: 'Home' })
      })
    },
    close() {
      if (!this.mobile) document.documentElement.style.overflow = "auto";
      this.closeSideBar({ isOpen: false })
    }
  },
  mounted() {
    if (this.authenticated && this.user.id) {
      this.$store.dispatch('dashboard/bookings/fetchBookings')
      this.$store.dispatch('user/fetchUnreadMessages', this.user.id)
    }
  },
  computed: {
    ...mapState({
      isOpen: state => state.isOpen,
      messages: state => state.user.unreadMessages,
      bookings: (state) => state.dashboard.bookings.withoutFilters,
    }),
    authenticated() {
      return this.$store.getters['auth/authenticated']
    },
    user() {
      return this.$store.getters['auth/getUser']
    },
    bookingNotificationCount() {
      let newBookings = 0;

      if (!this.bookings) {
        return newBookings;
      }

      this.bookings.data.forEach(booking => {
        if (booking.new) {
          newBookings += 1;
        }
      });

      return newBookings;
    },
  }
};
</script>

<style>
.sidebar {
  transition: all ease-in-out 0.3s;
}
</style>
