<template>
  <div class="col-span-12 lg:col-span-3 rounded-3xl overflow-hidden shadow-xl message-list border">
    <!--Header-->
    <MessageListHeader @filterConversations='filterConversations'/>
    <!--Messages-->
    <div id="messagesList" class="overflow-y-auto h-full">
      <div v-for="(bookingMessages, index) in bookingsMessages" :key="index">
        <MessageItem
          v-if="bookingMessages.lastMessage"
          :message="bookingMessages.lastMessage"
          :newMessagesCount="bookingMessages.newMessagesCount"
        />
      </div>
    </div>
  </div>
</template>
<script>
import MessageListHeader from "@/components/messages/MessagesList/partials/MessageListHeader";
import MessageItem from "@/components/messages/MessagesList/partials/MessageItem";

export default {
  name: 'index',
  components: {MessageItem, MessageListHeader},
  props: {
    bookingsMessages: Array
  },

  data() {
    return {
      filteredConversations: this.bookingsMessages
    }
  },

  methods: {
    filterConversations(query) {
      if (query != '') {
        let filtered = {};
        for (const conv in this.filteredConversations) {
          filtered[conv] = this.filteredConversations[conv].filter(message => message.body.includes(query))
        }
        this.filteredConversations = filtered;
      } else {
        this.filteredConversations = this.bookingsMessages;
      }
    }
  },
}
</script>

<style>
.message-list{
  height: 300px;
}

@media (min-width: 1024px) {
  .message-list{
    height: 480px;
  }
}
#messagesList > *:last-child {
  margin-bottom: 5rem !important;
}
</style>
