<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.25 3H3C2.60218 3 2.22064 3.15804 1.93934 3.43934C1.65804 3.72064 1.5 4.10218 1.5 4.5V15C1.5 15.3978 1.65804 15.7794 1.93934 16.0607C2.22064 16.342 2.60218 16.5 3 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V9.75"
          stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M13.875 1.87517C14.1734 1.5768 14.578 1.40918 15 1.40918C15.422 1.40918 15.8266 1.5768 16.125 1.87517C16.4234 2.17354 16.591 2.57821 16.591 3.00017C16.591 3.42213 16.4234 3.8268 16.125 4.12517L9 11.2502L6 12.0002L6.75 9.00017L13.875 1.87517Z"
          stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon',
}
</script>

<style lang="css" scoped></style>
