<template>
  <modal
    :with-close-button="false"
    @closed="working = false"
  >
    <slot />
    <template v-slot:content="{ close }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
          <div class="flex justify-between items-center">
            <div>
              <h3 class="font-bold font-poppins text-blue-800 text-xl">
                Invite Team Member
              </h3>
            </div>
            <div>
              <button @click="close" type="button" class="text-gray-500 p-1 rounded focus:shadow-outline">
                <XIcon class="w-6 h-6" />
              </button>
            </div>
          </div>

          <ValidationObserver ref="memberForm" v-slot="{ handleSubmit }">
            <form ref="form" @submit.prevent="handleSubmit(addMember(close))" method="post" class="space-y-6  ">
              <div class="text-sm text-blue-800 font-medium mt-6">

                <div class="flex items-center space-x-4">
                  <div class="flex-1">
                    <ValidationProvider mode="lazy" rules="required|max:255|email" vid="email" name="Email" v-slot="{ errors }">
                      <TextInput class="mt-2" ref="inputContainer" v-model="form.email" type="email" :errors="errors" placeholder="Email Address"/>
                    </ValidationProvider>
                  </div>

                  <div>
                    <ValidationProvider mode="lazy" rules="required" vid="role" name="Role" v-slot="{ errors }">
                      <span class="mt-2 flex items-center space-x-2">
                        <select name="role" class="form-select rounded-full capitalize bg-gray-50 inline-block px-8 py-4 border-transparent rounded-full focus:shadow-3xl focus:border-indigo-500 text-sm text-black" v-model="form.role">
                          <option value="admin">Basic</option>
                          <option value="agency">Agency</option>
                        </select>

                        <button type="button" v-tooltip="roleTooltip(form.role)">
                          <InfoIcon class="h-5 w-5 text-grayDark" />
                        </button>
                      </span>
                      <p v-if="errors.length" >
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>
                <p class="mt-2 text-gray-500 text-sm">
                  We'll send a team invitation to the provided email address.
                </p>
              </div>

              <div>
              </div>

              <div class="flex items-center justify-between mt-6">
                <button type="submit" class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-8 h-11 flex items-center justify-center disabled:opacity-60 relative" :disabled="working">
                  <span v-if="working" class="absolute">
                    <LoadingIcon class="text-white h-2" />
                  </span>
                  <span :class="{'text-transparent': working}">
                    Invite
                  </span>
                </button>

                <button @click="close" type="button" class="text-pink-500 font-bold text-sm">
                  Cancel
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import api from '@/api'
  import Modal from '@/components/shared/Modal'
  import XIcon from '@/components/svgs/XIcon'
  import InfoIcon from '@/components/svgs/InfoIcon'
  import LoadingIcon from '@/components/shared/LoadingIcon'
  import TextInput from "@/components/shared/TextInput"

  export default {
    name: 'AddTeamMemberDialog',

    props: {
      //
    },

    components: {
      Modal,
      XIcon,
      LoadingIcon,
      TextInput,
      InfoIcon,
    },

    data() {
      return {
        working: false,
        form: {
          email: '',
          role: 'admin',
        }
      }
    },

    methods: {
      roleTooltip(type) {
        if (type === 'agency') {
          return 'AGENCY level allows users to manage everything on your profiles, including Stripe connect setup.'
        }

        return 'BASIC level allows users to manage your profiles with basic actions that do not require primary account login.'
      },

      addMember(callback) {
        this.working = true
        api
          .post('/users/me/team/members', this.form)
          .then(({ data }) => {
            this.form.email = ''
            this.form.role = 'admin'
            this.$emit('invited', data.invitation)
            callback()
            this.$toast.success('Invitation sent!')
          })
          .catch(() => {
            this.$toast.error('The invitation was not sent!')
          })
          .finally(() => this.working = false)
      },
    }
  }
</script>
