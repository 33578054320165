<template>
  <div class="dashboard-card border border-gray-50">
    <h3>Details</h3>
    <div class="grid grid-cols-1 gap-6 text-sm lg:text-base">
      <div class="flex justify-between">
        <span class="text-gray-600">Status</span>
        <span class="text-black capitalize">{{ booking.status }}</span>
      </div>
      <div v-if="booking.booking_option" class="flex justify-between">
        <span class="text-gray-600">Type</span>
        <span class="text-black">
            <span v-if="booking.is_pitch">Pitch for <em>{{ booking.booking_option.name }}</em></span>
            <span v-else>{{ booking.booking_option.name }}</span>
            <span v-if="booking.booking_option.slug === 'miniview' && booking.status != 'pending'" class="text-gray-500">
              [<router-link :to="`/bookings/${booking.id}/miniview`" class="text-pink-500">show</router-link>]
            </span>
        </span>
      </div>

      <div v-if="userOwnsBookingShow" class="flex justify-between">
        <span class="text-gray-600">Show</span>
        <span class="text-black">{{ booking.show.name }}</span>
      </div>

      <div v-if="booking.booking_option_variation" class="flex justify-between">
        <span class="text-gray-600">
          Interview Media
        </span>
        <span class="text-black capitalize">
            {{ booking.booking_option_variation }}
        </span>
      </div>
      <div v-if="booking.booking_option && booking.booking_option.slug === 'media-feature'" class="flex justify-between">
        <span class="text-gray-600">Interview</span>
        <span class="text-black">{{ booking.booking_option_amount }} Questions</span>
      </div>
      <div v-if="booking.interview_duration > 0" class="flex justify-between">
        <span class="text-gray-600">Interview Duration</span>
        <span class="text-black">{{ booking.interview_duration }} Minutes</span>
      </div>
      <div class="flex justify-between">
        <span class="text-gray-600">Total Price</span>
        <span class="text-black">${{ totalPrice }}</span>
      </div>
      <div v-if="canEditPitchFee" class="flex justify-end">
        <EditPitchFeeModal />
      </div>
      <div v-if="booking.upsellPackages && booking.upsellPackages.length" class="flex justify-between">
        <span>
          <modal class="text-left text-pink-500 hover:underline">
            Promotional Upsells
            <template #content>
              <booking-upsell-packages :upsell-packages="booking.upsellPackages" />
            </template>
          </modal>
        </span>
        <span class="text-black">${{ ((upsellsValue)/100).toFixed(2) }}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-gray-600">Payment Status</span>
        <span class="text-black capitalize">{{ booking.payment_status }}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-gray-600">Order Number</span>
        <span class="text-black">#000{{ booking.id }}</span>
      </div>
      <template v-if="! booking.is_expired && booking.status == 'pending'">
        <div class="flex justify-between">
          <span class="text-gray-600">Expires On</span>
          <span class="text-black">{{ `${moment(booking.expires_at).format('LLL')}${userTimezone ? ` (${userTimezone})` : ''}` }}</span>
        </div>
      </template>
      <template v-if="booking.is_expired">
        <div class="flex justify-between">
          <span class="text-gray-600">Expired On</span>
          <span class="text-black">{{ `${moment(booking.expires_at).format('LLL')}${userTimezone ? ` (${userTimezone})` : ''}` }}</span>
        </div>
      </template>

      <template v-if="booking.guaranteed_response_by && $moment(booking.guaranteed_response_by).isAfter($moment())">
        <div class="flex justify-between">
          <span class="text-gray-600">Guaranteed Response By</span>
          <span class="text-customPurple">{{ `${moment(booking.guaranteed_response_by).format('LLL')}${userTimezone ? `( ${userTimezone})` : ''}` }}</span>
        </div>
      </template>

      <template>
        <div class="flex justify-between">
          <span class="text-gray-600">Date</span>
          <span class="text-black">{{booking.is_confirmed ? `${moment(booking.confirmed_date).format('LLL')}${userTimezone ? ` (${userTimezone})` : ''}` : '-' }}</span>
        </div>
      </template>
    </div>

    <template v-if="booking.type == 'normal'">
      <NormalBookingActions />
    </template>


    <div v-if="userIsInitiator && booking.status === 'completed' && booking.confirmed_by_invitee" class="mt-12 flex justify-center">
      <ReportNoShow :booking="booking" class="text-red-600">
        Report No-Show
      </ReportNoShow>
    </div>

    <div v-if="shouldShowActions" class="mt-6 -m-8 px-8 py-5 border-t border-guestio-dark-300">
      <template v-if="booking.confirmed_date">
        <modal class="text-sm w-full text-violet" key="reschedule-date-container">
          Re-Schedule Booking
          <template #content="{ close }">
            <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden self-start my-4">
              <Calendar :single="true" @close="close" @save="updateBooking($event, close)" />
            </div>
          </template>
        </modal>
      </template>

      <template v-else-if="userActiveAction && userActiveAction.properties.type == 'action-accept-reject'">
        <PitchActions v-if="booking.is_pitch" key="accept-pitch"/>
        <DetailsActionAcceptReject v-else key="accept" />
      </template>

      <template v-else-if="userActiveAction && userActiveAction.properties.type == 'action-extra-payment'">
        <DetailsActionExtraPayment key="accept-extra-payment" />
      </template>

      <template v-else-if="userActiveAction && userActiveAction.properties.type == 'action-pick-dates'">
        <DetailsPickDates  :activity="userActiveAction" key="calendar" />
      </template>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import moment from 'moment';
  import Modal from '@/components/shared/Modal'
  import NormalBookingActions from '@/components/bookings/individual/NormalBookingActions';
  import ReportNoShow from '@/components/bookings/individual/ReportNoShow';
  import BookingUpsellPackages from '@/components/bookings/individual/UpsellPackages';
  import DetailsPickDates from '@/components/bookings/individual/DetailsPickDates';
  import DetailsActionAcceptReject from "@/components/bookings/individual/activity/DetailsActionAcceptReject";
  import DetailsActionExtraPayment from "@/components/bookings/individual/activity/DetailsActionExtraPayment";
  import PitchActions from "@/components/bookings/individual/activity/PitchActions";
  import EditPitchFeeModal from "@/components/bookings/individual/activity/EditPitchFeeModal";
  import Calendar from '@/components/bookings/individual/Calendar';

  import timezones from '@/mixins/timezones';

  export default {
    name: "DetailsCard",

    components: {
      NormalBookingActions,
      Modal,
      BookingUpsellPackages,
      ReportNoShow,
      DetailsPickDates,
      DetailsActionAcceptReject,
      DetailsActionExtraPayment,
      Calendar,
      PitchActions,
      EditPitchFeeModal,
    },

    mixins: [timezones],

    data() {
      return {
        loading: true,
        activityError: false,
        sorting: 'asc',
        interval: null,
        showDetails: true,
      }
    },

    watch: {
      sorting() {
        this.fetchActivities()
      },
    },

    computed: {
       ...mapState({
        user: state => state.auth.user,
        activities: state => state.dashboard.bookings.bookingActivities,
        booking: state => state.dashboard.bookings.booking,
      }),

      shouldShowActions() {
        return (this.booking.confirmed_date && !this.booking.is_canceled)
          || (this.userActiveAction && this.isActionable(this.userActiveAction) && !this.booking.is_canceled);
      },

      userActiveAction() {
        const userActions = this.filteredActivities.filter(activity => {
          return this.isActionable(activity);
        });

        return userActions.length ? userActions.pop() : null;
      },

      userOwnsBookingGuest() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingGuest']
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      bookingStatus() {
        return this.$store.getters['dashboard/bookings/bookingStatus']
      },

      proposedTimes() {
        return this.booking.proposed_dates
      },

      userOwnsBookingShow() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
      },

      upsellsValue() {
        return this.booking.upsellPackages.reduce((total, upsell) => {
          return upsell.details.price + total
        }, 0)
      },

      userIsInitiator() {
        return this.$store.getters['dashboard/bookings/userIsInitiator']
      },

      filteredActivities() {
        let activities = this.activities

        // remove all actions if the booking has a date, is canceled or expired
        // or has been scheduled (and paid if needed) without date (outside Guestio)
        const extraPayment = activities.find(activity => activity.properties.type == 'action-extra-payment');
        let hasPaid = true;
        if (extraPayment) {
          hasPaid = activities.find(activity => activity.properties.type == 'success' && activity.properties.payment == true)
        }

        if (
          this.booking.confirmed_date 
          || (this.booking.scheduled_without_date && (hasPaid || this.booking.confirmed_date))
          || ['canceled', 'expired'].includes(this.booking.status)
        ) {
          activities = activities.filter(activity => !activity.properties.type.startsWith('action'))
        }

        // remove the initial accepte/reject action
        if (this.booking.accepted_at) {
          activities = activities.filter(activity => activity.properties.type != 'action-accept-reject')
        }

        // remove date proposer
        if (this.booking.confirmed_date) {
          activities = activities.filter(activity => activity.properties.type != 'action-pick-dates')
        }

        return activities;
      },

      canEditPitchFee() {
        return !this.userIsInitiator && this.booking.is_pitch && this.userActiveAction &&
        (this.userActiveAction.properties.type == 'action-extra-payment' ||
        this.userActiveAction.properties.type == 'action-pick-dates' && this.booking.proposed_dates.length == 0);
      },

      totalPrice(){
        return ((this.booking.price + this.booking.guestio_cash)/100).toFixed(2);
      }
    },

    methods: {
      moment(date) {
        return moment.utc(date).local()
      },

      isActionable(activity) {
        return [
          'action-accept-reject',
          'action-pick-dates',
          'action-extra-payment',
        ].includes(activity.properties.type)
      },

      fetchData() {
        this.fetchActivities();
      },

      fetchActivities() {
        this.loading = true
        this.activityError = false

        this.$store.dispatch('dashboard/bookings/fetchBookingActivities', {
          id: this.$route.params.bookingId,
          sort: this.sorting,
        })
        .catch(() => this.activityError = true)
        .finally(() => this.loading = false)
      },

      silentFetchActivities() {
        this.$store.dispatch('dashboard/bookings/fetchBookingActivities', {
          id: this.$route.params.bookingId,
          sort: this.sorting,
        })
      },
      updateBooking(date, callback) {
        this.loading = true

        this.$store.dispatch('dashboard/bookings/changeBookingDate', {
          id: this.booking.id,
          confirmed_date: date.clone().utc().format('YYYY-MM-DD HH:mm:ss'),
        })
        .then(() => {
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
          callback.call()
          window.Event.$emit('refreshActivity')
          window.Event.$emit('refreshBooking')
        })
        .catch(() => {
          this.loading = false
          this.slotsError = false
        })
      },
    },

    mounted() {
      this.fetchData();
      window.Event.$on('refreshActivity', this.fetchActivities);
    },

    beforeDestroy() {
      window.Event.$off('refreshActivity')
      clearInterval(this.interval)
    }

  }
</script>

<style scoped>

</style>
