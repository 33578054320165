<template>
  <div class="flex flex-col rounded-3xl py-6 px-8 bg-white overflow-hidden">
    <div class="flex flex-col justify-center overflow-hidden">
      <cropper
        ref="cropper"
        class="cropper overflow-hidden"
        :src="imageUrl"
        :touchResize="false"
        :wheelResize="false"
        image-restriction="area"
        :min-height="minHeight"
        :min-width="minWidth"
        :stencil-props="{
          aspectRatio: aspectRatio
        }"
        :default-size="defaultSize"
        :size-restrictions-algorithm="pixelsRestriction"
      ></cropper>
      <span class="text-center text-xs w-2/3 mx-auto mt-5">If image doesn't appear after 30 seconds try changing the image and then try cropping again.</span>
    </div>
    <div class="flex items-center justify-end pt-5">
      <button @click.stop="cancel" class="text-pink-500 hover:text-pink-700">
        Cancel
      </button>
      <button @click="save" class="ml-4 btn">
        <span v-if="loading">
          <loading-icon class="h-5 w-5 mr-4"/>
        </span>
        <span v-else>Save</span>
      </button>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";

export default {
  name: "CropImage",
  components: { Cropper },
  props: {
    imageUrl: String,
    loading: Boolean,
    aspectRatio: { type: Number, required: false, default: 10 / 8 },
    minHeight: {type: Number, required: false, default: 400},
    minWidth: {type: Number, required: false, default: 400}
  },
  methods: {
    save() {
      const { canvas } = this.$refs.cropper.getResult();
      this.$emit("cropped", canvas);
    },

    cancel() {
      this.$emit("cancel");
    },

    pixelsRestriction({ minWidth, minHeight, imageWidth, imageHeight }) {
      return {
        minWidth: minWidth,
        minHeight: minHeight,
        maxWidth: imageWidth,
        maxHeight: imageHeight
      };
    },

    defaultSize({ maxWidth, maxHeight }) {
      return {
        width: maxWidth,
        height: maxHeight
      };
    }
  }
};
</script>

<style scoped>
.cropper {
  max-height: 500px;
  width: 100%;
}
</style>
