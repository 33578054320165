<template>
  <div class="bg-gradient-to-b h-auto sm:h-80 from-customPurple to-pink-300 rounded-3xl p-8">
      <div class="flex items-center space-x-3 mb-12">
        <div class="h-12 w-12 rounded-full bg-white bg-opacity-25 flex justify-center items-center"><Padlock/></div>
         <h2 class=" text-3xl text-white font-bold">Unlock Pro</h2>
      </div>
      
      <div class="text-white text-sm font-light flex sm:flex-row flex-col justify-between mb-3 sm:mb-6">
        <div class="w-12/12 sm:w-6/12 flex items-center"><CheckSmall class="mr-3"/>Homepage Feature</div>
        <div class="w-12/12 sm:w-6/12 flex items-center"><CheckSmall class="mr-3"/>Audio and Video Guest pitches</div>
      </div>
      <div class="text-white text-sm font-light flex sm:flex-row flex-col justify-between mb-12">
        <div class="w-12/12 sm:w-6/12 flex items-center"><CheckSmall class="mr-3"/>Personalized and Optimized Profile</div>
        <div class="w-12/12 sm:w-6/12 flex items-center"><CheckSmall class="mr-3"/>Unlimited Wishlist & much more!</div>
      </div>
      
      <button @click="showProSubscription" type="button" class=" bg-customPurple text-sm font-light text-white rounded-full px-10 sm:px-24 py-3">
          Go Pro Now
      </button>
  </div>
</template>

<script>
import CheckSmall from "@/components/svgs/CheckSmall";
import Padlock from "@/components/svgs/Padlock";
export default {
name:'UnlockPro',
components: {
  CheckSmall,
  Padlock
},

methods: {
  showProSubscription() {
    this.$router.push({name: 'Settings', hash: '#pro-subscription'});
  }
},
}
</script>