import { render, staticRenderFns } from "./CalendarDateIcon.vue?vue&type=template&id=0bcc4982&scoped=true"
import script from "./CalendarDateIcon.vue?vue&type=script&lang=js"
export * from "./CalendarDateIcon.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0bcc4982",
  null
  
)

export default component.exports