<template>
  <div class="flex flex-col md:flex-row">
    <div class="w-64 flex-shrink-0">
      <label class="text-gray-600" for="email">Email</label>
    </div>
    <div class="md:ml-8 flex-1 mt-1 md:mt-0">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span class="font-semibold">{{ userProp.email }}</span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="emailForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <ValidationProvider rules="required|email|max:255" vid="email" name="Email address" v-slot="{ errors }">
              <div>
                <div class="rounded-lg shadow-sm flex-1">
                  <label for="email" class="sr-only">Email address</label>
                  <input id="email" name="email" type="text" class="guestio-form-input" v-model="form.email" placeholder="Email" />
                </div>
                <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
              </div>
            </ValidationProvider>

            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel},

    props: {
      userProp: Object,
      admin: {
        type: Boolean,
        default: false,
      },
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          email: this.userProp.email
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.email = this.userProp.email
      }
    },

    computed: {
      updateAction() {
        if (this.admin) {
          return `auth/updateAdminUser`
        }

        return `auth/updateUser`
      }
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch(this.updateAction, this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.emailForm.setErrors(error.response.data.errors)
            }
          })
      }
    }
  }
</script>
