<template>
  <div class="dashboard-card border border-gray-50">
    <h3>Details</h3>
    <div class="grid grid-cols-1 gap-6 text-sm lg:text-base">
      <div class="flex justify-between">
        <span class="text-gray-600">Title</span>
        <span class="text-black capitalize">{{ miniview.title }}</span>
      </div>
      <div class="flex justify-between">
        <span class="text-gray-600">Created</span>
        <span class="text-black capitalize">{{ date }}</span>
      </div>

      <div>
        <p class="text-sm font-bold mb-2.5">Shareable link</p>
        <ShareableLink :url="shareableLink" />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from "vuex";
  import moment from 'moment';
  import ShareableLink from "@/components/shared/ShareableLink";

  export default {
    name: "DetailsCard",
    components: { ShareableLink },

    computed: {
      ...mapGetters({
        miniview: 'miniviews/current',
      }),

      date() {
        return moment.utc(this.miniview.created_at).local().format('YYYY-MM-DD')
      },

      shareableLink() {
        return `${process.env.VUE_APP_URL}/miniviews/${this.miniview.uuid}`
      }
    },
  }
</script>
