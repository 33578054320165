<template>
  <button class="px-10 py-8 flex items-center w-full justify-between" :class="{'bg-gray-50': activeBooking == bookingId}" @click.stop="goToConversation">
    <img class="rounded-full h-16 w-16 mr-5 object-cover" :src="senderImage" alt="">
    <div class="text-left flex flex-col w-full truncate">
      <h2 class="font-semibold text-lg">{{message.sender.name}}</h2>
      <p class="text-sm text-gray-500 truncate">{{  message.body | truncate(50, "...") }}</p>
      <span class="text-purple-50 text-sm">{{localTimestamp}}</span>
    </div>
    <div class="flex">
      <span v-if="newMessagesCount > 0" class="bg-red-500 rounded-full px-3 py-1 text-white text-xs ml-3 m-auto">
        {{newMessagesCount}}
      </span>
    </div>
  </button>
</template>

<script>
  import moment from 'moment'
  import { mapMutations, mapState } from "vuex"

  export default {
    name: 'MessageItem',
    props:{
      message:Object,
      newMessagesCount: Number
    },

    methods: {
      ...mapMutations({
        setActiveBooking: "dashboard/messages/setActiveBooking"
      }),

      goToConversation() {
        window.Event.$emit('goToConversation', this.bookingId);
        this.setActiveBooking(this.bookingId);
        this.$store.dispatch('user/fetchUnreadMessages', this.user.id)
      }
    },

    computed: {
      ...mapState({
        activeBooking: state => state.dashboard.messages.activeBooking,
        user: state => state.auth.user
      }),

      localTimestamp() {
        const today = moment();
        const daysDiff =  today.diff(this.message.created_at, 'days');
        if (daysDiff < 7) {
          if (daysDiff == 0) {
            return moment.utc(this.message.created_at).local().format('h:mm A')
          }
          return moment.utc(this.message.created_at).local().fromNow();
        } else {
          return moment.utc(this.message.created_at).local().format('MMM D, YYYY')
        }
      },
      senderImage() {
        const defaultImage = require('@/assets/images/test_image.png');
        return this.message.sender.imageUrl ? this.message.sender.imageUrl : defaultImage
      },

      bookingId() {
        return this.message.booking.id
      }
    }
  }
</script>
