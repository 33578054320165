<template>
  <div class="flex justify-between mb-10 w-full flex-col md:flex-row items-center">
    <div class="flex flex-col md:flex-row items-center w-full md:w-auto">
      <!-- Month selector-->
      <div
        class="flex justify-between items-center bg-white rounded-full overflow-hidden lg:mr-6 h-12 month-selector lg:mb-0 w-full lg:w-auto border border-grey-700">
        <button @click.prevent="prevMonth"
                class="flex-1 px-6 h-full hover:bg-gray-200 transition duration-150 ease-in-out">
          <ChevronLeftIcon/>
        </button>
        <span class="flex-shrink-0 px-5 font-semibold whitespace-no-wrap select-none month">{{ filters.month }}</span>
        <button @click.prevent="nextMonth"
                class="flex-1 px-6 h-full flex justify-end items-center hover:bg-gray-200 transition duration-150 ease-in-out">
          <ChevronRightIcon/>
        </button>
      </div>
      <!--Search input-->
      <div class="flex items-center rounded-full bg-white h-12 pl-6 pr-16 my-5 lg:my-0 w-full lg:w-auto border border-grey">
        <svg class="mr-3" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M9 17C13.4183 17 17 13.4183 17 9C17 4.58172 13.4183 1 9 1C4.58172 1 1 4.58172 1 9C1 13.4183 4.58172 17 9 17Z"
            stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.9999 19L14.6499 14.65" stroke="black" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        <input type="search" placeholder="Search..." class="bg-transparent" v-model="filters.search">
      </div>
    </div>
    <!--Select-->
    <div class="flex items-center px-5 h-12 bg-white rounded-full lg:mr-5 w-full lg:w-auto border border-grey">
      <select name="type" class="pl-8 pr-8 pb-1 bg-transparent py-0 leading-none w-full text-center" v-model="filters.type">
        <option value="all">All</option>
        <option value="incoming">Incoming</option>
        <option value="outgoing">Outgoing</option>
      </select>
    </div>
  </div>
</template>
<script>
  import moment from 'moment'
  import ChevronLeftIcon from "@/components/svgs/ChevronLeftIcon";
  import ChevronRightIcon from "@/components/svgs/ChevronRightIcon";
  import anime from "animejs";
  export default {
    name: 'PaymentFilters',
    components: {ChevronRightIcon, ChevronLeftIcon},
    computed: {
      filters() {
        return this.$store.getters['dashboard/payments/filters']
      }
    },

    watch: {
      filters: {
        deep: true,

        handler(filters) {
          this.$store.commit('dashboard/payments/setFilters', filters)
        }
      }
    },

    methods: {
      prevMonth() {
        anime({
          targets: '.month',
          translateX: [-100, 0],
          duration: 600,
        });
        this.filters.month = moment(this.filters.month, 'MMMM YYYY').date(15).subtract(1, 'month').format('MMMM YYYY')
      },

      nextMonth() {
        anime({
          targets: '.month',
          translateX: [100, 0],
          duration: 600,
        });
        this.filters.month = moment(this.filters.month, 'MMMM YYYY').date(15).add(1, 'month').format('MMMM YYYY')
      }
    }
  }
</script>

<style scoped>
button {
  transition: all ease .3s;
}

.month-selector {
  width: 100%;
}

@media (min-width: 1024px) {
  .month-selector {
    width: 300px;
  }
}
</style>
