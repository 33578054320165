<template>
  <img :class="[width,height,borderRadius, margin]" class="object-cover flex-shrink-0" :src="gravatarSrc"/>
</template>

<script>
export default {
  name: "DefaultProfileImage",

  props: {
    hash: String,
    width: {
      type: String,
      default: 'w-10'
    },
    height: {
      type: String,
      default: 'h-10'
    },
    borderRadius: {
      type: String,
      default: 'rounded-full'
    },
    margin: {
      type: String,
      default: 'mr-4'
    }
  },

  computed: {
    gravatarSrc() {
      return `https://www.gravatar.com/avatar/${this.hash}?s=100&r=g`
    }
  }
};
</script>
