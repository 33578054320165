<template>
  <modal
    :with-close-button="false"
  >
    Cancel Booking
    <template v-slot:content="{ close }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
          <div class="flex justify-between items-center">
            <div class="flex items-center space-x-2">
              <span class="rounded-full h-10 w-10 bg-pink-500 bg-opacity-10 flex items-center justify-center">
                <CheckAltIcon class="h-5 w-5 text-pink-500" />
              </span>
              <h3 class="font-bold font-poppins text-blue-800">
                Cancel Booking?
              </h3>
            </div>
            <div>
              <button @click="close" type="button" class="text-gray-500 p-1 rounded focus:shadow-outline">
                <XIcon class="w-6 h-6" />
              </button>
            </div>
          </div>

          <div class="text-sm text-blue-800 font-medium mt-6">
            Are you sure you want to cancel this booking? This action cannot be undone.
          </div>

          <div class="mt-4">
            <div class="flex justify-between items-center">
              <label for="reason" class="font-semibold text-blue-800 text-sm">Reason</label>
            </div>
            <div class="mt-1">
              <textarea v-model="reason" name="reason" id="reason" cols="10" class="form-textarea rounded-lg text-sm w-full"></textarea>
            </div>
          </div>

          <div class="flex items-center justify-between mt-6">
            <button @click="cancelBooking(close)" type="button" class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-8 h-11 flex items-center justify-center disabled:opacity-60 relative" :disabled="! reason.length || working">
              <span v-if="working" class="absolute">
                <LoadingIcon class="text-white h-2" />
              </span>
              <span :class="{'text-transparent': working}">
                Yes, Cancel
              </span>
            </button>

            <button @click="close" type="button" class="text-pink-500 font-bold text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/shared/Modal'
  import XIcon from '@/components/svgs/XIcon'
  import CheckAltIcon from '@/components/svgs/CheckAltIcon'
  import LoadingIcon from '@/components/shared/LoadingIcon'

  export default {
    components: {
      Modal,
      CheckAltIcon,
      XIcon,
      LoadingIcon,
    },

    data() {
      return {
        working: false,
        reason: ''
      }
    },

    methods: {
      cancelBooking(callback) {
        this.working = true

        this.$store.dispatch('dashboard/bookings/cancelBooking', {
          id: this.$route.params.bookingId,
          reason: this.reason,
        }).then(() => {
          callback.call()
          window.Event.$emit('refreshActivity')
        }).finally(() => {
          this.reason = ''
        })
      },
    }
  }
</script>
