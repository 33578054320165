<template>
  <div class="space-y-5">
    <div v-for="(answerGroup, index) in miniview.answer_groups" :key="`miniview-${index}`" class="border border-gray-50 max-w-2xl w-full shadow-lg rounded-xl bg-white">
      <router-link
        :to="{name: 'MiniviewAnswers', params: { answerGroupId: answerGroup.id }}"
        class="grid grid-cols-1 md:grid-cols-12 py-6 px-12 bg-white rounded-xl shadow-lg cursor-pointer border border-gray-50 hover:border-pink-500 transition duration-500"
      >
        <div class="col-span-5 flex md:flex-col items-center md:items-start">
          <span class="text-xs font-bold uppercase mr-2 md:mr-0">Name</span>
          <span class="font-light">{{ answerGroup.name }}</span>
        </div>
        <div class="col-span-3 flex md:flex-col items-center md:items-start">
          <span class="text-xs font-bold uppercase mr-2 md:mr-0">Submitted</span>
          <span class="font-light">{{ date(answerGroup.created_at) }}</span>
        </div>
        <div class="col-span-4 flex items-center md:justify-end">
          <button class="flex items-center text-gray-500 text-sm">
            <span>Details</span>
            <svg class="h-6 ml-1" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.50016 12.2093L13.3335 17.5L7.50016 22.7907" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import moment from 'moment';


  export default {
    name: "MiniviewAnswerGroups",

    computed: {
      ...mapGetters({
        miniview: 'miniviews/current',
      }),
    },

    methods: {
      date(date) {
        return moment.utc(date).local().format('YYYY-MM-DD')
      },
    },
  }
</script>
