<template>
  <div>
    <template v-if="activity.properties.scheduling_link">
      <span>
        <template v-if="(sharedBy === 'invitee' && userIsInvitee) || (sharedBy === 'initiator' && userIsInitiator)">
          You've shared a scheduling link:
        </template>

        <template v-else>
          <em>{{ sharedBy === 'invitee' ? initiator.name : invitee.name }}</em> shared a scheduling link:
        </template>
      </span>

      <div class="mt-4 bg-guestio-gray-100 rounded-lg border-2 border-pink-500 px-6 py-4">
        <a :href="activity.properties.scheduling_link" class="text-sm font-semibold text-purple-50 hover:underline" target="_blank" rel="noopener">
          {{ activity.properties.scheduling_link }}
        </a>
      </div>

      <div class="mt-4">
        <p class="text-sm">
          If you scheduled a time for your inverview using the scheduling link, please add it to the booking.
        </p>
        <modal class="mt-2 bg-purple-50 rounded-full text-white text-sm font-bold px-5 py-2">
          <span>
            Schedule Date/Time
          </span>
          <template v-slot:content="{ close }">
            <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden self-start my-4">
              <calendar @close="close" :single="true" @save="changeBookingDate($event, close)" />
            </div>
          </template>
        </modal>
      </div>
    </template>

    <template v-else>
      <template v-if="(proposedBy === 'invitee' && userIsInvitee) || (proposedBy === 'initiator' && userIsInitiator)">
        <template v-if="bookingHasProposedDates">
          <span>
            You have chosen {{ bookingHasProposedDates > 1 ? 'these possible dates' : 'the following date'}}, now let's wait for <em>{{ proposedBy === 'invitee' ? initiator.name : invitee.name }}</em> to {{ bookingHasProposedDates > 1 ? 'choose one.' : 'confirm it.' }}
          </span>
          <div class="grid grid-cols-5 my-3 col-gap-4">
            <label v-for="date in activity.properties.dates" :key="date" class="h-full rounded-xl bg-gray-50">
              <div class="flex flex-col p-5 rounded-xl items-center justify-center">
                <span class="font-roboto text-sm text-gray-500 whitespace-no-wrap">
                  {{ $moment.utc(date).local().format('MMMM D') }}
                </span>
                <span class="font-bold font-roboto mt-2">{{$moment.utc(date).local().format('h:mm A')}}</span>
                <span>{{`${userTimezone ? ` (${userTimezone})` : ''}`}}</span>
              </div>
            </label>
          </div>

          <div class="mt-4 flex items-center space-x-4">
            <SendSchedulingLinkModal :booking="booking" class="font-medium text-sm text-pink-500">
              Send scheduling link
            </SendSchedulingLinkModal>
          </div>
        </template>

        <template v-else>
          <div>
            <em>{{ initiator.name }}</em> is waiting for you to propose some dates for the interview.
          </div>
        </template>
      </template>

      <template v-else>
        <div v-if="bookingHasProposedDates">
          <span>
            <em>{{ proposedBy === 'invitee' ? invitee.name : initiator.name }}</em> has chosen {{bookingHasProposedDates > 1 ? `${bookingHasProposedDates} possible dates.` : 'a date' }}
          </span>
        </div>
        <div v-else>
          Waiting for <em>{{ invitee.name }}</em> to propose some dates for the interview.
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import Modal from '@/components/shared/Modal'
  import Calendar from '@/components/bookings/individual/Calendar'
  import SendSchedulingLinkModal from '@/components/bookings/individual/SendSchedulingLinkModal'

  import timezones from '@/mixins/timezones';

  export default {
    props: {
      activity: Object,
    },

    components: {
      Modal,
      Calendar,
      SendSchedulingLinkModal,
    },

    mixins: [timezones],

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },
      userOwnsBookingShow() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
      },
      userOwnsBookingGuest() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingGuest']
      },
      userIsInvitee() {
        return this.$store.getters['dashboard/bookings/userIsInvitee']
      },
      userIsInitiator() {
        return this.$store.getters['dashboard/bookings/userIsInitiator']
      },
      bookingHasProposedDates() {
        return this.$store.getters['dashboard/bookings/bookingHasProposedDates']
      },
      invitee() {
        return this.$store.getters['dashboard/bookings/invitee'];
      },
      initiator() {
        return this.$store.getters['dashboard/bookings/initiator'];
      },
      proposedBy() {
        return this.activity.properties?.proposed_by || 'invitee';
      },
      sharedBy() {
        return this.activity.properties?.shared_by || 'invitee';
      },
    },

    methods: {
      updateBooking(dates, callback) {
        this.loading = true

        this.$store.dispatch('dashboard/bookings/setBookingProposedDates', {
          id: this.booking.id,
          proposed_dates: dates.map(slot => slot.utc().format('YYYY-MM-DD HH:mm:ss')),
        })
        .then(() => {
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
          callback.call()
          window.Event.$emit('refreshActivity')
          window.Event.$emit('refreshBooking')
        })
        .catch(() => {
          this.loading = false
          this.slotsError = false
        })
      },

      changeBookingDate(date, callback) {
        this.$store.dispatch('dashboard/bookings/changeBookingDate', {
          id: this.$route.params.bookingId,
          confirmed_date: date.toISOString(),
        }).then(() => {
          callback.call()
          window.Event.$emit('refreshActivity')
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
        })
      },
    }
  }
</script>
