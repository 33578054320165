<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.9994 14.0014V14.0014C13.2095 14.0014 15.0011 12.2098 15.0011 9.99971V9.99971C15.0011 7.78965 13.2095 5.99805 10.9994 5.99805H6.99776C4.7877 5.99805 2.99609 7.78965 2.99609 9.99971V9.99971C2.99609 12.2098 4.7877 14.0014 6.99776 14.0014V14.0014"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M13.0017 9.99902V9.99902C10.7916 9.99902 9 11.7906 9 14.0007V14.0007C9 16.2107 10.7916 18.0024 13.0017 18.0024H17.0033C19.2134 18.0024 21.005 16.2107 21.005 14.0007V14.0007C21.005 11.7906 19.2134 9.99902 17.0033 9.99902V9.99902"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "AttachmentIcon"
};
</script>
