<template>
  <div class="bg-lightBlack overflow-hidden shadow rounded-lg">
    <div class="px-4 py-5 sm:p-6">
      <div class="flex items-center">
        <div class="flex-shrink-0 bg-pink-500 rounded-md p-3">
          <svg class="h-6 w-6 text-white" width="20" height="26" viewBox="0 0 20 26" fill="none"
                xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd"
                  d="M18.4918 23.6037L10.2308 17.703L1.96973 23.6037V4.72138C1.96973 3.41782 3.02647 2.36108 4.33002 2.36108H16.1315C17.435 2.36108 18.4918 3.41782 18.4918 4.72138V23.6037Z"
                  stroke="white" stroke-width="2.83235" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </div>
        <div class="ml-5 w-0 flex-1">
          <dl>
            <dt class="text-md leading-5 font-medium text-gray-100 truncate">
              {{title}}
            </dt>
            <dd class="flex items-baseline">
              <div class="text-2xl leading-8 font-semibold text-white">
                {{amount}}
              </div>
              <div v-if="increasedBy" class="ml-2 flex items-baseline text-sm leading-5 font-semibold text-green-600">
                <svg class="self-center flex-shrink-0 h-5 w-5 text-green-500" fill="currentColor"
                      viewBox="0 0 20 20">
                  <path fill-rule="evenodd"
                        d="M5.293 9.707a1 1 0 010-1.414l4-4a1 1 0 011.414 0l4 4a1 1 0 01-1.414 1.414L11 7.414V15a1 1 0 11-2 0V7.414L6.707 9.707a1 1 0 01-1.414 0z"
                        clip-rule="evenodd"/>
                </svg>
                <span class="sr-only">
                  Increased by
                </span>
                {{increasedBy}}
              </div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Stats",
    props: ['title', 'amount', 'increasedBy']
  }
</script>

<style scoped>

</style>
