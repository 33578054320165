<template>
  <div @click="closeModal" :class="{'hidden': showCalendarModal === false}"
       class="fixed top-0 left-0 z-50 inset-0 modal-bg flex flex-col justify-center items-center p-6">
    <div @click.stop class="w-full lg:w-8/12 bg-white rounded-xl overflow-hidden">
      <!--Header-->
      <div class="border border-b p-8 rounded-t-xl flex justify-between">
        <div v-if="date">
          <span class="md:text-lg text-gray-500">{{ date.format('dddd') }}</span>
          <h4 class="text-2xl md:text-4xl font-bold">{{ date.format('MMMM D') }}</h4>
        </div>
        <button @click="closeModal">
          <svg class="w-8 h-8" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48.75 16.25L16.25 48.75" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M16.25 16.25L48.75 48.75" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"/>
          </svg>
        </button>
      </div>
      <!--Body-->
      <div>
        <CalendarRow
          v-for="booking in bookings"
          :key="`calendar-booking-row-${booking.id}`"
          :booking="booking"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import {mapMutations, mapState} from "vuex"
  import CalendarRow from "./partials/CalendarRow"

  export default {
    name: 'CalendarModal',
    components: {CalendarRow},
    methods: {
      ...mapMutations({
        setCalendarModal: "dashboard/bookings/setCalendarModal",
        setCalendarModalData: "dashboard/bookings/setCalendarModalData",
      }),
      closeModal() {
        this.setCalendarModal({showCalendarModal: false});
        this.setCalendarModalData({});
        document.body.classList.remove('overflow-hidden')
      }
    },
    computed: {
      ...mapState({
        showCalendarModal: state => state.dashboard.bookings.showCalendarModal,
        modalData: state => state.dashboard.bookings.calendarModalData
      }),

      date() {
        return this.modalData.date || null
      },

      bookings() {
        return this.modalData.bookings || []
      },
    }
  }
</script>

<style scoped>
  .modal-bg {
    background-color: rgba(0, 0, 0, 0.37);
  }
</style>
