import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './plugins/validate.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import LoadingIcon from '@/components/shared/LoadingIcon'
import VueMeta from 'vue-meta'
import PortalVue from 'portal-vue'
import VueTextareaAutosize from 'vue-textarea-autosize'
import VueClipboard from 'vue-clipboard2'
import InfiniteLoading from 'vue-infinite-loading';
import VueToast from 'vue-toast-notification';
import linkify from 'vue-linkify';
import moment from 'moment'
import reconfirm from '@/mixins/reconfirm'
import Tooltip from 'vue-directive-tooltip';

import 'vue-toast-notification/dist/theme-default.css';
import "vue-select/src/scss/vue-select.scss"
import "./assets/css/app.css";

window.Event = new Vue

Vue.config.productionTip = false

/** VUE GLOBAL COMPONENTS */
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)
Vue.component('loading-icon', LoadingIcon)
Vue.component('v-select', vSelect)

/** VUE PLUGINS */
Vue.use(VueMeta)
Vue.use(PortalVue)
Vue.use(VueTextareaAutosize)
Vue.use(VueClipboard);
Vue.use(Tooltip);
Vue.use(InfiniteLoading, {
  slots: {
    noMore: 'No more results',
    noResults: 'No results',
  }
});
Vue.use(VueToast, {
  position: 'top-right',
  duration: 10000
});

Vue.mixin(reconfirm);

Vue.prototype.$moment = moment

/** VUE GLOBAL FILTERS */
Vue.filter('truncate', function (text, length, clamp) {
  clamp = clamp || '...';
  var node = document.createElement('div');
  node.innerHTML = text;
  var content = node.textContent;
  return content.length > length ? content.slice(0, length) + clamp : content;
});

Vue.directive('linkified', linkify)

const app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');

export default app
