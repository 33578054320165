<template>
  <div>
    <div class="grid grid-flow-row-dense  grid-cols-12 my-3 col-gap-4 w-full">
      <label
        v-for="date in proposedDates" :key="date"
        class="cursor-pointer h-full shadow-2xl rounded-xl col-span-4"
      >
        <div class="border flex flex-col p-5 rounded-xl items-center justify-center" :class="confirmedDate == date ? 'border-violet' : 'border-gray-50'">
          <span class="font-roboto font-light text-gray-500">{{ $moment.utc(date).local().format('MMMM D') }}</span>
          <span class="font-roboto">{{ `${$moment.utc(date).local().format('h:mm A')}` }}</span>
          <span>{{`${userTimezone ? ` (${userTimezone})` : ''}`}}</span>
        </div>
        <input type="radio" class="hidden" v-model="confirmedDate" :value="date">
      </label>
    </div>

    <div class="mt-6 w-full flex justify-center items-center space-x-4">
      <button @click="confirmBooking" class="rounded-full bg-indigo-gradiant text-white text-sm font-bold px-5 py-2 w-5/12 flex items-center justify-center" :class="{'opacity-50': loading || ! confirmedDate}">
        <span v-if="loading">
          <loading-icon class="h-5 w-5"/>
        </span>
        <span v-else>Confirm</span>
      </button>

      <div>
        <modal class="text-sm w-full text-violet">
          Suggest more dates
          <template v-slot:content="{ close }">
            <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden self-start my-4">
              <Calendar @close="close" @save="updateBooking($event, close)" />
            </div>
          </template>
        </modal>
      </div>
    </div>

    <p v-if="confirmError" class="text-center mt-4 text-red-500">{{ confirmError }}</p>
  </div>
</template>

<script>
  import Modal from '@/components/shared/Modal';
  import Calendar from '@/components/bookings/individual/Calendar';

  import timezones from '@/mixins/timezones'

  export default {
    name: 'ConfirmBooking',

    props: {
      proposedDates: {
        type: Array,
        default: () => ([]),
      },
    },

    components: {
      Modal,
      Calendar,
    },

    mixins: [timezones],

    data() {
      return {
        confirmedDate: null,
        loading: false,
        confirmError: ''
      }
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      userIsInvitee() {
        return this.$store.getters['dashboard/bookings/userIsInvitee']
      },
    },

    methods: {
      confirmBooking() {
        this.loading = true

        this.$store.dispatch('dashboard/bookings/confirmBooking', {
          id: this.booking.id,
          confirmed_date: this.confirmedDate
        }).then(() => {
          this.$emit('close')
          window.Event.$emit('refreshActivity')
        }).catch(error => {
          if (error.response && error.response.data && error.response.data.errors) {
            console.log(error.response.data.errors);
            this.confirmError = error.response.data.errors.confirmed_date[0]
          } else {
            this.confirmError = 'There was an error. Try again later.';
          }
        }).finally(() => this.loading = false)
      },

      updateBooking(dates, callback) {
        this.loading = true

        this.$store.dispatch('dashboard/bookings/setBookingProposedDates', {
          id: this.booking.id,
          proposed_dates: dates.map(slot => slot.clone().utc().format('YYYY-MM-DD HH:mm:ss')),
          proposed_by: this.userIsInvitee ? 'invitee' : 'initiator',
        })
        .then(() => {
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
          callback.call()
          window.Event.$emit('refreshActivity')
          window.Event.$emit('refreshBooking')
        })
        .catch(() => {
          //
        })
        .finally(() => {
          this.loading = false
          this.confirmError = ''
        })
      },
    },
  }
</script>
