<template>
  <svg
    class="mr-2"
    :class="statusClass"
    :width="size == 'small' ? 7 : 16"
    :height="size == 'small' ? 7 : 16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0C3.58215 0 0 3.58215 0 8C0 12.4178 3.58215 16 8 16C12.4178 16 16 12.4178 16 8C16 3.58215 12.4178 0 8 0ZM12.7594 6.14633L7.72081 11.1849C7.54412 11.3616 7.30524 11.4608 7.05509 11.4608C6.8056 11.4608 6.56605 11.3616 6.38936 11.1849L3.24005 8.03558C2.87211 7.66764 2.87211 7.07206 3.24005 6.70478C3.60799 6.3375 4.20356 6.3375 4.57084 6.70478L7.05438 9.18832L11.4285 4.81486C11.7958 4.44758 12.392 4.44758 12.7593 4.81486C13.1272 5.18214 13.1272 5.77837 12.7593 6.14631L12.7594 6.14633Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: "StatusCircleIcon",
  props: {
    statusClass: {
      type: String,
      default: "",
      required: false
    },
    size: {
      type: String,
      default: "normal",
      required: false
    }
  }
};
</script>

<style lang="css" scoped></style>
