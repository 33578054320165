<template>
  <div>
    <div class="relative">
      <div v-if="! hasSlots" class="absolute inset-0 z-10 flex items-center justify-center bg-gray-200 bg-opacity-75">
        <div class="">
          <loading-icon class="h-10 w-10 text-pink-500"/>
        </div>
      </div>

      <div class="bg-white overflow-hidden relative">
        <transition
          enter-active-class="transition ease-in-out duration-300 transform"
          enter-class="-translate-x-16 opacity-0"
          enter-to-class="translate-x-0 opacity-100"
          leave-active-class="transition ease-in-out duration-100 transform absolute inset-0"
          leave-class="translate-x-0 opacity-100"
          leave-to-class="-translate-x-16 opacity-0"
        >
          <div
            v-if="! selectedDay"
            key="calendar"
            class="px-6 py-6 sm:px-12 lg:py-8"
          >
            <div class="flex justify-between items-center">
              <div class="flex justify-center">
                <span class="text-black font-semibold text-2xl">{{ this.month.format('MMMM YYYY') }}</span>
              </div>
              <div class="ml-4">
                <button
                  :disabled="month.startOf('month').isBefore(now)"
                  :class="month.startOf('month').isBefore(now) ? 'text-gray-400' : 'text-pink-500'"
                  @click.prevent="prevMonth()"
                  class="mr-4 w-auto m-0 px-2 py-0"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button @click.prevent="nextMonth()" class="w-auto m-0 px-2 py-0 text-pink-500">
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>

            <div>
              <div class="grid grid-cols-7 mt-6">
                <div v-for="weekday in weekdays" :key="weekday" class="uppercase text-xs text-gray-500 text-center">{{weekday}}</div>
              </div>

              <div class="grid grid-cols-7 gap-1 mt-3">
                <div class="relative pb-full" v-for="day in monthRange().by('day')" :key="`day-${day.format('YYYY-MM-DD')}`">
                  <div v-if="day.isSame(month, 'month')" class="absolute inset-0">
                    <template v-if="hasAvailabilityFor(day)">
                        <button @click="showAvailabilitySlotsFor(day)" class="relative w-full h-full rounded-full flex items-center justify-center overflow-hidden hover:border hover:border-pink-500 active:bg-pink-100 focus:bg-pink-100"
                        :class="{'bg-pink-500 text-white': dayHasSelectedSlots(day.format('YYYY-MM-DD')), 'text-pink-500': ! dayHasSelectedSlots(day.format('YYYY-MM-DD')), 'border border-pink-500': day.isSame(now, 'day')}">
                          <span class="flex flex-col leading-none">
                            <span class="text-sm sm:text-lg">{{ day.format('D') }}</span>
                            <span v-if="dayHasSelectedSlots(day.format('YYYY-MM-DD'))" class="absolute bottom-0 inset-x-0">
                              <span
                                v-for="slot in daySelectedSlots(day.format('YYYY-MM-DD'))"
                                class="text-white sm:text-xl"
                                :key="`slot-${slot.datetime}`"
                              >&bull;</span>
                            </span>
                          </span>
                        </button>
                    </template>
                    <template v-else>
                      <div class="w-full h-full rounded-full flex items-center justify-center overflow-hidden">
                        <span class="text-sm sm:text-lg text-gray-400">{{ day.format('D') }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition
          enter-active-class="transition ease-in-out duration-300 transform"
          enter-class="translate-x-16 opacity-0"
          enter-to-class="translate-x-0 opacity-100"
          leave-active-class="transition ease-in-out duration-300 transform absolute inset-0"
          leave-class="translate-x-0 opacity-100"
          leave-to-class="translate-x-16 opacity-0"
        >
          <div v-if="selectedDay" key="slotPicker">
            <availability-slots
              :day="selectedDay"
              :slots="slots[selectedDay.format('YYYY-MM-DD')]"
              :selected-slots-prop="selectedSlots"
              @updated-slots="updateSelectedSlots"
              @close="selectedDay = null"
            />
          </div>
        </transition>
      </div>
    </div>
    <div class="px-6 sm:px-12">
      <h3 class="text-lg text-gray-500 text-center">Your Selected Dates</h3>
      <div class="mt-2 grid grid-cols-3 gap-4 sm:gap-8">
        <div v-for="(slot, index) in 3" class="relative pb-120%" :key="`slot-${index}`">
          <div v-if="selectedSlots[index]" class="absolute inset-0 border rounded-lg shadow-lg bg-white flex flex-col items-center justify-center leading-none">
            <div class="sm:text-lg text-gray-500">{{ moment(selectedSlots[index].datetime).format('MMMM D') }}</div>
            <div class="mt-4 sm:mt-4 font-semibold sm:text-xl">{{ moment(selectedSlots[index].datetime).format('h:mm a') }}</div>
            <div class="mt-4 sm:mt-4">
              <button @click.prevent="removeSlot(selectedSlots[index])" class="text-xs text-red-400 hover:text-red-500 py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                remove
              </button>
            </div>
          </div>
          <div v-else class="absolute inset-0 border border-dashed border-gray-400 bg-gray-300 bg-opacity-50 rounded-lg">

          </div>
        </div>
      </div>
    </div>

    <div class="px-6 py-6 sm:px-12 sm:py-10">
      <div class="">
        <button @click="updateBooking" class="rounded-full h-16 bg-indigo-gradiant text-white text-lg sm:text-xl w-full flex justify-center items-center" :class="{'opacity-50': loading || ! selectedSlots.length}">
          <span v-if="loading">
            <loading-icon class="h-5 w-5"/>
          </span>
          <span v-else>Save</span>
        </button>
        <p v-if="slotsError" class="text-center mt-4 text-red-500">Please select at least 1 date and time</p>
      </div>
    </div>
  </div>
</template>

<script>
  import {mapState} from "vuex";
  import Moment from 'moment'
  import { extendMoment } from 'moment-range'
  import timezones from '@/mixins/timezones'
  import AvailabilitySlots from '@/components/Book/guest/AvailabilitySlots'
  const moment = extendMoment(Moment)

  export default {
    name: 'ScheduleBooking',
    mixins: [timezones],
    components: {AvailabilitySlots},

    data() {
      return {
        loading: false,
        slotsError: false,
        moment: moment,
        now: moment(),
        availability: null,
        timezone: null,
        selectedDay: null,
        month: moment(),
        weekdays: ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat']
      }
    },

    mounted() {
      this.guessTimezone()
      this.getViewData()
    },

    methods: {
      monthRange() {
        let start = this.month.clone().startOf('month').startOf('week')
        let end = this.month.clone().endOf('month').endOf('week')

        return moment.range(start, end)
      },

      showAvailabilitySlotsFor(day) {
        this.selectedDay = day
      },

      async getViewData() {
        let guestId = this.booking.guest.id

        await this.$store.dispatch('guest/fetchGuest', { id: guestId})
        await this.$store.dispatch('guest/availability/fetch', { guestId })
        await this.getSlots()
      },

      async getSlots() {
        this.$store.dispatch('guest/availability/fetchSlots', {
          guestId: this.booking.guest.id,
          month: this.month.format('YYYY-MM'),
          timezone: this.timezone,
        })
      },

      hasAvailabilityFor(day) {
        let dayString = day.format('YYYY-MM-DD')

        if (! this.slots[dayString]) {
          return false;
        }

        return Object.values(this.slots[dayString]).some(slot => slot.status == 'available')
      },

      guessTimezone() {
        let zone = moment().utcOffset()

        if (moment().isDST()) {
          zone -= 60
        }

        let timezone = this.timezones.find(timezone => timezone.timezone == Intl.DateTimeFormat().resolvedOptions().timeZone);

        if (! timezone) {
          timezone = this.timezones.find(timezone => timezone.offset == (zone / 60));
        }

        if (timezone) {
          this.timezone = timezone.timezone
        }
      },

      clearSlots() {
        this.$store.commit('guest/availability/setSlots', {})
      },

      prevMonth() {
        this.clearSlots()

        this.month.subtract(1, 'month')
        this.getSlots()
      },

      nextMonth() {
        this.clearSlots()

        this.month.add(1, 'month')
        this.getSlots()
      },

      slotIsSelected(slot) {
        return this.selectedSlots.find(s => s.datetime == slot.datetime)
      },

      updateSelectedSlots(slots) {
        this.slotsError = false

        if (slots.length === 3) {
          this.$store.commit('guestBooking/setSelectedSlots', slots)
          return
        }

        slots.forEach(slot => {
          if (this.slotIsSelected(slot)) {
            return
          }

          if (this.selectedSlots.length == 3) {
            this.$store.commit('guestBooking/shiftSelectedSlots')
          }

          this.$store.commit('guestBooking/addSelectedSlot', slot)
        })
      },

      removeSlot(slot) {
        let index = this.selectedSlots.indexOf(slot)
        this.$store.commit('guestBooking/removeSelectedSlot', index)
      },

      dayHasSelectedSlots(dateString) {
        return this.daySelectedSlots(dateString).length
      },

      daySelectedSlots(dateString) {
        return this.selectedSlots.filter(slot => slot.date == dateString)
      },

      updateBooking() {
        this.loading = true

        this.$store.dispatch('dashboard/bookings/setBookingProposedDates', {
          id: this.booking.id,
          proposed_dates: this.selectedSlots.map(slot => slot.utc),
        })
        .then(() => {
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
          this.$emit('close')
          window.Event.$emit('refreshActivity')
          window.Event.$emit('refreshBooking')
        })
        .catch(() => {
          this.loading = false
          this.slotsError = false
        })
      },

      // setProposedDate() {
      //   if (! this.proposedDate.length) {
      //     return
      //   }

      //   this.$store.dispatch('dashboard/bookings/setBookingProposedDates', {
      //     id: this.$route.params.bookingId,
      //     proposed_date: this.proposedDate,
      //   }).then(() => {
      //     this.$store.dispatch('dashboard/bookings/fetchBooking', {
      //       id: this.$route.params.bookingId,
      //     })
      //   }).finally(() => {
      //     this.proposed_date = ''
      //   })
      // },
    },

    computed: {
      ...mapState({
        booking: state => state.dashboard.bookings.booking,
        selectedSlots: state => state.guestBooking.selectedSlots,
        settings: state => state.guest.availability.settings,
        slots: state => state.guest.availability.slots,
      }),

      guest() {
        return this.booking.guest
      },

      hasSlots() {
        return !! Object.keys(this.slots).length
      },

      // minDatetime() {
      //   return (new Date).toISOString()
      // },
    },

    destroyed() {
      this.clearSlots()
      this.$store.commit('guestBooking/clearBooking')
    }
  }
</script>
