<template>
  <modal :with-close-button="false">
    <span class="text-red-500 underline cursor-pointer hover:text-red-600">Change Fee</span>
    <template v-slot:content="{ close }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
          <div class="flex justify-between items-center">
            <h3 class="font-bold text-lg font-poppins text-blue-800">
              Choose one of the following:
            </h3>
            <div>
              <button @click="close" type="button" class="text-gray-500 p-1 rounded focus:shadow-outline">
                <XIcon class="w-6 h-6" />
              </button>
            </div>
          </div>

          <div class="flex flex-col space-y-5 mt-8">
            <div v-for="(action, index) in actions" :key="index">
              <Radiobutton
                textColor="text-white"
                fontWeight="font-normal"
                hover="hover:text-violet"
                borderWidth="border"
                :value="action.action"
                alignItems="items-start"
                radioButtonSize="w-6 h-6"
                name="pitch-action"
                @change="selectPitchAction"
                :checked="form.action === action.action"
              >
                <template slot="content">
                  <div class="flex flex-col">
                    <span class="ml-4 flex flex-col text-sm">
                      <span class="leading-6">{{ action.title }}</span>
                      <span v-if="action.subtitle" class="font-semibold">{{ action.subtitle }}</span>
                    </span>
                  </div>
                </template>
              </Radiobutton>
              <div v-if="form.action === 'accept-special-price' && action.action == 'accept-special-price'" class="">
                <h1 class="font-semibold text-blue-800 mb-2 mt-4">Total amount</h1>
                <div
                  class="currency-input"
                  :class="{'error': form.price < 5}"
                >
                    $
                    <input
                      id="price"
                      type="number"
                      min="4"
                      oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
                      class="bg-transparent w-full"
                      v-model.number="form.price"
                    >
                  </div>
              </div>
            </div>
            <label class="font-semibold text-black">Reason of change</label>
            <textarea v-model="form.reason" maxlength="300"
                        class="w-full bg-gray-50 rounded-xl resize-none p-5 text-black text-lg focus:border focus:border-pink-500 focus:shadow-3xl focus:bg-white"
                        :class="{'border-red-500': false}"/>
          </div>

          <div class="flex items-center justify-between mt-8">
            <button @click="close" type="button" class="text-pink-500 font-bold text-sm px-12">
              Cancel
            </button>

            <button @click="confirmAction(close)" type="button" class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-12 h-11 flex items-center justify-center disabled:opacity-60 relative" :disabled="working || (booking.pitch_fee_options.fee_type == form.action && form.action != 'accept-special-price')">
              <span v-if="working" class="absolute">
                <LoadingIcon class="text-white h-2" />
              </span>
              <span :class="{'text-transparent': working}">
                Save changes
              </span>
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/shared/Modal'
  import XIcon from '@/components/svgs/XIcon'
  import LoadingIcon from '@/components/shared/LoadingIcon'
  import Radiobutton from '@/components/shared/Radiobutton';

  export default {
    name: 'EditPitchFeeModal',
    components: {
      Modal,
      XIcon,
      LoadingIcon,
      Radiobutton,
    },

    data() {
      return {
        working: false,
        form: {
          action: null,
          price: 5,
          processing: false,
          reason: '',
        },
        actions: [],
      }
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking'];
      },

      pendingAmount() {
        const pitchFeeOptions = this.booking.pitch_fee_options;
        if (pitchFeeOptions && pitchFeeOptions.original_booking_fee) {
          return pitchFeeOptions.original_booking_fee - this.booking.pitch_fee;
        }
        return this.booking.full_booking_fee - this.booking.pitch_fee;
      },
    },

    mounted() {
      if (this.booking.pitch_fee_options) {
        this.form.action = this.booking.pitch_fee_options.fee_type;
      }
      const initiator = this.booking.initiator == 'guest' ? 'Guest' : 'Show';
      this.actions = [
        {
          action: 'accept',
          title: 'Accept without any further fee',
          subtitle: this.booking.price > 0 ? `${initiator} would be charged $${(this.booking.price / 100).toFixed(2)}` : '',
        },
        {
          action: 'accept-pay-full',
          title: `Accept but ${initiator} must pay full booking fee`,
          subtitle: `${initiator} would be ${this.pendingAmount < 0 ? 'refunded' : 'charged'} $${(Math.abs(this.pendingAmount) / 100).toFixed(2)}`,
        },
        {
          action: 'accept-special-price',
          title: 'Accept and give a special discount on the booking fee',
          subtitle: `${initiator} would be charged a special price you propose.`,
        },
      ];
    },

    methods: {
      reRenderComponent() {
        window.Event.$emit('refreshActivity');
        window.Event.$emit('refreshBooking');
      },

      selectPitchAction(action) {
        this.form.action = action;

        if (action !== 'accept-special-price') {
          this.form.price = 5;
        }
      },

      confirmAction(callback) {
        this.form.processing = true;

        this.$store
          .dispatch(`dashboard/bookings/updatePitchFee`, {
            id: this.booking.id,
            ...this.form,
          })
          .then(() => {
            this.reRenderComponent();
            callback.call();
            this.form.reason = '';
          }).finally(() => {
            this.form.processing = false;
          });
      },
    }
  }
</script>

<style scoped>
.currency-input {
  @apply rounded-full max-w-lg bg-guestio-gray-100 py-3 px-5 flex items-center border border-transparent focus-within:border-violet;
}

.currency-input.error {
  @apply bg-red-200;
}
</style>