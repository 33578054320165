<template>
  <div>
    <div class="md:py-6 border-b last:border-b-0 border-gray-300">
      <user-icloud-calendar :integration="icloudCalendarIntegration" @refresh="fetchIntegrations" />
    </div>

    <div class="md:py-6 border-b last:border-b-0 border-gray-300 mt-8 sm:mt-0">
      <user-google-calendar :integration="googleCalendarIntegration" @refresh="fetchIntegrations" />
    </div>

    <div class="md:py-6 border-b last:border-b-0 border-gray-300 mt-8 sm:mt-0">
      <user-office-365-calendar :integration="office365CalendarIntegration" @refresh="fetchIntegrations" />
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import UserIcloudCalendar from './UserIcloudCalendar'
  import UserGoogleCalendar from './UserGoogleCalendar'
  import UserOffice365Calendar from './UserOffice365Calendar'
  export default {
    props: {
      userProp: Object,
    },

    components: {
      UserIcloudCalendar,
      UserGoogleCalendar,
      UserOffice365Calendar,
    },

    data() {
      return {
        integrations: [],
      }
    },

    computed: {
      icloudCalendarIntegration() {
        return this.integrations.find(integration => integration.slug == 'icloud-calendar')
      },

      googleCalendarIntegration() {
        return this.integrations.find(integration => integration.slug == 'google-calendar')
      },

      office365CalendarIntegration() {
        return this.integrations.find(integration => integration.slug == 'office365-calendar')
      },
    },

    methods: {
      fetchIntegrations() {
        api.get('/integrations?filter[slug]=icloud-calendar,google-calendar,office365-calendar')
          .then(({data}) => {
            this.integrations = data.data
          })
      }
    },

    created() {
      this.fetchIntegrations()
    }
  }
</script>
