<template>
  <modal :with-close-button="false" @opened="attemptFocus">
    <slot />

    <template #content="{ close }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-md w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <form method="post" @submit.prevent="sendLink(close)">
          <article class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
            <header class="flex justify-between items-center">
              <div class="flex items-center space-x-2">
                <span class="rounded-full h-10 w-10 bg-pink-500 bg-opacity-10 flex items-center justify-center">
                  <LinkIcon class="h-5 w-5 text-pink-500" />
                </span>
                <h3 class="font-bold font-poppins text-blue-800">
                  Send Scheduling Link
                </h3>
              </div>
              <div>
                <button @click="close" type="button" class="text-gray-500 p-1 rounded focus:shadow-outline">
                  <XIcon class="w-6 h-6" />
                </button>
              </div>
            </header>

            <main class="text-sm text-blue-800 font-medium mt-6">
              <p>
                You can send a scheduling link (eg. Calendly) from which the user can select a preferred time for your interview.
              </p>

              <div class="mt-4">
                <div class="flex justify-between items-center">
                  <label for="details" class="font-semibold text-blue-800 text-sm">
                    Link
                  </label>
                </div>
                <div class="mt-1">
                  <input ref="linkInput" type="url" name="url" class="form-input w-full rounded-full bg-gray-50 border border-transparent focus:border-pink-500 px-4 text-sm focus:shadow-3xl py-3" v-model="form.url" required/>
                </div>
              </div>
            </main>

            <footer class="flex items-center justify-between mt-6">
              <button type="submit" class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-8 h-11 flex items-center justify-center disabled:opacity-60 relative" :disabled="working || ! form.url.length">
                <span v-if="working" class="absolute">
                  <loading-icon class="text-white h-2" />
                </span>
                <span :class="{'text-transparent': working}">
                  Send Link
                </span>
              </button>

              <button @click.prevent="close" type="button" class="text-pink-500 font-bold text-sm disabled:opacity-60" :disabled="working">
                Cancel
              </button>
            </footer>
          </article>
        </form>
      </div>
    </template>
  </modal>
</template>

<script>
  import api from '@/api'
  import Modal from '@/components/shared/Modal'
  import LinkIcon from '@/components/svgs/LinkIcon'
  import XIcon from '@/components/svgs/XIcon'

  export default {
    name: 'SendSchedulingLinkModal',

    props: {
      booking: Object,
    },

    components: {
      Modal,
      LinkIcon,
      XIcon,
    },

    data() {
      return {
        working: false,
        form: {
          url: '',
        },
      }
    },

    computed: {
      userIsInvitee() {
        return this.$store.getters['dashboard/bookings/userIsInvitee'];
      },
    },

    methods: {
      sendLink(callback) {
        this.working = true

        api.patch(`/bookings/${this.booking.id}/scheduling-link`, {
          ...this.form,
          shared_by: this.userIsInvitee ? 'invitee' : 'initiator',
        })
          .then(() => {
            window.Event.$emit('refreshActivity')
            callback()
          })
          .catch(() => {
            this.$toast.error('Error. Something went wrong.')
          })
      },

      attemptFocus() {
        this.$nextTick(() => this.$refs.linkInput.focus())
      }
    },
  }
</script>
