<template>
  <div class="w-full lg:max-w-lg">
    <div class="flex flex-col">
       <span>{{ activity.description }}</span>
      <span class="text-gray-400 italic">{{ date }}</span>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'OrderStarted',

    props: {
      activity: Object,
      userTimezone: {
        type: String,
        default: ''
      }
    },

    computed: {
      date() {
        return `${moment(this.activity.created_at).format('MMM D, h:mm A')}${this.userTimezone !== '' ? ` (${this.userTimezone})` : ''}`
      }
    }
  }
</script>
