<template>
  <Dropdown>
    <template slot="button">
      <ActionsIcon/>
    </template>
    <template slot="content">
      <div
        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <button @click="editItem"
                  class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem">
            Edit
          </button>
          <button @click="deleteItem"
                  class="block w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 hover:text-red-700 focus:outline-none focus:bg-gray-100 focus:text-red-700"
                  role="menuitem">
            Delete
          </button>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import api from '@/api'
import { mapMutations, mapState } from "vuex";
import Dropdown from "@/components/shared/Dropdown";
import ActionsIcon from "@/components/svgs/ActionsIcon";

export default {
  name: "WishlistItemDropdown",
  components: {ActionsIcon, Dropdown},

  props: {
    item: Object,
  },

  methods: {
    ...mapMutations({
      setItem: "dashboard/smartlist/setItem",
      setShowForm: "dashboard/smartlist/setShowForm",
    }),

    deleteItem() {
      return api.delete(`/users/${this.user.id}/smartlist/${this.item.id}`)
        .then(() => {
          this.$emit('updated');
          this.setItem(null)
          this.setShowForm(false)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    editItem() {
      this.setItem(this.item)
      setTimeout(() => {
        const itemForm = document.getElementById('smartlist-title');
        itemForm.scrollIntoView()
      }, 500)

      this.setShowForm(true)
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
}
</script>