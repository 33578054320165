<template>
  <div class="flex flex-col md:flex-row ">
    <div class="w-full sm:w-64 flex-shrink-0 border-b pb-4 sm:border-0 sm:pb-0">
      <label class="text-xl sm:text-base text-gray-600" for="phone">Google Calendar</label>
    </div>
    <div class="md:ml-8 flex-1 mt-1 md:mt-0">
      <template v-if="! integration.id">
        <div class="w-full flex items-center sm:justify-end py-3 sm:py-0">
          <a class="sm:ml-8 flex items-center py-3 px-4 space-x-4 text-sm border border-gray-300 rounded-full shadow-sm bg-white text-gray-500" :href="`https://accounts.google.com/o/oauth2/auth?${connectUrl}`" target="_blank" rel="noopener">
            <GoogleLoginIcon class="w-6 h-6" />
            <span class="font-roboto">Sign in with Google</span>
          </a>
        </div>
      </template>
      <template v-else>
        <div v-if="! edit" class="w-full flex items-center justify-between py-3 sm:py-0">
          <span class="font-semibold">Connected</span>
          <button @click.prevent="edit = true" class="ml-8 flex items-center text-pink-500">
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
            <span class="ml-2 font-light">Edit</span>
          </button>
        </div>
        <div v-else class="w-full mt-4 sm:mt-0">
          <div class="flex items-center justify-between">
            <span class="font-semibold">Account</span>
            <span>
              {{ integration.account }}
            </span>
          </div>

          <div class="mt-6 flex items-center justify-between">
            <span class="font-semibold">Status</span>
            <span class="capitalize" :class="{'text-green-600': status == 'active', 'text-red-600': status == 'inactive'}">
              {{ status }}
            </span>
          </div>

          <ValidationObserver ref="googleForm" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(update)" method="post">
              <template v-if="data">
                <div class="mt-6">
                  <div class="font-semibold">Calendars to check for conflicts</div>
                  <div class="mt-1 flex-1 bg-gray-50 rounded shadow p-4 flex flex-col items-start space-y-4">
                    <div v-for="calendar in data.available_calendars" :key="`calendar-${calendar.id}`">
                      <label class="cursor-pointer select-none">
                        <input type="checkbox" class="form-checkbox h-5 w-5 text-pink-500" v-model="form.params.conflict_calendars" :value="calendar.id">
                        <span class="ml-2">
                          {{ calendar.name }}
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="mt-6">
                  <div class="font-semibold">Calendar to push interview events</div>
                  <div class="mt-1">
                    <select class="form-select w-full" v-model="form.params.events_calendar">
                      <option v-for="calendar in data.available_calendars" :value="calendar.id" :key="`calendar-option-${calendar.id}`">
                        {{ calendar.name }}
                      </option>
                    </select>
                  </div>
                </div>

                <div class="mt-4 sm:mt-6 flex flex-col sm:flex-row items-center justify-between pb-6">
                  <div>
                    <alert-dialog
                      class="w-full rounded-full py-3 text-center text-gray-500"
                      confirm="Disable Integration"
                      confirming="Disabling"
                      @confirm="disableIntegration"
                    >
                      <span>Disable integration</span>
                      <span slot="title">Disable Google Calendar Integration?</span>
                      <template v-slot:content>
                        <div>Are you sure you want to disable the Google Calendar integration?</div>
                      </template>
                    </alert-dialog>
                  </div>
                  <div class="flex items-center justify-end mt-6 sm:mt-0">
                    <button class="detail-cancel-button" :disabled="working" @click.prevent="edit = false">Cancel</button>
                    <button type="submit" class="detail-save-button" :disabled="working">
                      Save
                    </button>
                  </div>
                </div>
              </template>
            </form>
          </ValidationObserver>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import AlertDialog from '@/components/shared/AlertDialog'
  import GoogleLoginIcon from '@/components/svgs/GoogleLoginIcon'
  export default {
    props: {
      integration: {
        type: Object,
        default: () => ({})
      }
    },

    components: {
      AlertDialog,
      GoogleLoginIcon,
    },

    data() {
      return {
        working: false,
        edit: false,
        status: 'active',
        calendars: [],
        form: {
          params: {
            events_calendar: null,
            conflict_calendars: [],
          }
        },
        data: null,
      }
    },

    computed: {
      connectUrl() {
        let urlParts = {
          response_type: 'code',
          access_type: 'offline',
          client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
          redirect_uri: `${process.env.VUE_APP_URL}/google/authorization`,
          scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events',
          prompt: 'select_account consent'
        }

        return new URLSearchParams(urlParts)
      }
    },

    watch: {
      async edit(edit) {
        if (! edit) return

        await this.checkIntegration()

        this.fetchIntegration()
      }
    },

    methods: {
      checkIntegration() {
        api.get(`/integrations/${this.integration.id}/check`)
          .then(() => this.status = 'active')
          .catch(() => this.status = 'inactive')
      },

      refresh() {
        this.$emit('refresh')
      },

      update() {
        this.working = true

        api.put(`/integrations/${this.integration.id}`, {
          ...this.integration,
          ...this.form,
        })
          .then(() => {
            this.edit = false
          })
          .catch(error => {
            if (error.response && error.response.data && error.response.data.errors) {
              this.$refs.googleForm.setErrors(error.response.data.errors)
            }
          })
          .finally(() => this.working = false)
      },

      disableIntegration(callback) {
        api.delete(`/integrations/${this.integration.id}`)
          .then(() => {
            this.refresh()
            callback.call()
          })
          .catch(() => {
            alert('Error! The integration was not disabled')
          })
      },

      fetchIntegration() {
        api.get(`/integrations/${this.integration.id}`)
          .then(({ data }) => {
            if (data.data.params) {
              this.form.params.events_calendar = data.data.params.events_calendar
              this.form.params.conflict_calendars = data.data.params.conflict_calendars
            }
            if (data.data.data) {
              this.data = data.data.data
            }
          })
      }
    },
  }
</script>
