<template>
  <div class="mt-2 bg-gray-50 px-6 py-3 rounded-full border">
    <div class="flex justify-between">
      <span class="flex items-center space-x-2">
        <CardIcon class="h-5 w-5 text-gray-500" />
        <span class="capitalize">{{ paymentMethod.brand }} **** {{ paymentMethod.last4 }}</span>
      </span>
      <span class="text-gray-500">
        Expires {{ paymentMethod.exp_month }}/{{ paymentMethod.exp_year }}
      </span>
    </div>
  </div>
</template>

<script>
import CardIcon from '@/components/svgs/CardIcon';

export default {
  name: 'ExistingPaymentMethodCard',
  components: { CardIcon },
  props: {
    paymentMethod: { type: Object, required: true }
  }
}
</script>