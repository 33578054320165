<template>
  <div>
    <alert-dialog
      class="bg-indigo-gradiant rounded-full text-white text-sm font-bold px-5 py-2 mr-3"
      confirm="Reschedule date inside Guestio"
      @confirm="rescheduleInsideGuestio"
    >
      <span>Reschedule date inside Guestio</span>
      <span slot="title">Are you sure?</span>
      <template v-slot:content>
        <div>Are you sure you want to reschedule the booking date inside Guestio?</div>
      </template>
    </alert-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import AlertDialog from '@/components/shared/AlertDialog'

export default {
  name: 'RescheduleInsideGuestio',
  components: {
    AlertDialog,
  },

  props: {
    bookingId: Number
  },

  methods: {
    ...mapActions({
      undoScheduledOutside: "dashboard/bookings/undoScheduledOutside",
    }),

    async rescheduleInsideGuestio() {
      await this.undoScheduledOutside(this.bookingId)
    }
  },
}
</script>
