<template>
  <modal
    :with-close-button="false"
  >
    Accept Booking
    <template v-slot:content="{ close }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-sm sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
          <div class="flex justify-between items-center">
            <div class="flex items-center space-x-2">
              <span class="rounded-full h-10 w-10 bg-pink-500 bg-opacity-10 flex items-center justify-center">
                <CheckAltIcon class="h-5 w-5 text-pink-500" />
              </span>
              <h3 class="font-bold font-poppins text-blue-800">
                Accept Booking?
              </h3>
            </div>
            <div>
              <button @click="close" type="button" class="text-gray-500 p-1 rounded focus:shadow-outline">
                <XIcon class="w-6 h-6" />
              </button>
            </div>
          </div>

          <div class="text-sm text-blue-800 font-medium mt-6">
            After you accept this booking, you'll be able to schedule a specific date for the interview.
          </div>

          <div class="flex items-center justify-between mt-8">
            <button @click="acceptBooking(close)" type="button" class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-8 h-11 flex items-center justify-center disabled:opacity-60 relative" :disabled="working">
              <span v-if="working" class="absolute">
                <LoadingIcon class="text-white h-2" />
              </span>
              <span :class="{'text-transparent': working}">
                Yes, Accept
              </span>
            </button>

            <button @click="close" type="button" class="text-pink-500 font-bold text-sm">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/shared/Modal'
  import CheckAltIcon from '@/components/svgs/CheckAltIcon'
  import XIcon from '@/components/svgs/XIcon'
  import LoadingIcon from '@/components/shared/LoadingIcon'

  export default {
    components: {
      Modal,
      CheckAltIcon,
      XIcon,
      LoadingIcon,
    },

    data() {
      return {
        working: false,
      }
    },

    methods: {
      acceptBooking(callback) {
        this.working = true
        
        this.$store.dispatch('dashboard/bookings/acceptBooking', {
          id: this.$route.params.bookingId,
        }).then(() => {
          callback.call()
          this.$emit('reRender')
        }).finally(() => {
          this.working = false
        })
      }
    }
  }
</script>
