<template>
  <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 33C26.2843 33 33 26.2843 33 18C33 9.71573 26.2843 3 18 3C9.71573 3 3 9.71573 3 18C3 26.2843 9.71573 33 18 33Z" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M18 12V24" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M12 18H24" :stroke="fill" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "ImagePlusIcon",
  props: {
    fill: {
      type: String,
      default: "#292546"
    }
  }
};
</script>
