<template>
  <div v-if="userIsInitiator" class="flex flex-col items-center space-y-6">
    <h5 class="text-grayDark ">
      Do you accept paying ${{ (pendingAmount / 100).toFixed(0) }}?
    </h5>

    <div class="flex items-center justify-center w-full space-x-2">
      <AcceptBookingExtraPaymentModal
        @done="reRenderComponent"
        class="bg-indigo-gradiant w-full rounded-full text-white font-bold px-5 py-3"
      />

      <RejectBookingModal class="text-violet w-full" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AcceptBookingExtraPaymentModal from '@/components/bookings/individual/activity/AcceptBookingExtraPaymentModal';
import RejectBookingModal from '@/components/bookings/individual/activity/RejectBookingModal';

export default {
  name: 'DetailsActionExtraPayment',

  components: {
    AcceptBookingExtraPaymentModal,
    RejectBookingModal,
  },

  computed: {
    ...mapGetters({
      booking: 'dashboard/bookings/booking',
      userIsInitiator: 'dashboard/bookings/userIsInitiator',
    }),

    pendingAmount() {
      return this.booking.full_booking_fee - this.booking.pitch_fee;
    },
  },

  methods: {
    reRenderComponent() {
      window.Event.$emit("refreshActivity");
      window.Event.$emit("refreshBooking");
    }
  },
};
</script>
