<template>
  <div>
    <template v-if="userIsInvitee">
      {{ initiator.name }} changed the booking fee to ${{ (amount / 100).toFixed(0) }}
    </template>

    <template v-else>
      The fee has changed to ${{ (amount / 100).toFixed(0) }}
    </template>

    <div v-if="activity.properties.reason" class="mt-2">
      <div class="rounded-b-lg bg-white shadow-lg py-6 px-6">
        <div v-html="activity.properties.reason" class="whitespace-pre-wrap" v-linkified:options="{ className: 'hover:text-violet underline' }" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'PitchFeeChanged',

  props: {
    activity: Object,
  },

  computed: {
    ...mapGetters({
      booking: 'dashboard/bookings/booking',
      userIsInitiator: 'dashboard/bookings/userIsInitiator',
      userIsInvitee: 'dashboard/bookings/userIsInvitee',
      invitee: 'dashboard/bookings/invitee',
      initiator: 'dashboard/bookings/initiator',
    }),

    pendingAmount() {
      return this.booking.full_booking_fee - this.booking.pitch_fee;
    },

    amount() {
      return this.activity.properties.amount ? this.activity.properties.amount : this.pendingAmount;
    }
  },
}
</script>
