<template>
  <div>
    <template v-if="userIsInvitee">
      Waiting for <span class="font-semibold">{{ initiator.name }}</span> to accept and pay for the requested fee or cancel the booking request.
    </template>

    <template v-else>
      <span class="font-semibold">{{ invitee.name }}</span> has accepted your pitch, but you need to pay a booking fee (${{ (amount / 100).toFixed(0) }})
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'ActionExtraPayment',

  props: {
    activity: Object,
  },

  computed: {
    ...mapGetters({
      booking: 'dashboard/bookings/booking',
      userIsInvitee: 'dashboard/bookings/userIsInvitee',
      invitee: 'dashboard/bookings/invitee',
      initiator: 'dashboard/bookings/initiator',
    }),

    pendingAmount() {
      return this.booking.full_booking_fee - this.booking.pitch_fee;
    },

    amount() {
      return this.activity.properties.amount ? this.activity.properties.amount : this.pendingAmount;
    }
  },
}
</script>
