<template>
  <div class="flex items-center h-16 ml-4">
    <template v-if="! booking">
      <loading-icon class="w-6 h-6 text-pink-500"/>
    </template>
    <template v-else>
      <template v-if="userOwnsBookingShow">

        <template v-if="booking.guest.imageUrl">
          <router-link target="_blank" :to="`/guests/${booking.guest.id}`">
            <img class="rounded-full w-13 h-13 mr-2 object-cover" :src="booking.guest.imageUrl"
                 :alt="booking.guest.name">
          </router-link>
        </template>
        <template v-else>
          <router-link target="_blank" :to="`/guests/${booking.guest.id}`">
            <img class="rounded-full w-13 h-13 mr-2 object-cover" src="@/assets/images/test_image.png">
          </router-link>
        </template>
        <div>
          <div class="font-bold lg:text-base block">
             <router-link target="_blank" :to="`/guests/${booking.guest.id}`">
                <span class="capitalize">{{ booking.guest.name || 'John Doe' }}</span>
            </router-link>
          </div>
          <div class="text-grayDark text-sm capitalize">{{ title | truncate(25) }}</div>
        </div>
      </template>

      <template v-if="userOwnsBookingGuest">
        <template v-if="booking.show.imageUrl">
          <router-link target="_blank" :to="`/shows/${booking.show.id}`">
            <img class="rounded-full w-13 h-13 mr-2 object-cover" :src="booking.show.imageUrl" :alt="booking.show.name">
          </router-link>
        </template>
        <template v-else>
          <router-link target="_blank" :to="`/shows/${booking.show.id}`">
            <img class="rounded-full w-13 h-13 mr-2 object-cover" src="@/assets/images/test_image.png">
          </router-link>
        </template>
        <div>
          <div class="font-bold lg:text-base block">
            <router-link target="_blank" :to="`/shows/${booking.show.id}`">
              <span class="capitalize">{{ booking.show.name || 'John Doe' }}</span>
            </router-link>
          </div>
          <div class="text-grayDark text-sm capitalize">{{ booking.show.description | truncate(25) }}</div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
export default {
  name: "UserInformation",

  computed: {
    booking() {
      return this.$store.getters['dashboard/bookings/booking']
    },

    userOwnsBookingGuest() {
      return this.$store.getters['dashboard/bookings/userOwnsBookingGuest']
    },

    userOwnsBookingShow() {
      return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
    },

    title() {
      return this.booking.guest.title ? this.booking.guest.title : this.booking.guest.bio
    }
  }
}
</script>

<style scoped>

</style>
