<template>
  <div class="w-full lg:max-w-lg">
    <div class="flex flex-col md:flex-row">
      <template v-if="activity.properties.sender_id == user.id">
        You sent an audio pitch.
      </template>
      <template v-else>
        {{ activity.properties.sender_first_name }} sent an audio pitch.
      </template>
    </div>
    <div class="mt-2">
      <div class="overflow-hidden relative bg-guestio-gray-100" :class="activity.properties.message ? 'rounded-t-lg' : 'rounded-lg'">
        <AudioPreview :media-src="activity.properties.audio_url || activity.properties.media_url" />
      </div>

      <div v-if="activity.properties.message" class="rounded-b-lg bg-white shadow-lg py-6 px-6 mb-3">
        <div v-html="activity.properties.message" class="whitespace-pre-wrap" v-linkified:options="{ className: 'hover:text-violet underline' }" />
      </div>
      <span class="text-gray-400 italic">{{ date }}</span>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  import AudioPreview from '@/components/shared/AudioPreview'

  export default {
    name: 'AudioPitch',

    props: {
      activity: Object,
      user: Object,
      booking: Object,
      userTimezone: {
        type: String,
        default: ''
      }
    },

    components: {
      AudioPreview
    },

    computed: {
      date() {
        return `${moment(this.activity.created_at).format('MMM D, h:mm A')}${this.userTimezone !== '' ? ` (${this.userTimezone})` : ''}`
      }
    }
  }
</script>
