const routes = [
  {
    path: '/shows/:showId',
    name: 'Show',
    component: () => import('@/views/Show.vue')
  },
  {
    path: '/shows/:showId/book',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/ShowBook.vue'),
    children: [
      {
        path: '',
        name: 'ShowBook',
        component: () => import('@/views/show-booking/BookingProfile.vue'),
      },
      {
        path: 'options',
        name: 'ShowBookingOptions',
        props: true,
        component: () => import('@/views/show-booking/BookingOptions.vue'),
      },
      {
        path: 'pitch',
        name: 'ShowBookingPitch',
        props: true,
        component: () => import('@/views/show-booking/BookingPitch.vue'),
      },
      {
        path: 'questions',
        name: 'ShowBookingQuestions',
        props: true,
        component: () => import('@/views/show-booking/BookingQuestions.vue'),
      },
      {
        path: 'upsells',
        name: 'ShowBookingUpsells',
        props: true,
        component: () => import('@/views/show-booking/BookingUpsells.vue'),
      },
      {
        path: 'payment',
        name: 'ShowBookingPayment',
        props: true,
        component: () => import('@/views/show-booking/BookingPayment.vue'),
      }
    ]
  },
  {
    path: '/shows/:showId/create-review',
    name: 'CreateShowReview',
    component: () => import('@/components/profile/Reviews/CreateReview.vue')
  },
];

export default routes;
