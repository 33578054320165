<template>
  <div id="stripe-connect" class="flex flex-col md:flex-row justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-gray-600">
        <a href="#stripe-connect" name="stripe-connect">Stripe Connect</a>
      </span>
    </div>
    <div class="md:ml-8 flex-1 mt-1 md:mt-0">
      <div v-if="! user.has_stripe_connect" class="w-full">
        <div v-if="! showCountryForm" class="flex justify-between items-center">
          <span class="font-semibold flex-1">Not connected</span>

          <button  @click="showCountryForm = true" type="button" title="Link with Stripe Connect">
            <img src="/images/stripe-blue-on-light.png" alt="Stripe Connect">
          </button>
        </div>

        <form v-else method="post" @submit.prevent="createStripeAccount">
          <div class="flex justify-end space-x-4">
            <div>
              <label for="country_code" class="sr-only">
                Pick your country
              </label>
              <select name="country_code" id="country_code" class="form-select w-64" v-model="form.country_code" required>
                <option value hidden selected>Pick your country</option>
                <option v-for="{label, code} in countries" :value="code" :key="code">
                  {{ label }}
                </option>
              </select>
            </div>

            <button type="submit" class="flex-shrink-0 bg-indigo-gradiant text-white rounded-full px-4 py-2 text-sm disabled:opacity-60" :disabled="working || ! form.country_code">
              <loading-icon v-if="working" class="h-2" />
              <span v-else>Start Onboarding</span>
            </button>
          </div>

          <div v-if="account" class="mt-2 text-gray-500">
            If this page does not redirect after a few seconds, please <a :href="account.onboarding_link.url" class="text-pink-500 hover:underline" target="_self" rel="noopener">click here</a>
          </div>
        </form>
      </div>

      <div v-else>
        <template v-if="loading">
          <div class="flex justify-center">
            <loading-icon class="h-2 text-pink-500" />
          </div>
        </template>

        <template v-else>
          <div class="flex justify-between">
            <div class="flex items-center space-x-2">
              <span class="font-semibold">
                {{ accountStatus }}
              </span>
              <span class="text-guestio-gray-300">
                &bull;
                <template v-if="! account.details_submitted">
                  <a :href="account.onboarding_link.url" target="_self" rel="noopener" class="text-pink-500 text-sm hover:underline">
                    Continue Onboarding
                  </a>
                </template>

                <template v-else>
                  <a :href="account.dashboard_link.url" target="_self" rel="noopener" class="text-pink-500 text-sm hover:underline">
                    View Dashboard
                  </a>
                </template>
              </span>
            </div>

            <ConfirmDialog
              confirm-action="Yes, Unlink"
              dialog-title="Unlink Stripe Connect"
              dialog-text="Are you sure you want to unlink your Stripe Connect account? We won't be able to send you payouts."
              class="text-pink-500"
              @confirm="deleteStripeAccount"
            >
              Unlink account
            </ConfirmDialog>
          </div>

          <div v-if="account && ! account.details_submitted && onboardingSubmitted" class="mt-4 text-gray-500">
            If you submitted all the details, please allow a few minutes for your account to be updated.
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import axios from 'axios'
  import ConfirmDialog from '@/components/shared/ConfirmDialog'
  import countries from '@/mixins/countries'

  export default {
    components: {
      ConfirmDialog,
    },

    mixins: [countries],

    data() {
      return {
        showCountryForm: false,
        loading: true,
        working: false,

        account: null,

        form: {
          country_code: 'US',
        },
      }
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      },

      accountStatus() {
        if (! this.account) {
          return 'Unknown'
        }

        if (! this.account.details_submitted) {
          return 'Details Needed'
        }

        if (! this.account.charges_enabled) {
          return 'Pending'
        }

        return 'Connected'
      },

      onboardingSubmitted() {
        return this.$route.query['stripe-submitted']
      }
    },

    methods: {
      createStripeAccount() {
        this.working = true

        api.post('/user/stripe-account', this.form)
          .then(({ data }) => {
            window.location.href = data.onboarding_link.url
            this.account = data
          })
          .catch(error => {
            if (error.response) {
              return this.$toast.error(error.response.data.message)
            }

            this.$toast.error('Error! Something went wrong.')
          })
          .finally(() => this.working = false)
      },

      fetchStripeAccount() {
        api.get('/user/stripe-account')
          .then(({ data }) => {
            this.account = data

            if (! data.details_submitted || ! data.charges_enabled) {
              setTimeout(() => this.fetchStripeAccount(), 10000)
            }
          })
          .finally(() => this.loading = false)
      },

      fetchUserLocation() {
        axios.get('http://ip-api.com/json?fields=status,countryCode')
          .then(({ data }) => {
            if (data.status === 'success') {
              this.form.country_code = data.countryCode
            }
          })
      },

      deleteStripeAccount(callback) {
        api.delete('/user/stripe-account')
          .then(() => {
            window.Event.$emit('fetchUser')
            this.account = null
            this.$toast.success('Stripe Connect account was unlinked.')
          })
          .catch(() => {
            this.$toast.error('Error! Something went wrong.')
          })
          .finally(() => callback())
      }
    },

    mounted() {
      if (this.user.has_stripe_connect) {
        this.fetchStripeAccount()
      } else {
        this.fetchUserLocation()
      }
    }
  }
</script>
