const routes = [
  {
    path: '/miniviews-create',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/miniviews/create/CreateMiniview.vue'),
    children: [
      {
        path: '',
        name: 'MiniviewCreate1',
        component: () => import('@/views/miniviews/create/MiniviewName.vue'),
      },
      {
        path: 'questions',
        name: 'MiniviewCreate2',
        component: () => import('@/views/miniviews/create/MiniviewQuestions.vue'),
      },
      {
        path: 'success',
        name: 'MiniviewCreated',
        component: () => import('@/views/miniviews/create/MiniviewCreated.vue'),
      },
    ]
  },
  {
    path: '/miniviews/:uuid', 
    component: () => import('@/views/miniviews/answer/AnswerMiniview.vue'),
    children: [
      {
        path: '',
        name: 'MiniviewAnswerName',
        component: () => import('@/views/miniviews/answer/MiniviewAnswerName.vue'),
      },
      {
        path: 'questions',
        name: 'MiniviewAnswerQuestions',
        component: () => import('@/views/miniviews/answer/MiniviewAnswerQuestions.vue'),
      },
      {
        path: 'success',
        name: 'AnswerSubmitted',
        component: () => import('@/views/miniviews/answer/AnswerSubmitted.vue'),
      },
    ]
  }
];

export default routes;
