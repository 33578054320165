<template>
  <div class="flex space-x-3">
      <span class="underline text-sm font-bold">Bookings</span>
      <span class="text-sm font-normal capitalize truncate text-grayDark">></span>
      <router-link  :to="'/bookings'">
        <span v-if="userOwnsBookingShow" class=" text-sm font-normal capitalize truncate text-grayDark">{{booking.show.name }}</span>
        <span v-else class=" text-sm font-normal capitalize truncate text-grayDark">{{booking.guest.name}}</span>
    </router-link>
  </div>
</template>

<script>
export default {
    name:"BookingsNavigation",

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },
      userOwnsBookingShow() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
      },
    }
}
</script>
