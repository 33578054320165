<template>
  <div>
    <template v-if="userIsInvitee">
      <div>
        You can answer the MINIVIEW™ questions here:
      </div>
      <div class="mt-4">
        <router-link :to="{ name: 'BookingMiniview', params: {bookingId: booking.id}}" class="inline-block bg-indigo-gradiant rounded-full text-white text-sm font-bold px-5 py-2">
          Open MINIVIEW™
        </router-link>
      </div>
    </template>

    <template v-else>
      <div>
        You can view the MINIVIEW™ answers here:
      </div>
      <div class="mt-4">
        <router-link :to="{ name: 'BookingMiniview', params: {bookingId: booking.id}}" class="inline-block bg-indigo-gradiant rounded-full text-white text-sm font-bold px-5 py-2">
          Open MINIVIEW™
        </router-link>
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      activity: Object,
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },
      userIsInvitee() {
        return this.$store.getters['dashboard/bookings/userIsInvitee']
      },
    },
  }
</script>
