<template>
  <div class="flex items-center">
    <StatusCircleIcon :statusClass="statusClass" :size="statusSize"/>
    <span class="capitalize">{{ status }}</span>
  </div>
</template>

<script>
import StatusCircleIcon from '@/components/svgs/StatusCircleIcon'
  export default {
    name: 'BookingStatus',

    components: { StatusCircleIcon },

    props: {
      status: {
        type: String,
        default: 'pending'
      },
      statusSize: {
        type: String,
        default: 'normal',
        required: false
      }
    },

    computed: {
      statusClass() {
        switch(this.status) {
          case 'pending':
            return 'text-orange-500'
          case 'confirmed':
          case 'accepted':
          case 'scheduled':
            return 'text-green-500'
          case 'completed':
            return 'text-pink-500'
          case 'expired':
          case 'canceled':
            return 'text-red-500'
          default:
            return 'text-orange-500'
        }
      }
    }
  }
</script>
