<template>
  <div>
    <div class="flex items-center w-full">
      <router-link :to="{name: 'GuestDetails'}"
                   class="text-2xl lg:text-3xl transition duration-300 text-black text-center" active-class="font-bold"
                   exact>
        Guest Profile
      </router-link>
      <span class="text-2xl lg:text-3xl mx-4 text-gray-400 font-light">/</span>
      <router-link :to="{name: 'ShowIndex'}"
                   class="text-2xl lg:text-3xl transition duration-300 text-black text-center" active-class="font-bold">
        Show Profiles
      </router-link>
    </div>

    <div class="mt-4 xl:mt-12">
      <transition appear :css="false" @before-enter="beforeEnter" @enter="enter">
        <router-view/>
      </transition>
    </div>
  </div>
</template>

<script>
import anime from "animejs";

export default {
  name: "Details",

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    }
  },

  watch: {
    $route(to) {
      // console.log(to)
      if (to.name == 'Details') {
        this.redirect()
      }
    }
  },

  methods: {
    redirect() {
      if (this.user.type == 'show') {
        this.$router.push({name: 'ShowIndex'})
      } else {
        this.$router.push({name: 'GuestDetails'})
      }
    },
    beforeEnter(el) {
      el.style.transform = 'translateX(-20rem)';
    },
    enter(el, done) {
      anime({
        targets: el,
        translateX: 0,
        duration: 100,
        easing: 'cubicBezier(0.230, 1.000, 0.320, 1.000)',
        complete: done
      });
    },
  },

  created() {
    this.$store.dispatch('auth/getUser')
      .then(() => {
        if (this.$route.name == 'Details') {
          this.redirect()
        }
      });
  }
}
</script>
