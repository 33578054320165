<template>
  <div>
    <SubHeader/>
    <div class="w-full md:w-5/6 mx-auto py-6 lg:py-20 px-4 md:px-6 xl:px-0">
      <Notifications />
      <MiniviewsBanner v-if="$route.fullPath == '/user-miniviews' || !$route.path.includes('/user-miniviews')" />
      <transition appear :css="false" @before-enter="beforeEnter" @enter="enter">
        <router-view></router-view>
      </transition>
    </div>
    <ReconfirmLoginModal />
  </div>
</template>

<script>
import SubHeader from "../components/dashboard/SubHeader";
import anime from "animejs";
import Notifications from '@/components/dashboard/Notifications'
import ReconfirmLoginModal from '@/components/auth/ReconfirmLoginModal'
import MiniviewsBanner from "@/components/miniviews/MiniviewsBanner";

export default {
  name: "LoggedHome",
  components: { SubHeader, Notifications, ReconfirmLoginModal, MiniviewsBanner },

  created() {
    this.$store.dispatch('dashboard/bookings/fetchBookings', {
      withoutFilters: true,
      status: 'is_pending,is_accepted,is_scheduled',
      page: 1,
      limit: 1
    })
      .catch(() => this.bookingsError = true)
      .finally(() => this.loading = false)
  },

  methods: {
    beforeEnter(el) {
      el.style.transform = 'translateX(-20rem)';
    },
    enter(el, done) {
      anime({
        targets: el,
        translateX: 0,
        duration: 100,
        easing: 'cubicBezier(0.230, 1.000, 0.320, 1.000)',
        complete: done
      });
    },
  }
}
</script>

<style scoped>
@media (max-width: 1390px) {
  .container {
    max-width: 1024px;
  }
}
</style>
