<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    :stroke="color"
    :stroke-width="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="feather feather-search">
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
  props: {
    color: { type: String, required: false, default: 'currentColor'},
    strokeWidth:{
      type:String,
      default:'2'
    }
  }
}
</script>

<style lang="css" scoped></style>
