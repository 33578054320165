<template>
  <div>
    <input
      :value="value"
      :id="id"
      :type="type"
      :placeholder="placeholder"
      @input="$emit('input',$event.target.value)"
      class="text-input"
      :class="[fontSize, fontColor, backgroundColor, paddingX, paddingY, rounded]"
    />
    <p class="text-red-500 text-sm mt-3 font-light" v-if="errors.length">{{ errors[0] }}</p>
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    id : { type: String , required: false, default: ''},
    value : { type: String , required: false, default: ''},
    type : { type: String , required: false, default: 'text'},
    errors : { type: Array , required: false, default: () => []},
    placeholder: { type: String, required: false, default: ''},
    fontSize : { type: String , required: false, default: 'text-sm'},
    fontColor : { type: String , required: false, default: 'text-black'},
    backgroundColor : { type: String , required: false, default: 'bg-gray-50'},
    paddingX : { type: String , required: false, default: 'px-5'},
    paddingY : { type: String , required: false, default: 'py-2'},
    rounded : { type: String , required: false, default: 'rounded-full'},
  }
}
</script>

<style scoped>
.text-input { 
  @apply border-transparent py-4 w-full;
}
.text-input:focus {
  @apply border border-indigo-500 shadow-3xl !important;
}
</style>