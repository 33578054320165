<template>
  <router-link @click="setBooking" :to="`/bookings/${bookingProp.id}`"
               class="grid grid-cols-12 row-gap-2 lg:row-gap-0 col-gap-4 bg-white rounded-xl shadow-lg cursor-pointer border border-gray-50 hover:border-pink-500 transition duration-500">
    <!--Date-->
    <div
      class="row-span-4 col-span-2 lg:row-span-1 lg:col-span-1 flex flex-col items-center justify-center border-r py-8">
      <template v-if="date">
        <span>{{ date.format('MMM') }}</span>
        <span class="font-semibold text-xl">{{ date.format('D') }}</span>
      </template>

      <template v-else>
        <span class="font-semibold text-x">
          -
        </span>
      </template>
    </div>
    <!--Guest-->
    <div class="col-span-10 lg:col-span-5 flex items-center pt-5 lg:pt-0">
      <img class="w-12 h-12 lg:w-16 lg:h-16 rounded-full mr-4 object-cover" :src="entity.imageUrl" alt="">
      <div class="relative">
        <h4 class="font-semibold text-md lg:text-xl flex items-center">
          {{ entity.name }}
        </h4>
        <span class="text-pink-500 text-sm lg:text-base">{{ title | truncate(22, '...') }}</span>
      </div>
    </div>
    <!--Total-->
    <div class="col-start-3 col-span-3 lg:col-span-1 flex items-center justify-end pb-5 lg:pb-0">
      <span>${{ ((bookingProp.price + bookingProp.guestio_cash) / 100).toFixed(2) }}</span>
    </div>
    <!--Status-->
    <div class="col-span-3 lg:col-span-2 flex items-center justify-center pb-5 lg:pb-0">
      <BookingStatus :status="bookingProp.status"/>
    </div>
    <!--Messages-->
    <div class="col-span-3 lg:col-span-2 flex items-center justify-center pb-5 lg:pb-0">
      <button class="flex items-center">
        <svg class="w-5 mr-3" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M3.33317 3.33331H16.6665C17.5832 3.33331 18.3332 4.08331 18.3332 4.99998V15C18.3332 15.9166 17.5832 16.6666 16.6665 16.6666H3.33317C2.4165 16.6666 1.6665 15.9166 1.6665 15V4.99998C1.6665 4.08331 2.4165 3.33331 3.33317 3.33331Z"
            stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M18.3332 5L9.99984 10.8333L1.6665 5" stroke="#959595" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"/>
        </svg>
        <span v-if="bookingProp.unread_messages_count && ! bookingProp.is_completed"
              class="bg-red-500 rounded-full px-4 py-1 text-white block">
          {{ bookingProp.unread_messages_count }}
        </span>
      </button>
    </div>
    <!--Details-->
    <div class="col-span-12 lg:col-span-1  items-center pb-5 lg:pb-0 hidden xl:flex">
      <button class="flex items-center text-gray-500 text-sm">
        <span>Details</span>
        <svg class="h-6 ml-1" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.50016 12.2093L13.3335 17.5L7.50016 22.7907" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  </router-link>
</template>

<script>
import moment from 'moment'
import BookingStatus from '@/components/bookings/BookingStatus'

export default {
  name: "BookingRow",

  props: {
    bookingProp: {
      type: Object,
    }
  },

  components: {
    BookingStatus
  },

  computed: {
    user() {
      return this.$store.getters['auth/getUser']
    },

    bookedByFilter() {
      return this.$store.getters['dashboard/bookings/bookedByFilter']
    },

    date() {
      if (this.bookingProp.confirmed_date) {
        return moment(this.bookingProp.confirmed_date)
      }

      return null
    },

    bookingStatus() {
      if (this.bookingProp.is_completed) {
        return 'completed'
      } else if (this.bookingProp.is_canceled) {
        return 'canceled'
      } else if (this.bookingProp.is_confirmed) {
        return 'confirmed'
      } else if (this.bookingProp.is_expired) {
        return 'expired'
      }

      return 'pending'
    },

    guestImage() {
      const defaultImage = require('@/assets/images/test_image.png');
      return this.bookingProp.guest.imageUrl ? this.bookingProp.guest.imageUrl : defaultImage
    },

    title() {
      if (this.entity.type === 'guest') {
        return this.bookingProp.guest.title ? this.bookingProp.guest.title : this.bookingProp.guest.bio
      }

      return this.bookingProp.show.platform_type
    },

    entity() {
      if (this.bookedByFilter === 'me') {
        return this.bookingProp.initiator === 'guest' ? this.bookingProp.show : this.bookingProp.guest
      }

      return this.bookingProp.initiator === 'guest' ? this.bookingProp.guest : this.bookingProp.show
    }
  },

  methods: {
    setBooking() {
      this.$store.commit('dashboard/bookings/setBooking', this.bookingProp)
    }
  }
}
</script>

<style scoped>

</style>
