<template>
  <div class="w-full bg-white rounded-xl shadow-lg p-5 grid grid-cols-12 col-gap-4 border border-gray-50">
    <!--Date-->
    <button class="flex items-center col-span-3 md:col-span-2">
      <span class="uppercase font-bold mr-2">Date</span>
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.44981 1.21079L7.59871 4.94849C7.99935 5.42294 7.66421 6.15906 7.03449 6.15906H0.736703C0.114972 6.15906 -0.228495 5.43126 0.172486 4.94849L3.32138 1.21079C3.61564 0.867343 4.15555 0.867343 4.44983 1.21079H4.44981Z"
          fill="#D4D4D4"/>
        <path
          d="M3.32265 12.7891L0.173751 9.05139C-0.226886 8.57694 0.108256 7.84082 0.737968 7.84082L7.03576 7.84082C7.65749 7.84082 8.00096 8.56862 7.59997 9.05139L4.45108 12.7891C4.15683 13.1325 3.61691 13.1325 3.32263 12.7891L3.32265 12.7891Z"
          fill="black"/>
      </svg>
    </button>

    <!--Title-->
    <button class="flex items-center col-span-4 md:col-span-6">
      <span class="uppercase font-bold mr-2">Title</span>
    </button>

    <!--Answers-->
    <button class="flex items-center col-span-3">
      <span class="uppercase font-bold mr-2">Answers</span>
      <svg width="8" height="14" viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.44981 1.21079L7.59871 4.94849C7.99935 5.42294 7.66421 6.15906 7.03449 6.15906H0.736703C0.114972 6.15906 -0.228495 5.43126 0.172486 4.94849L3.32138 1.21079C3.61564 0.867343 4.15555 0.867343 4.44983 1.21079H4.44981Z"
          fill="#D4D4D4"/>
        <path
          d="M3.32265 12.7891L0.173751 9.05139C-0.226886 8.57694 0.108256 7.84082 0.737968 7.84082L7.03576 7.84082C7.65749 7.84082 8.00096 8.56862 7.59997 9.05139L4.45108 12.7891C4.15683 13.1325 3.61691 13.1325 3.32263 12.7891L3.32265 12.7891Z"
          fill="#D4D4D4"/>
      </svg>
    </button>
  </div>
</template>

<script>
  export default {
    name: "TableHeader"
  }
</script>
