<template>
  <div class="px-3 py-2 bg-gray-50 rounded-full flex items-center">
    <SearchIcon class="mr-3 w-8 h-8 flex-shrink-0"/>
    <input type="text" class="bg-transparent w-full" placeholder="Search..." :value="value" @input="$emit('input',$event.target.value)">
  </div>
</template>
<script>
import SearchIcon from "@/components/svgs/SearchIcon"

export default {
  name: 'SearchInputRounded',
  components: {SearchIcon},
  props: {
    value : { type: String , required: false, default: ''},
  }
}
</script>
