import api from '@/api';

const guestioConnect = {
  namespaced: true,

  state: {
    guestioConnectType: ''
  },

  getters: {
    guestioConnectType:  state => state.guestioConnectType,
  },

  actions: {
    getGuestCandidates(_, payload) {
      const url = `/guests/guestio-connect-candidates?page=${payload.page}`;

      return api.get(url, { params: { tags: payload.tags, categories: payload.categories, limit: payload.limit } })
        .then(({ data }) => {
          return Promise.resolve({ candidates: data.data, pagination: data.meta.pagination });
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    getShowCandidates(_, payload) {
      const url = `/shows/guestio-connect-candidates?page=${payload.page}`;

      return api.get(url, { params: { tags: payload.tags, categories: payload.categories, limit: payload.limit } })
        .then(({ data }) => {
          return Promise.resolve({ candidates: data.data, pagination: data.meta.pagination });
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    rejectGuestCandidate(_, payload) {
      const url = `/guests/reject-guestio-connect-candidate`;

      return api.post(url, { guest_profile_id: payload })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    rejectShowCandidate(_, payload) {
      const url = `/shows/reject-guestio-connect-candidate`;

      return api.post(url, { show_profile_id: payload })
        .then(({ data }) => {
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
  },

  mutations: {
    setGuestioConnectType(state, type) {
      state.guestioConnectType = type
    }
  }, 
};

export default guestioConnect;
