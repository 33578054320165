<template>
  <label class="flex items-center cursor-pointer my-1">
    <input
      type="checkbox"
      class="form-checkbox text-pink-500 border-gray-400 disabled:opacity-50"
      :class="[backgroundColor, borderWidth, size]"
      name="name"
      :value="value"
      @input="$emit('on-toggle', $event.target.value)"
      :checked="checked ? 'checked' : ''"
      :disabled="disabled"
    />
    <span class="ml-3" :class="[textColor, fontWeight, hover, fontSize]">{{ title }}</span>
    <span v-if="subtitle" class="ml-1 text-grayDark">{{ subtitle }}</span>
  </label>
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    value: [String, Number, Array, Boolean],
    title: String,
    subtitle: String,
    name: String,
    checked: Boolean,
    textColor: { type: String, required: false, default: ''},
    fontWeight: { type: String, required: false, default: 'font-light'},
    fontSize: { type: String, required: false, default: 'text-base'},
    hover: { type: String, required: false, default: ''},
    backgroundColor: { type: String, required: false, default: ''},
    borderWidth: { type: String, required: false, default: 'border-2'},
    size: { type: String, required: false, default: 'w-4 h-4'},
    disabled: { type: Boolean, default: false },
  }
}
</script>
