<template>
  <div class="dashboard-card">
    <h3>Your public profiles</h3>
    <div class="flex flex-col">
      <div v-if="user.guest" class="mb-3">
        <div class="font-semibold text-blue-800 mb-2">Guest profile</div>
        <router-link :to="{name: 'Guest', params: {guestId: user.guest.id}}" class="profile">
          <img v-if="!isVideo(profileImage(user.guest))" :src="profileImage(user.guest)" :alt="user.guest.name" class="image">
          <video v-else :src="profileImage(user.guest)" :alt="user.guest.name" class="image" loop="loop" muted="true" autoplay="true"></video>
          {{user.guest.name}}
        </router-link>
      </div>
      <div v-if="user.shows && user.shows.length">
        <div class="font-semibold text-blue-800 mb-2">Show profiles</div>
        <router-link v-for="(show, index) in user.shows" :key="index" :to="{name: 'Show', params: {showId: show.id}}" class="profile">
          <img :src="profileImage(show)" :alt="show.name" class="image">
          {{show.name}}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PublicProfiles",
  methods: {
    profileImage(profile) {
      const defaultImage = require(`@/assets/images/test_image.png`);
      return profile.imageUrl ? profile.imageUrl : defaultImage
    },
    isVideo(url) {
      const videoTypes = ['mp4', 'ogg', 'mov', 'qt']

      let extension = url.split(".").pop();
      return videoTypes.includes(extension);
    },
  },
  computed: {
    user() {
      return this.$store.getters["auth/getUser"];
    },
  }
};
</script>

<style scoped lang="scss">
  .profile {
    @apply flex items-center mb-3 font-medium;
    &:hover {
      @apply text-violet;
    }
  }
  .image {
    @apply w-10 h-10 rounded-full object-cover flex-shrink-0 mr-4;
  }
</style>