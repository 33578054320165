<template>
  <div v-if="['accepted', 'scheduled'].includes(booking.status)" class="space-y-6">
    <div class="grid grid-cols-1 gap-5">
      <CancelBookingAction class="text-red-600" />
    </div>
  </div>
</template>

<script>
  import CancelBookingAction from '@/components/bookings/individual/CancelBookingAction'

  export default {
    components: { CancelBookingAction},

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      hash() {
        return this.$route.hash
      }
    },

    methods: {
      changeBookingDate(date, callback) {
        this.$store.dispatch('dashboard/bookings/changeBookingDate', {
          id: this.$route.params.bookingId,
          confirmed_date: date.toISOString(),
        }).then(() => {
          callback.call()
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
        })
      },

      handleHash() {
        if (! this.hash.length) {
          return
        }

        if (this.hash == '#show-calendar') {
          return this.$refs.calendarModal.open()
        }
      }
    },

    mounted() {
      this.handleHash()
    }
  }
</script>
