<template>
  <div class="flex flex-col md:flex-row ">
    <div class="w-64 flex-shrink-0">
      <label class="text-gray-600" for="phone">Phone</label>
    </div>
    <div class="md:ml-8 flex-1 mt-1 md:mt-0">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <the-mask v-model="userProp.phone" :mask="'(###) ###-####'" readonly class="font-semibold text-black p-0" />
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="phoneForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider rules="required|min:10|max:10" vid="phone" name="Phone number" v-slot="{ errors }">
                <div>
                  <div class="rounded-lg shadow-sm">
                    <label for="phone" class="sr-only">Phone</label>
                    <the-mask id="phone" name="phone" type="tel" v-model="form.phone" :mask="'(###) ###-####'" class="guestio-form-input" />
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import { TheMask } from 'vue-the-mask'
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    props: {
      userProp: Object,
      admin: {
        type: Boolean,
        default: false,
      }
    },

    components: {TheMask, Edit, SaveOrCancel},

    data() {
      return {
        working: false,
        edit: false,
        form: {
          phone: this.userProp.phone
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.phone = this.userProp.phone
      }
    },

    computed: {
      updateAction() {
        if (this.admin) {
          return `auth/updateAdminUser`
        }

        return `auth/updateUser`
      }
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch(this.updateAction, this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.phoneForm.setErrors(error.response.data.errors)
            }
          })
      }
    }
  }
</script>
