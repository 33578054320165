<template>
  <div class="max-w-2xl w-full bg-white rounded-xl overflow-hidden my-4">
    <div class="px-6 py-6 sm:px-12 lg:py-8">
      <h3 class="text-3xl text-black font-bold">Promotional Upsells</h3>
      <div class="mt-8 space-y-8">
        <template v-for="(upsellPackage, index) in originalUpsellPackages">
          <div class="rounded-lg border-2 flex-1 px-6 py-4" :key="`package-${index}`">
            <div class="header flex items-center justify-between text-blue-800">
              <h3 class="text-blue-900 text-xl font-bold">
                {{ upsellPackage.title }}
              </h3>
              <span class="price text-2xl font-bold">
                ${{ upsellPackage.price }}
              </span>
            </div>
            <div class="description mt-2 leading-normal text-lg text-gray-500 whitespace-pre-wrap">{{ upsellPackage.description }}</div>
            <div class="meta mt-6 space-y-2" v-if="upsellPackage.url || upsellPackage.social_handle">
              <p v-if="upsellPackage.url && upsellPackage.url.length">
                <a :href="upsellPackage.url" target="_blank" class="text-indigo-500">
                  {{ upsellPackage.url }}
                </a>
              </p>
              <p v-if="upsellPackage.social_handle && upsellPackage.social_handle.length" class="text-pink-500">
                {{ upsellPackage.social_handle }}
              </p>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      upsellPackages: Array,
    },
    computed: {
      originalUpsellPackages() {
        return this.upsellPackages.map(upsell => {
          if (!upsell.details) {
            return upsell
          }

          return {
            ...upsell,
            title: upsell.details.title,
            description: upsell.details.description,
            url: upsell.details.url,
            social_handle: upsell.details.social_handle,
            price: parseFloat(upsell.details.price / 100).toFixed(2),
          }
        })
      }
    }
  }
</script>
