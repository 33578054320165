import api from '@/api'

const register = {
  namespaced: true,

  state: {
    currentStep: 1,
    steps: [
      {
        stepNumber: 1,
        name: 'Personal Information'
      },
      {
        stepNumber: 2,
        name: 'Go Pro'
      },
      {
        stepNumber: 3,
        name: 'Account Type'
      },
      {
        stepNumber: 4,
        name: 'Guest Details',
        type: 'guest'
      },
      {
        stepNumber: 5,
        name: 'Show Details',
        type: 'show'
      },
      {
        stepNumber: 6,
        name: 'Guest Linked Accounts',
        type: 'guest'
      },
      {
        stepNumber: 7,
        name: 'Interview Availability'
      }
    ],
    userForm: {
      device: 'spa',
      terms_accepted: null,
    },

    showForm: {
      tags: [],
      categories: [],
      types: [],
      type_urls: {},
      platform_stats: [
        {label: null, value: null},
        {label: null, value: null},
        {label: null, value: null},
      ],
      imageUrl: '',
      coverImageUrl: '',
      links: {
        social_media: {
          linkedin: '',
          twitter: '',
          instagram: '',
          youtube: '',
          facebook: '',
          wikipedia: '',
          website: '',
          tiktok: '',
        },
        episodes: [],
        media_features: [],
      },
      rephonic_id: null,
    },

    guestForm: {
      tags: [],
      categories: [],
      links: {
        social_media: {
          linkedin: '',
          twitter: '',
          instagram: '',
          youtube: '',
          facebook: '',
          wikipedia: '',
          website: '',
          tiktok: '',
        },
        podcasts: [],
        videos: [],
        articles: [],
      },
      imageUrl: '',
      experience: [''],
      topics: [''],
      questions: [''],
    },

    tags: [],

    selectedPlan: null,

    availablePlans: [
      {label: 'Free', price: 0, key: 'free'},
      {label: 'Pro Monthly', price: process.env.VUE_APP_PRO_MONTHLY_PRICE, key: 'monthly'},
      {label: 'Pro Yearly', price: process.env.VUE_APP_PRO_YEARLY_PRICE, key: 'yearly'},
    ],

    coupon: null,
  },

  getters: {
    currentStep: (state) => state.currentStep,
    tags: (state) => state.tags,
    selectedPlan: (state) => state.selectedPlan,
    availablePlans: (state) => state.availablePlans,
    coupon: (state) => state.coupon,
  },

  actions: {
    setCurrentStep: ({commit, state}, newValue) => {
      commit('setCurrentStep', newValue)
      return state.currentStep
    },

    fetchTags({ commit }) {
      return api.get(`/tags`)
        .then(({ data }) => {
          commit('setTags', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setCurrentStep(state, data) {
      state.currentStep = data;
    },

    setImageUrl(state, data) {
      if (data.guest) {
        state.guestForm.imageUrl = data.imageUrl;
      }else {
        state.showForm.imageUrl = data.imageUrl;
      }
    },

    setCoverImageUrl(state, data) {
      state.showForm.coverImageUrl = data.coverImageUrl;
    },

    setTags(state, tags) {
      for (let key in tags) {
        state.tags.push({name: tags[key], code: key })
      }
    },
    setEmail(state, email) {
      state.userForm.email = email;
    },

    clearShowForm(state) {
      state.showForm = {
        tags: [],
        categories: [],
        platform_stats: [
          {label: null, value: null},
          {label: null, value: null},
          {label: null, value: null},
        ],
        imageUrl: '',
        links: {
          social_media: {
            linkedin: '',
            twitter: '',
            instagram: '',
            youtube: '',
            facebook: '',
            wikipedia: '',
            website: '',
            tiktok: '',
          },
          episodes: [],
          media_features: [],
        },
        rephonic_id: null,
      }
    },

    setSelectedPlan(state, plan) {
      state.selectedPlan = plan
    },

    setCoupon(state, coupon) {
      state.coupon = coupon;
    },

    setRephonicId(state, rephonicId) {
      state.showForm.rephonic_id = rephonicId;
    },
  },
}

export default register
