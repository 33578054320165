<template>
  <div v-if="profile.user_id !== user.id && bookingOptions.length > 0 && profile.pitch_settings && profile.pitch_settings.is_enabled">
    <template v-if="authenticated">
      <button
        class="text-violet bg-white rounded-full font-semibold uppercase hover:opacity-90 transition-opacity duration-150 ease-in-out flex items-center"
        :class="[classes, padding, border]"
        @click.prevent="openModal">
        <span class="uppercase">{{ text }}</span>
      </button>
    </template>
    <template v-else>
      <modal
        class="text-violet bg-white rounded-full font-semibold uppercase hover:opacity-90 transition-opacity duration-150 ease-in-out"
        :class="[classes, padding, border]"
        key="loginModal"
      >
        <span class="uppercase">{{ text }}</span>
        <div slot="content">
          <LoginModal :redirect="redirectAfter"/>
        </div>
      </modal>
    </template>
    <PitchModal class="hidden" :type="type" ref="pitchModal" @continue="goToBookingPitch()"/>
  </div>
</template>

<script>
import { mapState } from "vuex"
import Modal from "@/components/shared/Modal"
import LoginModal from "@/components/shared/LoginModal"
import PitchModal from '@/components/profile/PitchModal';

export default {
  name: "PitchButton",
  components: { Modal,LoginModal, PitchModal },
  props: {
    profile: Object,
    classes: { type: String, required: false, default: "" },
    padding: { type: String, required: false, default: "px-8 py-2.5" },
    border: { type: String, required: false, default: "" },
    type: { type: String, default: 'show' }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authenticated: state => state.auth.authenticated
    }),

    redirectAfter() {
      return `/${this.type == 'show' ? 'shows' : 'guests'}/${this.profile.id}/book`;
    },

    text() {
      return `Pitch`;
    },

    routeName() {
      return this.type == 'show' ? 'ShowBookingPitch' : 'GuestBookingPitch';
    },

    routeParams() {
      let params = {};
      if (this.type == 'show') {
        params['showId'] = this.profile.id;
      } else {
        params['guestId'] = this.profile.id;
      }

      params['is_pitch'] = true;

      return params;
    },

    bookingOptions() {
      return this.profile.option_prices.filter(option => option.booking_option.slug != 'pitch' && option.booking_option.slug != 'miniview');
    },

    price() {
      if (this.profile.pitch_settings) {
        return this.profile.pitch_settings.price;
      }
      const pitch = this.bookingOptions.find(option => option.slug == 'pitch');
      if (pitch) {
        return pitch.price;
      }
      return 0;
    }
  },

  methods: {
    goToBookingPitch() {
      this.$store.commit(`${this.type}Booking/setIsPitch`, true);
      this.$router.push({ name: this.routeName, params: this.routeParams })
    },
    
    openModal(){
      this.$refs.pitchModal.toggleModal();
    }
  },
};
</script>
