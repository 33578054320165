<template>
  <div class="flex justify-between bg-gray-50 px-6 py-4 rounded-lg shadow-sm">
    <div>
      <div class="flex items-center space-x-2">
        <CardIcon class="h-5 w-5 text-gray-500" />
        <span class="capitalize">{{ paymentMethod.brand }} **** {{ paymentMethod.last4 }}</span>
      </div>
      <div class="text-sm text-gray-500">
        Expires {{ paymentMethod.exp_month }}/{{ paymentMethod.exp_year }}
      </div>
      <div class="mt-2">
        <span v-if="paymentMethod.default" class="bg-grayHome text-white text-xs px-2.5 py-1 rounded-md">Default</span>
      </div>
    </div>
    <slot name="button">
      <button @click.prevent="$emit('onClick')" type="button" class="text-pink-500 underline">
        Use
      </button>
    </slot>
  </div>
</template>

<script>
import CardIcon from '@/components/svgs/CardIcon';

export default {
  name: 'UseExistingPaymentMethodButton',
  components: { CardIcon },
  props: {
    paymentMethod: { type: Object, required: true }
  }
}
</script>