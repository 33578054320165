<template>
  <div>
    <div class="h-16 border-b border-gray-100 flex items-center px-6 xl:px-12">
      <button @click.prevent="closePicker" class="text-gray-500 flex items-center hover:text-pink-500 transition-colors duration-200 ease-in-out">
        <svg class="h-4 w-4 opacity-75" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M15 19l-7-7 7-7"></path></svg>
        <span class="ml-4">Return to Calendar</span>
      </button>
    </div>
    <div class="px-6 xl:px-12 mt-4 xl:mt-6">
      <h3 class="text-black font-semibold text-2xl text-center">{{ day.format('MMMM DD') }}</h3>

      <div class="mt-6 xl:mt-12 flex flex-col space-y-3">
        <div v-for="(slot, index) in selectedSlots" :key="`slot-${index}`" class="mx-auto relative">
          <span class="bg-transparent rounded-full bg-gray-50 h-12 px-5 inline-flex items-center">
            <ClockIcon class="mr-3 flex-shrink-0"/>
            <select class="bg-transparent" v-model="selectedSlots[index]">
              <option
                v-for="time in availableTimeSlots"
                :value="time"
                :key="`slot-option-${time.format('HH:mm')}`"
                :hidden="isSelectedTime(time) && selectedSlots[index].unix() !== time.unix()"
              >{{ time.format('h:mm a') }}</option>
            </select>
          </span>

          <span v-if="index > 0" class="absolute ml-2 inset-y-0 inline-flex items-center justify-center">
            <button @click.prevent="removeSlot(index)" type="button" class="rounded-full bg-gray-50 h-10 w-10 flex items-center justify-center">
              <XIcon class="h-5" />
            </button>
          </span>
        </div>
      </div>

      <div v-if="!single" class="flex justify-center mt-6">
        <button @click.prevent="addSlot" type="button" class="text-pink-500 underline mt-2 block">
          Add Additional Time +
        </button>
      </div>

      <div class="my-12">
       <button
          class="w-full bg-transparent hover:bg-pink-100 h-14 rounded-full border flex items-center justify-center transition-colors duration-300 ease-in-out"
          type="submit"
          :class="! selectedSlots.length ? 'border-gray-500 border-gray-500 opacity-50' : 'text-pink-500 border-pink-500'"
          :disabled="! selectedSlots.length"
          @click.prevent="selectSlots"
        >
          <span v-if="selectedSlots.length">
            Add {{ slotsText }}
          </span>
          <span v-else>Select some times</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import Moment from 'moment'
  import pluralize from 'pluralize'
  import { extendMoment } from 'moment-range'
  import ClockIcon from "@/components/svgs/ClockIcon"
  import XIcon from "@/components/svgs/XIcon"
  const moment = extendMoment(Moment)

  export default {
    name: "CalendarTimeSlots",
    components: { ClockIcon, XIcon, },
    props: {
      day: Object,
      single: Boolean,
      selectedSlotsProp: Array,
    },

    data() {
      return {
        now: moment(),
        selectedSlots: [],
      }
    },

    computed: {
      slotsText() {
        return `${this.selectedSlots.length} ${pluralize('Time', this.selectedSlots.length)}`
      },

      availableTimeSlots() {
        let start = this.day.clone().hour(0).startOf('hour')
        let end = this.day.clone().hour(23).endOf('hour')
        let slots = Array.from(moment.range(start, end).by('minutes', { step: 15 }))
        return slots.filter(slot => slot.isSameOrAfter(this.now))
      },

      findFirstAvailableSlot() {
        return this.availableTimeSlots.filter(slot => ! this.isSelectedTime(slot))[0]
      },
    },

    methods: {
      closePicker() {
        this.$emit('close')
      },

      selectSlots() {
        this.$emit('updated-slots', this.selectedSlots)
        this.closePicker()
      },

      addSlot() {
        this.selectedSlots.push(this.findFirstAvailableSlot)
      },

      removeSlot(index) {
        this.selectedSlots.splice(index, 1)
      },

      isSelectedTime(time) {
        return !! this.selectedSlots
          .filter(slot => slot.unix() === time.unix())
          .length
      }
    },

    created() {
      if (this.selectedSlotsProp) {
        this.selectedSlots = [
          ...this.selectedSlotsProp.filter(slot => {
            return slot.format('YYYY-MM-DD') === this.day.format('YYYY-MM-DD')
          })
        ]
      }
    },

    mounted() {
      if (! this.selectedSlots.length) {
        this.selectedSlots.push(this.findFirstAvailableSlot)
      }
    }
  }
</script>
