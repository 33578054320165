<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <label class="text-gray-600" for="">First and Last name</label>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 flex items-center justify-between">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <span class="font-semibold">{{ userProp.first_name }} {{ userProp.last_name }}</span>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="nameForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div>
              <ValidationProvider mode="lazy" rules="required|max:255" vid="first_name" name="First name" v-slot="{ errors }">
                <div>
                  <div class="rounded-lg shadow-sm">
                    <label for="first_name" class="sr-only">First Name</label>
                    <input name="first_name" id="first_name" type="text" class="guestio-form-input" v-model="form.first_name" placeholder="First Name" />
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>

              <ValidationProvider mode="lazy" rules="required|max:255" vid="last_name" name="Last name" v-slot="{ errors }">
                <div>
                  <div class="rounded-lg shadow-sm mt-4">
                    <label for="last_name" class="sr-only">First Name</label>
                    <input name="last_name" id="last_name" type="text" class="guestio-form-input" v-model="form.last_name" placeholder="Last Name" />
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </div>
              </ValidationProvider>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    components: {Edit, SaveOrCancel},

    props: {
      userProp: Object,
      admin: {
        type: Boolean,
        default: false,
      }
    },

    data() {
      return {
        working: false,
        edit: false,
        form: {
          first_name: this.userProp.first_name,
          last_name: this.userProp.last_name,
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) return

        this.form.first_name = this.userProp.first_name
        this.form.last_name = this.userProp.last_name
      }
    },

    computed: {
      updateAction() {
        if (this.admin) {
          return `auth/updateAdminUser`
        }

        return `auth/updateUser`
      }
    },

    methods: {
      update() {
        this.working = true

        this.$store.dispatch(this.updateAction, this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.nameForm.setErrors(error.response.data.errors)
            }
          })
      }
    }
  }
</script>
