<template>
  <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.375 13.125V16.625C18.375 17.0891 18.1906 17.5342 17.8624 17.8624C17.5342 18.1906 17.0891 18.375 16.625 18.375H4.375C3.91087 18.375 3.46575 18.1906 3.13756 17.8624C2.80937 17.5342 2.625 17.0891 2.625 16.625V13.125"
      stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6.125 8.75L10.5 13.125L14.875 8.75" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
    <path d="M10.5 13.125V2.625" stroke="currentColor" stroke-width="2" stroke-linecap="round"
          stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DownloadIcon',
}
</script>

<style lang="css" scoped></style>
