<template>
  <div>
    <template v-if="bookings.length || !initialSearch">
      <BookingStats :stats="stats"/>
      <BookingFilters @filterBookings="fetch"/>

      <!--Regular view-->
      <div v-if="calendarView === false" class="grid grid-cols-1 row-gap-10" ref="bookings">
        <div v-if="loading" class="max-w-md mx-auto flex justify-center">
          <loading-icon class="h-10 w-10 text-pink-500"/>
        </div>

        <template v-else>
          <!--Table header-->
          <TableHeader/>

          <!--Rows-->
          <BookingRow
            :booking-prop="booking"
            v-for="(booking, index) in bookings"
            :key="`booking-row-${index}`"
          />

          <div v-if="pagination" class="pagination my-12 flex justify-end">
            <nav class="h-10 flex items-center text-gray-500" role="pagination">
              <router-link :to="prevPageRoute" class="opacity-40 w-10 h-full flex items-center justify-center border border-gray-500 rounded-l-xl hover:opacity-60 transition ease-in-out duration-150">
                <ChevronLeftAltIcon class="h-5" />
              </router-link>

              <router-link
                v-for="page in paginationRange"
                :key="page"
                :to="pageRoute(page)"
                :class="{'opacity-40': page !== pagination.current_page}"
                class="w-10 h-full flex items-center justify-center border border-gray-500"
              >
                {{ page }}
              </router-link>

              <router-link v-if="pagination.links.next" :to="nextPageRoute" class="opacity-40 w-10 h-full flex items-center justify-center border border-gray-500 rounded-r-xl hover:opacity-60 transition ease-in-out duration-150">
                <ChevronRightAltIcon class="h-5" />
              </router-link>
            </nav>
          </div>
        </template>
      </div>
      <!--Calendar view-->
      <CalendarComponent v-else/>
    </template>

    <template v-else>
      <div v-if="loading" class="max-w-md mx-auto flex justify-center">
        <loading-icon class="h-10 w-10 text-pink-500"/>
      </div>
      <div v-else class="max-w-md mx-auto flex flex-col items-center">
        <h2 class="text-3xl font-semibold">
          No bookings found.
        </h2>
        <div class="mt-12">
          <button
            @click.prevent="reload"
            :disbled="loading"
            :class="{'opacity-50': loading}"
            class="flex items-center shadow border border-gray-50 rounded-full py-3 px-6 bg-gray-50 hover:bg-gray-100"
          >
            Reload
          </button>
        </div>
      </div>
    </template>

    <!--Calendar modal-->
    <portal to="modal">
      <CalendarModal v-if="calendarView === true"/>
    </portal>

  </div>
</template>
<script>
  import api from '@/api'
  import { range } from 'lodash'
  import BookingStats from "@/components/bookings/BookingStats";
  import BookingFilters from "@/components/bookings/BookingFilters";
  import BookingRow from "@/components/bookings/BookingRow";
  import TableHeader from "@/components/bookings/TableHeader";
  import CalendarComponent from "../../components/bookings/calendar/index";
  import CalendarModal from "../../components/bookings/calendar/CalendarModal";
  import ChevronLeftAltIcon from "@/components/svgs/ChevronLeftAltIcon"
  import ChevronRightAltIcon from "@/components/svgs/ChevronRightAltIcon"
  import {mapState} from "vuex";

  export default {
    name: "Bookings",

    components: {
      CalendarModal,
      CalendarComponent,
      TableHeader,
      BookingRow,
      BookingFilters,
      BookingStats,
      ChevronLeftAltIcon,
      ChevronRightAltIcon,
    },

    data() {
      return {
        loading: true,
        stats: {},
        bookingsError: false,
        statsError: false,
        initialSearch: true,
        currentPage: 1,
      }
    },

    methods: {
      clearFilters() {
        this.$store.commit('dashboard/bookings/clearFilters')
      },
      fetch() {
        this.currentPage = 1
        this.fetchBookings()
      },
      fetchBookings() {
        this.loading = true

        let params = {
          page: this.currentPage
        }

        if (this.filters.statuses.length ) {
          params.status = this.filters.statuses.join();
        }

        if (this.filters.search != '' ) {
          params.search = this.filters.search;
        }

        if (this.filters.amounts.length ) {
          let amounts = this.filters.amounts.map(price => price * 100);
          params.price = amounts.join();
        }

        params.bookedBy = this.filters.bookedBy
        params.limit = 10

        this.$store.dispatch('dashboard/bookings/fetchBookings', params)
          .catch(() => { this.bookingsError = true})
          .finally(() => this.loading = false)
      },

      fetchStats() {
        this.loading = true
        api.get('/bookings/stats').then(response => {
          this.stats = response.data.data
        })
          .catch(() => this.statsError = true)
          .finally(() => this.loading = false)
      },

      reload() {
        this.clearFilters();
        this.fetch();
      },

      pageRoute(pageNo) {
        return {
          name: 'Bookings',
          query: {
            ...this.urlQuery,
            page: pageNo,
          },
        }
      },
    },

    created() {
      this.currentPage = this.$route.query.page || 1
      this.fetchBookings()
      this.initialSearch = false
      this.fetchStats()
    },

    watch: {
      $route(to, from) {
        if (to.query.page === from.query.page) {
          return
        }

        this.currentPage = to.query.page
        this.$refs.bookings.scrollIntoView({behavior: 'smooth'})
        this.fetchBookings()
      }
    },

    computed: {
      ...mapState({
        calendarView: state => state.dashboard.bookings.calendarView,
        bookings: state => state.dashboard.bookings.bookings,
        pagination: state => state.dashboard.bookings.bookingsPagination,
        filters: state => state.dashboard.bookings.filters,
      }),

      urlQuery() {
        return this.$route.query
      },

      prevPageRoute() {
        if (! this.pagination.links.previous) {
          return this.pageRoute(1)
        }

        return this.pageRoute(this.pagination.current_page - 1)
      },

      nextPageRoute() {
        if (! this.pagination.links.next) {
          return this.pageRoute(this.pagination.total_pages)
        }

        return this.pageRoute(this.pagination.current_page + 1)
      },

      paginationRange() {
        if (this.pagination.total_pages <= 5) {
          return this.pagination.total_pages
        }

        if (this.pagination.current_page > 3) {
          return range(this.pagination.current_page - 2, this.pagination.current_page + 3)
        }

        return 5
      }
    },

    mounted() {
      this.$store.dispatch('dashboard/bookings/setBookingsAsSeen');
    }
  }
</script>

