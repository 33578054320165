import API from './index';

const login = (data) => {
  return API.post(`/auth/login`, data);
};

const logout = () => {
  return API.post(`/auth/logout`);
};

const register = (data) => {
  return API.post(`/auth/register`, data);
};

const setCSRFCookie = () => {
  return API.get(`/sanctum/csrf-cookie`);
}

const getUser = () => {
  return API.get(`/users/me`)
};

const updateUser = (userId, data) => {
  return API.put(`/users/${userId}`, data)
};

const requestResetPassword = (data) => {
  return API.post('/auth/password/email', data)
};

const resetPassword = (data) => {
  return API.post('/auth/password/reset', data)
};

const impersonate = (data) => {
  return API.post(`/auth/validate-token`, data);
};

const verifyEmail = (data) => {
  const url = data.split("auth/email/verify/");
  return API.get(`/auth/email/verify/${url[1]}`, data);
};

const resendEmailVerificationNotification = () => {
  return API.post(`/auth/email/resend`);
};

const storePaymentMethod = (data) => {
  return API.post('/user/payment/methods', data)
}

const deleteMedia = (data) => {
  return API.delete(`/users/${data.model_id}/media/${data.uuid}`)
}

const acceptTerms = () => {
  return API.post(`/user/terms/approve`);
}

const impersonateTeam = (teamId) => {
  return API.post(`/auth/impersonate/${teamId}`);
}

const stopTeamImpersonation = () => {
  return API.delete(`/auth/impersonate`);
}

export {
  login,
  logout,
  register,
  getUser,
  updateUser,
  setCSRFCookie,
  requestResetPassword,
  resetPassword,
  impersonate,
  verifyEmail,
  resendEmailVerificationNotification,
  storePaymentMethod,
  deleteMedia,
  acceptTerms,
  impersonateTeam,
  stopTeamImpersonation,
};
