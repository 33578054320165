import api from '@/api'

export default {
  namespaced: true,

  actions: {
    fetchReferrerCoupons() {
      return api.get(`/referrer-coupons`)
        .then(({ data }) => {
          return Promise.resolve(data.data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },

    fetchCoupon(_, code) {
      return api.get(`/coupons/${code}`)
        .then(({ data }) => {
          return Promise.resolve(data.data);
        })
        .catch(error => {
          return Promise.reject(error);
        });
    },
  },
}
