<template>
    <div  class="rounded-xl h-40 bg-center bg-no-repeat bg-cover category-card  mr-4 lg:mr-6 relative flex items-end  cursor-pointer pulse">
    <div class="rounded-xl absolute inset-0 flex items-end p-5 purple-gradiant" >
    </div>
  </div>
</template>

<script>

export default {
  name: "CategorySkeleton",
  data() {
    return {
      items: ['1','2','3','4'],
    }
  }
}
</script>

<style scoped>
.category-card {
  flex: 0 0 263px;
}
  
.slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
.pulse{
   background: 
    linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(rgb(211, 210, 210), rgb(211, 210, 210)),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(rgb(211, 210, 210), rgb(211, 210, 210)); 
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
  animation: loading 1.5s infinite;
}
</style>

