import api from '@/api'

const availability = {
  namespaced: true,

  state: {
    settings: {
      interview_durations: [
        {duration: 30, price: 0}
      ],
      type: 'unlimited',
      rolling_days: 30,
      start_date: new Date,
      end_date: null,
      max_interviews_per_day: 4,
      max_interviews_per_month: null,
      minimum_notice: 240,
      buffer_before: 60,
      buffer_after: 60,
      spot_increments: 60,
      timezone: null,
    },

    slots: {},

    requestMade: {},

    rules: [],

    defaultRules: [
      {
        type: 'wday',
        wday: 'sunday',
        intervals: []
      },
      {
        type: 'wday',
        wday: 'monday',
        intervals: [
          {from: '09:00', to: '17:00'}
        ]
      },
      {
        type: 'wday',
        wday: 'tuesday',
        intervals: [
          {from: '09:00', to: '17:00'}
        ]
      },
      {
        type: 'wday',
        wday: 'wednesday',
        intervals: [
          {from: '09:00', to: '17:00'}
        ]
      },
      {
        type: 'wday',
        wday: 'thursday',
        intervals: [
          {from: '09:00', to: '17:00'}
        ]
      },
      {
        type: 'wday',
        wday: 'friday',
        intervals: [
          {from: '09:00', to: '17:00'}
        ]
      },
      {
        type: 'wday',
        wday: 'saturday',
        intervals: []
      },
    ],

    availableDurations: [ 15, 30, 45, 60, 75, 90, 120, 180 ],
  },

  getters: {
    settings(state) {
      return state.settings
    },

    rules(state) {
      return state.rules
    },

    slots(state) {
      return state.slots
    },

    availableDurations(state) {
      return state.availableDurations
    }
  },

  actions: {
    fetch({ commit }, payload) {
      return api.get(`/guests/${payload.guestId}/availability`)
        .then(({ data }) => {
          commit('setAvailability', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    update({ commit }, payload) {
      return api.put(`/guests/${payload.guestId}/availability`, payload)
        .then(({ data }) => {
          commit('setAvailability', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchSlots({ commit }, payload) {
      return api.get(`/guests/${payload.guestId}/availability/slots`, {
        params: payload
      })
        .then(({ data }) => {
          commit('setSlots', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    createRequest({ commit }, payload) {
      return api.post(`/${payload.profileType}s/${payload.profileId}/${payload.profileType}-availability-request`, payload.profileId)
        .then(({ data }) => {
          commit('setRequestMade', data.data)
          return Promise.resolve(data);
        })
        .catch(error => {
          return Promise.reject(error);
        })
    },
  },

  mutations: {
    setSettings(state, payload) {
      let settingsPayload = JSON.parse(JSON.stringify(payload))
      delete settingsPayload.rules

      state.settings = {
        ...state.settings,
        ...settingsPayload
      }
    },

    setRules(state, payload) {
      state.rules = [
        ...state.rules,
        ...payload
      ]
    },

    setAvailability(state, payload) {
      let settingsPayload = JSON.parse(JSON.stringify(payload))
      delete settingsPayload.rules

      state.settings = {
        ...state.settings,
        ...settingsPayload
      }

      if (payload.rules.length) {
        state.rules = [
          ...payload.rules
        ]
      } else {
        state.rules = [
          ...state.defaultRules
        ]
      }
    },

    setSlots(state, payload) {
      state.slots = payload
    },

    setRequestMade(state, payload) {
      state.requestMade = payload
    },

    addInterviewDuration(state, payload) {
      state.settings.interview_durations.push(payload)
    }
  },
}

export default availability
