<template>
  <div class="max-w-5xl w-full max-h-screen grid grid-cols-1 sm:grid-cols-2 sm:gap-16 bg-white rounded-xl overflow-y-scroll" @click.stop>
    <div class="sm:block relative left-col bg-black px-12 py-16 overflow-hidden">
      <div class="flex flex-col items-center justify-center">
        <h3 class="text-white uppercase font-bold">
          Pro Plan
        </h3>
        <h4 class="text-white font-bold text-6xl">
          {{ plan == 'monthly' ? `$${plans.monthly}` : `$${plans.yearly}` }}
        </h4>

        <div class="mt-8 flex flex-col items-center">
          <p class="text-white opacity-70 text-sm">Unlimited posibilities.</p>
          <p class="text-white opacity-70 text-sm">Secure payments. Cancel anytime.</p>
          <ul class="mt-8 space-y-4">
            <li class="flex items-center text-white text-sm">
              <CheckIcon class="opacity-70 h-6 mr-4" />
              Homepage Feature
            </li>
            <li class="flex items-center text-white text-sm">
              <CheckIcon class="opacity-70 h-6 mr-4" />
              Personalized and Optimized Profile
            </li>
            <li class="flex items-center text-white text-sm">
              <CheckIcon class="opacity-70 h-6 mr-4" />
              Audio and Video Guest pitches
            </li>
            <li class="flex items-center text-white text-sm">
              <CheckIcon class="opacity-70 h-6 mr-4" />
              Customizable Upsells
            </li>
            <li class="flex items-center text-white text-sm">
              <CheckIcon class="opacity-70 h-6 mr-4" />
              Customizable Questions
            </li>
            <li class="flex items-center text-white text-sm">
              <CheckIcon class="opacity-70 h-6 mr-4" />
              Search Analytics for shows
            </li>
            <li class="flex items-center text-white text-sm">
              <CheckIcon class="opacity-70 h-6 mr-4" />
              Customizable booking flow
            </li>
          </ul>
        </div>
      </div>
      <div class="absolute bottom-0 inset-x-0 ml-10">

      </div>
    </div>

    <div class="right-col px-6 py-8 sm:pl-0 sm:pr-16 sm:py-16">
      <ValidationObserver ref="subscriptionForm" v-slot="{ handleSubmit }" tag="div">
        <form method="post" @submit.prevent="handleSubmit(submitPayment)">
          <div class="flex justify-center items-center space-x-4">
            <button @click="togglePlan('monthly')" type="button" class="text-sm font-bold focus:shadow-outline rounded-lg px-1 py-1">
              Pay monthly
            </button>
            <button @click="togglePlan()" type="button" class="bg-pink-500 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:shadow-outline" role="switch" aria-checked="false">
              <span class="sr-only">Plan Toggle</span>
              <span aria-hidden="true" :class="plan == 'monthly' ? 'translate-x-0' : 'translate-x-5'" class="pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
            </button>
            <button @click="togglePlan('yearly')" type="button" class="text-sm font-bold focus:shadow-outline rounded-lg px-1 py-1">
              Pay yearly
            </button>
          </div>

          <div class="mt-6 sm:mt-12">
            <h4 class="text-center text-3xl font-bold text-blue-800">
              Go Pro Now
            </h4>

            <template v-if="paymentMethods.length && useExistingPaymentMethod">
              <div class="mt-6 ">
                <div class="text-black font-medium">Payment method</div>
                <ExistingPaymentMethodCard :paymentMethod="paymentMethodToUse" />
                <div class="mt-4">
                  <button @click.prevent="useExistingPaymentMethod = null" type="button" class="text-pink-500 underline">
                    Use different payment method
                  </button>
                </div>
              </div>
            </template>

            <template v-else>
              <CreditCard :saveCardButton="false" @cardComplete="setCardComplete"/>

              <div v-if="!useExistingPaymentMethod" class="space-y-2 mt-12">
                <UseExistingPaymentMethodButton
                  v-for="(paymentMethod, index) in paymentMethods"
                  :key="`payment-methods-${index}`"
                  :paymentMethod="paymentMethod"
                  @onClick="useExistingPaymentMethod = paymentMethod.id"
                />
              </div>
            </template>

            <div class="coupons mt-6">
              <button v-if="!showCouponField" class="text-violet font-bold px-1 py-1 rounded" @click="showCouponField = true">
                Have a coupon code?
              </button>

              <div v-if="showCouponField">
                <div v-if="! coupon">
                  <label class="font-medium text-black">Coupon Code</label>
                  <div class="mt-2">
                    <TextInput
                      v-model="couponCode"
                      placeholder="Enter coupon code..."
                      :errors="couponError ? [couponError] : []"
                      @input="fetchCouponCode"
                    />
                  </div>
                </div>

                <div
                  v-else
                  class="flex justify-between  px-6 py-4 rounded-lg shadow-sm"
                  :class="coupon.is_valid ? 'bg-gray-50' : 'bg-red-100'"
                >
                  <div>
                    <div>
                      <h4 class="flex items-center space-x-2">
                        <span>Coupon:</span>
                        <span class="font-bold uppercase">
                          {{ coupon.code }}
                        </span>
                      </h4>
                    </div>

                    <div v-if="coupon.is_valid && coupon.description.length" class="mt-4 text-sm text-green-500 font-medium">
                      {{ coupon.description }}
                    </div>

                    <div v-if="! coupon.is_valid" class="mt-4 text-sm text-red-500 font-medium">
                      This coupon is not valid.
                    </div>
                  </div>
                  <button @click.prevent="setCoupon(null)" type="button" class="text-pink-500 underline">
                    Remove
                  </button>
                </div>
              </div>
            </div>

            <div v-if="paymentSetupError" class="mt-6">
              <p class="text-red-500">There was an error while attempting to start your subscription.</p>
              <p v-if="errorDetails" class="mt-2 text-red-500">{{ errorDetails }}</p>
            </div>

            <div class="mt-12">
              <button
                type="submit"
                class="font-semibold rounded-full h-16 sm:h-16 bg-indigo-gradiant text-white text-xl w-full flex justify-center items-center"
                :class="{'opacity-50': loading || ! stripeComplete || (coupon && !coupon.is_valid)}"
                :disabled="loading || ! stripeComplete || (coupon && !coupon.is_valid)">
                <loading-icon v-if="loading" class="h-5 w-5"/>
                <template v-else>
                  Unlock Pro Now
                </template>
              </button>

              <div class="mt-4 text-gray-500 text-sm text-center">
                Includes a 14 day trial before billing begins
              </div>
            </div>
          </div>
        </form>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
  import { debounce } from 'lodash';
  import api from '@/api'
  import CheckIcon from '@/components/svgs/CheckIcon'
  import { confirmCardSetup } from 'vue-stripe-elements-plus';
  import TextInput from "@/components/shared/TextInput";
  import CreditCard from '@/components/payment-methods/CreditCard';
  import ExistingPaymentMethodCard from '@/components/payment-methods/ExistingPaymentMethodCard';
  import UseExistingPaymentMethodButton from '@/components/payment-methods/UseExistingPaymentMethodButton';

  export default {
    name: 'SubscribeModal',

    props: {
      user: Object,
      plans: Object,
    },

    components: {
      CheckIcon,
      CreditCard,
      TextInput,
      ExistingPaymentMethodCard,
      UseExistingPaymentMethodButton,
    },

    data() {
      return {
        loading: false,
        plan: 'monthly',
        intent: null,
        cardComplete: false,
        card: null,
        paymentSetupError: false,
        errorDetails: null,
        defaultPaymentMethod: null,
        paymentMethods: [],
        useExistingPaymentMethod: null,
        showCouponField: false,
        couponCode: '',
        couponError: null,
      }
    },

    computed: {
      stripeComplete() {
        return this.useExistingPaymentMethod != null || this.cardComplete
      },
      price() {
        if(this.plan == 'yearly') {
          return process.env.VUE_APP_PRO_YEARLY_PRICE ? process.env.VUE_APP_PRO_YEARLY_PRICE : 997;
        }

        return process.env.VUE_APP_PRO_MONTHLY_PRICE ? process.env.VUE_APP_PRO_MONTHLY_PRICE : 97;
      },
      coupon() {
        return this.$store.getters['register/coupon'];
      },

      paymentMethodToUse() {
        if (this.useExistingPaymentMethod) {
          return this.paymentMethods.find(pm => pm.id == this.useExistingPaymentMethod);
        }
        return this.defaultPaymentMethod;
      },
    },

    watch: {
      useExistingPaymentMethod() {
        this.generatePaymentIntent()
      },
    },

    methods: {
      togglePlan(plan) {
        if (plan) {
          return this.plan = plan
        }

        this.plan = this.plan == 'monthly' ? 'yearly' : 'monthly'
      },

      setCoupon(coupon) {
        this.$store.commit('register/setCoupon', coupon);
        this.showCouponField = true;
      },

      async submitPayment() {
        this.loading = true
        this.paymentSetupError = false
        this.errorDetails = null

        if (this.useExistingPaymentMethod) {
          this.selectedPaymentMethod = this.paymentMethodToUse.id
          return this.createSubscription()
        }

        const { setupIntent, error } = await confirmCardSetup(this.intent.client_secret, {
          payment_method: {
            card: this.card
          }
        })

        if (error) {
          this.loading = false
          this.paymentSetupError = true

          if (error.message) {
            this.errorDetails = error.message
          }

          return
        }

        this.selectedPaymentMethod = setupIntent.payment_method

        await this.$store.dispatch('auth/storePaymentMethod', {
          payment_method: this.selectedPaymentMethod,
        }).catch(error => {
          if (error.response) {
            this.errorDetails = error.response.data.message
          }
        })

        this.createSubscription()
      },

      createSubscription() {
        api.post('/users/me/subscription', {
          plan: this.plan,
          payment_method: this.selectedPaymentMethod,
          price: this.price,
          coupon: this.coupon?.code,
        }).then(({ data }) => {
          this.$store.dispatch('auth/getUser')
          this.$emit('subscribed', data.data)
        }).catch(error => {
          if (error.response) {
            this.errorDetails = error.response.data.message
          }
          this.generatePaymentIntent()
          this.loading = false
          this.paymentSetupError = true
        })
      },

      generatePaymentIntent() {
        let appends = {
          usage: 'off_session',
        };

        if (this.useExistingPaymentMethod) {
          appends.payment_method = this.useExistingPaymentMethod
        }

        let params = new URLSearchParams(appends)

        api.get(`/user/payment/intent?${params}`).then(response => {
          this.intent = response.data.intent
        })
      },

      fetchPaymentMethods() {
        api
          .get('/user/payment/methods')
          .then(({ data }) => {
            if (! data.length) {
              this.generatePaymentIntent()
              return
            }
            this.defaultPaymentMethod = data.find(pm => pm.default);
            this.paymentMethods = data
            this.useExistingPaymentMethod = this.defaultPaymentMethod ? this.defaultPaymentMethod.id : data[0].id
          })
      },

      fetchReferrerCoupons() {
        this.$store
          .dispatch('coupons/fetchReferrerCoupons')
          .then(coupons => {
            if (!coupons.length) {
              return;
            }

            this.setCoupon(coupons[0]);
            this.showCouponField = true;
          });
      },

      fetchCouponCode: debounce(function (code) {
        this.couponError = null;

        if (!code.length) {
          return;
        }

        this.$store
          .dispatch('coupons/fetchCoupon', code)
          .then(coupon => {
            this.setCoupon(coupon);
          })
          .catch(error => {
            if (error.response && error.response.status === 404) {
              this.couponError = 'Coupon not found.';
            }
          })
      }, 500),

      setCardComplete(data) {
        this.cardComplete = data.complete;
        this.card = data.card;
      },
    },

    mounted() {
      this.fetchPaymentMethods();

      if (this.user.referred_by) {
        this.fetchReferrerCoupons();
      }
    },
  }
</script>
