<template>
  <div class="dashboard-card border border-gray-50">
    <div class="px-4">
      <template v-if="loading">
        <div class="h-40 flex items-center justify-center">
          <loading-icon class="w-5 h-5 text-pink-500" />
        </div>
      </template>
      <template v-else>
        {{ $route.params.action }}
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CompletedAction",

    data() {
      return {
        loading: true,
      }
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      userOwnsBookingGuest() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingGuest']
      },

      userOwnsBookingShow() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
      },

      action() {
        return this.$route.params.action
      }
    },

    methods: {
      confirm() {
        return this.$store.dispatch('dashboard/bookings/confirmCompletedBooking', {
          id: this.booking.id,
        }).then(({ data }) => {
          this.$toast.success(data.message)
        }).catch(error => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Your action did not complete.')
        }).finally(() => this.$router.push({name: 'Bookings'}))
      },

      reschedule() {
        return this.$store.dispatch('dashboard/bookings/rescheduleCompletedBooking', {
          id: this.booking.id,
        }).then(({ data }) => {
          this.$toast.success(data.message)
        }).catch(error => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Your action did not complete.')
        }).finally(() => this.$router.push({name: 'Bookings'}))
      },

      cancel() {
        return this.$store.dispatch('dashboard/bookings/cancelCompletedBooking', {
          id: this.booking.id,
        }).then(({ data }) => {
          this.$toast.success(data.message)
        }).catch(error => {
          if (error.response) {
            return this.$toast.error(error.response.data.message)
          }

          this.$toast.error('Your action did not complete.')
        }).finally(() => this.$router.push({name: 'Bookings'}))
      }
    },

    mounted() {
      if (! ['confirm', 'reschedule', 'cancel'].includes(this.action)) {
        return this.$router.push({name: 'Bookings'})
      }

      // run action
      this[this.action]()
    }
  }
</script>
