import Dashboard from "../views/Dashboard";
import LoggedHome from "../views/LoggedHome";
import BookingsIndex from "../views/bookings/Index";
import BookingDetail from "../views/bookings/Show";
import ActivityCard from "../components/bookings/individual/ActivityCard";
import ReviewCard from "../components/bookings/individual/ReviewCard";
import CompletedAction from "../components/bookings/individual/CompletedAction";
import Payments from "../views/Payments";
import Wishlist from "../views/Wishlist";
import Details from "../views/Details";
import Settings from "../views/Settings";
import Messages from "@/views/Messages";
import MiniviewsIndex from "@/views/miniviews/Index";
import MiniviewDetail from "@/views/miniviews/Show";
import MiniviewQuestions from "@/components/miniviews/individual/MiniviewQuestions";
import MiniviewAnswerGroups from "@/components/miniviews/individual/MiniviewAnswerGroups";
import MiniviewAnswers from "@/components/miniviews/individual/MiniviewAnswers";

const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: LoggedHome,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: '/',
        name: 'Dashboard',
        component: Dashboard
      },
      {
        path: '/bookings',
        name: 'Bookings',
        component: BookingsIndex
      },
      {
        path: '/bookings/:bookingId',
        name: 'IndividualBooking',
        component: BookingDetail,
        children: [
          {
            path: '/',
            name: 'Activity',
            component: ActivityCard,
            meta: {
              scrollToTop: false,
            }
          },
          {
            path: 'review',
            name: 'Review',
            component: ReviewCard,
            meta: {
              scrollToTop: false,
            }
          },
          {
            path: 'completed-action/:action',
            name: 'BookingCompletedAction',
            component: CompletedAction,
            meta: {
              scrollToTop: false,
            }
          },
          {
            path: 'miniview',
            name: 'BookingMiniview',
            component: () => import('@/components/bookings/individual/MiniviewCard')
          },
          {
            path: 'application',
            name: 'BookingApplication',
            component: () => import('@/components/bookings/individual/Application'),
            meta: {
              scrollToTop: false,
            }
          },
          {
            path: 'confirm-scheduled',
            name: 'ConfirmScheduled',
            component: () => import('@/views/bookings/ConfirmScheduled'),
          },
          {
            path: 'confirm-happened',
            name: 'ConfirmHappened',
            component: () => import('@/views/bookings/ConfirmHappened'),
          },
        ]
      },
      {
        path: '/user-miniviews',
        name: 'Miniviews',
        component: MiniviewsIndex
      },
      {
        path: '/user-miniviews/:miniviewId',
        component: MiniviewDetail,
        children: [
          {
            path: '/',
            name: 'MiniviewQuestions',
            component: MiniviewQuestions,
            meta: {
              scrollToTop: false,
            }
          },
          {
            path: 'answers',
            name: 'MiniviewAnswerGroups',
            component: MiniviewAnswerGroups,
            meta: {
              scrollToTop: false,
            }
          },
          {
            path: 'answers/:answerGroupId',
            name: 'MiniviewAnswers',
            component: MiniviewAnswers,
            meta: {
              scrollToTop: false,
            }
          },
        ]
      },
      {
        path: '/payments',
        name: 'Payments',
        component: Payments
      },
      {
        path: '/wishlist',
        name: 'Wishlist',
        component: Wishlist
      },
      {
        path: '/details',
        name: 'Details',
        component: Details,
        children: [
          {
            path: 'guest',
            name: 'GuestDetails',
            component: () => import('@/components/details/guest/GuestDetails')
          },
          {
            path: 'guest/create',
            name: 'GuestCreate',
            component: () => import('@/components/details/guest/GuestCreate')
          },
          {
            path: 'show',
            name: 'ShowIndex',
            component: () => import('@/components/details/show/ShowIndex')
          },
          {
            path: 'show/create',
            name: 'ShowCreate',
            component: () => import('@/components/details/show/ShowCreate')
          },
          {
            path: 'show/:showId',
            name: 'ShowDetails',
            component: () => import('@/components/details/show/ShowDetails')
          },
        ]
      },
      {
        path: '/settings',
        name: 'Settings',
        component: Settings
      },
      {
        path: '/admin-settings',
        name: 'AdminSettings',
        component: () => import('@/views/AdminSettings')
      },
      {
        path: '/messages',
        name: 'GeneralMessages',
        component: Messages
      },
    ]
  },
];

export default routes;
