<template>
  <transition
    enter-active-class="transition ease-out duration-100 transform"
    enter-class="opacity-0 scale-95"
    enter-to-class="opacity-100 scale-100"
    leave-active-class="transition ease-in duration-75 transform"
    leave-class="opacity-100 scale-100"
    leave-to-class="opacity-0 scale-95"
  >
    <div
      v-on-clickaway="closeDropdown"
      class="z-10 mt-2 origin-top-right absolute left-0 w-64 rounded-xl shadow-lg"
    >
      <div class="bg-white rounded-xl shadow-xs" @click.stop>
        <div class="relative left-col p-5 overflow-hidden">
          <div class="mb-4">
            <span class="text-gray-600 font-light">Status</span>
            <label
              v-for="(status, index) in statuses"
              :key="`tag-${index}`"
              class="flex items-center cursor-pointer my-1"
            >
              <input
                type="checkbox"
                class="form-checkbox text-pink-500 w-5 h-5 border-2 border-gray-400"
                name="status"
                :value="status.id"
                v-model="activeStatuses"
              />
              <span class="ml-3 font-light">
                <BookingStatus :status="status.name" statusSize="small"/>
                </span>
            </label>
          </div>
          <div class="mb-6">
            <span class="text-gray-600 font-light">Amount</span>
            <div class="mx-2">
              <vue-slider
                dragOnClick
                v-model="activeAmounts"
                :enable-cross="false"
                ref="amountRangeSlider"
                :tooltip-formatter="'${value}'"
                :marks="activeAmounts"
                :lazy="true"
                @drag-end="dragEnd"
                :max="1000"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import BookingStatus from '@/components/bookings/BookingStatus'

export default {
  name: "FiltersDropdownBookings",

  components: { VueSlider, BookingStatus },

  data() {
    return {
      statuses: [
        {
          id: 'is_completed',
          name: 'completed',
        },
        {
          id: 'is_scheduled',
          name: 'scheduled',
        },
        {
          id: 'is_accepted',
          name: 'accepted',
        },
        {
          id: 'is_pending',
          name: 'pending',
        },
        {
          id: 'is_canceled',
          name: 'canceled',
        },
        {
          id: 'is_expired',
          name: 'expired',
        },
      ]
    }
  },

  mixins: [clickaway],

  computed: {
    activeStatuses: {
      get() {
        return this.$store.getters['filters/activeStatuses']
      },
      set(value) {
        this.$store.commit('filters/setActiveStatuses', value)
      }
    },
    activeAmounts: {
      get() {
        return this.$store.getters['filters/activeAmounts']
      },
      set(value) {
        this.$store.commit('filters/setActiveAmounts', value)
      }
    },
  },

  methods: {
    closeDropdown() {
      this.$emit('close-dropdown')
    },
    dragEnd() {
      var amounts = this.$refs.amountRangeSlider.getValue()
      this.$store.commit('filters/setActiveAmounts', amounts)
      this.$emit('filter-amounts', amounts)
    }
  },

  watch: {
    activeStatuses(statuses) {
      this.$emit('filter-statuses', statuses)
    },
  },
};
</script>

<style lang="scss">
  .vue-slider {
    .vue-slider-process {
      @apply bg-pink-500;
    }

    .vue-slider-dot-handle {
      @apply bg-white border-pink-500;
    }

    .vue-slider-mark-step {
      display: none;
    }

    :hover {
      .vue-slide-process {
        @apply bg-pink-500;
      }

      .vue-slider-dot-handle {
        @apply bg-white border-pink-500;
        border-color: #B503D0 !important;
      }

      .vue-slider-dot-handle-focus {
        @apply bg-white border-pink-500;
        border-color: #B503D0 !important;
      }

      .vue-slider-process {
        background-color: #B503D0 !important;
      }

      .vue-slider-mark-step {
        display: none;
      }
    }
  }
  .vue-slider-ltr {
    height: 2px !important;
  }
</style>
