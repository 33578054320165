<template>
  <div class="flex flex-col items-end lg:w-10/12" :class="own ? 'ml-auto' : ''">
    <template v-if="! own">
      <div class="flex items-start w-full mb-3">
        <div class="ml-4 sm:ml-6 p-4 sm:p-6 rounded-xl w-full overflow-hidden bg-gray-50 text-sm lg:text-base"
             :class="background ? background : 'bg-gray-50'">
          <div class="flex flex-col md:flex-row w-full">
            <div class="flex items-center md:items-start space-x-2">
              <img class="w-10 h-10 sm:w-12 sm:h-12 rounded-full flex-shrink-0" :src="senderImage" alt="">
              <span class="text-violet font-bold leading-6 md:hidden">{{this.message.sender.name}}</span>
            </div>
            <div class="md:ml-3 mt-1 md:mt-0 overflow-hidden break-words flex-1">
              <span class="text-violet font-bold leading-6 mb-3 hidden md:block">{{this.message.sender.name}}</span>
              <div v-html="message.body" class="whitespace-pre-wrap" v-linkified:options="{ className: 'hover:text-violet underline' }"/>
            </div>
          </div>
        </div>
      </div>
    </template>


    <template v-if="own">
      <div class="p-4 sm:p-6 bg-indigo-500 rounded-xl w-full overflow-hidden text-white text-sm lg:text-base mb-2">
        <div v-html="message.body" class="whitespace-pre-wrap" v-linkified:options="{ className: 'hover:text-violet underline' }"/>
      </div>
    </template>
    <span class="mx-3 my-3 lg:my-0 text-xs font-bold text-gray-500 flex items-center flex-shrink-0 opacity-75">
      <svg class="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor"
           stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path
        d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
      <span class="ml-1">{{ localTimestamp }}</span>
    </span>
  </div>
</template>
<script>
import moment from 'moment'

export default {
  name: 'Message',

  props: {
    own: {
      type: Boolean,
      default: false,
    },
    background: {
      type: String,
      default: '',
    },

    message: Object,

    userTimezone: {
      type: String,
      default: ''
    }
  },

  computed: {
    localTimestamp() {
      return `${moment.utc(this.message.created_at).local().format('h:mm a')}${this.userTimezone !== '' ? ` (${this.userTimezone})` : ''}`
    },

    senderImage() {
      const defaultImage = require('@/assets/images/test_image.png');
      return this.message.sender.imageUrl ? this.message.sender.imageUrl : defaultImage
    },
  }
}
</script>
