<template>
  <div v-if="profile.user_id !== user.id" class="flex-shrink-0 max-w-xs lg:w-auto lg:max-w-none">
    <template v-if="authenticated && profile.option_prices.length > 0">
      <router-link
        class="text-white rounded-full"
        :class="[classes, padding, fontSize, {'bg-indigo-gradiant': profile.color == null}]"
        :style="backgroundColor"
        v-if="profile.lowest_price !== null"
        :to="{ name: routeName, params: { guestId: profile.id } }"
      >
        <span class="uppercase">{{ text }}</span>
      </router-link>
    </template>
    <template v-else-if="authenticated">
      <BookingAvailabilityModal
        class="text-white rounded-full"
        :class="[classes, padding, fontSize, {'bg-indigo-gradiant': profile.color == null}]"
        :style="backgroundColor"
        :profileId="this.profile.id"
        profileType="guest"
      >
        <span class="uppercase">{{ text }}</span>
        <template #content="{ close }">
          <div class="max-w-4xl w-full sm:gap-16 bg-white rounded-xl overflow-hidden text-center" >
            <h1 class="m-10 text-2xl">
              This guest does not currently have booking options available. We'll send them a message and let them know you're interested, and we'll notify you when their availability opens up!
            </h1>

            <div class="mb-6 flex items-center justify-center">
              <button
                @click="close"
                type="button"
                class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-8 py-2.5"
              >
                OK
              </button>
            </div>
          </div>
        </template>
      </BookingAvailabilityModal>
    </template>
    <template v-else>
      <modal
        class="text-white rounded-full"
        :class="[classes, padding, fontSize, {'bg-indigo-gradiant': profile.color == null}]"
        :style="backgroundColor"
        key="loginModal"
      >
        <span class="uppercase">{{ text }}</span>
        <div slot="content">
          <LoginModal :redirect="redirectAfter"/>
        </div>
      </modal>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import BookingAvailabilityModal from "@/components/shared/BookingAvailabilityModal";
import Modal from "@/components/shared/Modal";
import LoginModal from "@/components/shared/LoginModal";
import isMobile from "is-mobile";

export default {
  name: "BookNowButton",
  components: {Modal, LoginModal, BookingAvailabilityModal},
  props: {
    profile: Object,
    showPrice: {type: Boolean, required: false, default: true},
    classes: {
      type: String,
      required: false,
      default: "text-center font-semibold lg:mb-0 w-full lg:w-auto"
    },
    padding: {
      type: String,
      required: false,
      default: "px-8 py-2.5"
    },
    fontSize: {
      type: String,
      required: false,
      default: "text-base"
    },
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authenticated: state => state.auth.authenticated
    }),

    redirectAfter() {
      return `/guests/${this.profile.id}/book`;
    },

    text() {
      const now = isMobile() ? '' : 'now';

      if(this.profile.option_prices.length) {
        let price = 'See Price';
        let message = '';

        if(this.authenticated) {
          price = this.profile.lowest_price == 0 ? 'FREE' : `$${this.profile.lowest_price}`;

          message = `Book ${now} ${
          this.showPrice ? ` - ${price}` : ""
        }`;
        }

        return this.authenticated ? message: price
      } else {
        return "Request to Book"
      }
    },

    backgroundColor() {
      return this.profile.color ? `background-color: ${this.profile.color}` : ''
    },

    routeName() {
      return this.user.shows.length > 1 ? 'GuestBook' : 'GuestBookingOptions';
    }
  },
};
</script>
