import api from '@/api'
import moment from 'moment'
const payments = {
  namespaced: true,

  state: {
    filters: {
      type: 'all',
      month: moment().format('MMMM YYYY'),
      search: '',
    },

    sorting: '-payment_date',

    payments: [
      {
        id: 1,
        date: '18',
        price: 500,
        fromTo: 'Hank Williams'
      },
      {
        id: 1,
        date: '18',
        price: 1500,
        fromTo: 'Hank Williams'
      },
      {
        id: 1,
        date: '18',
        price: 600,
        fromTo: 'Hank Williams'
      },
      {
        id: 1,
        date: '18',
        price: 500,
        fromTo: 'Hank Williams'
      },
      {
        id: 1,
        date: '18',
        price: 500,
        fromTo: 'Hank Williams'
      }
    ]
  },

  getters: {
    payments: state => state.payments,

    completedPayments(state) {
      if (! state.payments.length) {
        return []
      }

      return state.payments.filter(payment => payment.status == 'completed')
    },

    filters: state => state.filters,

    filteredPayments(state) {
      return state.payments.filter(payment => {
        return payment.name.toLowerCase().includes(state.filters.search.toLowerCase())
      }).filter(payment => {
        return state.filters.type != 'all' ? payment.type == state.filters.type : true
      })
    }
  },

  actions: {
    fetchPayments({ commit, state }) {
      return api.get('/payments', {
        params: {
          'filter[for_month]': state.filters.month,
          sort: state.sorting,
        }
      }).then(({ data }) => {
        commit('setPayments', data.data)
        return Promise.resolve(data.data)
      })
      .catch(error => {
        return Promise.reject(error)
      })
    }
  },

  mutations: {
    setPayments(state, data) {
      state.payments = data
    },

    clearPayments(state) {
      state.payments = []
    },

    setFilters(state, data) {
      state.filters = data
    },

    clearFilters(state) {
      state.filters = {
        type: 'all',
        month: moment().format('MMMM YYYY'),
        search: '',
      }

      state.sorting = '-payment_date'
    },

    setSorting(state, sorting) {
      state.sorting = sorting
    }
  },

}

export default payments
