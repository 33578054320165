<template>
  <div v-if="user.teams && user.teams.length && teams.length" class="mt-8">
    <h4 class="uppercase font-medium text-sm text-grayDark">
      Team Member
    </h4>

    <div class="mt-6 space-y-8">
      <div v-for="(team, index) in teams" :key="index">
        <div class="flex items-center">
          <div>
            <template v-if="team.owner.imageUrl">
              <img v-if="!mediaIsVideo(team.owner.imageUrl)"
                class="w-10 h-10 rounded-full flex-shrink-0 mr-4"
                :src="team.owner.imageUrl"
                alt="User image"
              />

              <video v-else
                loop="loop"
                muted="true"
                autoplay="true"
                class="object-cover w-10 h-10 rounded-full flex-shrink-0 mr-4"
                :src="team.owner.imageUrl"
                alt="User image"
              ></video>
            </template>

            <template v-else>
              <DefaultProfileImage class="w-10 h-10" />
            </template>
          </div>

          <div>
            <div class="flex">
              <h4 class="font-bold capitalize">
                {{ team.owner.first_name }} {{ team.owner.last_name }}
              </h4>
              <div v-if="team.unread_count" class="rounded-full bg-red-500 text-white font-bold px-1 ml-3 text-xs h-5 w-5 flex items-center justify-center">
                {{ team.unread_count }}
              </div>
            </div>
            <span class="text-pink-500 text-sm">{{ team.owner.title }}</span>
          </div>
        </div>

        <div class="mt-4">
          <modal
            :with-close-button="false"
            class="inline-block rounded-full bg-guestio-gray-100 px-8 py-3"
            @opened="impersonateTeam(team)"
          >
            Manage
            <template #content>
              <ImpersonationModal>
                Please wait...
              </ImpersonationModal>
            </template>
          </modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import { mapActions } from 'vuex'
  import DefaultProfileImage from '@/components/svgs/DefaultProfileImage'
  import Modal from '@/components/shared/Modal'
  import ImpersonationModal from '@/components/auth/ImpersonationModal'

  export default {
    components: {
      DefaultProfileImage,
      Modal,
      ImpersonationModal,
    },

    data() {
      return {
        teams: []
      }
    },

    computed: {
      user() {
        return this.$store.getters["auth/getUser"];
      },
    },

    methods: {
      ...mapActions({
        startImpersonation: 'auth/startImpersonation'
      }),

      mediaIsVideo(url) {
        const videoTypes = ['mp4', 'ogg', 'mov', 'qt']
        const extension = url.split('.').pop();

        return videoTypes.includes(extension);
      },

      fetchTeams() {
        api.get('/teams')
          .then(({ data }) => {
            this.teams = data.data
          })
      },

      impersonateTeam(team) {
        this.startImpersonation(team.id)
          .then(() => {
            setTimeout(() => {
              window.location.reload()
            }, 1000)
          })
          .catch(error => {
            if (error.response) {
              this.$toast.error(error.response.data.message)
              return
            }

            this.$toast.error('Cannot manage this account at this time.')
          })
      }
    },

    mounted() {
      this.fetchTeams()
    }
  }
</script>
