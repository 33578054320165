<template>
  <star-rating
    v-model="ratingNumber"
    :increment="0.5"
    :show-rating="false"
    :star-size="starSize"
    :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]"
    :inactive-color="inactiveColor"
    :active-color="activeColor"
    :padding="padding"
    :read-only="!editable"
    :border-width="borderWidth"
    :border-color="activeColor"
    @rating-selected ="setRating"
  />
</template>

<script>
import StarRating from "vue-star-rating";

export default {
  name: 'ProfileRating',
  components: { StarRating },
  props: {
    rating: Number,
    starSize: { type: Number, required: false, default: 22},
    activeColor: { type: String, required: false, default: '#FFC400'},
    inactiveColor: { type: String, required: false, default: '#F5F5F5'},
    padding: { type: Number, required: false, default: 4},
    borderWidth: { type: Number, required: false, default: 0},
    editable: { type: Boolean, required: false, default: false},
  },

  data() {
    return {
      ratingNumber: 0
    }
  },

  watch: {
    rating(val){
      this.ratingNumber = val;
    }
  },

  mounted() {
    this.ratingNumber = this.rating
  },

  methods: {
    setRating: function(rating){
      this.$emit('ratingSelected', rating)
    }
  },
}
</script>
