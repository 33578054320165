<template>
  <div class="flex flex-col itemCard flex-shrink-0">
    <div
      class="rounded-lg pb-full mb-4 relative overflow-hidden w-full">
      <div class="absolute hover:opacity-70 ease-in transition-opacity inset-0 bg-center bg-no-repeat bg-cover pulse" >
       
      </div>
    </div>
    <div class="grid grid-cols-6 gap-2">
      <div class="leading-5 w-full col-span-5">
        <div class="w-16 h-5 pulse mb-1 rounded-lg" />
        <div class="w-10 h-5 pulse rounded-lg" />
      </div>
        <div  class="w-6 h-6 pulse rounded-lg"/>
    </div>
  </div>
</template>

<script>

  export default {
    name: "ItemSkeleton",
  };
</script>

<style scoped>
@keyframes pulse-bg {
    0% { background-color: #b9babb; }
    50% { background-color: #e3e4e5; }
    100% { background-color: #b9babb; }
  }

  
.slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
.pulse{
   background: 
    linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(rgb(211, 210, 210), rgb(211, 210, 210)),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(rgb(211, 210, 210), rgb(211, 210, 210));  
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
  animation: loading 1.5s infinite;
}
</style>
