<template>
  <button @click="toggle" type="button" class="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" role="switch" aria-checked="false">
    <span class="sr-only">{{ label }}</span>
    <span aria-hidden="true" class="pointer-events-none absolute bg-white w-full h-full rounded-md"></span>
    <!-- Enabled: "bg-indigo-600", Not Enabled: "bg-gray-200" -->
    <span aria-hidden="true" :class="{'bg-violet': enabled, 'bg-gray-300': ! enabled}" class="pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200"></span>
    <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
    <span aria-hidden="true" :class="{'translate-x-5': enabled, 'translate-x-0': ! enabled}" class="pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-300 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200"></span>
  </button>
</template>

<script>
  export default {
    name: "ToggleButton",

    props: {
      label: {
        type: String,
        default: 'Toggle Button'
      },

      enabled: {
        type: Boolean,
        default: false,
      }
    },

    methods: {
      toggle() {
        this.$emit('updated', ! this.enabled)
      }
    }
  }
</script>
