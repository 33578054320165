<template>
  <div v-if="!dontShow">
    <div
      v-if="!showForm"
      @click="showForm = true"
      class="separator text-violet cursor-pointer py-6"
      :class="{'opacity-0 hover:opacity-100 transition duration-150 ease-in-out': showOnHover}"
    >
      Add wishlist group
    </div>
    <transition
      v-else
      appear
      enter-active-class="transition ease-out duration-1000"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class=""
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      mode="out-in"
    >
      <div>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form method="post" @submit.prevent="handleSubmit(createGroup)">
            <div class="grid grid-cols-1 sm:grid-cols-2 xl:col-gap-56 lg:col-gap-40">
              <div class="grid grid-cols-1 row-gap-4">
                <div>
                  <ValidationProvider rules="required|max:255" name="Name" vid="name" v-slot="{ errors }">
                    <TextInput v-model="form.name" :errors="errors" placeholder="New section name" fontSize="text-lg" ref="nameInput"/>
                  </ValidationProvider>
                </div>

                <div class="flex items-center">
                  <button type="submit" class="mr-4 disabled:opacity-50 flex items-center bg-indigo-gradiant rounded-full text-white font-semibold px-8 py-3" :disabled="working">
                    <loading-icon v-if="working" class="h-2" />
                    <span v-else>Add section</span>
                  </button>
                  <button type="button" @click="closeForm" class="text-pink-500 hover:text-pink-700 font-semibold">Cancel</button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </transition>
  </div>
</template>

<script>
import api from '@/api'
import {mapState} from "vuex";
import TextInput from "@/components/shared/TextInput";

export default {
  name: "AddGroup",
  components: {TextInput},

  props: {
    showOnHover: { type: Boolean, default: true },
    dontShow: { type: Boolean, default: false },
    type: { type: String, default: 'guest'}
  },

  data() {
    return {
      showForm: false,
      working: false,
      form: {
        name: ''
      },
    }
  },

  methods: {
    closeForm() {
      this.showForm = false;
    },

    createGroup() {
      this.form.type = this.type;
      this.working = true;
      api.post(`/users/${this.user.id}/smartlist-groups`, this.form)
        .then(() => {
          this.$emit('groupCreated');
          this.closeForm();
        })
        .finally(() => {
            this.form.name = '';
            this.working = false;
        })
    }
 
  },

  mounted() {
    if (this.group) {
      this.showForm = true;
      this.form = this.group
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      group: state => state.dashboard.smartlist.group,
    }),
  },
}
</script>

<style scoped>
  .separator {
    display: flex;
    align-items: center;
    text-align: center;
  }
  .separator::before, .separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid #B503D0;
  }
  .separator::before {
    margin-right: 1em;
  }
  .separator::after {
    margin-left: 1em;
  }
</style>