<template>
  <div>
    <Modal ref="modal" :withCloseButton="false">
      <template #content>
        <div class="content">
          <div class="header bg-indigo-gradiant">
            <div class="flex items-center justify-between gap-4">
              <div class="icon-container"><AttachmentIcon /></div>

              <h3 class="text-xl">
                <span class="font-medium"> Guestio </span>
                <span class="font-light"> Connect </span>
              </h3>
            </div>
            <div class="cursor-pointer" @click="onClose">
              <XIcon class="h-6 w-6 text-white" />
            </div>
          </div>

          <div v-if="!loading && !noMoreCandidates" class="content-body">
            <div v-if="candidate" class="card-container">
              <div
                class="card candidate-card mb-8"
                :style="`background-image: url(${candidate.imageUrl})`"
              >
                <div class="card-body text-white p-10 pt-12">
                  <p class="text-xl font-bold">{{ candidate.name }}</p>
                  <p class="font-light mb-2">{{ candidate.title }}</p>
                  <div class="flex justify-between w-full">
                    <ProfileRating
                      :rating="candidate.avg_rating"
                      activeColor="#B503D0"
                      inactiveColor="#D4D4D4"
                      :starSize="22"
                    />

                    <div class="cursor-pointer" @click="toggleCandidateInfo">
                      <InfoIcon class="h-7 w-7" />
                    </div>
                  </div>
                </div>
              </div>

              <!-- Info -->
              <div v-if="showCandidateInfo" class="info-card card mb-8 pr-4">
                <div class="flex justify-end">
                  <div
                    class="cursor-pointer py-4 pl-4"
                    @click="toggleCandidateInfo"
                  >
                    <XIcon class="h-5 w-5 text-defaultGray" />
                  </div>
                </div>
                <div class="flex-1 overflow-y-auto custom-scrollbar">
                  <div class="card-body">
                    <!-- Booking Options -->
                    <h3 class="heading">Booking Options</h3>

                    <div class="flex gap-4 text-center mb-8">
                      <div
                        v-for="option in candidate.option_prices"
                        :key="option.id"
                        class="booking-option-card"
                        @click="goToBookingOption(option.booking_option.id)"
                      >
                        <img
                          :src="option.booking_option.icon_url"
                          class="icon"
                        />

                        <p>{{ option.booking_option.name }}</p>
                      </div>
                    </div>

                    <h3 class="heading">About {{ candidate.name }}</h3>

                    <p class="text-defaultGray text-xs">
                      <span v-if="candidatesType === 'guest'">{{
                        candidate.bio
                      }}</span>
                      <span v-else>{{ candidate.description }}</span>
                    </p>
                  </div>
                </div>

                <!-- Social Media/Following -->
                <!-- Todo: make a component out of this and reuse it where appropiate -->
                <div class="px-10 my-12">
                  <div v-if="!fetchingProfileReach">
                    <div
                      v-if="profileReach.length"
                      class="flex space-x-8 text-blue-800 text-sm"
                    >
                      <template v-for="(reach, index) in profileReach">
                        <div :key="`reach-${index}`" class="social-badge">
                          <component
                            :is="`${reach.platform}-icon`"
                            :class="{
                              'h-6 pl-3': reach.platform == 'twitter',
                              'h-8 pl-1': reach.platform != 'twitter',
                            }"
                          />
                          <span class="ml-2 nowrap uppercase">
                            {{ reach.from }} - {{ reach.to }}
                          </span>
                        </div>
                      </template>
                    </div>
                  </div>
                  <loading-icon v-else class="h-5 w-5 text-pink-500" />
                </div>
              </div>
            </div>

            <div class="action-buttons flex items-center gap-4">
              <div class="icon-container" @click="rejectCandidate">
                <XIcon class="h-8 w-8 text-red-500" stroke-width="3" />
              </div>
              <div
                v-if="!candidate.is_favourited"
                class="icon-container"
                @click="addToSmartList"
              >
                <HeartIconGradient v-if="!addingToSmartList" class="w-6 h-6" />
                <loading-icon v-else class="h-5 w-5 text-pink-500" />
              </div>
              <div class="icon-container" @click="onCheckIconClick">
                <CheckIcon class="h-8 w-8 text-fluoGreen" stroke-width="3" />
              </div>
            </div>
          </div>

          <div v-else>
            <div v-if="noMoreCandidates" class="text-blue-800 text-lg">
              There are no more {{ candidatesType }}s that match your interests!
            </div>
            <loading-icon v-else class="h-10 w-10 text-pink-500" />
          </div>

          <div class="footer flex items-center gap-8">
            <div><ArrowInBox /> <span>NOPE</span></div>
            <div><ArrowInBox class="up" /> <span>ADD TO SMARTLIST</span></div>
            <div><ArrowInBox class="right" /> <span>BOOK</span></div>
          </div>
        </div>
      </template>
    </Modal>

    <!-- Booking Types Modal -->
    <Modal ref="bookingTypesModal" :withCloseButton="false">
      <template #content>
        <div class="booking-types-modal-content">
          <h2 class="text-blue-800 text-lg font-semibold mb-11">
            How do you want to continue this booking?
          </h2>

          <div class="flex flex-col md:flex-row gap-7 mb-10">
            <div
              class="booking-type-option"
              :class="{ selected: bookingType === 'pitch' }"
              @click="setBookingType('pitch')"
            >
              <img src="@/assets/images/megaphone-icon.png" />
              <h4 class="title">Pitch</h4>
              <p class="description">Pitch your guest for free</p>
            </div>
            <div
              class="booking-type-option"
              :class="{ selected: bookingType === 'regular' }"
              @click="setBookingType('regular')"
            >
              <img src="@/assets/images/mic-vintage-icon.png" />
              <h4 class="title">Regular Booking</h4>
              <p class="description">
                Select a Booking option and pay the regular fee
              </p>
            </div>
          </div>

          <div class="flex flex-col-reverse md:flex-row gap-6">
            <button
              class="booking-type-btn text-violet"
              @click="closeBookingTypesModal()"
            >
              Cancel
            </button>
            <button
              class="booking-type-btn text-white bg-indigo-gradiant"
              @click="goToBookingType()"
            >
              Continue
            </button>
          </div>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Modal from "@/components/shared/Modal";
import AttachmentIcon from "@/components/svgs/AttachmentIcon";
import XIcon from "@/components/svgs/XIcon";
import CheckIcon from "@/components/svgs/CheckIcon";
import ArrowInBox from "@/components/svgs/ArrowInBox";
import InfoIcon from "@/components/svgs/InfoIcon";
import TwitterIcon from "@/components/svgs/ReachIcons/TwitterIcon";
import TiktokIcon from "@/components/svgs/ReachIcons/TiktokIcon";
import YoutubeIcon from "@/components/svgs/ReachIcons/YoutubeIcon";
import InstagramIcon from "@/components/svgs/ReachIcons/InstagramIcon";
import HeartIconGradient from "@/components/svgs/HeartIconGradient";
import ProfileRating from "@/components/shared/ProfileRating";

export default {
  name: "GuestioConnect",
  components: {
    Modal,
    AttachmentIcon,
    XIcon,
    CheckIcon,
    ArrowInBox,
    InfoIcon,
    TwitterIcon,
    TiktokIcon,
    YoutubeIcon,
    InstagramIcon,
    HeartIconGradient,
    ProfileRating
  },
  props: { candidatesType: { type: String, default: "guest" } },

  data() {
    return {
      loading: true,
      candidates: [],
      candidate: null,
      profileReach: [],
      fetchAction: undefined,
      rejectAction: undefined,
      fetchingProfileReach: false,
      currentCandidateIndex: 0,
      noMoreCandidates: false,
      showCandidateInfo: false,
      addingToSmartList: false,
      params: {
        categories: [],
        tags: [],
        page: 1,
        limit: 10
      },
      lookAheadTreshold: 3,
      pagination: {},
      bookingType: "regular"
    };
  },

  computed: {
    ...mapGetters({
      user: "auth/getUser"
    })
  },

  methods: {
    ...mapActions({
      getGuestCandidates: "guestioConnect/getGuestCandidates",
      getShowCandidates: "guestioConnect/getShowCandidates",
      rejectGuestCandidate: "guestioConnect/rejectGuestCandidate",
      rejectShowCandidate: "guestioConnect/rejectShowCandidate"
    }),

    ...mapMutations({
      setPreviousPage: "dashboard/bookings/setPreviousPage",
    }),

    init(type) {
      this.candidates = [];
      this.candidate = null;
      this.currentCandidateIndex = 0;
      this.noMoreCandidates = false;
      this.showCandidateInfo = false;

      this.params.page = 1;

      this.candidatesType = type;

      this.buildCategoriesAndTags();

      this.fetchAction =
        this.candidatesType === "guest"
          ? this.getGuestCandidates
          : this.getShowCandidates;

      this.rejectAction =
        this.candidatesType === "guest"
          ? this.rejectGuestCandidate
          : this.rejectShowCandidate;

      this.fetchData();
      this.$refs.modal.open();
    },

    async fetchData() {
      this.loading = true;
      const { candidates, pagination } = await this.fetchAction(this.params);
      this.candidates = candidates;
      this.pagination = pagination;

      if (!candidates.length) {
        this.noMoreCandidates = true;
        return;
      }

      this.currentCandidateIndex = 0;
      this.candidate = this.candidates[this.currentCandidateIndex];
      this.loading = false;

      this.fetchProfileReach();
    },

    async fetchProfileReach() {
      this.fetchingProfileReach = true;
      await this.$store
        .dispatch("guest/fetchProfileReach", this.candidate.id)
        .then(({ data }) => {
          this.profileReach = data.data;
        });
      this.fetchingProfileReach = false;
    },

    buildCategoriesAndTags() {
      let categories = [];
      let tags = [];

      this.user.shows.forEach(show => {
        categories = categories.concat(
          show.categories.map(category => category.id)
        );
        tags = tags.concat(show.tags.map(tag => tag.name));
      });

      const guest = this.user.guest;
      if (guest) {
        categories = categories.concat(
          guest.categories.map(category => category.id)
        );
        tags = tags.concat(guest.tags.map(tag => tag.name));
      }

      this.params.categories = [...new Set(categories)];
      this.params.tags = [...new Set(tags)];
    },

    rejectCandidate() {
      if (this.addingToSmartList) return;
      this.rejectAction(this.candidate.id);
      this.nextCandidate();
    },

    async addToSmartList() {
      const type = this.candidate.type === "guest" ? "guests" : "shows";

      this.addingToSmartList = true;
      await this.$store.dispatch("dashboard/smartlist/toggleVip", {
        profileId: this.candidate.id,
        type
      });
      this.addingToSmartList = false;

      this.nextCandidate();
    },

    nextCandidate() {
      this.candidate = null;

      if (this.currentCandidateIndex === this.candidates.length - 1) {
        if (this.params.page < this.pagination.total_pages) {
          this.params.page++;
          this.fetchData();
        } else {
          this.noMoreCandidates = true;
        }
        return;
      }

      this.currentCandidateIndex++;
      this.candidate = this.candidates[this.currentCandidateIndex];
    },

    onCheckIconClick() {
      if (this.addingToSmartList) return;

      // both pitch and bookings enabled
      if (
        this.candidate.option_prices.length &&
        this.candidate.pitch_settings.is_enabled
      ) {
        this.$refs.bookingTypesModal.toggle();
        return;
      }

      // either pitch or booking enabled
      this.goToPitchOrBook();
    },

    goToPitchOrBook() {
      var routeName = "";

      if (this.candidate.option_prices.length) {
        // booking
        routeName = this.candidatesType === "guest" ? "GuestBook" : "ShowBook";
      } else {
        // pitch
        this.$store.commit(`${this.candidatesType}Booking/setIsPitch`, true);
        routeName =
          this.candidatesType == "guest"
            ? "GuestBookingPitch"
            : "ShowBookingPitch";
      }

      const params =
        this.candidatesType === "guest"
          ? { guestId: this.candidate.id }
          : { showId: this.candidate.id };

      this.$router.push({
        name: routeName,
        params
      });
    },

    goToBookingOption(bookingOptionId) {
      if (this.addingToSmartList) return;

      const routeName =
        this.candidatesType === "guest"
          ? "GuestBookingOptions"
          : "ShowBookingOptions";

      const params =
        this.candidatesType === "guest"
          ? { guestId: this.candidate.id }
          : { showId: this.candidate.id };

      params.bookingOptionId = bookingOptionId;

      this.$router.push({
        name: routeName,
        params
      });
    },

    toggleCandidateInfo() {
      this.showCandidateInfo = !this.showCandidateInfo;
    },

    onClose() {
      this.setPreviousPage('profile');
      this.$refs.modal.close();
    },

    // Booking Types
    setBookingType(type) {
      this.bookingType = type;
    },

    goToBookingType() {
      this.setPreviousPage('guestio_connect');

      var routeName = "";

      if (this.bookingType === "regular") {
        // regular booking selected
        routeName = this.candidatesType === "guest" ? "GuestBook" : "ShowBook";
      } else {
        // pitch selected
        this.$store.commit(`${this.candidatesType}Booking/setIsPitch`, true);

        routeName =
          this.candidatesType == "guest"
            ? "GuestBookingPitch"
            : "ShowBookingPitch";
      }

      const params =
        this.candidatesType === "guest"
          ? { guestId: this.candidate.id }
          : { showId: this.candidate.id };

      this.$router.push({
        name: routeName,
        params
      });
    },

    closeBookingTypesModal() {
      this.$refs.bookingTypesModal.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.content {
  @apply w-full h-full bg-gray-25 flex flex-col items-center justify-between;
}

.icon-container {
  @apply rounded-full bg-white  flex justify-center items-center;
}

.header {
  @apply w-full flex items-center justify-between text-white px-16 py-8;
  .icon-container {
    @apply p-3 bg-opacity-25;
  }
}

.content-body {
  @apply w-full flex-1 flex flex-col items-center justify-center px-4;
}

.card-container {
  @apply h-full flex items-center justify-center w-full gap-9;
  max-height: 478px;
}

.action-buttons {
  .icon-container {
    @apply p-4 cursor-pointer;
    box-shadow: 15px 15px 42px rgba(96, 115, 165, 0.2);
  }
}

.footer {
  @apply text-xs text-defaultGray pt-8 pb-16;
  div {
    @apply flex items-center gap-2;
  }
  .up {
    transform: rotate(90deg);
  }
  .right {
    transform: rotate(180deg);
  }
}

.card {
  @apply rounded-lg h-full w-full;
  box-shadow: 0px 14px 30px rgba(0, 0, 0, 0.07);
  background-size: cover;
}
.card.candidate-card {
  @apply flex flex-col justify-end bg-lightBlack;
  max-width: 356px;
  .card-body {
    @apply rounded-bl-lg rounded-br-lg;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  }
}

.card.info-card {
  @apply bg-white flex flex-col;
  max-width: 622px;
  .card-body {
    @apply px-10;
  }
  .heading {
    @apply text-xl text-blue-800 font-bold mb-4;
  }
}

.booking-option-card {
  @apply bg-guestio-gray-100 flex flex-col items-center justify-evenly gap-4 rounded-lg pt-5 pb-3 px-4 cursor-pointer;
  // min-height: 104px;
  .icon {
    min-height: 32px;
    min-width: 32px;
  }
  p {
    @apply text-xs text-blue-800 font-semibold;
    max-width: 80px;
  }
}

.social-badge {
  @apply flex items-center justify-center bg-guestio-gray-100 rounded-lg px-4 py-1;
  .nowrap {
    white-space: nowrap;
  }
}

.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #ebebeb;
  border-radius: 10px;
}

// Booking types
.booking-types-modal-content {
  @apply w-2/3 text-center shadow-lg rounded-3xl bg-white px-8 md:px-12 py-6 md:py-14;
  max-width: 600px;

  .booking-type-option {
    @apply w-full md:w-1/2 flex flex-col items-center px-6 py-7 rounded-xl border cursor-pointer border-defaultGray;

    .title {
      @apply text-blue-800 font-semibold text-base mb-6;
    }

    .description {
      @apply text-grayDark text-xs;
    }
  }

  .booking-type-option.selected {
    @apply border-violet;
    background-color: #fef7ff;
  }

  .booking-type-btn {
    @apply rounded-full w-full py-4 text-lg font-bold;
  }
}
</style>