<template>
  <div>
    <div class="flex items-center justify-between">
      <span class="flex flex-col">
        <span class="text-3xl font-bold text-black">
          {{ entity.name }}
        </span>
        <span class="mt-2 rating flex items-end">
          <ProfileRating :rating="review.number" activeColor="#B503D0" inactiveColor="#413F51" :starSize="25"/>
          <span class="ml-2 font-bold text-xs">{{ review.number }}/5 stars</span>
        </span>
      </span>
      <span v-if="withEdit">
        <button @click="$emit('edit')" class="flex items-center text-pink-500">
          <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
          <span class="ml-2 font-light">Edit</span>
        </button>
      </span>
    </div>

    <div class="mt-8">
      <span class="text-gray-500 text-xl">
        <em>"{{ review.comment }}"</em>
      </span>
    </div>

    <div class="mt-4 leading-none">
      <div>
        <span class="font-semibold text-xl italic">{{ review.name }}</span>
      </div>
      <div class="mt-2">
        <span class="text-gray-500 opacity-75">{{ moment(review.created_at).format('MMMM D, YYYY') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import ProfileRating from "@/components/shared/ProfileRating";

  export default {
    props: {
      entity: Object,

      review: Object,

      withEdit: {
        type: Boolean,
        default: true,
      }
    },

    components: {
      ProfileRating,
    },

    methods: {
      moment(date) {
        return moment.utc(date).local()
      }
    }
  }
</script>
