<template>
  <svg
    width="23"
    height="20"
    viewBox="0 0 23 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.8767 1.85626C18.4522 -0.618104 14.5065 -0.618104 12.0815 1.85626C11.9471 1.99346 11.8184 2.13797 11.6955 2.28933C11.5725 2.13749 11.4439 1.99346 11.309 1.85578C8.8845 -0.618592 4.93877 -0.618592 2.51384 1.85578C0.0888987 4.33014 0.0893772 8.35709 2.51384 10.832L11.3568 19.8569C11.4501 19.9522 11.5725 20 11.695 20C11.8175 20 11.9399 19.9522 12.0332 19.8569L20.8762 10.832C23.3016 8.35758 23.3016 4.33063 20.8767 1.85626Z"
      fill="url(#paint0_linear_7207_21960)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_7207_21960"
        x1="2.77639"
        y1="2.62626"
        x2="22.6282"
        y2="4.94204"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#6203D0" />
        <stop offset="1" stop-color="#DC3CF6" />
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
export default {
  name: "HeartIconGradient"
};
</script>
