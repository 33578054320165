import api from '@/api'

const smartlist = {
  namespaced: true,

  state: {
    smartlist: [],
    smartlistGroups: [],
    item: null,
    group: null,
    showForm: false,
    vip: null,
  },

  actions: {
    toggleVip({commit}, payload) {
      return api.put(`/favorites/${payload.type}/${payload.profileId}`).then(({data}) => {
        commit('setVip', data.data)
        return Promise.resolve(data.data)
      })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchSmartlist({commit}, userId) {
      return api.get(`/users/${userId}/smartlist`).then(({data}) => {
        commit('setSmartlist', data.data)
        return Promise.resolve(data.data)
      })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchSmartlistGroups({commit}, userId) {
      return api.get(`/users/${userId}/smartlist-groups?include=items`).then(({data}) => {
        commit('setSmartlistGroups', data.data)
        return Promise.resolve(data.data)
      })
        .catch(error => {
          return Promise.reject(error)
        })
    },
  },

  mutations: {
    setVip(state, vip) {
      state.vip = vip
    },

    setItem(state, item) {
      state.item = item
    },

    setGroup(state, group) {
      state.group = group
    },

    setShowForm(state, data) {
      state.showForm = data
    },

    setSmartlist(state, data) {
      state.smartlist = data
    },

    setSmartlistGroups(state, data) {
      state.smartlistGroups = data
    },
  },

}

export default smartlist;
