<template>
  <button type="button" @click.stop="edit" class="ml-8 flex items-center edit-color">
    <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#B503D0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path></svg>
    <span class="ml-2 font-light edit-color">
      {{ title }}
    </span>
  </button>
</template>

<script>
export default {
  name: 'Edit',

  props: {
    title: {
      type: String,
      default: 'Edit',
    }
  },

  methods: {
    edit() {
      this.$emit('edit')
    }
  },
}
</script>

<style scoped>
  .edit-color {
    color: #B503D0;
  }
</style>