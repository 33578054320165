<template>
  <div class="max-w-4xl w-full grid grid-cols-1 sm:grid-cols-2 sm:gap-16 bg-white rounded-xl overflow-hidden" @click.stop>
    <div class="hidden sm:block relative left-col bg-black px-16 py-16 overflow-hidden">
      <div>
        <h3 class="text-white font-bold text-4xl">
          Book the world’s top guest & shows!
        </h3>

        <div class="mt-12">
          <p class="text-gray-400">Don't have an account?</p>
          <div class="mt-2">
            <button @click.prevent="setRedirectAndContinue" class="text-pink-500 hover:underline">Sign up for free</button>
          </div>
        </div>
      </div>
      <div class="absolute bottom-0 inset-x-0 ml-10">
        <img class="w-full h-auto" src="/images/bg_patern.png" alt="">
      </div>
    </div>

    <div class="right-col px-6 py-8 sm:pl-0 sm:pr-16 sm:py-16">
      <div class="flex sm:block justify-center">
        <svg class="h-10 sm:h-12" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 56"><path fill-rule="evenodd" clip-rule="evenodd" d="M41.1 11.743V0H20.552v.056C10.696.806 2.935 9.037 2.935 19.082c0 10.54 8.543 19.083 19.082 19.083 10.539 0 19.082-8.544 19.082-19.083v-7.339zm-12.864 7.554a6.432 6.432 0 10-12.865 0 6.432 6.432 0 0012.865 0z" fill="url(#paint0_linear)"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.896 41.354zm-.021-.028a4.404 4.404 0 00-6.966 5.39l3.495-2.68C.909 46.716.91 46.717.912 46.72l.002.003.006.008.014.018.033.042.094.116c.074.091.172.208.294.348.245.278.586.648 1.024 1.077a25.186 25.186 0 003.84 3.063c3.388 2.21 8.43 4.385 15.065 4.385s11.678-2.175 15.066-4.385a25.18 25.18 0 003.84-3.063c.438-.43.78-.799 1.023-1.077a13.444 13.444 0 00.389-.464l.033-.042.013-.018.006-.008.003-.003c.001-.002.003-.003-3.492-2.683l3.495 2.68a4.404 4.404 0 00-6.97-5.385 10.703 10.703 0 01-.66.704 16.39 16.39 0 01-2.49 1.982c-2.24 1.46-5.637 2.955-10.256 2.955-4.618 0-8.016-1.495-10.254-2.955a16.387 16.387 0 01-2.492-1.982 10.651 10.651 0 01-.66-.704c0-.002-.002-.003-.003-.005z" fill="#F0F0F0"/><defs><linearGradient id="paint0_linear" x1="36.089" y1="34.555" x2="32.428" y2=".036" gradientUnits="userSpaceOnUse"><stop stop-color="#6203D0"/><stop offset="1" stop-color="#DC3CF6"/></linearGradient></defs></svg>
        </div>
        <h1 class="text-center sm:text-left font-bold text-black mt-4 text-2xl sm:text-3xl">Sign into your account</h1>

        <div class="mt-6 sm:mt-12">
          <ValidationObserver ref="loginForm" v-slot="{ handleSubmit }">
            <form method="post" @submit.prevent="handleSubmit(login)">
              <div>
                <label class="font-medium text-black">Email</label>
                <ValidationProvider rules="required" name="Email" vid="email" v-slot="{ errors }">
                  <div class="mt-2">
                    <input ref="emailInput" type="email" class="form-input text-black sm:text-lg bg-gray-50 w-full px-5 sm:py-3 rounded-full border-transparent focus:bg-white focus:shadow-3xl" v-model="loginForm.email">
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <div class="mt-4 sm:mt-6">
                <label class="font-medium text-black">Password</label>
                <ValidationProvider rules="required" name="Password" vid="password" v-slot="{ errors }">
                  <div class="mt-2">
                    <input type="password" class="form-input text-black sm:text-lg bg-gray-50 w-full px-5 sm:py-3 rounded-full border-transparent focus:bg-white focus:shadow-3xl" v-model="loginForm.password">
                  </div>
                  <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                </ValidationProvider>
              </div>

              <p class="text-red-500 text-sm mt-1" v-if="error">{{ error }}</p>

              <div class="mt-4 sm:mt-6 flex justify-between items-center">
                <label class="flex items-center text-xs sm:text-sm">
                  <input type="checkbox" class="form-checkbox h-4 w-4 sm:h-5 sm:w-5 text-pink-500" v-model="loginForm.remember_me">
                  <span class="ml-2">Remember me</span>
                </label>

                <div>
                  <router-link class="text-pink-500 hover:underline text-xs sm:text-sm" to="/">Forgot your password?</router-link>
                </div>
              </div>

              <div class="mt-8 sm:mt-12">
                <button type="submit" class="btn w-full sm:w-auto" :disabled="loading" :class="{'opacity-50': loading}">
                  <span v-if="loading">
                    <loading-icon class="h-5 w-5 mr-4"/>
                  </span>
                  <span v-else>Sign In</span>
                </button>
              </div>
            </form>
          </ValidationObserver>
        </div>

        <div class="sm:hidden mt-6">
          <div class="relative">
            <div class="absolute inset-0 flex items-center">
              <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center text-sm leading-5">
              <span class="px-2 bg-white text-gray-500">
                Don't have an account?
              </span>
            </div>
          </div>
          <div class="mt-4 text-center sm:text-left">
            <button class="text-sm sm:text-base text-pink-500 hover:underline">Sign up for free</button>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
    export default {
      name: 'LoginModal',

      props: {
        redirect: {
          type: String,
          default: '',
        },

        info: {
          type: Object,
          required: false
        }
      },

      components: {
        //
      },

      data() {
        return {
          loading: false,
          loginForm: {
            email: null,
            password: null,
            remember_me: false,
            device: 'spa',
          },
          error: ''
        }
      },

      methods: {
        login() {
          this.loading = true

          this.$store.dispatch('auth/login', this.loginForm).then(() => {
            window.Event.$emit('closeModal')
            if (this.redirect.length) {
              this.$router.push(this.redirect)
              return
            }
            this.loading = false
            this.loginForm.password = null
            window.Event.$emit('loggedIn', this.info)
          }).catch(errors => {
            this.error = errors.response.data.message
            this.loginForm.password = null
            this.loading = false
            if (errors.response) {
              this.$refs.loginForm.setErrors(errors.response.data.errors)
            }
          })
        },

        setRedirectAndContinue() {
          localStorage.setItem('redirect_path', this.redirect)
          this.$router.push({name: 'register-personal-information'})
        }
      },

      mounted() {
        window.Event.$on('openModal', () => {
          this.$nextTick(() => {
            this.$refs.emailInput.focus()
          })
        })
      },

      destroyed() {
        window.Event.$off('openModal')
      }
    }
</script>
