module.exports = {
  data() {
    return {
      timezones: [
      { offset: -12, label: "(UTC-12:00) International Date Line West", timezone: "Etc/GMT+12" },
      { offset: -11, label: "(UTC-11:00) Coordinated Universal Time-11", timezone: "Etc/GMT+11" },
      { offset: -10, label: "(UTC-10:00) Hawaii", timezone: "Etc/GMT+10" },
      { offset: -8, label: "(UTC-08:00) Alaska", timezone: "America/Anchorage" },
      // { offset: -7, label: "(UTC-07:00) Pacific Daylight Time (US & Canada)", timezone: "America/Los_Angeles" },
      { offset: -8, label: "(UTC-08:00) Pacific Standard Time (US & Canada)", timezone: "US/Pacific" },
      { offset: -7, label: "(UTC-07:00) Los Angeles", timezone: "America/Los_Angeles" },
      { offset: -7, label: "(UTC-07:00) Arizona", timezone: "America/Creston" },
      // { offset: -6, label: "(UTC-07:00) Chihuahua, La Paz, Mazatlan", timezone: "America/Chihuahua" },
      { offset: -7, label: "(UTC-07:00) Mountain Time (US & Canada)", timezone: "US/Mountain" },
      { offset: -6, label: "(UTC-06:00) Central America", timezone: "America/Belize" },
      { offset: -6, label: "(UTC-06:00) Central Time (US & Canada)", timezone: "US/Central" },
      { offset: -6, label: "(UTC-06:00) Guadalajara, Mexico City, Monterrey", timezone: "America/Bahia_Banderas" },
      // { offset: -6, label: "(UTC-06:00) Saskatchewan", timezone: "America/Regina" },
      { offset: -5, label: "(UTC-05:00) Bogota, Lima, Quito", timezone: "America/Bogota" },
      { offset: -5, label: "(UTC-05:00) Eastern Time (US & Canada)", timezone: "US/Eastern" },
      // { offset: -4, label: "(UTC-04:00) Eastern Daylight Time (US & Canada)", timezone: "America/Detroit" },
      { offset: -5, label: "(UTC-05:00) Indiana (East)", timezone: "America/Indiana/Marengo" },
      { offset: -4.5, label: "(UTC-04:30) Caracas", timezone: "America/Caracas" },
      { offset: -4, label: "(UTC-04:00) Asuncion", timezone: "America/Asuncion" },
      // { offset: -3, label: "(UTC-04:00) Atlantic Time (Canada)", timezone: "America/Glace_Bay" },
      { offset: -4, label: "(UTC-04:00) Cuiaba", timezone: "America/Campo_Grande" },
      { offset: -4, label: "(UTC-04:00) Georgetown, La Paz, Manaus, San Juan", timezone: "America/Anguilla" },
      { offset: -4, label: "(UTC-04:00) Santiago", timezone: "America/Santiago" },
      // { offset: -2.5, label: "(UTC-03:30) Newfoundland", timezone: "America/St_Johns" },
      { offset: -3, label: "(UTC-03:00) Brasilia", timezone: "America/Sao_Paulo" },
      { offset: -3, label: "(UTC-03:00) Buenos Aires", timezone: "America/Buenos_Aires" },
      { offset: -3, label: "(UTC-03:00) Cayenne, Fortaleza", timezone: "America/Araguaina" },
      { offset: -3, label: "(UTC-03:00) Greenland", timezone: "America/Godthab" },
      { offset: -3, label: "(UTC-03:00) Montevideo", timezone: "America/Montevideo" },
      { offset: -3, label: "(UTC-03:00) Salvador", timezone: "America/Bahia" },
      { offset: -2, label: "(UTC-02:00) Coordinated Universal Time-02", timezone: "America/Noronha" },
      { offset: -1, label: "(UTC-01:00) Cape Verde Is.", timezone: "Atlantic/Cape_Verde" },
      // { offset: 1, label: "(UTC) Casablanca", timezone: "Africa/Casablanca" },
      { offset: 0, label: "(UTC) Coordinated Universal Time", timezone: "America/Danmarkshavn" },
      { offset: 0, label: "(UTC) Edinburgh, London", timezone: "Europe/London" },
      { offset: 0, label: "(UTC) Dublin, Lisbon", timezone: "Europe/Lisbon" },
      { offset: 0, label: "(UTC) Monrovia, Reykjavik", timezone: "Atlantic/Reykjavik" },
      { offset: 1, label: "(UTC+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna", timezone: "Europe/Amsterdam" },
      { offset: 1, label: "(UTC+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague", timezone: "Europe/Belgrade" },
      { offset: 1, label: "(UTC+01:00) Brussels, Copenhagen, Madrid, Paris", timezone: "Europe/Paris" },
      { offset: 1, label: "(UTC+01:00) West Central Africa", timezone: "Africa/Lagos" },
      { offset: 1, label: "(UTC+01:00) Windhoek", timezone: "Africa/Windhoek" },
      { offset: 2, label: "(UTC+02:00) Sarajevo, Skopje, Warsaw, Zagreb", timezone: "Europe/Warsaw" },
      { offset: 2, label: "(UTC+02:00) Cairo", timezone: "Africa/Cairo" },
      // { offset: 3, label: "(UTC+02:00) E. Europe", timezone: "Europe/Kiev"       },
      { offset: 2, label: "(UTC+02:00) Harare, Pretoria", timezone: "Africa/Johannesburg" },
      { offset: 2, label: "(UTC+02:00) Tripoli", timezone: "Africa/Tripoli" },
      { offset: 2, label: "(UTC+02:00) Kaliningrad", timezone: "Europe/Kaliningrad" },
      { offset: 3, label: "(UTC+03:00) Beirut", timezone: "Asia/Beirut" },
      { offset: 3, label: "(UTC+03:00) Damascus", timezone: "Asia/Damascus" },
      { offset: 3, label: "(UTC+03:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius", timezone: "Europe/Helsinki" },
      { offset: 3, label: "(UTC+03:00) Athens, Bucharest", timezone: "Asia/Athens" },
      { offset: 3, label: "(UTC+03:00) Istanbul", timezone: "Europe/Istanbul" },
      { offset: 3, label: "(UTC+03:00) Jerusalem", timezone: "Asia/Jerusalem" },
      { offset: 3, label: "(UTC+03:00) Amman", timezone: "Asia/Amman" },
      { offset: 3, label: "(UTC+03:00) Baghdad", timezone: "Asia/Baghdad" },
      { offset: 3, label: "(UTC+03:00) Kuwait, Riyadh", timezone: "Asia/Kuwait" },
      { offset: 3, label: "(UTC+03:00) Nairobi", timezone: "Africa/Nairobi" },
      { offset: 3, label: "(UTC+03:00) Moscow, St. Petersburg, Volgograd, Minsk", timezone: "Europe/Moscow" },
      { offset: 4, label: "(UTC+04:00) Samara, Ulyanovsk, Saratov", timezone: "Europe/Samara" },
      { offset: 4.5, label: "(UTC+03:30) Tehran", timezone: "Asia/Tehran" },
      { offset: 4, label: "(UTC+04:00) Abu Dhabi, Muscat", timezone: "Asia/Dubai" },
      { offset: 5, label: "(UTC+04:00) Baku", timezone: "Asia/Baku" },
      { offset: 4, label: "(UTC+04:00) Port Louis", timezone: "Indian/Mahe" },
      { offset: 4, label: "(UTC+04:00) Tbilisi", timezone: "Asia/Tbilisi" },
      { offset: 4, label: "(UTC+04:00) Yerevan", timezone: "Asia/Yerevan" },
      { offset: 4.5, label: "(UTC+04:30) Kabul", timezone: "Asia/Kabul" },
      { offset: 5, label: "(UTC+05:00) Ashgabat, Tashkent", timezone: "Asia/Ashgabat" },
      { offset: 5, label: "(UTC+05:00) Yekaterinburg", timezone: "Asia/Yekaterinburg" },
      { offset: 5, label: "(UTC+05:00) Islamabad, Karachi", timezone: "Asia/Karachi" },
      { offset: 5.5, label: "(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi", timezone: "Asia/Kolkata" },
      { offset: 5.5, label: "(UTC+05:30) Sri Jayawardenepura", timezone: "Asia/Colombo" },
      { offset: 5.75, label: "(UTC+05:45) Kathmandu", timezone: "Asia/Kathmandu" },
      { offset: 6, label: "(UTC+06:00) Nur-Sultan (Astana)", timezone: "Antarctica/Vostok" },
      { offset: 6, label: "(UTC+06:00) Dhaka", timezone: "Asia/Dhaka" },
      { offset: 6.5, label: "(UTC+06:30) Yangon (Rangoon)", timezone: "Asia/Rangoon" },
      { offset: 7, label: "(UTC+07:00) Bangkok, Hanoi, Jakarta", timezone: "Asia/Bangkok" },
      { offset: 7, label: "(UTC+07:00) Novosibirsk", timezone: "Asia/Novosibirsk" },
      { offset: 8, label: "(UTC+08:00) Beijing, Chongqing, Hong Kong, Urumqi", timezone: "Asia/Hong_Kong" },
      { offset: 8, label: "(UTC+08:00) Krasnoyarsk", timezone: "Asia/Krasnoyarsk" },
      { offset: 8, label: "(UTC+08:00) Kuala Lumpur, Singapore", timezone: "Asia/Kuala_Lumpur" },
      { offset: 8, label: "(UTC+08:00) Perth", timezone: "Australia/Perth" },
      { offset: 8, label: "(UTC+08:00) Taipei", timezone: "Asia/Taipei" },
      { offset: 8, label: "(UTC+08:00) Ulaanbaatar", timezone: "Asia/Ulaanbaatar" },
      { offset: 8, label: "(UTC+08:00) Irkutsk", timezone: "Asia/Irkutsk" },
      { offset: 9, label: "(UTC+09:00) Osaka, Sapporo, Tokyo", timezone: "Asia/Tokyo" },
      { offset: 9, label: "(UTC+09:00) Seoul", timezone: "Asia/Seoul" },
      { offset: 9.5, label: "(UTC+09:30) Adelaide", timezone: "Australia/Adelaide" },
      { offset: 9.5, label: "(UTC+09:30) Darwin", timezone: "Australia/Darwin" },
      { offset: 10, label: "(UTC+10:00) Brisbane", timezone: "Australia/Brisbane" },
      { offset: 10, label: "(UTC+10:00) Canberra, Melbourne, Sydney", timezone: "Australia/Sydney" },
      { offset: 10, label: "(UTC+10:00) Guam, Port Moresby", timezone: "Pacific/Guam" },
      { offset: 10, label: "(UTC+10:00) Hobart", timezone: "Australia/Hobart" },
      { offset: 9, label: "(UTC+09:00) Yakutsk", timezone: "Asia/Yakutsk" },
      { offset: 11, label: "(UTC+11:00) Solomon Is., New Caledonia", timezone: "Antarctica/Macquarie" },
      { offset: 11, label: "(UTC+11:00) Vladivostok", timezone: "Asia/Sakhalin" },
      { offset: 12, label: "(UTC+12:00) Auckland, Wellington", timezone: "Pacific/Auckland" },
      { offset: 12, label: "(UTC+12:00) Coordinated Universal Time+12", timezone: "Etc/GMT-12" },
      { offset: 12, label: "(UTC+12:00) Fiji", timezone: "Pacific/Fiji" },
      { offset: 12, label: "(UTC+12:00) Magadan", timezone: "Asia/Magadan" },
      { offset: 13, label: "(UTC+13:00) Nuku'alofa", timezone: "Etc/GMT-13" },
      { offset: 13, label: "(UTC+13:00) Samoa", timezone: "Pacific/Apia" }
      ]
    }
  },

  computed: {
    userTimezone() {
      let offset = new Date().getTimezoneOffset();
      return offset < 0 ? `GMT+${(offset/-60)}` : `GMT-${(offset/60)}`;
    }
  }
}
