<template>
  <div id="dashboard-activity-card" class="dashboard-card border border-gray-50 mb-10 overflow-y-scroll" style="max-height: 700px">
    <!--Dropdown-->
    <div class="relative inline-block text-left">
      <div class="flex items-center">
        <span class="block text-gray-500 text-sm lg:text-base">Sort results by:</span>
        <span class="relative">
            <button type="button"
              @click.prevent="dropdownIsOpen = ! dropdownIsOpen"
                    class="inline-flex justify-center w-full rounded-md px-4 py-2 bg-white leading-5 font-medium text-gray-700 hover:text-gray-500 text-sm lg:text-base"
                    id="options-menu" aria-haspopup="true" aria-expanded="true">
                <span v-if="sorting == 'desc'">Newest Activity</span>
                <span v-if="sorting == 'asc'">Oldest Activity</span>
              <svg class="-mr-1 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"/>
              </svg>
            </button>
            <transition
              enter-active-class="transition ease-out duration-100 transform"
              enter-class="opacity-0 scale-95"
              enter-to-class="opacity-100 scale-100"
              leave-active-class="transition ease-in duration-75 transform"
              leave-class="opacity-100 scale-100"
              leave-to-class="opacity-0 scale-95"
            >
              <div v-if="dropdownIsOpen" v-on-clickaway="closeDropdown" class="z-10 origin-top-right absolute left-0 ml-4 -mr-32 w-48 rounded-md shadow-lg">
                <div class="rounded-md bg-white shadow-xs">
                  <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                    <button
                      @click.prevent="sorting = 'desc'; dropdownIsOpen = false"
                       class="relative w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                       role="menuitem">
                      <span class="block truncate">
                        Newest Activity
                      </span>
                      <span v-if="sorting == 'desc'" class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </button>
                    <button
                      @click.prevent="sorting = 'asc'; dropdownIsOpen = false"
                       class="relative w-full text-left px-4 py-2 text-sm leading-5 text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                       role="menuitem">
                      <span class="block truncate">
                        Oldest Activity
                      </span>
                      <span v-if="sorting == 'asc'" class="text-indigo-600 absolute inset-y-0 right-0 flex items-center pr-4">
                        <svg class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                          <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                        </svg>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </transition>
         </span>
      </div>

    </div>
    <template v-if="loading">
      <div class="h-40 flex items-center justify-center">
        <loading-icon class="w-5 h-5 text-pink-500" />
      </div>
    </template>
    <template v-else>
      <!--    Time line-->
      <div class="relative flex-1">
        <timeline>
          <timeline-item
            v-for="activity in filteredActivities"
            :key="`timeline-${activity.id}`"
            :bg-color="timelineColor(activity)"
            class="font-poppins"
          >
            <template slot="others">
              <component
                :is="timelineIcon(activity)"
                class="flex flex-col items-center justify-center m-auto"
                :color="iconColor(activity)"
                :class="[
                  {'w-4 h-4': activity.properties.type == 'message'},
                  {'w-5 h-5': ! ['message'].includes(activity.properties.type)}
                ]"/>
            </template>
            <div v-if="activity.properties.type == 'message'" :key="`activity-${activity.id}`">
              <Message 
                v-if="findMessage(activity.properties.message_id)"
                :user-timezone="userTimezone"
                :message="findMessage(activity.properties.message_id)"
                :own="activity.properties.sender === user.id"
                :key="`activity-${activity.id}`"
              />
              <UpdateDateFromExternalLink
                v-if="(proposedBy === 'invitee' && userIsInvitee) || (proposedBy === 'initiator' && userIsInitiator) || bookingHasProposedDates"
                :booking="booking"
                :message="findMessage(activity.properties.message_id)"
                :own="activity.properties.sender === user.id"
              />
            </div>
            <div v-else-if="activity.properties.type == 'message_intent'" :key="`activity-${activity.id}`">
              <MessageDateIntent v-if="activity.properties.intent == 'datetime'" :activity="activity" @action-taken="fetchActivities" />
              <MessageUrlIntent v-else-if="activity.properties.intent == 'calendar-url'" :activity="activity"  @action-taken="fetchActivities"/>
            </div>
            <div v-else-if="activity.properties.type == 'action'" :key="`activity-${activity.id}`">
              <span>{{ activity.description }}</span>
              <template v-if="booking.guest.user_id == user.id">
                <modal class="flex mt-3">
                  <label for="interview-date" class="bg-indigo-gradiant rounded-full text-white text-sm font-bold px-5 py-2">
                    {{booking.proposed_dates.length ? 'Change' : 'Choose'}} dates
                  </label>
                  <template v-slot:content="{ close }">
                    <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden self-start my-4">
                      <ScheduleBooking @close="close"/>
                    </div>
                  </template>
                </modal>
              </template>
              <template v-else>
                <ConfirmBooking/>
              </template>
            </div>
            <div v-else-if="activity.properties.type == 'confirm-scheduled-booking'">
              <BookingWithoutInterviewDate :activity="activity" :booking="booking" :user="user" />
            </div>
            <div v-else-if="activity.properties.type == 'booking-question-answer'" :key="`activity-${activity.id}`">
              <InfoBookingQuestionAnswer :activity="activity" />
            </div>
            <div v-else-if="activity.properties.type == 'proposed_dates'" :key="`activity-${activity.id}`">
              <ActionProposedDates :activity="activity" />
            </div>
            <div v-else-if="activity.properties.type == 'order-started'" :key="`activity-${activity.id}`">
              <OrderStarted :user-timezone="userTimezone" :activity="activity" />
            </div>
            <div v-else-if="activity.properties.type == 'action-accept-reject'" :key="`activity-${activity.id}`">
              <ActionAcceptRejectBooking :user-timezone="userTimezone" :activity="activity" />
            </div>
            <div v-else-if="activity.properties.type == 'action-extra-payment'" :key="`activity-${activity.id}`">
              <ActionExtraPayment :activity="activity" />
            </div>
            <div v-else-if="activity.properties.type == 'action-pick-dates'" :key="`activity-${activity.id}`">
              <ActionPickDates :activity="activity" />
            </div>
            <div v-else-if="activity.properties.type == 'pitch-fee-changed'" :key="`activity-${activity.id}`">
              <PitchFeeChanged :activity="activity" />
            </div>
            <div v-else-if="activity.properties.type == 'open-miniview'" :key="`activity-${activity.id}`">
              <OpenMiniview :activity="activity" />
            </div>
            <div v-else-if="activity.properties.type == 'video_pitch'" :key="`activity-${activity.id}`">
              <VideoPitch
                :user-timezone="userTimezone"
                :activity="activity"
                :user="user"
                :booking="booking"
              />
            </div>
            <div v-else-if="activity.properties.type == 'audio_pitch'" :key="`activity-${activity.id}`">
              <AudioPitch
                :user-timezone="userTimezone"
                :activity="activity"
                :user="user"
                :booking="booking"
              />
            </div>
            <div v-else-if="activity.properties.type == 'text_pitch'" :key="`activity-${activity.id}`">
              <TextPitch
                :user-timezone="userTimezone"
                :activity="activity"
                :user="user"
                :booking="booking"
              />
            </div>
            <div v-else-if="activity.properties.confirmed" :key="`activity-${activity.id}`">
              {{ activity.description }} <span v-if="activity.properties.date"> for  <b>{{`${moment(activity.properties.date).format('MMMM D, h:mm A')}${userTimezone ? ` (${userTimezone})` : ''}`}}</b></span>
            </div>
            <div v-else-if="activity.properties.updated" :key="`activity-${activity.id}`">
              <span class="capitalize">{{ activity.properties.username }}</span> updated the interview date to  <b>{{ `${moment(activity.properties.date).format('MMMM D, h:mm A')}${userTimezone ? ` (${userTimezone})` : ''}` }}</b>
            </div>
            <div class="flex flex-col" v-else :key="`activity-${activity.id}`">
             <span>{{ activity.description }}</span> <span class="text-gray-400 italic">{{ `${moment(activity.created_at).format('MMM D, h:mm A')}${userTimezone ? ` (${userTimezone})` : ''}` }}</span>
              <div v-if="activity.properties.type == 'info'" class="flex mt-3 items-center">
                <router-link :to="redirectTo" class="bg-indigo-gradiant rounded-full text-white text-sm font-bold px-5 py-2 mr-3">
                  View profile
                </router-link>
                <RejectBooking/>
              </div>
              <RescheduleInsideGuestio
                v-if="activity.properties.scheduled_outside && booking.status != 'completed'"
                :bookingId="booking.id"
              />
            </div>
            <template v-if="activity.properties.details && activity.properties.details ">
              <div class="bg-gray-50 mt-4 rounded-xl p-6">{{ activity.properties.details }}</div>
            </template>
          </timeline-item>
        </timeline>
      </div>

      <div v-if="canSendMessage" class="flex-shrink-0 lg:relative lg:p-0">
        <transition name="simple-fade" appear mode="out-in">
          <template v-if="createVideoPitch">
            <CreateVideoPitch @close="createVideoPitch = false" key="video-pitch" />
          </template>

          <template v-else-if="createAudioPitch">
            <CreateAudioPitch @close="createAudioPitch = false" key="audio-pitch" />
          </template>

          <template v-else>
            <!--Send-->
            <SendInput @sent="silentFetchActivitiesAndMessages" key="message-input" />
          </template>
        </transition>
      </div>
    </template>
  </div>
</template>

<script>
  import {mapGetters, mapState} from "vuex";
  import moment from 'moment'
  import { mixin as clickaway } from 'vue-clickaway'
  import {Timeline, TimelineItem} from 'vue-cute-timeline'
  import FlagIcon from "@/components/svgs/FlagIcon";
  import DollarSignIcon from "@/components/svgs/DollarSignIcon";
  import ChatIcon from "@/components/svgs/ChatIcon";
  import ClockIcon from "@/components/svgs/ClockIcon";
  import MicIcon from "@/components/svgs/MicIcon";
  import VideoIcon from "@/components/svgs/VideoIcon";
  import CalendarCheckIcon from "@/components/svgs/CalendarCheckIcon";
  import CalendarDateIcon from "@/components/svgs/CalendarDateIcon";
  import CircleAlertIcon from "@/components/svgs/CircleAlertIcon";
  import CheckAltIcon from "@/components/svgs/CheckAltIcon";
  import Message from "@/components/bookings/individual/activity/Message";
  import UpdateDateFromExternalLink from "@/components/bookings/individual/activity/UpdateDateFromExternalLink";
  import ActionAcceptRejectBooking from "@/components/bookings/individual/activity/ActionAcceptRejectBooking";
  import ActionExtraPayment from "@/components/bookings/individual/activity/ActionExtraPayment";
  import ActionProposedDates from "@/components/bookings/individual/activity/ActionProposedDates";
  import ActionPickDates from "@/components/bookings/individual/activity/ActionPickDates";
  import OpenMiniview from "@/components/bookings/individual/activity/OpenMiniview";
  import CreateVideoPitch from "@/components/bookings/individual/activity/CreateVideoPitch";
  import CreateAudioPitch from "@/components/bookings/individual/activity/CreateAudioPitch";
  import SendInput from "@/components/bookings/individual/activity/SendInput";
  import ScheduleBooking from '@/components/bookings/individual/ScheduleBooking'
  import ConfirmBooking from '@/components/bookings/individual/activity/ConfirmBooking'
  import VideoPitch from '@/components/bookings/individual/activity/VideoPitch'
  import AudioPitch from '@/components/bookings/individual/activity/AudioPitch'
  import TextPitch from '@/components/bookings/individual/activity/TextPitch'
  import RejectBooking from '@/components/bookings/individual/activity/RejectBooking'
  import Modal from '@/components/shared/Modal'
  import MessageDateIntent from '@/components/bookings/individual/activity/MessageDateIntent'
  import MessageUrlIntent from '@/components/bookings/individual/activity/MessageUrlIntent'
  import InfoBookingQuestionAnswer from '@/components/bookings/individual/activity/InfoBookingQuestionAnswer'
  import BookingWithoutInterviewDate from '@/components/bookings/individual/activity/BookingWithoutInterviewDate'
  import OrderStarted from '@/components/bookings/individual/activity/OrderStarted'
  import PitchFeeChanged from "@/components/bookings/individual/activity/PitchFeeChanged";
  import RescheduleInsideGuestio from "@/components/bookings/individual/activity/RescheduleInsideGuestio";

  import timezones from '@/mixins/timezones';

  export default {
    name: "ActivityCard",

    mixins: [clickaway, timezones],

    data() {
      return {
        loading: true,
        activityError: false,
        sorting: 'asc',
        dropdownIsOpen: false,
        chosenDate: null,
        interval: null,
        createVideoPitch: false,
        createAudioPitch: false,
      }
    },

    components: {
      Timeline,
      TimelineItem,
      FlagIcon,
      DollarSignIcon,
      ChatIcon,
      Message,
      UpdateDateFromExternalLink,
      CalendarCheckIcon,
      ClockIcon,
      CalendarDateIcon,
      CircleAlertIcon,
      ScheduleBooking,
      ConfirmBooking,
      SendInput,
      Modal,
      CreateVideoPitch,
      CreateAudioPitch,
      MicIcon,
      VideoIcon,
      VideoPitch,
      AudioPitch,
      TextPitch,
      RejectBooking,
      CheckAltIcon,
      ActionExtraPayment,
      ActionAcceptRejectBooking,
      ActionProposedDates,
      ActionPickDates,
      OpenMiniview,
      MessageDateIntent,
      MessageUrlIntent,
      InfoBookingQuestionAnswer,
      OrderStarted,
      PitchFeeChanged,
      BookingWithoutInterviewDate,
      RescheduleInsideGuestio,
    },

    watch: {
      sorting() {
        this.fetchActivities()
      }
    },

    computed: {
      ...mapGetters({
        userIsInvitee: 'dashboard/bookings/userIsInvitee',
      }),
    
      ...mapState({
        user: state => state.auth.user,
        messages: state => state.dashboard.bookings.bookingMessages,
        activities: state => state.dashboard.bookings.bookingActivities,
        booking: state => state.dashboard.bookings.booking,
      }),

      userOwnsBookingGuest() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingGuest']
      },

      filteredActivities() {
        let activities = this.activities.filter(activity => activity.properties.type !== 'booking-question-answer');

        // remove all actions if the booking has a date, is canceled or expired
        if (this.booking.confirmed_date || ['canceled', 'expired'].includes(this.booking.status)) {
          activities = activities.filter(activity => !activity.properties.type.startsWith('action'))
        }

        // remove the initial accepte/reject action
        // if (this.booking.accepted_at) {
        //   activities = activities.filter(activity => activity.properties.type != 'action-accept-reject')
        // }

        // remove date proposer
        if (this.booking.confirmed_date) {
          activities = activities.filter(activity => activity.properties.type != 'action-pick-dates')
        }

        // If there are many activities of type confirm-scheduled-booking, show only the last one
        // and only show it if there hasn't been answered yet
        let confirmScheduledBookingactivities = activities.filter(activity => activity.properties.type == 'confirm-scheduled-booking');
        if (confirmScheduledBookingactivities.length > 0) {
          let answered = confirmScheduledBookingactivities.filter(activity => activity.properties.scheduled != undefined);
          const answeredIds = answered.map(({id}) => id);
          activities = activities.filter(activity => !answeredIds.includes(activity.id));
          let notAnswered = confirmScheduledBookingactivities.filter(activity => activity.properties.scheduled == undefined);
          const notAnsweredIds = notAnswered.map(({id}) => id);
          if (notAnsweredIds.length > 1) {
            delete notAnsweredIds[notAnsweredIds.length - 1];
            activities = activities.filter(activity => !notAnsweredIds.includes(activity.id));
          }
        }
        return activities
      },

      unreadMessages() {
        return this.messages.filter(message => {
          return !message.read_at && message.sender.user_id != this.user.id
        })
      },

      canSendMessage() {
        return !this.booking.is_canceled && !this.booking.is_expired
      },

      redirectTo() {
        if (this.userOwnsBookingGuest) {
          return { name: 'Show', params: {showId: this.booking.show.id}}
        } else {
          return { name: 'Guest', params: {guestId: this.booking.guest.id}}
        }
      },

      proposedBy() {
        const pickDatesActivity = this.activities.find(activity => activity.properties.type == 'action-pick-dates')
        if (!pickDatesActivity) {
          return '';
        }
        return pickDatesActivity.properties?.proposed_by || 'invitee';
      },

      userIsInvitee() {
        return this.$store.getters['dashboard/bookings/userIsInvitee']
      },
      userIsInitiator() {
        return this.$store.getters['dashboard/bookings/userIsInitiator']
      },
      bookingHasProposedDates() {
        return this.$store.getters['dashboard/bookings/bookingHasProposedDates']
      },
    },

    methods: {
      moment(date) {
        return moment.utc(date).local()
      },

      timelineColor(activity) {
        if (! activity.properties.type) {
          return ''
        }

        switch(activity.properties.type) {
          case 'message':
            return '#ECF6FE'
          case 'success':
          case 'pitch-fee-changed':
            return '#D5F7E3'
          case 'action-extra-payment':
            if (this.userIsInvitee) {
              return '#F0CDF6'
            }
            return '#D5F7E3'
          case 'warning':
            return '#FFF9E6'
          case 'info':
          case 'order-started':
          case 'booking-question-answer':
            return '#EFE6FA'
          case 'proposed_dates':
          case 'action':
          case 'action-accept-reject':
          case 'video_pitch':
          case 'audio_pitch':
            return '#F0CDF6'
          default: // error - red
            return '#FDEEEE'
        }
      },

      timelineIcon(activity) {
        if (! activity.properties.type) {
          return ''
        }

        switch(activity.properties.type) {
          case 'message':
            return 'ChatIcon'
          case 'action-extra-payment':
            if (this.userIsInvitee) {
              return 'CheckAltIcon'
            }
            return 'CalendarCheckIcon'
          case 'success':
          case 'pitch-fee-changed':
            if (activity.properties.payment) {
              return 'DollarSignIcon'
            }
            return 'CalendarCheckIcon'
          case 'warning':
            return 'CircleAlertIcon'
          case 'proposed_dates':
          case 'action':
          case 'message_intent':
            return 'CalendarDateIcon'
          case 'action-accept-reject':
          case 'booking-question-answer':
            return 'CheckAltIcon'
          case 'info':
          case 'order-started':
            return 'ClockIcon'
          case 'video_pitch':
            return 'VideoIcon'
          case 'audio_pitch':
            return 'MicIcon'
          default: // error - red
            return 'FlagIcon'
        }
      },

      iconColor(activity) {
        if (! activity.properties.type) {
          return ''
        }

        switch(activity.properties.type) {
          case 'message':
            return '#3FA2F7'
          case 'success':
          case 'action-extra-payment':
          case 'pitch-fee-changed':
            return '#1FD76C'
          case 'warning':
            return '#FFC400'
          case 'info':
          case 'order-started':
          case 'booking-question-answer':
            return '#6304D1'
          case 'proposed_dates':
          case 'action':
          case 'video_pitch':
          case 'audio_pitch':
          case 'message_intent':
            return '#B503D0'
          default: // error - red
            return '#EB5757'
        }
      },

      closeDropdown() {
        this.dropdownIsOpen = false
      },

      fetchData() {
        this.fetchActivities();
        this.fetchMessages();
      },

      fetchActivities() {
        this.loading = true
        this.activityError = false

        this.$store.dispatch('dashboard/bookings/fetchBookingActivities', {
          id: this.$route.params.bookingId,
          sort: this.sorting,
        })
        .catch(() => this.activityError = true)
        .finally(() => {
          this.loading = false
          setTimeout(() => {
            const cardViewport = document.getElementById('dashboard-activity-card');
            cardViewport.scroll({ top: cardViewport.scrollHeight, behavior: 'smooth' });
          }, 500)
        })
      },

      silentFetchActivitiesAndMessages() {
        this.$store.dispatch('dashboard/bookings/fetchBookingActivities', {
          id: this.$route.params.bookingId,
          sort: this.sorting,
        }).then(() => {
          this.$store.dispatch('dashboard/bookings/fetchBookingMessages', {
            id: this.$route.params.bookingId,
          }).then(() => {
            if (this.unreadMessages.length) {
              this.markMessagesAsRead().then(() => {
                const cardViewport = document.getElementById('dashboard-activity-card');
                cardViewport.scroll({ top: cardViewport.scrollHeight, behavior: 'auto' });
              })
            }
          })
        })
      },

      silentFetchActivities() {
        this.$store.dispatch('dashboard/bookings/fetchBookingActivities', {
          id: this.$route.params.bookingId,
          sort: this.sorting,
        })
      },

      fetchMessages() {
        this.$store.dispatch('dashboard/bookings/fetchBookingMessages', {
          id: this.$route.params.bookingId,
        }).then(() => {
          if (this.unreadMessages.length) {
            this.markMessagesAsRead().then(() => {
              this.silentFetchMessages()
            })
          }
        })
      },

      silentFetchMessages() {
        this.silentFetchActivities();
        this.$store.dispatch('dashboard/bookings/fetchBookingMessages', {
          id: this.$route.params.bookingId,
        }).then(() => {
          if (this.unreadMessages.length) {
            this.markMessagesAsRead()
          }
        })
      },

      markMessagesAsRead() {
        return this.$store.dispatch('dashboard/bookings/markMessagesAsRead', {
          id: this.$route.params.bookingId,
        })
      },

      findMessage(messageId) {
        return this.messages.find(message => message.id == messageId)
      },
    },

    mounted() {
      this.fetchData();
      window.Event.$on('refreshActivity', this.fetchActivities);

      this.interval = setInterval(() => {
        this.silentFetchMessages()
      }, 10000)

      window.Event.$on('start-video-pitch', () => this.createVideoPitch = true)
      window.Event.$on('start-audio-pitch', () => this.createAudioPitch = true)
    },

    beforeDestroy() {
      window.Event.$off('refreshActivity')
      window.Event.$off('start-video-pitch')
      window.Event.$off('start-audio-pitch')

      clearInterval(this.interval)
    }
  }
</script>

<style>
  .timeline-item {
    border-bottom: inherit !important;
  }

  .timeline-circle {
    /* left: -43px !important; */
    top: -1rem !important;
    width: 43px !important;
    height: 43px !important;
    @apply rounded-full p-0 my-2 flex items-center;
  }

  @media (max-width: 768px) {
    .timeline-circle {
      width: 30px !important;
      height: 30px !important;
    }
    .timeline {
      margin-left: 12px;
      margin-right: 12px;
    }
    .timeline-item {
      margin-left: 18px !important;
    }
    .timeline-circle {
      /* top: -2rem !important; */
      left: -32px !important;
    }
  }

</style>
