<template>
  <div class="bg-guestio-gray-100 h-16 rounded-lg flex items-center px-6 space-x-5">
    <div>
      <button v-if="! playing" @click.prevent="play()" type="button" class="p-2.5 text-pink-500 focus:outline-none rounded-full focus:shadow-outline border-2 border-pink-500">
        <PlayAltIcon class="h-3 w-3 text-pink-500" />
      </button>
      <button v-else @click.prevent="stop()" type="button" class="p-2.5 text-pink-500 focus:outline-none rounded-full focus:shadow-outline border-2 border-pink-500">
        <StopIcon class="h-3 w-3 text-pink-500" />
      </button>
    </div>
    <div class="flex-1 mt-2">
      <div @click="seek" class="progress relative flex-1 h-1.5 bg-guestio-gray-300 rounded-full flex items-center">
        <div
          :style="{ width: this.playProgress + '%' }"
          class="absolute inset-y-0 rounded-full bg-pink-500 pointer-events-none">
        </div>
        <div
          :style="{ left: this.playProgress + '%' }"
          class="absolute w-4 h-4 rounded-full bg-white shadow -ml-2 pointer-events-none"></div>
      </div>
      <div class="flex items-center justify-between mt-2">
        <div class="text-xs font-poppins text-blue-800 whitespace-nowrap">
          0:00
        </div>
        <div class="text-xs font-poppins text-blue-800 whitespace-nowrap">
          {{ audioLength | time }}
        </div>
      </div>
    </div>

    <audio
      class="hidden"
      ref="audioPlayer"
      @play="playing = true"
      @pause="playing = false"
      @durationchange="fixDuration"
      playsinline
    ></audio>
  </div>
</template>

<script>
  import PlayAltIcon from '@/components/svgs/PlayAltIcon'
  import StopIcon from '@/components/svgs/StopIcon'

  export default {
    name: 'AudioPreview',

    props: {
      mediaSrc: String,
    },

    components: {
      PlayAltIcon,
      StopIcon,
    },

    data() {
      return {
        playing: false,
        audioLength: 0,
        interval: null,
        currentSeconds: 0,
      }
    },

    watch: {
      playing(playing) {
        if (playing) {
          this.interval = setInterval(() => {
            this.currentSeconds = this.$refs.audioPlayer.currentTime
          }, 100)
        } else {
          if (this.interval) {
            clearInterval(this.interval)
          }
        }
      },

      mediaSrc(mediaSrc) {
        this.playing = false
        this.currentSeconds = 0
        this.audioLength = 0
        this.$refs.audioPlayer.src = mediaSrc
      }
    },

    computed: {
      playProgress() {
        return this.currentSeconds / this.audioLength * 100;
      },
    },

    filters: {
      time(value) {
        value = Math.round(value)

        if (value < 60) {
          let seconds = value.toString().padStart(2, '0')

          return `0:${seconds}`
        }

        let minutes = Math.floor(value / 60)
        let seconds = Number(value % 60).toString().padStart(2, '0')

        return `${minutes}:${seconds}`
      }
    },

    methods: {
      play() {
        this.$refs.audioPlayer.play()
      },

      stop() {
        this.$refs.audioPlayer.pause();
        this.$refs.audioPlayer.currentTime = 0
      },

      seek(e) {
        const el = e.target.getBoundingClientRect();
        const seekPos = (e.clientX - el.left) / el.width;

        this.currentSeconds = this.audioLength * seekPos;
        this.$refs.audioPlayer.currentTime = this.currentSeconds

        if (! this.playing) {
          this.$refs.audioPlayer.play()
        }
      },

      fixDuration(event) {
        if (event.target.duration != Infinity) {
          this.$refs.audioPlayer.currentTime = 0
          this.audioLength = event.target.duration
          return
        }

        this.$refs.audioPlayer.currentTime = 999999
      },
    },

    mounted() {
      this.$refs.audioPlayer.src = this.mediaSrc
    }
  }
</script>
