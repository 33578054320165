<template>
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.2673 8.42996C15.424 8.23074 15.4041 7.94518 15.2208 7.76988C15.0389 7.59457 14.7533 7.58527 14.5594 7.74996L5.71941 15.2711L1.05121 13.4648C0.636817 13.3068 0.352611 12.9203 0.324731 12.4767C0.295512 12.0345 0.527935 11.6148 0.919731 11.405L20.2997 0.548758C20.6092 0.381413 20.9864 0.398679 21.2799 0.592587C21.5747 0.787822 21.7368 1.12914 21.7036 1.47978L20.0036 19.2009C19.969 19.5688 19.7618 19.8995 19.4471 20.0921C19.131 20.2847 18.7419 20.3179 18.3992 20.1798L12.7175 17.9804L9.15018 21.3219C8.8819 21.5729 8.48878 21.6407 8.15143 21.4932C7.81409 21.3445 7.5976 21.0098 7.60026 20.6419V18.0175L15.2673 8.42996Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: "SendIcon"
}
</script>

<style scoped>

</style>
