<template>
  <div class="h-16">
    <div class="flex bg-gray-50 rounded-r-full rounded-l-full justify-between">
      <router-link
        :to="{name:'Activity'}"
        class="flex-1 text-center rounded-full py-3 lg:py-4 transition-colors duration-300 ease-out text-sm lg:text-base"
      >
        Activity
      </router-link>

      <router-link
        :to="{name:'BookingApplication'}"
        class="flex-1 text-center py-3 lg:py-4 rounded-full transition-colors duration-300 ease-out text-sm lg:text-base"
      >
        Application
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    name: "BookingTabs"
  }
</script>

<style scoped>
.router-link-exact-active{
  @apply bg-lightBlack text-white ;
}
</style>
