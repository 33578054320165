<template>
  <div>
    <div class="flex justify-between text-white">
      <h4 class="text-2xl font-bold mb-4">{{ title }}</h4>
      <router-link v-if="redirect" class="text-sm" :to="redirect">See all</router-link>
    </div>
    <flickity v-if="flickity" ref="flickity" :options="flickityOptions">
      <slot name="item"></slot>
    </flickity>
    <div v-else class="flex overflow-x-scroll w-full slider">
      <slot name="item"></slot>
    </div>
  </div>
</template>

<script>
import Flickity from 'vue-flickity';

export default {
  name: "Slider",
  props: {
    items: Array,
    title: String,
    flickity: {
      type: Boolean,
      default: false
    },
    redirect: Object
  },
  components: {
    Flickity
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 0,
        prevNextButtons: false,
        pageDots: false,
        wrapAround: false,
        cellAlign: 'left'
      }
    }
  }
}
</script>

<style scoped>
.slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
</style>

