<template>
  <div v-if="userIsInvitee" class="flex flex-col space-y-5">
    <div v-for="(action, index) in actions" :key="index">
      <Radiobutton
        textColor="text-white"
        fontWeight="font-normal"
        hover="hover:text-violet"
        borderWidth="border"
        :value="action.action"
        alignItems="items-start"
        radioButtonSize="w-6 h-6"
        name="pitch-action"
        @change="selectPitchAction"
        :checked="form.action === action.action"
      >
        <template slot="content">
          <div class="flex flex-col">
            <span class="ml-4 flex flex-col text-sm">
              <span class="leading-6">{{ action.title }}</span>
              <span v-if="action.subtitle" class="font-semibold">{{ action.subtitle }}</span>
            </span>
          </div>
        </template>
      </Radiobutton>
      <div v-if="form.action === 'accept-special-price' && action.action == 'accept-special-price'" class="">
        <h1 class="font-semibold text-blue-800 mb-2 mt-4">Total amount</h1>
        <div
          class="currency-input"
          :class="{'error': form.price < 5}"
        >
            $
            <input
              id="price"
              type="number"
              min="4"
              oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"
              class="bg-transparent w-full"
              v-model.number="form.price"
            >
          </div>
      </div>
    </div>
    <div class="flex justify-center">
      <button
        class="mt-6 rounded-full bg-indigo-gradiant text-white text-xl font-bold h-12 px-16 disabled:opacity-50 disabled:cursor-not-allowed"
        :disabled="form.processing || ! form.action || (form.action === 'accept-special-price' && form.price < 5)"
        @click="confirmAction"
      >
        <span v-if="form.processing">
          <loading-icon class="h-5 w-5"/>
        </span>
        <span v-else>Continue</span>
      </button>

      <RejectBookingModal class="hidden" ref="rejectModal" />
    </div>
  </div>
</template>

<script>
import Radiobutton from '@/components/shared/Radiobutton';
import RejectBookingModal from './RejectBookingModal.vue';

export default {
  name: 'PitchActions',

  components: {
    Radiobutton,
    RejectBookingModal,
  },

  data() {
    return {
      form: {
        action: null,
        price: 5,
        processing: false,
      },
      actions: [],
      loading: false,
    }
  },

  computed: {
    booking() {
      return this.$store.getters['dashboard/bookings/booking'];
    },
    userIsInvitee() {
      return this.$store.getters['dashboard/bookings/userIsInvitee'];
    },

    pendingAmount() {
      return this.booking.full_booking_fee - this.booking.pitch_fee;
    },
  },

  mounted() {
    const initiator = this.booking.initiator == 'guest' ? 'Guest' : 'Show';
    this.actions = [
      {
        action: 'accept',
        title: 'Accept without any further fee',
        subtitle: this.booking.price > 0 ? `${initiator} would be charged $${(this.booking.price / 100).toFixed(2)}` : '',
      },
      {
        action: 'accept-pay-full',
        title: `Accept but ${initiator} must pay full booking fee`,
        subtitle: `${initiator} would be ${this.pendingAmount < 0 ? 'refunded' : 'charged'} $${(Math.abs(this.pendingAmount) / 100).toFixed(2)}`,
      },
      {
        action: 'accept-special-price',
        title: 'Accept and give a special discount on the booking fee',
        subtitle: `${initiator} would be charged a special price you propose.`,
      },
      {
        action: 'reject',
        title: 'Reject',
      }
    ];

  },

  methods: {
    reRenderComponent() {
      window.Event.$emit('refreshActivity');
      window.Event.$emit('refreshBooking');
    },

    selectPitchAction(action) {
      this.form.action = action;

      if (action !== 'accept-special-price') {
        this.form.price = 5;
      }
    },

    confirmAction() {
      if (this.form.action === 'reject') {
        return this.$refs.rejectModal.toggle();
      }

      this.form.processing = true;

      this.$store
        .dispatch(`dashboard/bookings/acceptPitch`, {
          id: this.booking.id,
          ...this.form,
        })
        .then(() => {
          this.reRenderComponent();
        }).finally(() => {
          this.form.processing = false;
        });
    },
  },
};
</script>

<style scoped>
.currency-input {
  @apply rounded-full max-w-lg bg-guestio-gray-100 py-3 px-5 flex items-center border border-transparent focus-within:border-violet;
}

.currency-input.error {
  @apply bg-red-200;
}
</style>
