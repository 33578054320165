<template>
  <div>
    <!--Hero-->
    <section style="background-position-y: 50%"
             class="w-full px-6 py-10 lg:px-10 lg:py-24 text-center text-white flex flex-col items-center relative coverImage">
      <div class="relative z-30 flex flex-col items-center">
        <h1 class="text-4xl lg:text-5xl xl:text-6xl text-white mb-8 font-bold md:w-1/2 leading-tight">
          Book the world’s top guests & shows!
        </h1>
        <div class="w-full md:w-1/2 text-center mb-10">
          <p class="font-light  md:text-lg text-grayHome">
            Guestio is the world's only marketplace to find professional guests and shows.
          </p>
        </div>
      </div>
      <img id="img1" class="hidden lg:block rounded-xl absolute right-0 w-40" src="@/assets/images/hero/9.png" alt="">
      <img id="img2" class="hidden lg:block rounded-xl absolute left-0 w-48" src="@/assets/images/hero/3.png" alt="">
      <img id="img3" class="hidden lg:block rounded-xl absolute right-0" src="@/assets/images/hero/5.png" alt="">
      <img id="img4" class="hidden lg:block rounded-xl absolute left-0 w-40 z-10" src="@/assets/images/hero/7.png"
           alt="">
      <Bubble id="bubble1" class="absolute left-0 top-0"/>
      <Bubble id="bubble2" class="absolute right-0 top-0 hidden lg:block"/>
      <!--Filters ONLY ON MOBILE-->
      <div class="lg:hidden w-full">
        <Search :query="{showing: profilesType}"/>
      </div>
    </section>

    <section class="px-6 lg:px-24 py-20 bg-black grid grid-cols-1 gap-y-12 relative">
      <!--Filters-->
      <div class="w-full justify-center lg:absolute lg:left-0 lg:right-0 lg:-mt-8 hidden lg:flex">
        <Search :query="{showing: profilesType}" />
      </div>

      <div class="w-10/12 sm:w-6/12 md:w-3/12  rounded-full text-white bg-blue-800 shadow-xl">
        <button @click="selectType('guests')"
                :class="profilesType === 'guests' ? 'font-semibold rounded-full  bg-indigo-gradiant' : 'rounded-l-full bg-blue-800 font-light text-guestio-gray-300'"
                class="text-center w-1/2 py-3 text-xs md:text-base">Guests
        </button>
        <button @click="selectType('shows')"
                :class="profilesType === 'shows' ? 'font-semibold rounded-full  bg-indigo-gradiant' : 'rounded-r-full bg-blue-800 font-light text-guestio-gray-300'"
                class="rounded-r-full text-center w-1/2 py-3 text-xs md:text-base">Shows
        </button>
      </div>
      <SliderSkeleton v-show="!featuredLoaded">
        <template slot="item">
          <ItemSkeleton v-for="item in items" :key="item"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6 "/>
        </template>
      </SliderSkeleton>

      <Slider 
        v-if="featuredProfiles.length !== 0" 
        title="Featured" 
        :redirect="{ name: 'Browse', query: { showing: profilesType, featured: 1 } }"
      >
        <template slot="item">
          <Item v-for="(profile, index) in featuredProfiles" :key="index"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6" :profile="profile"/>
        </template>
      </Slider>

      <SliderSkeleton v-show="!guestioTalentLoaded">
        <template slot="item">
          <ItemSkeleton v-for="item in items" :key="item"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6 "/>
        </template>
      </SliderSkeleton>

      <Slider 
        v-if="guestioTalentProfiles.length !== 0" 
        title="Guestio Talent" 
        :redirect="{ name: 'Browse', query: { showing: profilesType, isGuestioTalent: 1 } }"
      >
        <template slot="item">
          <Item v-for="(profile, index) in guestioTalentProfiles" :key="index"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6" :profile="profile"/>
        </template>
      </Slider>

      <SliderSkeleton v-show="!freeLoaded">
        <template slot="item">
          <ItemSkeleton v-for="item in items" :key="item"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6 "/>
        </template>
      </SliderSkeleton>

      <Slider v-if="1 == 2 && freeProfiles.length !== 0 && containsValidFreeProfiles" title="Free profiles" :redirect="{ name: 'Browse', query: { showing: profilesType, prices: 0 } }">
        <template slot="item">
          <Item v-for="(profile, index) in freeProfiles" :key="index"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6 " :profile="profile"/>
        </template>
      </Slider>


      <SliderSkeleton v-if="categories.length === 0">
        <template slot="item">
          <CategorySkeleton v-for="item in items" :key="item" class="w-6/12 md:w-2/12"/>
        </template>
      </SliderSkeleton>

      <Slider v-else title="Categories">
        <template slot="item">
          <CategoryCard v-for="category in categories" :category="category" :key="category.id"/>
        </template>
      </Slider>

        <SliderSkeleton v-show="profilesLoading">
        <template slot="item">
          <ItemSkeleton v-for="item in items" :key="item"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6 "/>
        </template>
      </SliderSkeleton>

      <Slider v-if="allProfiles.length" :title="`All ${profilesType}`"
              :redirect="{ name: 'Browse', query: { showing: profilesType } }">
        <template slot="item">
          <Item v-for="(profile, index) in allProfiles" :key="index"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6" :profile="profile"/>
        </template>
      </Slider>

      <SliderSkeleton v-show="!premiumLoaded">
        <template slot="item">
          <ItemSkeleton v-for="item in items" :key="item"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6 "/>
        </template>
      </SliderSkeleton>

      <Slider v-if="premiumProfiles.length" :title="`Premium ${profilesType}`"
              :redirect="{ name: 'Browse', query: { showing: profilesType, premium: 1 } }">
        <template slot="item">
          <Item v-for="(profile, index) in premiumProfiles" :key="index"
                class="w-6/12 md:w-2/12 mr-4 lg:mr-6" :profile="profile"/>
        </template>
      </Slider>
    </section>
  </div>
</template>

<script>
import api from '@/api'
import {mapState, mapGetters, mapMutations} from "vuex";
import Item from "@/components/home/Item";
import ItemSkeleton from "@/components/home/ItemSkeleton";
import Bubble from "@/components/home/Bubble";
import Slider from "@/components/home/Slider";
import SliderSkeleton from "@/components/home/SliderSkeleton";
import Search from "@/components/home/Search";
import CategoryCard from "@/components/home/CategoryCard";
import CategorySkeleton from "@/components/home/CategorySkeleton";

export default {
  name: "Home",

  components: {
    CategoryCard,
    CategorySkeleton,
    Search,
    Slider,
    SliderSkeleton,
    Bubble,
    Item,
    ItemSkeleton
  },

  created() {
    this.referredBy = this.$route.query.ref
    this.fetchData();
    window.Event.$on('loggedIn', info => {
      this.favorite(info.id)
    })
  },

  data() {
    return {
      featuredLoaded: false,
      guestioTalentLoaded: false,
      premiumLoaded: false,
      freeLoaded: false,
      profilesLoading: true,
      currentPage: 1,
      profilesType: 'guests',
      referredBy: null,
      infiniteId: +new Date(),
      items: ['1','2','3','4','5','6','7','8','9','10','11','12','13','14'],
    }
  },
  methods: {
    ...mapMutations({
      setAllProfiles: `home/setAllProfiles`,
    }),
    loadProfiles() {
      this.profilesLoading = true;
      api.get(`/${this.profilesType}?page=${this.currentPage}`)
        .then(({data}) => {
          if (data.data.length) {
            this.currentPage += 1;
            this.setAllProfiles(data.data);
          }
        })
        .finally(() => {
          this.profilesLoading = false;
        })
    },

    fetchData() {
      this.loadProfiles();
      let params = {}
      if (this.referredBy) {
        params.ref = this.referredBy
        this.$store.dispatch('home/incrementReferralViews', params.ref);
      }
      this.$store.dispatch('home/fetchFeatured', params)
        .then(() => {
            this.featuredLoaded = true;
          });
      this.$store.dispatch('home/fetchGuestioTalent', params)
        .then(() => {
            this.guestioTalentLoaded = true;
          });
      this.$store.dispatch('home/fetchPremium', params)
        .then(() => {
            this.premiumLoaded = true;
          });
      this.$store.dispatch('home/fetchFree', params)
        .then(() => {
            this.freeLoaded = true;
          });
      this.$store.dispatch('home/fetchCategories', params);
    },
    toggledVip() {
      this.selectType();
    },

    selectType(type = null) {
      if (type) {
        this.profilesType = type;
      }
      this.infiniteId += 1;
      this.currentPage = 1;
      this.setAllProfiles([]);
      this.fetchData();
    },

    handleRedirect() {
      if (!this.redirectPath) {
        return;
      }

      let redirectPath = this.redirectPath
      localStorage.removeItem('redirect_path')

      this.$router.push(redirectPath)
    },

    async favorite(id) {
      let profile = {}
      profile = this.featuredProfiles.find(profile => profile.id == id)
      if (!profile.is_favourited) {
        await this.$store.dispatch('dashboard/smartlist/toggleVip', {profileId: id, type: this.profilesType})
      }
    },
  },
  computed: {
    ...mapState({
      featured: state => state.home.featured,
      guestioTalent: state => state.home.guestioTalent,
      premium: state => state.home.premium,
      allProfiles: state => state.home.allProfiles,
      free: state => state.home.free,
    }),
    ...mapGetters({
      categories: 'home/filledCategories',
    }),
    authenticated() {
      return this.$store.getters["auth/authenticated"];
    },
    redirectPath() {
      return localStorage.getItem('redirect_path')
    },

    featuredProfiles() {
      if (this.profilesType === 'guests') {
        return this.featured.guests
      } else {
        return this.featured.shows
      }
    },

    guestioTalentProfiles() {
      if (this.profilesType === 'guests') {
        return this.guestioTalent.guests
      } else {
        return this.guestioTalent.shows
      }
    },

    premiumProfiles() {
      if (this.profilesType === 'guests') {
        return this.premium.guests
      } else {
        return this.premium.shows
      }
    },

    freeProfiles() {
      if (this.profilesType === 'guests') {
        return this.free.guests
      } else {
        return this.free.shows
      }
    },

    containsValidFreeProfiles() {
      return this.freeProfiles.filter(profile => { return this.freeProfiles[0].type == 'show' ? profile.platform_type != null : true }).length > 0;
    }
  },

  mounted() {
    this.handleRedirect()
  },

  destroyed() {
    window.Event.$off('loggedIn')
  },
};
</script>
