import api from '@/api'

const booking = {
  namespaced: true,

  state: {
    availableSteps: [
      { name: 'profile', enabled: false, route: 'ShowBook' },
      { name: 'options', enabled: false, route: 'ShowBookingOptions' },
      { name: 'pitch', enabled: true, route: 'ShowBookingPitch' },
      { name: 'questions', enabled: false, route: 'ShowBookingQuestions' },
      { name: 'upsells', enabled: false, route: 'ShowBookingUpsells' },
      { name: 'payment', enabled: true, route: 'ShowBookingPayment' },
    ],
    steps: [],
    currentStep: 1,
    guest: null,
    selectedInterviewDuration: null,
    booking: null,
    guestioCash: 0,
    questions: [],
    availableUpsellPackages: [],
    upsells: [],
    paymentMethod: null,
    pitch: null,
    bookingOptions: [],
    selectedBookingOption: null,
    isPitch: false,
    wantsGuaranteedResponse: false,
  },

  getters: {
    guest: state => state.guest,
    booking: state => state.booking,
    availableUpsellPackages: state => state.availableUpsellPackages,
    upsells: state => state.upsells,
    availableSteps: state => state.availableSteps,
    enabledSteps: state => state.availableSteps.filter(step => step.enabled),
    pitch: state => state.pitch,
    bookingOptions: state => state.bookingOptions,
    selectedBookingOption: state => state.selectedBookingOption,
    isPitch: state => state.isPitch,
    wantsGuaranteedResponse: state => state.wantsGuaranteedResponse,
  },

  actions: {
    create({ commit, state, rootState }) {
      return new Promise((resolve, reject) => {
        return api.post(`/shows/${rootState.shows.show.id}/book`, {
          guest_profile_id: state.guest.id,
          interview_duration: state.selectedBookingOption.price.interview_duration,
          guestio_cash: state.guestioCash,
          questions: state.questions,
          upsells: state.upsells,
          payment_method: state.paymentMethod,
          pitch: state.pitch,
          booking_options: {
            booking_option_id: state.selectedBookingOption.id,
            price_id: state.selectedBookingOption.price.id,
            amount: state.selectedBookingOption.amount || 1,
          },
          source: 'web',
          is_pitch: state.isPitch,
          wants_guaranteed_response: state.wantsGuaranteedResponse,
        }).then(response => {
          commit('setBooking', response.data.data)
          resolve(response.data.data)
        }).catch(error => {
          reject(error)
        })
      })
    },

    fetchBookingOptions({commit}) {
      return new Promise((resolve, reject) => {
        return api.get('/booking-options')
          .then(({ data }) => {
            commit('setBookingOptions', data.data)
            resolve(data.data)
          })
          .catch(error => {
            reject(error)
          })
        })
    }
  },

  mutations: {
    nextStep(state) {
      if (state.enabledSteps.length == state.currentStep) {
        return
      }
      state.currentStep++
    },

    prevStep(state) {
      if (state.currentStep == 1) {
        return
      }
      state.currentStep--
    },

    setCurrentStep(state, step) {
      state.currentStep = step
    },

    setSteps(state, data) {
      state.steps = data
    },

    setGuest(state, guest) {
      state.guest = guest
    },

    setSelectedInterviewDuration(state, duration) {
      state.selectedInterviewDuration = duration
    },

    setBooking(state, data) {
      state.booking = data
    },

    setBookingOptions(state, data) {
      state.bookingOptions = data
    },

    clearBooking(state) {
      state.currentStep = 1
      state.steps = []
      state.guest = null
      state.selectedInterviewDuration = null
      state.guestioCash = 0
      state.booking = null
      state.questions = []
      state.availableUpsellPackages = []
      state.upsells = []
      state.pitch = null
      state.selectedBookingOption = null
      state.bookingOptions = []
      state.isPitch = false
      state.wantsGuaranteedResponse = false
    },

    enableStep(state, stepName) {
      let stepIndex = state.availableSteps.findIndex(step => step.name == stepName)

      if (stepIndex === -1) {
        return
      }

      state.availableSteps[stepIndex].enabled = true
    },

    disableStep(state, stepName) {
      let stepIndex = state.availableSteps.findIndex(step => step.name == stepName)

      if (stepIndex === -1) {
        return
      }

      state.availableSteps[stepIndex].enabled = false
    },

    setGuestioCash(state, data) {
      state.guestioCash = data
    },

    setQuestions(state, data) {
      state.questions = data
    },

    setAvailableUpsellPackages(state, data) {
      state.availableUpsellPackages = data
    },

    setUpsells(state, data) {
      state.upsells = data
    },

    setPaymentMethod(state, data) {
      state.paymentMethod = data
    },

    setPitch(state, pitch) {
      state.pitch = pitch
    },

    setSelectedBookingOption(state, option) {
      state.selectedBookingOption = option
    },

    setIsPitch(state, isPitch) {
      state.isPitch = isPitch;
    },

    setWantsGuaranteedResponse(state, wantsGuaranteedResponse) {
      state.wantsGuaranteedResponse = wantsGuaranteedResponse;
    },
  },
}

export default booking
