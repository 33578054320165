<template>
  <div>
    <div class="relative">
      <div v-if="! hasDays" class="absolute inset-0 z-10 flex items-center justify-center bg-gray-200 bg-opacity-75">
        <div class="">
          <loading-icon class="h-10 w-10 text-pink-500"/>
        </div>
      </div>

      <div class="bg-white overflow-hidden relative">
        <transition
          enter-active-class="transition ease-in-out duration-300 transform"
          enter-class="-translate-x-16 opacity-0"
          enter-to-class="translate-x-0 opacity-100"
          leave-active-class="transition ease-in-out duration-100 transform absolute inset-0"
          leave-class="translate-x-0 opacity-100"
          leave-to-class="-translate-x-16 opacity-0"
        >
          <div
            v-if="! selectedDay"
            key="calendar"
            class="px-6 py-6 sm:px-12 lg:py-8"
          >
            <div class="flex justify-between items-center">
              <div class="flex justify-center">
                <span class="text-black font-semibold text-2xl">{{ this.month.format('MMMM YYYY') }}</span>
              </div>
              <div class="ml-4">
                <button
                  :disabled="month.startOf('month').isBefore(now)"
                  :class="month.startOf('month').isBefore(now) ? 'text-gray-400' : 'text-pink-500'"
                  @click.prevent="prevMonth()"
                  class="mr-4 w-auto m-0 px-2 py-0"
                >
                  <i class="fas fa-chevron-left"></i>
                </button>
                <button @click.prevent="nextMonth()" class="w-auto m-0 px-2 py-0 text-pink-500">
                  <i class="fas fa-chevron-right"></i>
                </button>
              </div>
            </div>

            <div>
              <div class="grid grid-cols-7 mt-6">
                <div v-for="weekday in weekdays" :key="weekday" class="uppercase text-xs text-gray-500 text-center">{{weekday}}</div>
              </div>

              <div class="grid grid-cols-7 gap-1 mt-3">
                <div class="relative pb-full" v-for="day in days" :key="`day-${day.format('YYYY-MM-DD')}`">
                  <div v-if="day.isSame(month, 'month')" class="absolute inset-0">
                    <template v-if="day.isSameOrAfter(now, 'day')">
                      <button @click="showTimeSlotsFor(day)" class="relative w-full h-full rounded-full flex items-center justify-center overflow-hidden hover:border hover:border-pink-500 active:bg-pink-100 focus:bg-pink-100"
                      :class="{'bg-pink-500 text-white': dayHasSelectedSlots(day.format('YYYY-MM-DD')), 'text-pink-500': ! dayHasSelectedSlots(day.format('YYYY-MM-DD')), 'border border-pink-500': day.isSame(now, 'day')}">
                        <span class="flex flex-col leading-none">
                          <span class="text-sm sm:text-lg">{{ day.format('D') }}</span>
                          <span v-if="dayHasSelectedSlots(day.format('YYYY-MM-DD'))" class="absolute bottom-0 inset-x-0">
                            <span
                              v-for="(slot, index) in daySelectedSlots(day.format('YYYY-MM-DD'))"
                              class="text-white sm:text-xl"
                              :key="`slot-${index}`"
                            >&bull;</span>
                          </span>
                        </span>
                      </button>
                    </template>
                    <template v-else>
                      <div class="w-full h-full rounded-full flex items-center justify-center overflow-hidden">
                        <span class="text-sm sm:text-lg text-gray-400">{{ day.format('D') }}</span>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <transition
          enter-active-class="transition ease-in-out duration-300 transform"
          enter-class="translate-x-16 opacity-0"
          enter-to-class="translate-x-0 opacity-100"
          leave-active-class="transition ease-in-out duration-300 transform absolute inset-0"
          leave-class="translate-x-0 opacity-100"
          leave-to-class="translate-x-16 opacity-0"
        >
          <div v-if="selectedDay" key="slotPicker">
            <calendar-time-slots
              :single="single"
              :day="selectedDay"
              :selected-slots-prop="selectedSlots"
              @updated-slots="updateSelectedSlots"
              @close="selectedDay = null"
            />
          </div>
        </transition>
      </div>
    </div>

    <template v-if="! selectedDay">
      <div class="px-6 sm:px-12">
        <h3 class="text-lg text-gray-500 text-center">Your Selected {{ single ? 'Date' : 'Dates' }}</h3>
        <div v-if="single" class="mt-2">
          <div class="relative">
            <div v-if="selectedSlots[0]" class="px-2 py-4 border rounded-lg shadow-lg bg-white flex flex-col items-center justify-center leading-none">
              <div class="sm:text-lg text-gray-500">{{ moment(selectedSlots[0]).format('MMMM D') }}</div>
              <div class="mt-4 sm:mt-4 font-semibold sm:text-xl">{{ moment(selectedSlots[0]).format('h:mm a') }}</div>
              <div class="mt-4 sm:mt-4">
                <button @click.prevent="removeSlot(0)" class="text-xs text-red-400 hover:text-red-500 py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                  remove
                </button>
              </div>
            </div>
            <div v-else class="h-24 border border-dashed border-gray-400 bg-gray-300 bg-opacity-50 rounded-lg">

            </div>
          </div>
        </div>
        <div v-else class="mt-2 grid grid-cols-3 gap-2 sm:gap-4">
          <div v-for="(slot, index) in 3" class="relative pb-120%" :key="`selected-slot-${index}`">
            <div v-if="selectedSlots[index]" class="absolute inset-0 border rounded-lg shadow-lg bg-white flex flex-col items-center justify-center leading-none">
              <div class="text-xs sm:text-base text-gray-500 font-light">{{ selectedSlots[index].format('MMMM D') }}</div>
              <div class="mt-4 sm:mt-4 font-semibold sm:text-xl">{{ selectedSlots[index].format('h:mm a') }}</div>
              <div class="mt-4 sm:mt-4">
                <button @click.prevent="removeSlot(index)" class="text-xs text-red-400 hover:text-red-500 py-1 px-2 rounded focus:outline-none focus:shadow-outline">
                  remove
                </button>
              </div>
            </div>
            <div v-else class="absolute inset-0 border border-dashed border-gray-400 bg-gray-300 bg-opacity-50 rounded-lg">

            </div>
          </div>
        </div>
      </div>

      <div class="px-6 py-6 sm:px-12 sm:py-10">
        <div class="">
          <button @click="saveDates" class="rounded-full h-16 bg-indigo-gradiant text-white text-lg sm:text-xl w-full flex justify-center items-center" :class="{'opacity-50': loading || ! selectedSlots.length}">
            <span v-if="loading">
              <loading-icon class="h-5 w-5"/>
            </span>
            <span v-else>Save</span>
          </button>
          <p v-if="slotsError" class="text-center mt-4 text-red-500">Please select at least 1 date and time</p>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import Moment from 'moment'
  import { extendMoment } from 'moment-range'
  import timezones from '@/mixins/timezones'
  import CalendarTimeSlots from '@/components/bookings/individual/CalendarTimeSlots'
  const moment = extendMoment(Moment)

  export default {
    name: 'Calendar',
    mixins: [timezones],
    components: {CalendarTimeSlots},

    props: {
      single: {
        type: Boolean,
        default: false
      }
    },

    data() {
      return {
        loading: false,
        slotsError: false,
        moment: moment,
        now: moment(),
        timezone: null,
        selectedDay: null,
        days: [],
        selectedSlots: [],
        month: moment(),
        weekdays: ['Sun', 'Mon', 'Tue', 'Wen', 'Thu', 'Fri', 'Sat']
      }
    },

    computed: {
      hasDays() {
        return this.days.length
      }
    },

    mounted() {
      this.guessTimezone()
      this.prepareDays()
    },

    methods: {
      prepareDays() {
        let start = this.month.clone().startOf('month').startOf('week')
        let end = this.month.clone().endOf('month').endOf('week')

        this.days = Array.from(moment.range(start, end).by('day'))
      },

      showTimeSlotsFor(day) {
        this.selectedDay = day
      },

      guessTimezone() {
        let zone = moment().utcOffset()

        if (moment().isDST()) {
          zone -= 60
        }

        let timezone = this.timezones.find(timezone => timezone.timezone == Intl.DateTimeFormat().resolvedOptions().timeZone);

        if (! timezone) {
          timezone = this.timezones.find(timezone => timezone.offset == (zone / 60));
        }

        if (timezone) {
          this.timezone = timezone.timezone
        }
      },

      clearDays() {
        this.days = []
      },

      prevMonth() {
        this.month.subtract(1, 'month')
        this.prepareDays()
      },

      nextMonth() {
        this.month.add(1, 'month')
        this.prepareDays()
      },

      slotIsSelected(slot) {
        return this.selectedSlots.find(s => s.unix() == slot.unix())
      },

      updateSelectedSlots(slots) {
        this.slotsError = false

        slots.forEach(slot => {
          if (this.single) {
            this.selectedSlots = [slot]
            return
          }

          if (this.slotIsSelected(slot)) {
            return
          }

          if (this.selectedSlots.length == 3) {
            this.selectedSlots.shift()
          }

          this.selectedSlots.push(slot)
        })
      },

      removeSlot(index) {
        this.selectedSlots.splice(index, 1)
      },

      dayHasSelectedSlots(dateString) {
        return this.daySelectedSlots(dateString).length
      },

      daySelectedSlots(dateString) {
        return this.selectedSlots.filter(slot => slot.format('YYYY-MM-DD') == dateString)
      },

      saveDates() {
        this.loading = true

        if (this.single) {
          this.$emit('save', this.selectedSlots[0])
          return
        }

        this.$emit('save', this.selectedSlots)
      },
    },
  }
</script>
