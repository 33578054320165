<template>
  <modal :with-close-button="false">
    <slot />

    <template v-slot:content="{ close }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-4 pt-6 pb-5 sm:p-8 sm:pb-6">
          <div class="sm:flex sm:items-start">
            <div class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <svg class="h-6 w-6 text-red-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-headline">
                <slot name="title">Title</slot>
              </h3>
              <div class="mt-2">
                <p class="text-sm leading-5 text-gray-500">
                  <slot name="content">content</slot>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <span class="flex w-full rounded-md shadow-sm sm:ml-3 sm:w-auto">
            <template v-if="! loading">
              <button @click.prevent="confirmAction(close)" type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5" :class="typeClass">
                {{ confirm }}
              </button>
            </template>
            <template v-else>
              <template v-if="confirmDelay > 0">
                <span class="flex items-center justify-between">
                  <button v-if="confirmCounter > 0" @click="cancelAction()" type="button" class="inline-flex justify-center w-10 rounded-l-md border border-transparent px-2 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    <svg class="w-5 h-5 text-white" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M6 18L18 6M6 6l12 12"></path></svg>
                  </button>
                  <span v-if="confirmCounter > 0" class="flex-1 inline-flex justify-center rounded-r-md border border-transparent px-2 py-2 bg-red-600 bg-opacity-50 text-base leading-6 font-medium text-white shadow-sm  transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    {{ confirming }} in ... {{ confirmCounter }}
                  </span>
                  <span v-else class="flex-1 inline-flex justify-center rounded-md border border-transparent px-4 py-2 bg-red-600 bg-opacity-50 text-base leading-6 font-medium text-white shadow-sm  transition ease-in-out duration-150 sm:text-sm sm:leading-5">
                    {{ confirming }}...
                  </span>
                </span>
              </template>
              <template v-else>
                <button type="button" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:shadow-outline-red transition ease-in-out duration-150 sm:text-sm sm:leading-5 opacity-50" disabled :class="typeClass">
                {{ confirm }}
              </button>
              </template>
            </template>
          </span>
          <span class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:w-auto">
            <button @click="closeAlert(close)" type="button" class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5">
              {{ cancel }}
            </button>
          </span>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from '@/components/shared/Modal'
  export default {
    props: {
      danger: {
        type: Boolean,
        default: false,
      },

      confirm: {
        type: String,
        default: 'Confirm'
      },

      confirming: {
        type: String,
        default: 'Confirming',
      },

      cancel: {
        type: String,
        default: 'Cancel'
      },

      confirmDelay: {
        type: Number,
        default: 0, // seconds
      },

      type: {
        type: String,
        default: 'danger',
      }
    },

    data() {
      return {
        loading: false,
        confirmCounter: this.confirmDelay,
        interval: null,
      }
    },

    components: { Modal },

    computed: {
      typeClass() {
        return ''
      }
    },

    methods: {
      confirmAction(callback) {
        this.loading = true

        if (this.confirmDelay == 0) {
          this.$emit('confirm', callback)
          return
        }

        this.interval = setInterval(() => {
          this.confirmCounter--

          if (this.confirmCounter <= 0) {
            this.$emit('confirm', callback)
            clearInterval(this.interval)
          }
        }, 1000)
      },

      cancelAction() {
        clearInterval(this.interval)
        this.confirmCounter = this.confirmDelay
        this.loading = false
      },

      closeAlert(callback) {
        this.cancelAction()
        this.$emit('cancel')
        callback.call()
      }
    },
  }
</script>
