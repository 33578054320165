<template>
  <div class="main-container grid grid-cols-12 row-gap-10 md:col-gap-10">
    <template v-if="! booking">
      <div class="h-40 col-span-12 flex justify-center items-center">
        <loading-icon class="w-10 h-10 text-pink-500" />
      </div>
    </template>
    <template v-else>
      <!--Left col-->
      <div class="col-span-12 md:col-span-12 lg:col-span-5">
        <div class="grid grid-cols-1 row-gap-8">
          <BookingsNavigation/>
          <UserInformation/>
          <DetailsCard/>
          <AdHocBookingActions />
          <Questions/>
        </div>
      </div>
      <!--Right col-->
      <div class="col-span-12 md:col-span-12 lg:col-span-7">
        <div class="grid grid-cols-1">
          <BookingTabs v-if="$route.name !== 'BookingMiniview'" class="mb-5"/>
          <transition :css="false" @before-enter="beforeEnter" @enter="enter">
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import DetailsCard from "@/components/bookings/individual/DetailsCard";
import Questions from "@/components/bookings/individual/Questions";
import BookingsNavigation from "@/components/bookings/individual/BookingsNavigation";
import UserInformation from "@/components/bookings/individual/UserInformation";
import BookingTabs from "@/components/bookings/individual/BookingTabs";
import AdHocBookingActions from '@/components/bookings/individual/AdHocBookingActions';
import anime from "animejs";

export default {
  name: "IndividualBooking",

    components: {BookingTabs, UserInformation, Questions, DetailsCard,AdHocBookingActions,BookingsNavigation},

  computed: {
    booking() {
      return this.$store.getters['dashboard/bookings/booking']
    },
  },

  created() {
    this.fetchBooking()

    window.Event.$on('refreshBooking', () => this.fetchBooking());
  },

  beforeDestroy() {
    window.Event.$off('refreshBooking')
  },

  mounted() {
    window.scrollTo({top: 0, behaviour: 'smooth'})
  },
  methods: {
    fetchBooking() {
      this.$store.dispatch('dashboard/bookings/fetchBooking', {
        id: this.$route.params.bookingId
      })
    },
    beforeEnter(el) {
      el.style.transform = 'translateX(30rem)';
    },
    enter(el, done) {
      anime({
        targets: el,
        translateX: 0,
        duration: 200,
        easing: 'cubicBezier(0.230, 1.000, 0.320, 1.000)',
        complete: done
      });
    },
  }
}
</script>

<style scoped>

</style>
