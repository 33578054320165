const routes = [
  {
    path: '/guests/:guestId',
    name: 'Guest',
    component: () => import('@/views/Guest.vue')
  },
  {
    path: '/guests/:guestId/create-review',
    name: 'CreateGuestReview',
    component: () => import('@/components/profile/Reviews/CreateReview.vue')
  },
  {
    path: '/guests/:guestId/book',
    meta: {
      requiresAuth: true,
    },
    component: () => import('@/views/GuestBook.vue'),
    children: [
      {
        path: '',
        name: 'GuestBook',
        component: () => import('@/views/guest-booking/BookingProfile.vue'),
      },
      {
        path: 'pitch',
        name: 'GuestBookingPitch',
        props: true,
        component: () => import('@/views/guest-booking/BookingPitch.vue'),
      },
      {
        path: 'options',
        name: 'GuestBookingOptions',
        props: true,
        component: () => import('@/views/guest-booking/BookingOptions.vue'),
      },
      {
        path: 'miniview-questions',
        name: 'GuestBookingMiniviewQuestions',
        props: true,
        component: () => import('@/views/guest-booking/BookingMiniviewQuestions.vue'),
      },
      {
        path: 'questions',
        name: 'GuestBookingQuestions',
        props: true,
        component: () => import('@/views/guest-booking/BookingQuestions.vue'),
      },
      {
        path: 'upsells',
        name: 'GuestBookingUpsells',
        props: true,
        component: () => import('@/views/guest-booking/BookingUpsells.vue'),
      },
      {
        path: 'payment',
        name: 'GuestBookingPayment',
        props: true,
        component: () => import('@/views/guest-booking/BookingPayment.vue'),
      }
    ]
  },
];

export default routes;
