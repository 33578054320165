<template>
  <div class='credit-card-inputs'>
    <div>
      <label class="font-medium text-black">Credit Card</label>
      <div class="mt-2">
        <card-number class="stripe-element card-number"
          ref="cardNumber"
          :stripe="stripeKey"
          :options="stripeOptions"
          @change="number = $event.complete"
        />
      </div>
    </div>

    <div class="mt-6 grid grid-cols-1 row-gap-6 sm:grid-cols-3 md:gap-4">
      <div>
        <label class="font-medium text-black">Expire Date</label>
        <div class="mt-2">
          <card-expiry class="stripe-element card-expiry"
            ref="cardExpiry"
            :stripe="stripeKey"
            :options="stripeOptions"
            @change="expiry = $event.complete"
          />
        </div>
      </div>
      <div>
        <label class="font-medium text-black">CVC</label>
        <div class="mt-2">
          <card-cvc class="stripe-element card-cvc"
            ref="cardCvc"
            :stripe="stripeKey"
            :options="stripeOptions"
            @change="cvc = $event.complete"
          />
        </div>
      </div>
      <div>
        <label class="font-medium text-black">Zip</label>
        <div class="mt-2">
          <postal-code class="stripe-element postal-code"
            ref="postalCode"
            :stripe="stripeKey"
            :options="stripeOptions"
            @change="postal_code = $event.complete"
          />
        </div>
      </div>
    </div>

    <div v-if="saveCardButton" class="mt-6">
      <label class="cursor-pointer select-none flex items-center">
        <input type="checkbox" v-model="saveCard" @change="$emit('saveCard', saveCard)" class="form-checkbox h-5 w-5 text-pink-500">
        <span class="ml-2">
          Save this card for future bookings
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import { CardNumber, CardExpiry, CardCvc, PostalCode } from 'vue-stripe-elements-plus'

export default {
  name: 'CreditCard',
  components: { CardNumber, CardExpiry, CardCvc, PostalCode },
  props: {
    saveCardButton: { type: Boolean, default: true }
  },
  data() {
    return {
      saveCard: false,
      number: false,
      expiry: false,
      cvc: false,
      postal_code: false,
      stripeOptions: {
          style: {
            base: {
              '::placeholder': {
                  color: '#a0aec0',
              },
              color: '#161616',
              // textAlign: 'center',
              lineHeight: '27px',
              // letterSpacing: '3.6px',
              fontSize: '18px',
              fontFamily: 'Poppins, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"',
            }
          },
        },
    }
  },

  watch: {
    number(isComplete){
      if (isComplete && this.expiry && this.cvc && this.postal_code) {
        this.$emit('cardComplete', { complete: true, card: this.$refs.cardNumber.$refs.element._element });
      } else {
        this.$emit('cardComplete', { complete: false });
      }
    },
    expiry(isComplete){
      if (isComplete && this.number && this.cvc && this.postal_code) {
        this.$emit('cardComplete', { complete: true, card: this.$refs.cardNumber.$refs.element._element });
      } else {
        this.$emit('cardComplete', { complete: false });
      }
    },
    cvc(isComplete){
      if (isComplete && this.number && this.expiry && this.postal_code) {
        this.$emit('cardComplete', { complete: true, card: this.$refs.cardNumber.$refs.element._element });
      } else {
        this.$emit('cardComplete', { complete: false });
      }
    },
    postal_code(isComplete){
      if (isComplete && this.number && this.expiry && this.cvc) {
        this.$emit('cardComplete', { complete: true, card: this.$refs.cardNumber.$refs.element._element });
      } else {
        this.$emit('cardComplete', { complete: false });
      }
    },
  },

  computed: {
    stripeKey() {
      return process.env.VUE_APP_STRIPE_KEY
    },
  },
}
</script>

<style>
  .StripeElement {
    @apply text-black text-lg bg-gray-50 w-full px-5 py-3 rounded-full border border-transparent leading-normal;
  }
  .StripeElement.StripeElement--focus {
    @apply border-pink-500 shadow-3xl bg-white;
  }
  .StripeElement.StripeElement--complete {
    @apply bg-green-200;
  }
  .StripeElement.StripeElement--invalid {
    @apply border-red-500;
  }
</style>