<template>
  <div @click="closeModal()" class="grid grid-cols-12 w-full items-center border-b gap-3 xl:gap-5 p-8 cursor-pointer hover:bg-gray-50">
    <!-- Image-->
    <div class="xl:col-span-1 col-span-2 rounded-full overflow-hidden">
      <img class="object-cover" :src="booking.guest.imageUrl || 'https://picsum.photos/200'" alt="">
    </div>
    <!-- Name, description-->
    <div class="xl:col-span-3 col-span-9">
      <h4 class="text-xl font-bold">{{ booking.guest.name }}</h4>
      <span class="text-pink-500">{{ title | truncate(22) }}</span>
    </div>
    <!-- Description-->
    <p class="xl:col-span-2 col-span-12 truncate">{{ booking.show.name }}</p>
    <!-- Price-->
    <span class="block xl:col-span-1 col-span-3">${{ (booking.price / 100).toFixed(2) }}</span>
    <!-- Status-->
    <div class="xl:col-span-2 col-span-6 justify-center xl:justify-start flex items-center ">
      <BookingStatus :status="bookingStatus" />
    </div>
    <!-- Notifications-->
    <div class="flex items-center xl:col-span-1 col-span-3">
      <svg class="flex-shrink-0 mr-2" width="20" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.33366 1.33333H16.667C17.5837 1.33333 18.3337 2.08333 18.3337 2.99999V13C18.3337 13.9167 17.5837 14.6667 16.667 14.6667H3.33366C2.41699 14.6667 1.66699 13.9167 1.66699 13V2.99999C1.66699 2.08333 2.41699 1.33333 3.33366 1.33333Z"
          stroke="#959595" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M18.3337 3L10.0003 8.83333L1.66699 3" stroke="#959595" stroke-width="2" stroke-linecap="round"
              stroke-linejoin="round"/>
      </svg>
      <span v-if="booking.unread_messages_count && ! booking.is_completed" class="bg-red-500 rounded-full px-4 py-1 text-white block">
          {{ booking.unread_messages_count }}
        </span>
    </div>
    <!-- Details-->
    <div class="xl:flex items-center hidden">
      <span>Details</span>
    </div>
  </div>
</template>
<script>
  import BookingStatus from '@/components/bookings/BookingStatus'
  export default {
    name: 'CalendarRow',

    props: {
      booking: Object,
    },

    components: { BookingStatus },

    computed: {
      bookingStatus() {
        if (this.booking.is_completed) {
          return 'completed'
        } else if (this.booking.is_canceled) {
          return 'canceled'
        } else if (this.booking.is_confirmed) {
          return 'confirmed'
        } else if (this.booking.is_expired) {
          return 'expired'
        }

        return 'pending'
      },

      title() {
        return this.booking.guest.title ? this.booking.guest.title : this.booking.guest.bio
      }
    },

    methods: {
      closeModal() {
        this.$store.commit('dashboard/bookings/setCalendarModal', { showCalendarModal: false })
        this.$store.commit('dashboard/bookings/setCalendarView', { calendarView: false })
        this.$store.commit('dashboard/bookings/setCalendarModalData', {})
        document.body.classList.remove('overflow-hidden')

        this.setBooking()

        this.$router.push(`/bookings/${this.booking.id}`)
      },

      setBooking() {
        this.$store.commit('dashboard/bookings/setBooking', this.booking)
      },
    }
  }
</script>
