<template>
  <div>
    <PaymentStats/>
    <PaymentsFilters/>

    <template v-if="loading">
      <div class="flex items-center justify-center bg-gray-50 py-8 rounded-xl shadow-lg h-24">
        <loading-icon class="h-3 text-pink-500" />
      </div>
    </template>
    <template v-else>
      <div v-if="filteredPayments.length" class="grid grid-cols-1 row-gap-10">
        <TableHeader/>

        <PaymentRow v-for="payment in filteredPayments" :payment="payment" :key="`payment-row-${payment.id}`"/>
      </div>
      <div v-else class="flex items-center justify-center bg-gray-50 py-8 rounded-xl shadow-lg h-24">
        <span class="">No payments found</span>
      </div>
    </template>
  </div>
</template>

<script>
  import PaymentRow from "../components/payments/PaymentRow"
  import PaymentsFilters from "../components/payments/PaymentFilters"
  import PaymentStats from "../components/payments/PaymentStats"
  import TableHeader from "../components/payments/TableHeader"
  import { mapState } from "vuex"

  export default {
    name: "Payments",

    components: {PaymentStats, PaymentsFilters, PaymentRow, TableHeader},

    data() {
      return {
        loading: true,
        paymentsError: null,
      }
    },

    computed: {
      ...mapState({
        payments: state => state.dashboard.payments.payments,
        filters: state => state.dashboard.payments.filters,
        sorting: state => state.dashboard.payments.sorting,
      }),

      completedPayments() {
        return this.$store.getters['dashboard/payments/completedPayments']
      },

      filteredPayments() {
        return this.$store.getters['dashboard/payments/filteredPayments']
      },
    },

    watch: {
      'filters.month'() {
        this.$store.commit('dashboard/payments/setFilters', {
          ...this.filters,
          type: 'all',
          search: '',
        })

        this.$store.commit('dashboard/payments/setSorting', '-payment_date')

        this.fetchPayments()
      },

      sorting() {
        this.fetchPayments()
      }
    },

    methods: {
      fetchPayments() {
        this.loading = true
        this.paymentsError = null

        this.$store.dispatch('dashboard/payments/fetchPayments')
          .catch(error => {
            if (error.response) {
              this.paymentsError = error.response.data.message
            } else {
              this.paymentsError = 'An error occured while trying to fetch payments.'
            }
          })
          .finally(() => this.loading = false)
      },

      clearPayments() {
        this.$store.commit('dashboard/payments/clearPayments')
      },

      clearFilters() {
        this.$store.commit('dashboard/payments/clearFilters')
      }
    },

    created() {
      this.fetchPayments()
    },

    destroyed() {
      this.clearPayments()
      this.clearFilters()
    }
  }
</script>

