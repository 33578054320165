<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1"
      y="19"
      width="18"
      height="18"
      rx="1"
      transform="rotate(-90 1 19)"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.63281 9.99349L14.377 9.99349Z"
      fill="currentColor"
    />
    <path
      d="M5.63281 9.99349L14.377 9.99349"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M9.13433 13.4863L5.625 9.99974L9.13433 6.51316"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: "ArrowInBox"
};
</script>

