<template>
  <label class="flex cursor-pointer my-1" :class="[alignItems]">
    <input
      type="radio"
      class="form-radio text-pink-500 border-gray-400"
      :class="[backgroundColor, borderWidth, radioButtonSize]"
      :name="name"
      :value="value"
      @input="$emit('change', $event.target.value)"
      :checked="checked ? 'checked' : ''"
    />
    <slot name="content"></slot>
    <span v-if="title" class="ml-3" :class="[textColor, fontWeight, hover, fontSize]">
      {{ title }}
    </span>
  </label>
</template>

<script>
export default {
  name: 'Radiobutton',
  props: {
    value: [String, Number, Array],
    title: String,
    name: String,
    checked: Boolean,
    textColor: { type: String, required: false, default: ''},
    fontSize: { type: String, required: false, default: 'text-base'},
    fontWeight: { type: String, required: false, default: 'font-light'},
    hover: { type: String, required: false, default: ''},
    backgroundColor: { type: String, required: false, default: ''},
    borderWidth: { type: String, required: false, default: 'border-2'},
    alignItems: { type: String, required: false, default: 'items-center'},
    radioButtonSize: { type: String, required: false, default: 'w-4 h-4'},
  },
}
</script>