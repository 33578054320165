<template>
  <div id="invitation"
    class="w-full p-5 xl:rounded-full rounded bg-indigo-gradiant text-white flex flex-wrap xl:flex-no-wrap items-center xl:justify-between relative">
    <span class="hidden xl:block w-56 bg-transparent"></span>
    <span class="bg-yellow-600 px-2 py-1 text-black xl:absolute left-0 top-0 xl:mt-6 xl:-ml-10 text-sm">REFER A FRIEND, GET 7%</span>
    <p class="mr-8 xl:text-lg py-3 xl:py-0 w-full xl:w-auto xl:text-center">For every person you refer, you will earn 7% of the transactions they book on Guestio.</p>
    <modal>
      <span class="px-6 bg-white rounded-full text-pink-500 py-2 font-semibold whitespace-no-wrap">Invite a friend</span>
      <div slot="content">
        <div class="bg-white rounded-xl p-3">
          <div class="font-semibold mb-8">Invite a friend</div>
          <div class="flex">
            <label class="text-sm text-indigo-500 mb-0 mr-3" for="referral_link">
              Referral link
            </label>
            <button
              ref="btnRef"
              v-on:mouseenter="toggleTooltip()"
              v-on:mouseleave="toggleTooltip()"
              style="transition:all .15s ease"
              v-clipboard:copy="referralUrl"
              v-clipboard:success="onCopy"
              v-clipboard:error="onError"
            >
              <CopyIcon/>
              <div ref="tooltipRef" v-bind:class="{'hidden': !tooltipShow, 'block': tooltipShow}" class="ml-3 block z-50 max-w-xs text-left no-underline break-words rounded-full">
                <div class="bg-indigo-500 text-white text-xs py-2 px-3 mb-0 rounded-full">
                  {{copyMessage}}
                </div>
              </div>
            </button>
          </div>
          <small class="block text-gray-500 mt-3 mb-4">Copy your personal referral link and share it with your friends.</small>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>

import anime from "animejs";
import Modal from '@/components/shared/Modal';
import CopyIcon from "@/components/svgs/CopyIcon";
import Popper from "popper.js";

  export default {
    name: "Invitation",
    components: { Modal, CopyIcon },

    data() {
      return {
        copyMessage: 'Copy to clipboard',
        tooltipShow: false
      }
    },

    mounted(){
      anime({
        targets:'#invitation',
        opacity:[0, 1],
        delay:400,
        duration:2000,
        easing: 'cubicBezier(0.420, 0.000, 1.000, 1.000)',
      })
    },

    methods: {
      onCopy() {
        this.copyMessage = 'Copied!'
      },
      onError() {
        alert(`Failed to copy. Referral link is: ${this.referralUrl}`)
      },

      toggleTooltip(){
        if(this.tooltipShow){
          this.tooltipShow = false;
        } else {
          this.tooltipShow = true;
          new Popper(this.$refs.btnRef, this.$refs.tooltipRef, {
            placement: "right"
          });
        }
      },
    },

    computed: {
      user() {
        return this.$store.getters["auth/getUser"];
      },

      referralUrl() {
        return `${process.env.VUE_APP_URL}?ref=${this.user.id}`
      }
    }
  }
</script>

<style scoped>

</style>

