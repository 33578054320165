<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <rect width="18" height="18" fill="url(#pattern0)" />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="18"
    >
      <rect width="18" height="18" fill="url(#pattern1)" />
    </mask>
    <g mask="url(#mask0)">
      <rect width="18" height="18" fill="currentColor" />
    </g>
    <defs>
      <pattern
        id="pattern0"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlink:href="#image0" transform="scale(0.0104167)" />
      </pattern>
      <pattern
        id="pattern1"
        patternContentUnits="objectBoundingBox"
        width="1"
        height="1"
      >
        <use xlink:href="#image0" transform="scale(0.0104167)" />
      </pattern>
      <image
        id="image0"
        width="96"
        height="96"
        xlink:href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAAAXNSR0IArs4c6QAAAyhJREFUeAHtmz1rFUEUhuNXqaKC2CgB01iIWAiCIFrYaGXnz8hPyB+wk5Q2VjaKtY2FjaJgqSLGoJjGNqKFxHPAwOKdmeSys+edu/sMHLLM2Z33zvPeMzvZTZaWaBCAAAQgMFUCB0Y48es2p5sW1yxOWxy3OGZx0uL/9sM6Pln87iQ27XjDwnMPOv0cFghcttwTi58WO5XivY1D24PARcs/tagFvTvOhz20J532pXPN4o9FF1rNYwwwuKnma/lzi5qwU2OFGHA4NcOG+/ym+spiOeAzuimDt4ODK9QTOGRDPbNYrjdkcSRf5gZvi2TAfaNxdXAiCCQJ3LLe1Do9ZB/b0I4V7wQGhNyEF2EJumPwL3XM4DCYwGvTG3KpyY0dsgS1XgG+7bwSbPiuHLsgI3F7l8ZYf7b+i9iNyuA3bTx/+vnV4puFP8rINX8aOvnmkHJr9Dz9D22cM5OnOSeAo5Xg351Tl9P/EThXwYDHrdNseRd0qgK89QpjDDpEywac6Dlzv8G+7DnG4JeP2QDf7ZR2OYPD3Y9AywYc2c8ECuf8KuSaSbVsQF9Ivk1tvo3ZgJBHCX0dHrMBfdmEXI8BIZjzIhiQZxOSwYAQzHkRDMizCclgQAjmvAgG5NmEZDAgBHNeJOqN2CP7CGfzHyOZ8ffBfdqKXfyixwCrdq3/Ocwo2kebxTxvsFo493wEeZagPOUv+VS9DAakWfrL+5BH2RiQNmAj3V2/FwPSTDEgzSWs93OUEhWQJk0FpLmE9WJAGOq0EAakuYT0+sv8rRAlE+EeMEs67Nvv0hiAAbMExD1hW1CfJxUw63boEhT1ONr/u/377FyLPf44+kLxjHJy29Jvyqcks2+TvRPsvGdz3ukRC/EsnyVI/M3GAAwQExDLUwEYICYglqcCMEBMQCxPBWCAmIBYngrAADEBsTwVgAFiAmJ5KgADxATE8lQABogJiOWpAAwQExDLUwEYICYglqcCMEBMQCxPBWCAmIBYngrAADEBsTwVgAFiAmJ5KgADxATE8lQABogJiOWpAAwQExDLUwEYICYglqcCMEBMAHkIQAACEICAiMBf1CNUwRIAOuwAAAAASUVORK5CYII="
      />
    </defs>
  </svg>
</template>

<script>
export default {
  name: "FacebookIcon"
};
</script>

<style scoped></style>
