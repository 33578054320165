import api from '@/api'
import availability from './availability'

const guest = {
  namespaced: true,

  state: {
    guest: {},
    guests: [],
    pagination: {
      total: 0,
      count: 0,
      per_page: 15,
      current_page: 1,
      total_pages: 1,
      links: {}
    },
    priceStats: {
      cheapest_guest_price: 0,
      expensive_guest_price: 1000,
    },
  },

  getters: {
    getGuest: state => state.guest,
    getGuests: state => state.guests,
    pagination(state) {
      return state.pagination
    },
  },

  actions: {
    fetchGuest({ commit }, payload) {
      let url = `/guests/${payload.id}`;
      if (payload.includes) {
        url += `?include=${payload.includes}`
      }
      return api.get(url)
        .then(({ data }) => {
          commit('setGuest', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    create({ commit }, payload) {
      return api.post('/guests', payload)
        .then(({ data }) => {
          commit('setGuest', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    guests({ commit }, payload) {
      let url = `/guests?page=${payload.page}`;
      if (payload.tags) {
        url += `&filter[tag]=${payload.tags}`
      }
      if (payload.categories) {
        url += `&filter[category]=${payload.categories}`
      }
      if (payload.name) {
        url += `&filter[guest_profiles.name]=${payload.name}`
      }
      if (payload.prices) {
        url += `&filter[prices]=${payload.prices}`
      }
      if (payload.rating) {
        url += `&filter[rating]=${payload.rating}`
      }
      if (payload.featured) {
        url += `&filter[featured]=${payload.featured}`
      }
      if (payload.isGuestioTalent) {
        url += `&filter[is_guestio_talent]=${payload.isGuestioTalent}`
      }
      if (payload.premium) {
        url += `&filter[premium]=${payload.premium}`
      }
      if (payload.sortBy) {
        url += `&sort=${payload.sortBy}`
      }
      if (payload.options) {
        url += `&filter[options]=${payload.options}`
      }
      if (payload.limit) {
        url += `&limit=${payload.limit}`
      }

      return api.get(url)
        .then(({ data }) => {
          commit('setGuests', data.data)
          commit('setPagination', data.meta)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateGuest({ commit }, guest) {
      return api.put(`/guests/${guest.id}`, guest)
        .then(({ data }) => {
          commit('setGuest', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    deleteMedia(context, payload) {
      return api.delete(`/guests/${payload.model_id}/media/${payload.uuid}`)
        .then(() => {
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchGuestPriceStats({ commit }) {
      return api.get('/guests/price-stats')
        .then(({ data }) => {
          commit('setPriceStats', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchGuestLinks({ commit }, payload) {
      let url = `/guests/${payload.id}/links`;
      if (payload.excludes) {
        url += `?exclude=${payload.excludes}`
      }
      return api.get(url)
        .then(({ data }) => {
          commit('setGuestLinks', { data: data.data, type: payload.type})
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchBookingQuestions(context, guestId) {
      return api.get(`/guests/${guestId}/booking-questions`)
    },

    fetchPitchQuestions(_, guestId) {
      return api.get(`/guests/${guestId}/booking-questions?type=pitch`);
    },

    fetchBookingFlowPreferences(context, guestId) {
      return api.get(`/guests/${guestId}/booking-flow-preferences`)
    },

    updateBookingFlowPreferences(context, params) {
      return api.put(`/guests/${params.guestId}/booking-flow-preferences`, {updatedPreferences: params.updatedPreferences})
    },

    storeBookingQuestion(context, payload) {
      return api.post(`/guests/${payload.guestId}/booking-questions`, payload)
    },

    updateBookingQuestion(context, payload) {
      return api.put(`/guests/${payload.guestId}/booking-questions/${payload.questionId}`, payload)
    },

    deleteBookingQuestion(context, payload) {
      return api.delete(`/guests/${payload.guestId}/booking-questions/${payload.questionId}`)
    },

    fetchUpsellPackages(context, guestId) {
      return api.get(`/guests/${guestId}/upsell-packages`)
    },

    storeUpsellPackage(context, payload) {
      return api.post(`/guests/${payload.guestId}/upsell-packages`, payload)
    },

    updateUpsellPackage(context, payload) {
      return api.put(`/guests/${payload.guestId}/upsell-packages/${payload.packageId}`, payload)
    },

    deleteUpsellPackage(context, payload) {
      return api.delete(`/guests/${payload.guestId}/upsell-packages/${payload.packageId}`)
    },

    storeProfileReach(context, payload) {
      return api.post(`/guests/${payload.guestId}/profile-reach`, payload)
    },

    fetchProfileReach(context, guestId) {
      return api.get(`/guests/${guestId}/profile-reach`)
    },

    deleteProfileReach(context, payload) {
      return api.delete(`/guests/${payload.guestId}/profile-reach/${payload.platform}`)
    },

    fetchPitchSettings(context, guestId) {
      return api.get(`/guests/${guestId}/pitch-settings`);
    },

    updatePitchSettings(context, params) {
      return api.put(`/guests/${params.modelId}/pitch-settings`, params);
    },
  },

  mutations: {
    setGuest(state, data) {
      state.guest = {
        ...state.guest,
        ...data
      }
    },

    setGuestLinks(state, payload) {
      if (payload.type) {
        if (!state.guest.links) {
          state.guest.links = {};
        }
        state.guest.links[payload.type] = payload.data[payload.type];
      } else {
        state.guest = {
          ...state.guest,
          links: payload.data
        }
      }
    },

    clearGuest(state) {
      state.guest = {}
    },

    setGuests(state, data) {
      state.guests = data;
    },

    setPagination(state, data) {
      if (data.pagination) {
        state.pagination = data.pagination;
      }
    },

    setPriceStats(state, data) {
      if (data.cheapest_guest_price) {
        state.priceStats.cheapest_guest_price = data.cheapest_guest_price;
      }
      if (data.expensive_guest_price) {
        state.priceStats.expensive_guest_price = data.expensive_guest_price;
      }
    },

    setPronunciation(state, pronunciation) {
      state.guest.pronunciation = pronunciation
    }
  },

  modules: {
    availability,
  }
}

export default guest
