<template>
  <div class="flex flex-col md:flex-row ">
    <div class="w-64 flex-shrink-0">
      <label class="text-gray-600" for="phone">Notifications</label>
    </div>
    <div class="md:ml-8 flex-1 mt-1 md:mt-0">
      <div v-if="! edit" class="w-full flex items-center justify-between">
        <div class="w-full space-y-4">
          <div class="flex justify-between">
            <span>Email notifications</span>
            <span>
              <span v-if="userProp.email_notifications" class="text-green-600">Enabled</span>
              <span v-else class="text-red-600">Disabled</span>
            </span>
          </div>
          <div class="flex justify-between">
            <span>Sms notifications</span>
            <span>
              <span v-if="userProp.sms_notifications" class="text-green-600">Enabled</span>
              <span v-else class="text-red-600">Disabled</span>
            </span>
          </div>
        </div>
        <Edit @edit="edit = true"/>
      </div>
      <div v-else class="w-full">
        <ValidationObserver ref="notificationsForm" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(update)" method="post">
            <div class="space-y-6">
              <div>
                <label class="cursor-pointer select-none flex items-center">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-pink-500 disabled:opacity-50" v-model="form.email_notifications" :disabled="! userProp.has_team">
                  <span class="ml-2">
                    Email notifications
                  </span>
                </label>
              </div>
              <div>
                <label class="cursor-pointer select-none flex items-center">
                  <input type="checkbox" class="form-checkbox h-5 w-5 text-pink-500" v-model="form.sms_notifications">
                  <span class="ml-2">
                    Sms notifications
                  </span>
                </label>
              </div>
            </div>
            <div class="mt-4 flex items-center justify-end">
              <SaveOrCancel @cancel="edit = false" :working="working"/>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
  </div>
</template>

<script>
  import api from "@/api";
  import Edit from "@/components/shared/Edit";
  import SaveOrCancel from "@/components/shared/SaveOrCancel";

  export default {
    props: {
      userProp: Object,
      admin: {
        type: Boolean,
        default: false,
      },
    },

    components: {Edit, SaveOrCancel},

    data() {
      return {
        working: false,
        edit: false,
        members: [],
        form: {
          email_notifications: this.userProp.email_notifications,
          sms_notifications: this.userProp.sms_notifications,
        }
      }
    },

    watch: {
      edit(edit) {
        if (edit) {
          this.fetchTeamMembers()
          return
        }

        this.form.email_notifications = this.userProp.email_notifications
        this.form.sms_notifications = this.userProp.sms_notifications
      }
    },

    computed: {
      updateAction() {
        if (this.admin) {
          return `auth/updateAdminUser`
        }

        return `auth/updateUser`
      },

      hasTeamMembersWithEmailNotificationTurnedOn() {
        return !! this.members
          .filter(user => user.pivot.email_notifications)
          .length
      }
    },

    methods: {
      update() {
        if (! this.form.email_notifications && this.userProp.has_team && ! this.hasTeamMembersWithEmailNotificationTurnedOn) {
          this.$toast.error('At least one team member needs to have the email notifications turned on')
          return
        }

        this.working = true

        this.$store.dispatch(this.updateAction, this.form)
          .then(() => {
            this.working = false
            this.edit = false
          })
          .catch(error => {
            this.working = false
            if (error.response) {
              this.$refs.notificationsForm.setErrors(error.response.data.errors)
            }
          })
      },

      fetchTeamMembers() {
        api
          .get('/users/me/team/members')
          .then(({ data }) => {
            this.members = data.data
          })
      }
    }
  }
</script>
