<template>
  <div class="w-full bg-gray-50 absolute left-0 right-0 bottom-0 p-6 flex items-center justify-between">
    <div class="bg-white rounded-3xl px-6 py-3 flex items-center w-full mr-4" :class="{'bg-red-100': sendingError}">
      <textarea-autosize
        v-model="body"
        :min-height="25"
        :max-height="300"
        rows="1"
        style="min-height:25px"
        class="w-full bg-transparent"
        name="text"
        id="text"
        placeholder="Type a Message..."
        />
    </div>
    <button
      @click="sendMessage"
      type="button"
      class="text-white p-4 flex justify-center items-center rounded-full"
      :disabled="disabled"
      :class="disabled ? 'bg-gray-700 cursor-not-allowed' : 'bg-indigo-gradiant'"
    >
      <SendIcon class="flex-shrink-0 w-5 h-5"/>
    </button>
  </div>
</template>
<script>
import SendIcon from "@/components/svgs/SendIcon";

export default {
  name: 'ConversationFooter',
  components: {SendIcon},

  props:{
    booking: Object,
  },

  data() {
    return {
      sending: false,
      sendingError: false,
      body: '',
    }
  },
  
  methods: {
    sendMessage() {
      this.sending = true
      this.sendingError = false
      this.$store.dispatch('dashboard/bookings/sendMessage', {
        body: this.body,
        id: this.booking.id
      }).then(() => {
        this.body = ''
        this.$emit('sent')
      }).catch(() => {
        this.sendingError = true
      }).finally(() => this.sending = false)
    }
  },

  computed: {
    charCount() {
      return this.body.length
    },
    disabled() {
      return this.charCount == 0 || this.sending
    },
  },
}
</script>
