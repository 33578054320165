<template>
  <div :class="{'opacity-50': discarding}">
    <div>Do you want to schedule this Booking for the <span class="font-bold text-pink-500">{{ $moment(activity.properties.value).format('Do of MMMM, h:mm a') }}</span> ({{ user.timezone || 'US/Central' }})?</div>
    <div class="mt-2 flex space-x-2">
      <button type="button" @click="changeBookingDate($moment(activity.properties.value))" class="bg-purple-50 rounded-full text-white text-sm font-bold px-10 py-2 disabled:opacity-60" :disabled="working">
        <loading-icon v-if="working" class="h-2" />
        <span v-else>Yes</span>
      </button>
      <modal type="button" class="bg-white border border-purple-50 text-purple-50 rounded-full text-sm font-semibold px-6 py-2">
        Pick a different Date/Time
        <template v-slot:content="{ close }">
          <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden self-start my-4">
            <calendar @close="close" :single="true" @save="changeBookingDate($event, close)" />
          </div>
        </template>
      </modal>

      <button type="button" @click="discardActivity" class="text-purple-50 text-sm px-2 py-2 hover:underline">
        Discard this
      </button>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import Modal from '@/components/shared/Modal'
  import Calendar from '@/components/bookings/individual/Calendar'

  export default {
    props: {
      activity: Object,
    },

    data() {
      return {
        working: false,
        discarding: false,
      }
    },

    components: {
      Modal, Calendar
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      user() {
        return this.$store.getters['auth/getUser']
      }
    },

    methods: {
      changeBookingDate(date, callback) {
        this.working = true

        this.$store.dispatch('dashboard/bookings/changeBookingDate', {
          id: this.$route.params.bookingId,
          confirmed_date: date.toISOString(),
        }).then(() => {
          this.$emit('action-taken')
          this.working = false
          callback.call()
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
        })
      },

      discardActivity() {
        this.discarding = true

        api.delete(`/bookings/${this.$route.params.bookingId}/activities/${this.activity.id}`)
          .then(() => this.$emit('action-taken'))
      }
    }
  }
</script>
