import api from '@/api'
import moment from 'moment'

const bookings = {
  namespaced: true,

  state: {
    filters: {
      month: moment().format('MMMM YYYY'),
      search: '',
      statuses: [],
      amounts: [],
      bookedBy: 'me',
    },
    activeFilters: [],

    sorting: '-booking_date',

    bookings: [],
    bookingsPagination: null,
    withoutFilters: null,

    booking: null,

    bookingActivities: [],

    bookingMessages: [],

    showCalendarModal: false,

    calendarView: false,

    calendarModalData: {},

    bookingReviews: {},

    previousPage: 'profile',
  },

  getters: {
    bookings: (state) => state.bookings,

    booking: (state) => state.booking,

    bookingActivities: (state) => state.bookingActivities,

    bookingHasProposedDates: ({ booking }) => {
      if (! booking) {
        return false
      }

      return Object.keys(booking.proposed_dates).length
    },

    bookingMessages: (state) => state.bookingMessages,

    userOwnsBookingGuest: (state, getters, rootState) => {
      if (!rootState.auth.user.id) {
        return false
      }

      if (!state.booking) {
        return false
      }

      return state.booking.guest.user_id == rootState.auth.user.id
    },

    userOwnsBookingShow: (state, getters, rootState) => {
      if (!rootState.auth.user.id) {
        return false
      }

      if (!state.booking) {
        return false
      }

      return state.booking.show.user_id == rootState.auth.user.id
    },

    userIsInvitee: ({ booking }, getters, rootState) => {
      if (!rootState.auth.user.id) {
        return false
      }

      if (!booking) {
        return false
      }

      return (booking.initiator == 'show' && booking.guest.user_id == rootState.auth.user.id)
        || (booking.initiator == 'guest' && booking.show.user_id == rootState.auth.user.id)
    },

    userIsInitiator: ({ booking }, getters, rootState) => {
      if (!rootState.auth.user.id) {
        return false
      }

      if (!booking) {
        return false
      }

      return (booking.initiator == 'show' && booking.show.user_id == rootState.auth.user.id)
        || (booking.initiator == 'guest' && booking.guest.user_id == rootState.auth.user.id)
    },

    initiator: (state) => {
      if (state.booking.initiator === 'guest') {
        return state.booking.guest;
      }

      return state.booking.show;
    },

    invitee: (state) => {
      if (state.booking.initiator === 'guest') {
        return state.booking.show;
      }

      return state.booking.guest;
    },

    date(state) {
      if (!state.booking) {
        return false
      }

      if (state.booking.confirmed_date) {
        return moment(state.booking.confirmed_date)
      }

      return moment(state.booking.created_at)
    },

    bookingStatus(state) {
      if (!state.booking) {
        return 'unknown'
      }

      if (state.booking.is_completed) {
        return 'completed'
      } else if (state.booking.is_canceled) {
        return 'canceled'
      } else if (state.booking.is_confirmed) {
        return 'confirmed'
      } else if (state.booking.is_expired) {
        return 'expired'
      }


      return 'pending'
    },

    showReview: state => state.bookingReviews.show_rating,

    guestReview: state => state.bookingReviews.guest_rating,

    filters: state => state.filters,

    activeFilters: state => state.activeFilters,

    monthFilter: state => state.filters.month,

    bookedByFilter: state => state.filters.bookedBy,

    filteredBookings(state) {
      return state.bookings.filter(booking => {
        return booking.guest.name.toLowerCase().includes(state.filters.search.toLowerCase())
          || booking.show.name.toLowerCase().includes(state.filters.search.toLowerCase())
      })
    },

    previousPage: state => state.previousPage,
  },

  actions: {
    fetchBookings({commit}, payload = {}) {
      let url = `/bookings?page=${payload.page}`;
      if (payload.status) {
        url += `&filter[status]=${payload.status}`
      }
      if (payload.price) {
        url += `&filter[price]=${payload.price}`
      }
      if (payload.month) {
        url += `&filter[month]=${payload.month}`
      }
      if (payload.search) {
        url += `&filter[search]=${payload.search}`
      }
      if (payload.bookedBy) {
        url += `&filter[bookedBy]=${payload.bookedBy}`
      }
      if (payload.limit) {
        url += `&limit=${payload.limit}`
      }

      return api.get(url).then(({data}) => {
        if (payload.withoutFilters) {
          commit('setAllBookings', data)
        } else {
          commit('setBookings', data.data)
        }
        commit('setBookingsPagination', data.meta.pagination)
        return Promise.resolve(data.data)
      })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchCalendarBookings({commit}, payload) {
      // todo: apply filters and sorting query params as needed
      return api.get('/bookings/calendar-view', {
        params: payload
      }).then(({ data }) => {
        commit('setBookings', data.data)
        return Promise.resolve(data.data)
      })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchBooking({commit}, payload) {
      return api.get(`/bookings/${payload.id}?include=upsellPackages,miniviewQuestions,questionAnswers`)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    confirmScheduled({commit}, id) {
      return api.post(`/bookings/${id}/confirm-scheduled`)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    undoScheduledOutside({commit}, id) {
      return api.post(`/bookings/${id}/undo-scheduled-outside`)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    confirmHappened({commit}, id) {
      return api.post(`/bookings/${id}/confirm-happened`)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchBookingActivities({commit}, payload) {
      return api.get(`/bookings/${payload.id}/activities?sort=${payload.sort}`)
        .then(({data}) => {
          commit('setBookingActivities', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    fetchBookingMessages({commit}, payload) {
      return api.get(`/bookings/${payload.id}/messages`)
        .then(({data}) => {
          commit('setBookingMessages', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    sendMessage({commit}, payload) {
      return api.post(`/bookings/${payload.id}/messages`, {
        body: payload.body
      }).then(({data}) => {
        commit('addMessage', data.data)
        return Promise.resolve(true)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    markMessagesAsRead(context, payload) {
      return api.patch(`/bookings/${payload.id}/messages/read`)
    },

    rescheduleBooking({commit}, payload) {
      return api.patch(`/bookings/${payload.id}/reschedule`, payload)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    confirmBooking({commit}, payload) {
      return api.patch(`/bookings/${payload.id}/confirm`, payload)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    rejectBooking({commit}, payload) {
      return api.patch(`/bookings/${payload.id}/reject`, payload)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    changeBookingDate({commit}, payload) {
      return api.patch(`/bookings/${payload.id}/change-date`, payload)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    acceptBooking({commit}, payload) {
      return api.patch(`/bookings/${payload.id}/accept`)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    acceptPitch({ commit }, payload) {
      return api.patch(`/bookings/${payload.id}/accept-pitch`, payload)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updatePitchFee({ commit }, payload) {
      return api.patch(`/bookings/${payload.id}/update-pitch-fee`, payload)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    processExtraPayment({ commit }, payload) {
      return api.patch(`/bookings/${payload.id}/charge-extra-payment`, payload)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        });
    },

    cancelBooking({commit}, payload) {
      return api.patch(`/bookings/${payload.id}/cancel`, payload)
        .then(({data}) => {
          commit('setBooking', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    setBookingProposedDates(context, payload) {
      return api.patch(`/bookings/${payload.id}/propose-date`, payload)
    },

    fetchReviews({ commit }, payload) {
      return api.get(`/bookings/${payload.id}/reviews`)
        .then(({ data }) => {
          commit('setReviews', data.data)
          return Promise.resolve(true)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    storeGuestReview({ commit }, payload) {
      return api.post(`/guests/${payload.guest_id}/reviews`, payload)
        .then(({ data }) => {
          commit('setGuestReview', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateGuestReview({ commit }, payload) {
      return api.put(`/guests/${payload.guest_id}/reviews/${payload.review_id}`, payload)
        .then(({ data }) => {
          commit('setGuestReview', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    storeShowReview({ commit }, payload) {
      return api.post(`/shows/${payload.show_id}/reviews`, payload)
        .then(({ data }) => {
          commit('setShowReview', data.data)
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    updateShowReview({ commit }, payload) {
      return api.put(`/shows/${payload.show_id}/reviews/${payload.review_id}`, payload)
        .then(({ data }) => {
          commit('setShowReview', data.data)
          return Promise.resolve(data)
        })
        .catch(error => Promise.reject(error))
    },

    confirmCompletedBooking(context, payload) {
      return api.patch(`/bookings/${payload.id}/completed-action/confirm`, payload)
        .then(({data}) => Promise.resolve(data))
        .catch(error => Promise.reject(error))
    },

    rescheduleCompletedBooking(context, payload) {
      return api.patch(`/bookings/${payload.id}/completed-action/reschedule`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    cancelCompletedBooking(context, payload) {
      return api.patch(`/bookings/${payload.id}/completed-action/cancel`, payload)
        .then(({data}) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    },

    setBookingsAsSeen() {
      return api.patch(`/bookings/see-bookings`, )
        .then(({data}) => {
          return Promise.resolve(data)
        })
        .catch(error => {
          return Promise.reject(error)
        })
    }
  },

  mutations: {
    setBookings(state, data) {
      state.bookings = data
    },

    setAllBookings(state, data) {
      state.withoutFilters = data
    },

    setBooking(state, data) {
      state.booking = data
    },

    setBookingActivities(state, data) {
      state.bookingActivities = data
    },

    setBookingMessages(state, data) {
      state.bookingMessages = data
    },

    addMessage(state, data) {
      state.bookingMessages.push(data)
    },

    setCalendarModal(state, data) {
      state.showCalendarModal = data.showCalendarModal;
    },

    setCalendarView(state, data) {
      state.calendarView = data.calendarView;
    },

    setReviews(state, data) {
      state.bookingReviews = data
    },

    setGuestReview(state, data) {
      state.bookingReviews.guest_rating = data
    },

    setShowReview(state, data) {
      state.bookingReviews.show_rating = data
    },

    clearRatings(state) {
      state.bookingReviews = {}
    },

    setFilters(state, data) {
      state.filters = data
    },

    clearFilters(state) {
      state.filters = {
        month: moment().format('MMMM YYYY'),
        search: '',
        statuses: [],
        amounts: [],
      }
      state.activeFilters = []

      state.sorting = '-booking_date'
    },

    setCalendarModalData(state, data) {
      state.calendarModalData = data
    },

    setBookingsPagination(state, data) {
      state.bookingsPagination = data
    },

    setPreviousPage(state, page) {
      state.previousPage = page;
    },
  },
}

export default bookings
