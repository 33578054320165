<template>
  <div>
    <div class="mt-6 bg-guestio-gray-100 p-6 lg:px-8 rounded-lg">
      <div class="header flex justify-between items-center">
        <div class="flex">
          <button @click="closeAudioPitch" type="button">
            <XIcon class="h-6 text-blue-800" />
          </button>

          <h4 class="ml-4 font-poppins text-lg font-bold text-blue-800">Record Audio</h4>
        </div>

        <div v-if="previewRecording">
          <button @click.prevent="redoRecording" type="button" class="flex items-center space-x-2 text-pink-500">
            <RedoIcon class="w-4 h-4" />
            <span>Redo</span>
          </button>
        </div>
      </div>

      <div class="mt-6">
        <div class="overflow-hidden relative pb-1/4 rounded-lg">
          <div class="absolute inset-0 z-10">
            <audio ref="audio" :class="{'opacity-50': isReadyToRecord}" class="bg-purple-200 video-js vjs-default-skin w-full h-full object-cover"></audio>
          </div>
        </div>

        <div v-if="deviceError" class="mt-4 bg-red-100 p-4 rounded-lg shadow-md flex items-center">
          <span>
            <CircleAlertIcon class="w-4" color="#e53e3e" />
          </span>
          <span class="ml-2 text-red-700 text-sm">
            Please allow access to your camera and mic to record pitch.
          </span>
        </div>

        <div v-if="sendingErrorMessage" class="mt-4 bg-red-100 p-4 rounded-lg shadow-md flex items-center">
          <span>
            <CircleAlertIcon class="w-4" color="#e53e3e" />
          </span>
          <span class="ml-2 text-red-700 text-sm">
            {{ sendingErrorMessage }}
          </span>
        </div>

        <div class="hidden">
            <audio
              ref="audioRecording"
              @play="playing = true"
              @pause="playing = false"
              @durationchange="fixDuration"
              playsinline
            ></audio>
        </div>

        <template v-if="previewRecording">
          <div class="mt-4 bg-black bg-opacity-30 h-14 rounded-lg flex items-center px-6 space-x-5">
            <div class="flex items-center">
              <button v-if="! playing" @click.prevent="$refs.audioRecording.play()" type="button" class="p-2 focus:outline-none rounded focus:shadow-outline">
                <PlayAltIcon class="h-4 w-4 text-white" />
              </button>
              <button v-else @click.prevent="$refs.audioRecording.pause(); $refs.audioRecording.currentTime = 0" type="button" class="p-2 focus:outline-none rounded focus:shadow-outline">
                <StopIcon class="h-4 w-4 text-white" />
              </button>
            </div>
            <div @click="seek" class="progress relative flex-1 h-2 bg-white rounded-full flex items-center">
              <div
                :style="{ width: this.playProgress + '%' }"
                class="absolute inset-y-0 rounded-full bg-pink-500 pointer-events-none">
              </div>
              <div
                :style="{ left: this.playProgress + '%' }"
                class="absolute w-5 h-5 rounded-full bg-white shadow -ml-2.5 pointer-events-none"></div>
            </div>
            <div class="text-sm font-poppins font-bold text-white whitespace-nowrap">
              {{ recordedSeconds | time }}
            </div>
          </div>
        </template>

        <template v-if="! previewRecording">
          <div v-if="! isRecording">
            <div v-if="audioDevices.length" class="mt-4">
              <label for="audio-input" class="text-black font-medium">
                Audio Input
              </label>
              <div class="mt-1">
                <select v-model="audioInput" id="audio-input" class="form-select w-full px-5 py-3 rounded-full focus:bg-white">
                  <option v-for="device in audioDevices" :value="device.id" :key="`audio-input-${device.id}`">
                    {{ device.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="isRecording" class="flex items-center justify-center">
            <div class="px-2 py-1 rounded-lg bg-blue-800 text-white bg-opacity-50 flex items-center">
              <span class="flex h-4 w-4 relative">
                <span class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                <span class="relative inline-flex rounded-full h-4 w-4 bg-red-600"></span>
              </span>
              <span class="ml-2 text-lg font-bold font-poppins">
                {{ recordedSeconds | time }}
              </span>
            </div>
          </div>

          <div v-if="isReadyToRecord" class="flex justify-center mt-8 relative z-10">
            <button v-if="! isRecording" @click="startRecording" type="button" class="btn hover:opacity-90 text-sm">
              Start Recording
            </button>

            <button v-else @click="stopRecording" type="button" class="bg-indigo-gradiant p-6 rounded-full flex items-center justify-center hover:opacity-90">
              <StopIcon class="h-6 w-6" />
            </button>
          </div>
        </template>
      </div>
    </div>
    <div v-if="previewRecording" class="flex flex-col md:flex-row mt-8 items-center w-full">
      <div class="flex-1 w-full ">
        <div class="rounded-full px-6 py-4 bg-gray-50 flex items-center w-full" :class="{'bg-red-100': sendingErrorMessage}">
          <input
            v-model="body"
            type="text"
            class="bg-transparent w-10 flex-grow text-sm"
            :maxlength="maxChars"
            placeholder="Type a Message..."
            ref="message">
          <span class="text-gray-500 ml-2 block flex-shrink-0 text-xs">{{ charCount }}/{{ maxChars }}</span>
        </div>
      </div>

      <div class="md:ml-4 mt-4 md:mt-0 flex items-center">
        <button @click="closeAudioPitch" type="button" class="p-4 rounded-full flex items-center justify-center hover:opacity-90 border-2 border-red-600 hover:bg-red-100 transition-colors duration-150 ease-in-out">
          <XIcon class="h-6 w-6 text-red-600"/>
        </button>

        <div class="mx-4 text-center block">
          {{ recordedSeconds | time }}
        </div>

        <template v-if="sending">
          <div class="ml-4 flex items-center justify-center">
            <loading-icon class="h-2 text-pink-500"/>
          </div>
        </template>
        <template v-else>
          <button @click="sendPitch" type="button" :disabled="recordedSeconds == 0" class="p-4 rounded-full flex items-center justify-center hover:opacity-90 border-2 border-green-600 hover:bg-green-100 transition-colors duration-150 ease-in-out disabled:opacity-50" title="Send Audio Pitch">
            <CheckIcon class="h-6 w-6 text-green-600"/>
          </button>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import 'video.js/dist/video-js.css'
  import 'videojs-record/dist/css/videojs.record.css'
  import videojs from 'video.js'
  import 'webrtc-adapter'
  // eslint-disable-next-line
  import RecordRTC from 'recordrtc'
  // eslint-disable-next-line
  import WaveSurfer from 'wavesurfer.js';
  import MicrophonePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.microphone.js';
  WaveSurfer.microphone = MicrophonePlugin;

  import 'videojs-wavesurfer/dist/css/videojs.wavesurfer.css';
  // eslint-disable-next-line
  import Wavesurfer from 'videojs-wavesurfer/dist/videojs.wavesurfer.js';

  // eslint-disable-next-line
  import Record from 'videojs-record/dist/videojs.record.js'
  import XIcon from '@/components/svgs/XIcon'
  import CheckIcon from '@/components/svgs/CheckIcon'
  import CircleAlertIcon from '@/components/svgs/CircleAlertIcon'
  import PlayAltIcon from '@/components/svgs/PlayAltIcon'
  import RedoIcon from '@/components/svgs/RedoIcon'
  import StopIcon from '@/components/svgs/StopIcon'
  import api from '@/api'
  export default {
    name: 'CreateAudioPitch',

    components: {
      XIcon, CheckIcon, CircleAlertIcon, RedoIcon, PlayAltIcon, StopIcon
    },

    data() {
      return {
        previewRecording: false,
        playing: false,
        isReadyToRecord: false,
        isRecording: false,
        deviceError: false,
        audioDevices: [],
        audioInput: null,
        currentSeconds: 0,
        recordedSeconds: 0,
        sending: false,
        sendingErrorMessage: null,
        maxChars: 300,
        body: '',
        player: null,
        timerInterval: null,
        options: {
          controls: false,
          bigPlayButton: false,
          pip: false,
          fluid: false,
          width: 640,
          height: 480,
          controlBar: {
            fullscreenToggle: false,
          },
          plugins: {
            wavesurfer: {
              backend: 'WebAudio',
              waveColor: '#C130EE',
              cursorColor: '#e9d8fd',
              progressColor: '#C130EE',
              normalize: false,
              debug: false,
              cursorWidth: 2,
              displayMilliseconds: false,
              hideScrollbar: true,
              plugins: [
                // enable microphone plugin
                WaveSurfer.microphone.create({
                  bufferSize: 4096,
                  numberOfInputChannels: 1,
                  numberOfOutputChannels: 1,
                  constraints: {
                    video: false,
                    audio: true
                  }
                })
              ]
            },
            record: {
              audio: true,
              video: false,
              maxLength: 60,
              displayMilliseconds: false,
              debug: false,
            }
          }
        }
      }
    },

    watch: {
      isRecording(isRecording) {
        if (isRecording) {
          this.startTimer()
        } else {
          this.stopTimer()
        }
      },

      playing(playing) {
        if (! this.previewRecording) {
          return
        }

        if (playing) {
          this.interval = setInterval(() => {
            this.currentSeconds = this.$refs.audioRecording.currentTime
          }, 100)
        } else {
          if (this.interval) {
            clearInterval(this.interval)
          }
        }
      }
    },

    computed: {
      charCount() {
        return this.body.length
      },

      playProgress() {
        return this.currentSeconds / this.recordedSeconds * 100;
      },

      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      userIsGuest() {
        return this.user.guest && this.user.guest.id == this.booking.guest_profile_id
      }
    },

    filters: {
      time(value) {
        if (value < 60) {
          let seconds = Math.round(value).toString().padStart(2, '0')

          return `0:${seconds}`
        }

        let minutes = Math.floor(value / 60)
        let seconds = Number(value % 60).toString().padStart(2, '0')

        return `${minutes}:${seconds}`
      }
    },

    methods: {
      redoRecording() {
        this.player.record().reset()
        this.player.record().getDevice()
        this.currentSeconds = 0
        this.recordedSeconds = 0
        this.previewRecording = false
        this.$refs.audioRecording.src = null

      },

      seek(e) {
        const el = e.target.getBoundingClientRect();
        const seekPos = (e.clientX - el.left) / el.width;

        this.currentSeconds = this.recordedSeconds * seekPos;
        this.$refs.audioRecording.currentTime = this.currentSeconds

        if (! this.playing) {
          this.$refs.audioRecording.play()
        }
      },

      setAudioInputDevice() {
        this.player.record().setAudioInput(this.audioInput)
      },

      startRecording() {
        if (! this.player) {
          return
        }

        this.player.record().start()
      },

      stopRecording() {
        if (! this.player) {
          return
        }

        this.player.record().stop()
        this.player.record().stopDevice()
      },

      closeAudioPitch() {
        this.$emit('close')
      },

      sendPitch() {
        this.sending = true
        this.sendingErrorMessage = null
        let form = new FormData

        form.append('audio', this.player.recordedData)
        form.append('message', this.body)
        form.append('sender', this.userIsGuest ? 'guest' : 'show')

        api.post(`/bookings/${this.booking.id}/audio-pitch`, form)
          .then(() => {
            window.Event.$emit('refreshActivity')
            this.$emit('close')
          })
          .catch(error => {
            if (! error.response) {
              this.sendingErrorMessage = 'The video pitch was not saved.'
              return
            }

            if (error.response.status == 426) {
              this.sendingErrorMessage = 'Only PRO users have access to this feature.'
              return
            }

            if (error.response.data.message) {
              this.sendingErrorMessage = error.response.data.message
              return
            }

            this.sendingErrorMessage = 'The video pitch was not saved.'
          })
          .finally(() => this.sending = false)
      },

      applyAudioWorkarounds() {
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
        let isEdge = /Edge/.test(navigator.userAgent)

        if (! isSafari && ! isEdge) {
          return
        }

        if (isSafari && window.MediaRecorder !== undefined) {
            // this version of Safari has MediaRecorder
            // but use the only supported mime type
            this.options.plugins.record.audioMimeType = 'audio/mp4';
        } else {
            // support recording in safari 11/12
            // see https://github.com/collab-project/videojs-record/issues/295
            // eslint-disable-next-line
            this.options.plugins.record.audioRecorderType = StereoAudioRecorder;
            this.options.plugins.record.audioSampleRate = 44100;
            this.options.plugins.record.audioBufferSize = 4096;
            this.options.plugins.record.audioChannels = 2;
        }
      },

      initPlayer() {
        this.player = videojs(this.$refs.audio, this.options, function() {
          // print version information at startup
          // var msg = 'Using video.js ' + videojs.VERSION +
          //     ' with videojs-record ' + videojs.getPluginVersion('record') +
          //     ', videojs-wavesurfer ' + videojs.getPluginVersion('wavesurfer') +
          //     ', wavesurfer.js ' + WaveSurfer.VERSION + ' and recordrtc ' +
          //     RecordRTC.version;
          // videojs.log(msg);
        });

        this.addEventListeners()

        setTimeout(() => {
          this.player.record().getDevice()
        }, 100)
      },

      addEventListeners() {
        // device is ready
        this.player.on('deviceReady', () => {
          this.deviceError = false
          this.isReadyToRecord = true
          this.player.record().enumerateDevices()
        })

        this.player.on('enumerateReady', () => {
          this.fetchDevices()
        })

        // user clicked the record button and started recording
        this.player.on('startRecord', () => {
          this.isRecording = true
        })

        this.player.on('stopRecord', () => {
          this.isRecording = false
          this.previewRecording = true
        })

        // user completed recording and stream is available
        this.player.on('finishRecord', () => {
          this.isRecording = false
          this.previewRecording = true

          this.$refs.audioRecording.src = URL.createObjectURL(this.player.recordedData)
        })

        // error handling
        this.player.on('error', (element, error) => {
          console.warn(error);
        })

        this.player.on('deviceError', () => {
          this.deviceError = true
        })
      },

      fetchDevices() {
        this.videoDevices = this.player.record().devices
          .filter((d) => d.kind === 'videoinput' && d.deviceId.length)
          .map((d) => {
            return {
              name: d.label,
              id: d.deviceId
            }
          })
        this.audioDevices = this.player.record().devices
          .filter((d) => d.kind === 'audioinput' && d.deviceId.length)
          .map((d) => {
            return {
              name: d.label,
              id: d.deviceId
            }
          })
      },

      startTimer() {
        this.recordedSeconds = 0
        this.timerInterval = setInterval(() => {
          this.recordedSeconds++
        }, 1000)
      },

      stopTimer() {
        if (! this.timerInterval) {
          return
        }

        clearInterval(this.timerInterval)
      },

      fixDuration(event) {
        if (event.target.duration != Infinity) {
          this.$refs.audioRecording.currentTime = 0
          this.recordedSeconds = event.target.duration
          return
        }

        this.$refs.audioRecording.currentTime = 999999
      }
    },

    mounted() {
      this.applyAudioWorkarounds()
      this.initPlayer()
    },

    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }

      if (this.interval) {
        clearInterval(this.interval)
      }

      if (this.timerInterval) {
        clearInterval(this.timerInterval)
      }
    }
  }
</script>
