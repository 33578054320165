<template>
  <div class="w-full lg:h-24 bg-gray-50 flex items-center shadow-lg">
    <nav class="nav-container custom-scrollbar violet-scrollbar">
      <router-link class=" dashboard-nav-button" :to="{ name: 'Dashboard' }">
        Dashboard
      </router-link>
      <router-link class="dashboard-nav-button flex justify-center items-center flex-shrink-0" :to="{ name: 'Bookings' }">
        <span class="mr-2">Bookings</span>
        <span v-if="bookingNotificationCount > 0" class="rounded-full bg-red-500 text-white px-3 text-sm text-center inline-block">
          {{ bookingNotificationCount }}
        </span>
      </router-link>
      <router-link class="dashboard-nav-button flex-shrink-0" :to="{ name: 'Miniviews' }">MINIVIEW™</router-link>
      <router-link class="dashboard-nav-button flex-shrink-0" :to="{ name: 'Wishlist' }">Wishlist
      </router-link>
      <router-link class="dashboard-nav-button" :to="{ name: 'Payments' }">Payments
      </router-link>
      <router-link class="dashboard-nav-button" :to="{ name: 'Details' }">Profiles
      </router-link>
      <router-link class="dashboard-nav-button flex-shrink-0" :to="{ name: 'Settings' }">Account settings
      </router-link>
      <!-- <router-link class="dashboard-nav-button flex justify-center items-center" :to="{ name: 'GeneralMessages' }">Messages
        <span v-if="messages && messages.length" class="rounded-full bg-red-500 text-white px-3 ml-2">
          {{ messages.length }}
        </span>
      </router-link> -->
    </nav>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SubHeader',

  computed: {
    ...mapState({
      bookings: (state) => state.dashboard.bookings.withoutFilters,
      // messages: (state) => state.user.unreadMessages,
    }),

    bookingNotificationCount() {
      let newBookings = 0;

      if (! this.bookings) {
        return newBookings;
      }

      this.bookings.data.forEach(booking => {
        if(booking.new) {
          newBookings += 1;
        }
      });

      return newBookings;
    },
  },
};
</script>

<style scoped>
.nav-container {
  @apply w-full lg:container mx-auto flex overflow-x-auto  xl:justify-center;
}

.router-link-exact-active,
.router-link-active {
  @apply text-pink-500 bg-white;
}

.dashboard-nav-button {
  @apply inline-block text-center transition duration-500 items-center p-8 lg:p-10;
}

.dashboard-nav-button:hover {
  @apply bg-white;
}

.custom-scrollbar::-webkit-scrollbar {
  height: 5px;
}

</style>
