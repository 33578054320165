<template>
  <modal
    :with-close-button="false"
  >
    Accept
    <template v-slot:content="{ close }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <div class="bg-white px-6 py-6 sm:px-12 sm:py-10">
          <div>
            <h3 class="font-bold text-2xl text-blue-800">
              Confirm payment
            </h3>
          </div>

          <form method="post" class="block mt-6" @submit.prevent="preparePayment(close)">
            <div>
              <template v-if="toBeChargedAmount > 0">
                <template v-if="paymentMethods.length && useExistingPaymentMethod">
                  <div class="text-black font-medium">Payment method</div>
                  <ExistingPaymentMethodCard :paymentMethod="paymentMethodToUse" />
                  <div class="mt-4">
                    <button @click.prevent="useExistingPaymentMethod = null" type="button" class="text-pink-500 underline">
                      Use different payment method
                    </button>
                  </div>
                </template>

                <template v-else>
                  <CreditCard @saveCard="setSaveCard" @cardComplete="setCardComplete"/>

                  <div v-if="!useExistingPaymentMethod" class="space-y-2 mt-12 max-h-60 overflow-y-scroll">
                    <UseExistingPaymentMethodButton
                      v-for="(paymentMethod, index) in paymentMethods"
                      :key="`payment-methods-${index}`"
                      :paymentMethod="paymentMethod"
                      @onClick="useExistingPaymentMethod = paymentMethod.id"
                    />
                  </div>
                </template>
              </template>

              <div class="mt-6" v-if="guestioCashBalance > 0 && totalPrice > 0">
                <div>
                  <div v-if="useGuestioCash" class="text-black font-medium mb-4">Payment method</div>
                  <label class="cursor-pointer select-none flex items-center">
                    <input type="checkbox" v-model="useGuestioCash" class="form-checkbox h-5 w-5 text-pink-500">
                    <span class="ml-2"> Use Guestio Cash <span class="font-semibold">(${{ (guestioCashAmount / 100).toFixed(0) }})</span></span>
                  </label>
                </div>
              </div>

              <div v-if="paymentSetupError" class="mt-6 bg-red-100 px-4 py-2 rounded-lg">
                <p class="text-sm text-red-700 font-bold">Payment error</p>
                <p v-if="errorDetails" class="mt-1 text-red-600 text-sm">
                  {{ errorDetails }}
                </p>
              </div>
            </div>

            <div class="flex items-center flex-col mt-8 space-y-6">
              <button type="submit" class="font-semibold rounded-full h-12 bg-indigo-gradiant text-white text-base sm:text-xl w-full flex justify-center items-center disabled:opacity-50" :disabled="working || ! formComplete">
                <span v-if="working">
                  <loading-icon class="h-5 w-5"/>
                </span>
                <template v-else>
                  <svg class="w-6 h-6" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path></svg>
                  <span v-if="toBeChargedAmount > 0" class="ml-2">
                    Confirm payment ${{ (toBeChargedAmount / 100).toFixed(0) }}
                  </span>
                  <span v-else class="ml-2">
                    Confirm
                  </span>
                </template>
              </button>

              <button @click="close" type="button" class="text-pink-500 text-sm">
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { confirmCardSetup } from 'vue-stripe-elements-plus';
import api from '@/api';
import Modal from '@/components/shared/Modal';
// import CheckAltIcon from '@/components/svgs/CheckAltIcon'
// import XIcon from '@/components/svgs/XIcon'
import LoadingIcon from '@/components/shared/LoadingIcon';
import { mapGetters } from 'vuex';
import CreditCard from '@/components/payment-methods/CreditCard';
import ExistingPaymentMethodCard from '@/components/payment-methods/ExistingPaymentMethodCard';
import UseExistingPaymentMethodButton from '@/components/payment-methods/UseExistingPaymentMethodButton';

export default {
  components: {
    Modal,
    // CheckAltIcon,
    // XIcon,
    LoadingIcon,
    CreditCard,
    ExistingPaymentMethodCard,
    UseExistingPaymentMethodButton,
  },

  data() {
    return {
      intent: null,
      working: false,
      guestioCashBalance: 0,
      useGuestioCash: false,
      cardComplete: false,
      card: null,
      paymentSetupError: false,
      errorDetails: null,
      defaultPaymentMethod: null,
      paymentMethods: [],
      useExistingPaymentMethod: null,
      saveCard: false,
    }
  },

  computed: {
    ...mapGetters({
      booking: 'dashboard/bookings/booking',
    }),

    formComplete() {
      if (this.toBeChargedAmount === 0) {
        return true;
      }

      return this.totalPrice && (this.useExistingPaymentMethod != null || this.cardComplete);
    },

    totalPrice() {
      return this.booking.full_booking_fee - this.booking.pitch_fee;
    },

    toBeChargedAmount() {
      if (! this.useGuestioCash) {
        return this.totalPrice;
      }

      let amount = this.totalPrice - this.guestioCashAmount;

      return amount > 0 && amount < 50 ? 50 : amount
    },

    guestioCashAmount() {
      return this.guestioCashBalance > this.totalPrice ? this.totalPrice : this.guestioCashBalance;
    },

    paymentMethodToUse() {
      if (this.useExistingPaymentMethod) {
        return this.paymentMethods.find(pm => pm.id == this.useExistingPaymentMethod);
      }
      return this.defaultPaymentMethod;
    },
  },

  watch: {
    useGuestioCash(useGuestioCash) {
      if (useGuestioCash) {
        this.$store.commit('guestBooking/setGuestioCash', this.guestioCashAmount)
        return
      }

      this.$store.commit('guestBooking/setGuestioCash', 0)
    },

    useExistingPaymentMethod() {
      this.generatePaymentIntent()
    }
  },

  mounted() {
    this.fetchPaymentMethods()
    this.fetchUserBalance()
  },

  methods: {
    async preparePayment(callback) {
      if (this.toBeChargedAmount > 0) {
        let confirmed = await this.confirmLogin()

        if (! confirmed) {
          return
        }
      }

      this.working = true
      this.paymentSetupError = false
      this.errorDetails = null

      if (this.toBeChargedAmount == 0 || this.useExistingPaymentMethod) {
        let paymentMethod = this.paymentMethodToUse ? this.paymentMethodToUse.id : null;
        return this.charge(callback, paymentMethod);
      }

      const { setupIntent, error } = await confirmCardSetup(this.intent.client_secret, {
        payment_method: {
          card: this.card
        }
      })

      if (error) {
        this.working = false
        this.paymentSetupError = true

        if (error.message) {
          this.errorDetails = error.message
        }

        return
      }

      await this.$store.dispatch('auth/storePaymentMethod', {
        payment_method: setupIntent.payment_method,
      }).catch(error => {
        if (error.response) {
          this.errorDetails = error.response.data.message
        }
      })

      this.charge(callback, setupIntent.payment_method);
    },

    async charge(callback, paymentMethod = null) {
      try {
        let params = {
          id: this.$route.params.bookingId,
          guestio_cash: this.useGuestioCash ? this.guestioCashAmount : null,
          save_card: this.saveCard
        };
        if (paymentMethod) {
          params['payment_method'] = paymentMethod;
        }
        await this.$store.dispatch('dashboard/bookings/processExtraPayment', params);

        callback.call();
        this.$emit('done');
      } catch (error) {
        console.log(error);
        if (error.response) {
          this.errorDetails = error.response.data.message;
        }

        this.generatePaymentIntent();
        this.paymentSetupError = true;
      } finally {
        this.working = false;
      }
    },

    generatePaymentIntent() {
      let appends = '';

      if (this.useExistingPaymentMethod) {
        appends = `?payment_method=${this.useExistingPaymentMethod}`
      }

      api.get(`/user/payment/intent${appends}`).then(response => {
        this.intent = response.data.intent
      })
    },

    fetchPaymentMethods() {
      api
        .get('/user/payment/methods')
        .then(({ data }) => {
          if (! data.length) {
            this.generatePaymentIntent()
            return
          }
          this.defaultPaymentMethod = data.find(pm => pm.default);
          this.paymentMethods = data
          this.useExistingPaymentMethod = this.defaultPaymentMethod ? this.defaultPaymentMethod.id : data[0].id
        })
    },

    fetchUserBalance() {
      api
        .get('/balance')
        .then(({ data }) => {
          this.guestioCashBalance = Number.isNaN(Number.parseFloat(data.data.raw.guestio_cash))
            ? 0
            : parseFloat(data.data.raw.guestio_cash);
        });
    },

    setCardComplete(data) {
        this.cardComplete = data.complete;
        this.card = data.card;
      },

      setSaveCard(shouldSave) {
        this.saveCard = shouldSave;
      },
  },
}
</script>
