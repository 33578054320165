<template>
  <div>
      <div class="mb-10">
        <router-link :to="{name: 'MiniviewAnswerGroups'}" class="text-pink-500 hover:underline text-sm flex items-center">
          <ChevronLeftIcon class="h-3 w-3" />
          <span class="ml-2">Back</span>
        </router-link>
      </div>
      <MiniviewQuestions class="w-full" :answerGroup="answerGroup" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ChevronLeftIcon from '@/components/svgs/ChevronLeftIcon';
import MiniviewQuestions from "@/components/miniviews/individual/MiniviewQuestions";

  export default {
    name: "MiniviewAnswers",
    components: { ChevronLeftIcon, MiniviewQuestions },

    computed: {
      ...mapGetters({
        miniview: 'miniviews/current',
      }),

      answerGroup() {
        return this.miniview.answer_groups.find(ag => ag.id == this.$route.params.answerGroupId)
      }
    },
  }
</script>
