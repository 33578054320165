<template>
  <Dropdown>
    <template slot="button">
      <ActionsIcon fill="#292546" :isEmpty="false"/>
    </template>
    <template slot="content">
      <div
        class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
        <div class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
          <button @click="editItem"
                  class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                  role="menuitem">
            Edit name
          </button>
          <button @click="deleteGroup"
                  class="block w-full text-left px-4 py-2 text-sm text-red-500 hover:bg-gray-100 hover:text-red-700 focus:outline-none focus:bg-gray-100 focus:text-red-700"
                  role="menuitem">
            Delete group
          </button>
        </div>
      </div>
    </template>
  </Dropdown>
</template>

<script>
import api from '@/api'
import {mapState, mapMutations} from "vuex";
import Dropdown from "@/components/shared/Dropdown";
import ActionsIcon from "@/components/svgs/ActionsIcon";

export default {
  name: "WishlistGroupDropdown",
  components: {ActionsIcon, Dropdown},

  props: {
    group: Object,
  },

  methods: {
    ...mapMutations({
      setGroup: "dashboard/smartlist/setGroup",
    }),

    deleteGroup() {
      this.working = true;
      api.delete(`/users/${this.user.id}/smartlist-groups/${this.group.id}`)
        .then(() => {
          this.$emit('updated');
        })
        .finally(() => {
            this.working = false;
        })

    },

    editItem() {
      this.setGroup(this.group);
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
}
</script>