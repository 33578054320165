<template>
  <div v-if="userIsInvitee" class="flex justify-center items-center w-full space-x-2">
    <AcceptBookingModal
      @reRender="reRenderComponent"
      class="bg-indigo-gradiant w-full rounded-full text-white font-bold px-5 py-4 mr-3"
    />
    <RejectBookingModal class="text-violet w-full" />
  </div>
</template>

<script>
import AcceptBookingModal from '@/components/bookings/individual/activity/AcceptBookingModal';
import RejectBookingModal from '@/components/bookings/individual/activity/RejectBookingModal';

export default {
  name: 'DetailsActionAcceptReject',

  components: { AcceptBookingModal, RejectBookingModal },

  computed: {
    booking() {
      return this.$store.getters['dashboard/bookings/booking'];
    },
    userOwnsBookingShow() {
      return this.$store.getters['dashboard/bookings/userOwnsBookingShow'];
    },
    userOwnsBookingGuest() {
      return this.$store.getters['dashboard/bookings/userOwnsBookingGuest'];
    },
    userIsInvitee() {
      return this.$store.getters['dashboard/bookings/userIsInvitee'];
    }
  },

  methods: {
    reRenderComponent() {
      window.Event.$emit("refreshActivity");
      window.Event.$emit("refreshBooking");
    }
  }
};
</script>

<style></style>
