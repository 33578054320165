<template>
  <div class="flex justify-between items-center p-5 border-b">
    <SearchInputRounded v-model="searchQuery" class="w-full flex-shrink"/>
  </div>
</template>
<script>
import SearchInputRounded from "@/components/shared/SearchInputRounded";

export default {
  name: 'MessageListHeader',
  components: {SearchInputRounded},
  data() {
    return {
      searchQuery: ''
    }
  },

  watch: {
    searchQuery(value) {
      this.$emit('filterConversations', value)
    }
  },
}
</script>
