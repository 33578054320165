<template>
  <footer class="w-full bg-brownGray text-white px-10 pt-12 pb-8 lg:px-32">
    <div class="flex flex-col md:flex-row justify-between">
      <div class="flex-1">
        <form @submit.prevent="subscribe" method="post">
          <h4 class="text-xl">Join our mailing list</h4>
          <p class="text-xs mt-1">Subscribe for updates on new guests and shows</p>
          <div class="flex flex-col space-y-4 xl:space-y-0 xl:space-x-4 mt-6 w-full xl:flex-row">
            <div class="max-w-sm w-full">
              <div>
                <input name="email" type="email" class="text-black form-input h-14 px-4 text-sm w-full rounded-full disabled:bg-gray-400"
                       placeholder="yourname@example.com" required :disabled="form.processing" v-model="form.email">
              </div>
              <div v-if="form.processed" class="text-green-500 mt-2">
                Thanks for subscribing!
              </div>
            </div>
            <div>
              <button type="submit" class="bg-indigo-gradiant h-14 rounded-full px-8 text-lg font-bold flex items-center justify-center disabled:opacity-75" :disabled="form.processing">
                <loading-icon v-if="form.processing" class="h-2" />
                <span v-else>
                  Subscribe
                </span>
              </button>
            </div>
          </div>
        </form>
      </div>

      <div
        class="flex-1 flex-wrap md:flex-no-wrap mt-12 md:mt-2 flex items-start flex-col md:items-end md:justify-start">
        <div class="mb-4 md:mb-0">
          <img class="h-8" src="/images/logo.png" alt="Guestio">
        </div>

        <div class="md:mt-8">
          <nav>
            <ul class="flex items-center space-x-6">
              <li>
                <a href="https://twitter.com/traviscchappell?lang=en" target="_blank" rel="noopener" title="Twitter"
                   class="hover:text-gray-500 transition duration-150 ease-in-out">
                  <TwitterIcon/>
                </a>
              </li>
              <li>
                <a href="https://www.facebook.com/travis.chappell15" target="_blank" rel="noopener" title="Facebook"
                   class="hover:text-gray-500 transition duration-150 ease-in-out">
                  <FacebookIcon/>
                </a>
              </li>
              <li>
                <a href="https://www.youtube.com/channel/UC0ex2Vz6Jj9cTSiHy7KTICA" target="_blank" rel="noopener"
                   title="Youtube" class="hover:text-gray-500 transition duration-150 ease-in-out">
                  <YoutubeIcon/>
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/travischappell" target="_blank" rel="noopener" title="Instagram"
                   class="hover:text-gray-500 transition duration-150 ease-in-out">
                  <InstagramIcon/>
                </a>
              </li>
            </ul>
            <div class="flex justify-end">
              <a href="https://guestio.com/faq/" class="text-white mt-4">FAQ</a>
            </div>
          </nav>
        </div>
      </div>
    </div>

    <div class="flex flex-col justify-between md:items-center mt-8 md:flex-row">
      <p class="order-3 md:order-1 mt-8 text-sm text-gray-400 md:mt-0">&copy; Guestio {{ year }}, All Rights
        Reserved</p>

      <nav class="order-2">
        <ul class="flex flex-col space-y-4 md:space-y-0 md:space-x-6 md:flex-row md:items-center">
          <li>
            <router-link :to="{ name: 'Home' }" class="text-xl">Home</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Browse', query: { showing: 'guests' } }" class="text-xl">Guests</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Browse', query: { showing: 'shows' } }" class="text-xl">Shows</router-link>
          </li>
        </ul>
      </nav>
    </div>
  </footer>
</template>

<script>
import api from '@/api'
import InstagramIcon from "@/components/svgs/SocialNetworks/InstagramIcon";
import TwitterIcon from "@/components/svgs/SocialNetworks/TwitterIcon";
import FacebookIcon from "@/components/svgs/SocialNetworks/FacebookIcon";
import YoutubeIcon from "@/components/svgs/SocialNetworks/YoutubeIcon";

export default {
  name: "Footer",
  components: {YoutubeIcon, FacebookIcon, TwitterIcon, InstagramIcon},

  data() {
    return {
      form: {
        processing: false,
        processed: false,
        email: ''
      }
    }
  },

  computed: {
    year() {
      return new Date().getFullYear()
    }
  },

  methods: {
    subscribe() {
      this.form.processing = true
      this.form.processed = false

      api.post('/mailing-list', this.form)
        .then(() => {
          this.form.email = ''
          this.form.processed = true
        })
        .catch(error => {
          if (error.response) {
            this.$toast.error(error.response.data.message)
            return
          }

          this.$toast.error('Something went wrong')
        })
        .finally(() => {
          this.form.processing = false
        });
    }
  }
}
</script>

