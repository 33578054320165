<template>
  <router-link :to="{ name: 'Browse', query: { categories: '' + category.id } }" class="rounded-xl h-40 bg-center bg-no-repeat bg-cover category-card relative flex items-end mr-4 lg:mr-6 cursor-pointer" :style="backgroundImage">
    <div class="rounded-xl absolute inset-0 flex items-end p-5 purple-gradiant" >
      <span class="relative z-10 text-white font-bold capitalize">{{ category.name }}</span>
    </div>
  </router-link>
</template>
<script>
export default {
  name: 'CategoryCard',
  props: {
    category: Object
  },
  computed: {
    backgroundImage() {
      const defaultImage = require('@/assets/images/test_image.png');
      return `background-image:url('${this.category.image_url ? this.category.image_url : defaultImage }')`
    },
  }
}
</script>

<style scoped>
.category-card {
  flex: 0 0 263px;
}

.purple-gradiant {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 44.27%, #000000 100%), linear-gradient(0deg, rgba(255, 0, 245, 0.4), rgba(255, 0, 245, 0.4));
}

</style>
