<template>
  <div class="space-y-8">
    <div v-for="(miniviewQuestion, index) in miniviewQuestions" :key="`miniview-${index}`" class="border border-gray-50 max-w-2xl w-full shadow-lg rounded-xl bg-white">
      <div class="px-8 py-4">
        <h4 class="text-blue-800 font-bold text-lg">
          {{ index + 1 }}. {{ miniviewQuestion.body }}
        </h4>
      </div>

      <div v-if="miniviewQuestion.question_media_url" class="px-8 mb-8">
        <VideoPreview v-if="miniviewQuestion.question_media_type === 'video_recordings'" :media-src="miniviewQuestion.question_media_url" />
        <AudioPreview v-else-if="miniviewQuestion.question_media_type === 'audio_recordings'" :media-src="miniviewQuestion.question_media_url" />
      </div>

      <div v-if="miniviewQuestion.answer_media_url" class="pt-4 pb-8 px-8 border-t border-guestio-gray-300">
        <div class="flex items-center justify-between">
          <h4 class="text-blue-800 font-bold">{{ answerGroup ? `${answerGroup.name}'s ` : 'Your ' }} Answer</h4>
          <modal v-if="!answerGroup" ref="previewModal" :with-close-button="false" class="text-pink-500 space-x-2 flex items-center text-sm">
            <EditAltIcon class="h-5 w-5" />
            <span>Edit</span>

            <template #content="{ close }">
              <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
                <RecordVideoAnswer
                  v-if="miniviewQuestion.answer_media_type === 'video_recordings'"
                  @close="close"
                  :miniview="miniview"
                  :isMiniviewAnswer="true"
                  @update-miniview="updateMiniview(index, $event, 'video_recordings')"
                />
                <RecordAudioAnswer
                  v-else-if="miniviewQuestion.answer_media_type === 'audio_recordings'"
                  @close="close"
                  :miniview="miniview"
                  :isMiniviewAnswer="true"
                  @update-miniview="updateMiniview(index, $event, 'audio_recordings')"
                />
              </div>
            </template>
          </modal>
        </div>
        <div class="mt-4">
          <VideoPreview v-if="miniviewQuestion.answer_media_type === 'video_recordings'" :media-src="miniviewQuestion.answer_media_url" />
          <AudioPreview v-else-if="miniviewQuestion.answer_media_type === 'audio_recordings'" :media-src="miniviewQuestion.answer_media_url" />
          <a v-if="answerGroup && miniviewQuestion.answer_media_uuid" :href="downloadUrl + miniviewQuestion.answer_media_uuid" title="AnswerName" class="text-pink-500 font-semibold">Download</a>
        </div>
      </div>

      <div v-if="canAnswer" class="mt-4 px-8 pb-8 flex space-x-1">
        <modal v-if="!miniviewQuestion.answer_media_url" :with-close-button="false" class="bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
          <VideoIcon class="text-pink-500 h-4" />
          <span class="ml-3">Record Your Answer</span>

          <template #content="{ close }">
            <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
              <RecordVideoAnswer @close="close" :miniview="miniview" :isMiniviewAnswer="true" @update-miniview="updateMiniview(index, $event, 'video_recordings')" />
            </div>
          </template>
        </modal>

        <modal v-if="!miniviewQuestion.answer_media_url" :with-close-button="false" class="bg-pink-500 text-sm text-blue-800 bg-opacity-10 flex items-center px-6 py-3 rounded-full hover:bg-opacity-20 focus:shadow-outline">
          <MicIcon class="text-pink-500 h-4" />
          <span class="ml-3">Record Your Answer</span>

          <template #content="{ close }">
            <div class="max-w-xl w-full bg-white rounded-xl overflow-auto my-4 p-6 lg:px-8 max-h-screen-10">
              <RecordAudioAnswer @close="close" :miniview="miniview" :isMiniviewAnswer="true" @update-miniview="updateMiniview(index, $event, 'audio_recordings')" />
            </div>
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VideoPreview from '@/components/shared/VideoPreview';
import AudioPreview from '@/components/shared/AudioPreview';
import Modal from '@/components/shared/Modal'
import EditAltIcon from '@/components/svgs/EditAltIcon'
import VideoIcon from '@/components/svgs/VideoIcon';
import MicIcon from '@/components/svgs/MicIcon';
import RecordVideoAnswer from '@/components/bookings/individual/RecordVideoAnswer';
import RecordAudioAnswer from '@/components/bookings/individual/RecordAudioAnswer';

  export default {
    name: "MiniviewQuestions",
    components: { VideoPreview, AudioPreview, Modal, VideoIcon, MicIcon, RecordVideoAnswer, RecordAudioAnswer, EditAltIcon },
    props: {
      canAnswer: { type: Boolean, default: false },
      answerGroup: { type: [Object, null], default: null },
    },

    computed: {
      ...mapGetters({
        miniview: 'miniviews/current',
      }),

      miniviewQuestions() {
        if (this.answerGroup) {
          return this.miniview.questions.map(q => {
            const answer = q.answers.filter(a => a.answer_group_id == this.answerGroup.id);
            const ret = { ...q };
            if (answer.length) {
              ret['answer_media_url'] = answer[0].url;
              ret['answer_media_type'] = answer[0].type;
              ret['answer_media_uuid'] = answer[0].media_uuid;
            }
            return ret;
          });
        }
        return this.miniview.questions;
      },

      downloadUrl() {
        return process.env.VUE_APP_API_ENDPOINT + '/download/media/'
      }
    },

    methods: {
      updateMiniview(index, media, type) {
        let originalMiniviewQuestion = this.miniviewQuestions[index]
        this.$set(originalMiniviewQuestion, 'answer_media_url', media.media_url)
        this.$set(originalMiniviewQuestion, 'answer_media_type', type)

        this.$emit('questionAnswered', { questionId: originalMiniviewQuestion.id, url: media.media_url, type } )
      },
    },
  }
</script>
