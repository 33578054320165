<template>
  <div>
    <template v-if="userIsInvitee">
      <p>Welcome to the activity feed of your new Booking!</p>
      <p v-if="!activity.properties.is_pitch">To start you need to <b>Accept</b> or the <b>booking.</b></p>
      <div v-else class="mb-5.5">
        <p class="mb-4">You have a new pitch from <span class="font-semibold">{{ activity.properties.initiator_profile_name }}</span></p>
        <p class="text-grayDark text-sm">If you accept this fee will replace your normal booking fee</p>
      </div>
    </template>
    <template v-else>
      Waiting for the {{ userOwnsBookingShow ? 'guest' : 'Show' }} to accept or reject your booking request.
      <div v-if="activity.properties.is_pitch">
        <p class="mb-7">Here is your pitch:</p>
      </div>
    </template>
    <div v-if="activity.properties.is_pitch" class="flex w-full space-x-5">
      <div class="w-full">
        <div v-if="activity.properties.media_type == 'video'" class="overflow-hidden rounded-lg mb-4">
          <VideoPreview :media-src="activity.properties.media_url" class="rounded-none" />
        </div>
        <div v-if="activity.properties.media_type == 'audio'" class="overflow-hidden relative bg-guestio-gray-100 rounded-lg mb-4">
          <AudioPreview :media-src="activity.properties.media_url" />
        </div>
        <div v-if="activity.properties.message" class="flex items-start w-full">
          <div class="py-4 px-6 rounded-xl w-full overflow-hidden bg-gray-50 text-sm lg:text-base">
            <div class="flex w-full">
              <div class="ml-3 overflow-hidden break-words flex-1">
                <span class="text-blue-800 font-bold leading-6 mb-3">
                  {{ activity.properties.initiator_profile_name }}
                </span>
                <div v-html="activity.properties.message" class="whitespace-pre-wrap" v-linkified:options="{ className: 'hover:text-violet underline' }" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex justify-end" style="min-width: 108px;">
        <span class="text-sm text-defaultGray font-light mt-3">{{ date }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  
  import VideoPreview from '@/components/shared/VideoPreview';
  import AudioPreview from '@/components/shared/AudioPreview'

  export default {
    name: 'ActionAcceptRejectBooking',

    components: {
      VideoPreview,
      AudioPreview,
    },

    props: {
      activity: Object,
      userTimezone: {
        type: String,
        default: ''
      }
    },

    computed: {
      userOwnsBookingShow() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
      },
      userIsInvitee() {
        return this.$store.getters['dashboard/bookings/userIsInvitee']
      },
      date() {
        return `${moment(this.activity.created_at).format('MMM D, h:mm A')}${this.userTimezone !== '' ? ` (${this.userTimezone})` : ''}`;
      }
    }
  }
</script>
