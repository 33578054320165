<template>
  <div>
    <div class="bg-white overflow-hidden sm:rounded-md border border-blue-800">
      <ul class="divide-y divide-blue-800">
        <template v-if="userProp.account_type == 'standard'">
          <li class="flex items-center">
            <div class="min-w-56 flex-shrink-0 border-r border-blue-800">
              <div class="text-gray-500 flex items-center px-4 py-4 sm:px-6">
                Plan name
              </div>
            </div>
            <div class="flex-1 text-blue-800 font-bold flex items-center px-4 py-4 sm:px-6">
              Standard
            </div>
          </li>
        </template>

        <template v-else-if="userProp.account_type == 'pro'">
          <li class="flex items-center">
            <div class="min-w-56 flex-shrink-0 border-r border-blue-800 flex items-center">
              <span class="text-gray-500 px-4 py-4 sm:px-6">
                Plan name
              </span>
            </div>
            <div class="flex-1 flex items-center px-4 py-4 sm:px-6">
              <span class="text-blue-800 font-bold">
                Pro
              </span>
              <span v-if="subscription.id" class="ml-2 text-gray-500">
                <em>({{ subscription.stripe_status }}{{ subscription.stripe_status == 'trialing' ? ' until ' + $moment(subscription.trial_ends_at).format('MMMM D, YYYY') : '' }})</em>
              </span>
            </div>
          </li>

          <template v-if="subscription.id && $moment(subscription.ends_at)">
            <li class="flex items-center">
              <div class="min-w-56 flex-shrink-0 border-r border-blue-800 flex items-center">
                <span class="text-gray-500 px-4 py-4 sm:px-6">
                  Plan Cycle
                </span>
              </div>
              <div class="flex-1 flex items-center px-4 py-4 sm:px-6">
                <span class="text-blue-800">
                  {{ subscription.plan_interval == 'month' ? 'Monthly' : 'Yearly' }}
                </span>
              </div>
            </li>

            <li class="flex items-center">
              <div class="min-w-56 flex-shrink-0 border-r border-blue-800 flex items-center">
                <span class="text-gray-500 px-4 py-4 sm:px-6">
                  Start Date
                </span>
              </div>
              <div class="flex-1 flex items-center px-4 py-4 sm:px-6">
                <span class="text-blue-800">
                  {{ $moment(subscription.current_period_start).format('MMMM D, YYYY') }}
                </span>
              </div>
            </li>

            <li class="flex items-center">
              <div class="min-w-56 flex-shrink-0 border-r border-blue-800 flex items-center">
                <span class="text-gray-500 px-4 py-4 sm:px-6">
                  Next billing date
                </span>
              </div>
              <div class="flex-1 flex items-center px-4 py-4 sm:px-6">
                <span class="text-blue-800">
                  {{ $moment(subscription.current_period_end).format('MMMM D, YYYY') }}
                </span>
              </div>
            </li>

            <li class="flex items-center">
              <div class="min-w-56 flex-shrink-0 border-r border-blue-800 flex items-center">
                <span class="text-gray-500 px-4 py-4 sm:px-6">
                  Next billing amount
                </span>
              </div>
              <div class="flex-1 flex items-center px-4 py-4 sm:px-6">
                <span class="text-blue-800 font-bold">
                  <span v-if="subscription.ends_at">
                    -
                  </span>
                  <span v-else>
                    ${{ (subscription.plan_price / 100).toFixed(2) }}
                  </span>
                </span>
              </div>
            </li>
          </template>

          <template v-else>
            <li class="flex items-center">
              <div class="min-w-56 flex-shrink-0 border-r border-blue-800 flex items-center">
                <span class="text-gray-500 px-4 py-4 sm:px-6">
                  Next billing date
                </span>
              </div>
              <div class="flex-1 flex items-center px-4 py-4 sm:px-6">
                <span class="text-blue-800">
                  -
                </span>
              </div>
            </li>
          </template>
        </template>
      </ul>
    </div>

    <div v-if="subscription.id && subscription.ends_at && subscription.active" class="mt-2">
      <p class="text-gray-500 text-sm">
        <em>Your subscription was cancelled and will expire on {{ $moment(subscription.ends_at).format('MMMM DD, YYYY') }}.</em>
      </p>
    </div>

    <div class="action mt-4 flex items-center">
      <template v-if="userProp.account_type == 'standard'">
        <modal
          class="bg-indigo-gradiant text-white rounded-full h-14 font-bold px-8 text-normal hover:opacity-75 transition-opacity duration-150 ease-in-out"
        >
          <span>Go Pro Now</span>
          <template v-slot:content="{ close }">
            <SubscribeModal :user="userProp" :plans="plans" @subscribed="subscribed($event, close)" />
          </template>
        </modal>
<!--
        <button
          type="button"
          class="bg-indigo-gradiant text-white rounded-full h-14 font-bold px-8 text-normal hover:opacity-75 transition-opacity duration-150 ease-in-out disabled:opacity-60"
          :disabled="form.processing"
          @click="joinTheWaitlist"
        >
          <loading-icon v-if="form.processing" class="h-2 text-white"/>
          <span v-else>
            Join the waitlist
          </span>
        </button>

        <p v-if="form.error" class="ml-4 text-sm text-red-600">
          {{ form.error }}
        </p>

        <p v-if="form.success" class="ml-4 text-sm text-green-600">
          {{ form.success }}
        </p>
        -->
      </template>

      <template v-else-if="userProp.account_type == 'pro' && subscription.id && ! subscription.ends_at">
        <ConfirmDialog
          confirm-action="Yes, Cancel"
          dialog-title="Cancel Pro Subscription?"
          dialog-text="Are you sure you want to cancel your PRO subscription?"
          class="bg-indigo-gradiant text-white font-bold px-8 py-3 rounded-full transition duration-200 ease-in-out hover:opacity-75 focus:opacity-75"
          @confirm="cancelSubscription"
        >
          Cancel Pro Subscription
        </ConfirmDialog>
      </template>
    </div>

    <template v-if="invoices.length">
      <div class="mt-8">
        <h3 class="text-3xl text-black font-bold">Your Invoices</h3>
        <div class="mt-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200">
                <thead class="bg-gray-50">
                  <tr>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Date
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Number
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Amount
                    </th>
                    <th scope="col" class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(invoice, index) in invoices"
                    :key="`invoice-${index}`"
                    :class="index % 2 == 0 ? 'bg-white' : 'bg-gray-50'"
                  >
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-blue-800 font-bold">
                      {{ $moment(invoice.date).format('MMMM DD, YYYY') }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ invoice.number }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {{ invoice.amount }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-sm text-right">
                      <a :href="invoice.download_url" target="_blank" class="text-pink-500 underline">Download</a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import api from '@/api'
  import SubscribeModal from './SubscribeModal'
  import Modal from '@/components/shared/Modal'
  import ConfirmDialog from '@/components/shared/ConfirmDialog'

  export default {
    props: {
      userProp: Object,
    },

    components: {
      SubscribeModal,
      Modal,
      ConfirmDialog,
    },

    data() {
      return {
        plans: {
          monthly: process.env.VUE_APP_PRO_MONTHLY_PRICE || 97,
          yearly: process.env.VUE_APP_PRO_YEARLY_PRICE || 997,
        },
        subscription: {},
        invoices: [],

        form: {
          processing: false,
          error: null,
          success: false,
        },
      }
    },

    methods: {
      fetchSubscription() {
        api
          .get('/users/me/subscription')
          .then(({ data }) => {
            if (! data.data.id) {
              return
            }

            this.subscription = data.data
          })
      },

      fetchInvoices() {
        api
          .get('/users/me/invoices')
          .then(({ data }) => {
            if (! data.data.length) {
              return
            }

            this.invoices = data.data
          })
      },

      subscribed(subscription, callback) {
        this.subscription = subscription
        callback.call()

        this.fetchInvoices()
      },

      cancelSubscription(callback) {
        api
          .delete('/users/me/subscription')
          .then(({ data }) => {
            this.$store.dispatch('auth/getUser')
            this.subscription = data.data
          })
          .catch(error => {
            if (error.response) {
              return this.$toast.error(error.response.data.message)
            }

            this.$toast.error('The subscription was not canceled')
          })
          .finally(() => callback.call())
      },

      joinTheWaitlist() {
        this.form.processing = true;
        this.form.error = null;
        this.form.sucess = null;

        api.post('/join-waitlist', {
          email: this.userProp.email,
          source: 'app',
        }).then(() => {
          this.form.success = "You've beed added to the waitlist.";
        }).catch(() => {
          this.form.error = 'Something went wrong. Please try again or contact support.';
        }).finally(() => {
          this.form.processing = false;
        });
      }
    },

    mounted() {
      this.fetchSubscription()
      this.fetchInvoices()
    }
  }
</script>
