<template>
  <div v-if="profile.user_id !== user.id" class="flex-shrink-0 max-w-xs lg:w-auto lg:max-w-none">
    <template>
      <modal
        class="text-white rounded-full px-8 py-2.5 text-center font-semibold lg:mb-0 w-full lg:w-auto"
        :class="[{'bg-indigo-gradiant': profile.color == null}]"
        :style="backgroundColor"
      >
        <span class="uppercase">Inquire</span>
        <template v-slot:content="{ close }">
          <InquireUserModal @close="close" :profileId="profile.id" :profileType="profileType" />
        </template>
      </modal>
    </template>
  </div>
</template>

<script>
import {mapState} from "vuex";
import Modal from "@/components/shared/Modal";
import InquireUserModal from "@/components/shared/InquireUserModal";

export default {
  name: "InquireButton",
  components: { Modal, InquireUserModal },
  props: {
    profile: Object,
    profileType: String,
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      authenticated: state => state.auth.authenticated
    }),

    backgroundColor() {
      return this.profile.color ? `background-color: ${this.profile.color}` : ''
    },
  },
};
</script>
