import { extend } from 'vee-validate';
import { required, email, confirmed, min_value, alpha_num } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: '{_field_} is required'
});
extend('email', email);
extend('alpha_num', alpha_num);
extend('min_value', min_value);
extend('confirmed', {
  ...confirmed,
  message: 'The Password and Password confirmation fields do not match'
});
extend('min', {
  validate(value, { length }) {
    return value.length >= length;
  },
  params: ['length'],
  message: 'The {_field_} field must have at least {length} characters'
});
extend('max', {
  validate(value, { length }) {
    return value.length <= length;
  },
  params: ['length'],
  message: 'The {_field_} cannot exceed {length} characters'
});
extend('min_value', {
  validate(value, { min_value }) {
    return parseInt(min_value) <= parseInt(value);
  },
  params: ['min_value'],
  message: 'Min {_field_} is {min_value}'
});
extend('max_price', {
  validate(value, { max_price }) {
    return parseInt(max_price) >= parseInt(value);
  },
  params: ['max_price'],
  message: '{_field_} cannot be more than your highest booking option (${max_price})'
});
extend('max_value', {
  validate(value, { max_value }) {
    return parseInt(max_value) >= parseInt(value);
  },
  params: ['max_value'],
  message: '{_field_} cannot be more than ({max_value})'
});
extend('url', {
  validate: (str) => {
      var pattern = new RegExp(/^(http|https):\/\/[^ "]+$/)
      return !!pattern.test(str);
  },
  message: 'This is not a valid URL'
})
extend('podcast', {
  validate: (str) => {
      var pattern = new RegExp(/^(http|https):\/\/[^ "]+$/);
      return !!pattern.test(str);
  },
  message: 'This is not a valid podcast URL'
});
extend('video', {
  validate: (str) => {
      var pattern = new RegExp(/^(http|https):\/\/[^ "]+$/);
      return !!pattern.test(str);
  },
  message: 'This is not a valid video URL'
});
extend('hasDomain', {
  validate: (str, { value }) => {
    if (value === 'website') {
      return new RegExp(/[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g).test(str)
    }

    var pattern = new RegExp(`.${value}.com|.${value}.org`,'i');
    return !!pattern.test(str);
  },
  params: ['value'],
  message: 'This is not a valid {value} URL'
});

