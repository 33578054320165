<template>
  <header
    class="w-full py-2 px-8 flex bg-lightBlack items-center justify-between">
    <div class="flex items-center">
      <!-- Hamburguer button -->
      <button
        @click.stop="toggleSidebar"
        class="text-2xl text-right block text-white mr-8">
        <i class="fas fa-bars"></i>
      </button>
      <!-- Logo -->
      <span class="flex items-center" v-if="!hideHeader">
        <router-link class="inline-block mr-5 flex-shrink-0" :to="{ name: 'Home' }">
          <img class="w-32" src="../../assets/images/newLogo.svg" alt="Logo"/>
        </router-link>
        <span class="text-gray-400 xl:text-base font-light hidden lg:inline-block">guest booking marketplace</span>
      </span>
    </div>
    <div class="flex items-center justify-end">
      <template v-if="! authenticated">
        <!-- Free trail button -->
        <router-link
          :to="{ name: 'register-personal-information'}"
          class="gradiant-button hidden text-white rounded-full px-5 py-2 text-sm lg:text-md xl:text-base xl:px-10 font-semibold md:mr-8 md:block flex-shrink-0 hover:shadow-none">
          Start free now
        </router-link>
        <!-- Sign in button -->
        <router-link
          :to="{ name: 'Login' }"
          class="flex items-center text-gray-300 text-base hidden md:block flex-shrink-0">
          <UserIcon class="w-8 h-8 mr-4 inline-block"/>
          <span>Sign in</span>
        </router-link>
      </template>
      <template v-else>
        <Dropdown>
          <template slot="button">
            <div v-if="adminUser.id"
                 class="flex items-center text-gray-300 text-base hidden md:block flex-shrink-0">
              <div class="flex">
                <img v-if="adminUser.thumbUrl" :src="adminUser.thumbUrl" :alt="adminUser.name"
                     class="w-8 h-8 rounded-full object-cover flex-shrink-0 mr-4">
                <template v-else>
                  <DefaultProfileImage width="w-8" height="h-8" :hash="adminUser.email_hash"/>
                </template>
                <span class="capitalize flex items-center">{{ adminUser.first_name }} {{
                    adminUser.last_name.substring(0, 1)
                  }}.</span>
              </div>
            </div>
            <div v-else-if="user.id"
                 class="flex items-center text-gray-300 text-base hidden md:block flex-shrink-0">
              <div class="flex">
                <img v-if="user.thumbUrl" :src="user.thumbUrl" :alt="user.name"
                     class="w-8 h-8 rounded-full object-cover flex-shrink-0 mr-4">
                <template v-else>
                  <DefaultProfileImage width="w-8" height="h-8" :hash="user.email_hash"/>
                </template>
                <span class="capitalize flex items-center">{{ user.first_name }} {{
                    user.last_name.substring(0, 1)
                  }}.</span>
              </div>
            </div>
          </template>
          <template v-slot:content="{ close }">
            <div
              class="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div @click="close" class="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <div v-if="adminUser.id && adminUser.team_role === 'admin'" class="block px-4 py-2 text-sm text-gray-100 bg-blue-800 -mt-1">
                  Admin for <span class="font-semibold">{{ user.first_name }} {{ user.last_name }}</span>
                </div>
                <router-link :to="{name:'Dashboard'}"
                             class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                             role="menuitem">Dashboard
                </router-link>
                <router-link :to="{name:'GuestDetails'}"
                             class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                             role="menuitem">Profile
                </router-link>
                <router-link :to="{name:'Settings'}"
                             class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                             role="menuitem">Settings
                </router-link>

                <button @click="logout"
                        class="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900 focus:outline-none focus:bg-gray-100 focus:text-gray-900"
                        role="menuitem">
                  Log out
                </button>
              </div>
            </div>
          </template>
        </Dropdown>

      </template>
    </div>
  </header>
</template>

<script>
import DefaultProfileImage from "@/components/svgs/DefaultProfileImage";
import {mapMutations, mapState} from "vuex";
import isMobile from "is-mobile";
import Dropdown from "@/components/shared/Dropdown";
import UserIcon from "@/components/svgs/UserIcon";

export default {
  name: "Header",
  components: {UserIcon, Dropdown, DefaultProfileImage},
  data() {
    return {
      mobile: isMobile()
    };
  },
  methods: {
    ...mapMutations({
      setSidebar: "SET_SIDEBAR"
    }),
    toggleSidebar() {
      this.setSidebar({isOpen: !this.isOpen});

      if (this.isOpen) {
        if (!this.mobile) document.documentElement.style.overflow = "hidden";
        return;
      }
      document.documentElement.style.overflow = "auto";
    },
    logout() {
      this.$store.dispatch('auth/logout').then(response => {
        console.log(response);
        this.$router.push({name: 'Home'})
      })
    }
  },
  watch: {
    $route: function () {
      if (
        this.$router.currentRoute.name === "Home" ||
        this.$router.currentRoute.name === "Register" ||
        this.$router.currentRoute.name === "Login"
      ) {
        this.isHome = true;
      } else {
        this.isHome = false;
      }
    }
  },

  computed: {
    ...mapState({
      isOpen: state => state.isOpen,
      guest: state => state.guest.guest,
      show: state => state.shows.show,
    }),
    authenticated() {
      return this.$store.getters["auth/authenticated"];
    },

    user() {
      return this.$store.getters["auth/getUser"];
    },

    adminUser() {
      return this.$store.getters["auth/getAdminUser"];
    },

    hideHeader() {
      return ((this.$route.name == 'GuestProfile' || this.$route.name == 'Guest') && this.guest.remove_branding) || (this.$route.name == 'Show' && this.show.remove_branding)
    }
  }
};
</script>
