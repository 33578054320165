<template>
  <transition
    v-if="group && group.id == id"
    appear
    enter-active-class="transition ease-out duration-1000"
    enter-class="opacity-0"
    enter-to-class="opacity-100"
    leave-active-class=""
    leave-class="opacity-100"
    leave-to-class="opacity-0"
    mode="out-in"
  >
    <div>
      <ValidationObserver v-slot="{ handleSubmit }">
        <form method="post" @submit.prevent="handleSubmit(updateGroup)">
              <div class="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0">
                <ValidationProvider rules="required|max:255" name="Name" vid="name" v-slot="{ errors }">
                  <TextInput v-model="form.name" :errors="errors" placeholder="Group name" fontSize="text-lg" ref="nameInput"/>
                </ValidationProvider>
                <button type="submit" class="mx-0 sm:mx-4 disabled:opacity-50 flex items-center bg-indigo-gradiant rounded-full text-white font-semibold px-8 py-3 bg-red-300" :disabled="working">
                  <loading-icon v-if="working" class="h-2" />
                  <span v-else>Update name</span>
                </button>
                <button type="button" @click="setGroup" class="text-pink-500 hover:text-pink-700 font-semibold ml-4">Cancel</button>
              </div>
        </form>
      </ValidationObserver>
    </div>
  
  </transition>
  <span v-else class="text-2xl font-bold">{{name}}</span>
</template>

<script>
import api from '@/api'
import {mapState, mapMutations} from "vuex";
import TextInput from "@/components/shared/TextInput";

export default {
  name: 'EditGroupName',
  components: {TextInput},

  props: {
    name: String,
    id: Number,
  },

  data() {
    return {
      working: false,
      form: {
        name: this.name
      },
    }
  },

  methods: {
    ...mapMutations({
      setGroup: "dashboard/smartlist/setGroup",
    }),

    updateGroup() {
      this.working = true;
      api.put(`/users/${this.user.id}/smartlist-groups/${this.group.id}`, this.form)
        .then(() => {
          this.$emit('updated');
          this.setGroup(null)
        })
        .finally(() => {
            this.working = false;
        })
    }
  },

  mounted() {
    if (this.group) {
      this.form.name = this.group.name
    }
  },

  computed: {
    ...mapState({
      user: state => state.auth.user,
      group: state => state.dashboard.smartlist.group,
    }),
  },

}
</script>