<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke="currentColor"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      :stroke-width="strokeWidth"
      d="M5 13l4 4L19 7"
    />
  </svg>
</template>

<script>
export default {
  name: "CheckIcon",
  props: { strokeWidth: { type: String, default: "2" } }
};
</script>
