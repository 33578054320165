<template>
  <div>
    <div>{{ activity.description }}:</div>
    <div class="bg-gray-50 mt-4 rounded-xl p-6">
      <h4 class="text-black font-semibold">{{ activity.properties.question }}</h4>
      <p>{{ activity.properties.answer }}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'InfoBookingQuestionAnswer',

    props: {
      activity: Object,
    }
  }
</script>
