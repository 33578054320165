<template>
  <modal class="w-full min-h-56">
    <div class="h-full border border-gray-100 bg-white shadow-lg rounded-3xl p-6 relative">
      <span @click.stop="" class="absolute right-0 top-0 m-5">
        <Dropdown :item="item" @updated="$emit('updated')"/>
      </span>
      <div v-if="profile" class="flex items-center mb-6">
        <img v-if="profile.imageUrl && !isVideo" :src="profile.imageUrl" :alt="profile.name" class="w-16 h-16 rounded-full object-cover flex-shrink-0 mr-4">
        <video v-else-if="profile.imageUrl" :src="profile.imageUrl" loop="loop" muted="true" autoplay="true" class="w-16 h-16 rounded-full object-cover flex-shrink-0 mr-4"></video>
        <div class="flex flex-col items-start">
          <h2 class="name text-blue-800">{{profile.name}}</h2>
          <p class="text-pink-500 text-sm font-medium text-left whitespace-nowrap">{{ (profile.type == 'guest' ? title : profile.description) | truncate(25, "...")}}</p>
          <ProfileRating :rating="profile.avg_rating" activeColor="#B503D0" inactiveColor="#D4D4D4" :starSize="16"/>
        </div>
      </div>
      <div v-else class="flex mb-6">
        <div class="image">{{item.name.charAt(0)}}</div>
        <div class="flex flex-col items-start">
          <h2 class="name">{{item.name}}</h2>
          <span v-if="item.domain" class="domain w-48 xxl:w-full truncate">{{item.domain}}</span>
          <p v-for="email in item.emails" :key="email" class="text">{{email}}</p>
          <span v-if="item.phone" class="text">{{item.phone}}</span>
        </div>
      </div>

      <div class="flex flex-col items-start">
        <div v-for="(note, index) in sortedNotes" :key="index" class="pb-2 pt-1 text-sm text-gray-500 text-left">
          <span class="font-bold">{{date(note.date)}}</span><span class="italic"> {{note.date ? '-': ''}} {{note.text | truncate(65, "... read more") }}</span>
        </div>
      </div>

    </div>
    <div slot="content" class="w-full max-w-4xl">
      <ShowItemModal :item="item" @noteAdded="$emit('updated')"/>
    </div>
  </modal>
</template>

<script>
  import { orderBy, take } from 'lodash'
  import moment from 'moment'
  import ProfileRating from "@/components/shared/ProfileRating";
  import Dropdown from "@/components/wishlist/partials/WishlistItemDropdown";
  import Modal from "@/components/shared/Modal";
  import ShowItemModal from "@/components/wishlist/partials/ShowItemModal";

  export default {
    name: 'WishlistItem',
    components: {ProfileRating, Dropdown, Modal, ShowItemModal},
    props:{
      item: Object,
    },

    methods: {
      date(date) {
        if (date) {
          return moment(date).format('DD/MM')
        }
        return null
      },
    },

    computed: {
      title() {
        return this.profile.title ? this.profile.title : this.profile.bio
      },

      profile() {
        return this.item.profile
      },

      sortedNotes() {
        return take(
          orderBy(
            this.item.notes,
            ({ date }) => date || moment().subtract(2, 'years').format(),
            ['desc']
          ),
        2)
      },

      isVideo() {
        const videoTypes = ['mp4', 'ogg', 'mov', 'qt']

        let extension = this.profile.imageUrl.split(".").pop();
        return videoTypes.includes(extension);
      },
    }
  }
</script>

<style scoped>
  .image {
    @apply rounded-full h-16 w-16 bg-white flex items-center justify-center uppercase text-3xl font-extrabold text-blue-800 text-opacity-25 mr-4;
  }

  .name {
    @apply font-bold text-lg leading-snug mb-1;
  }

  .domain {
    @apply font-bold text-xs leading-snug;
  }

  .text {
    @apply text-xs leading-snug mb-1;
  }
</style>
