<template>
  <div class="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3 mb-10">
    <div class="overflow-hidden rounded-xl shadow-lg border border-gray-50">
      <div class="px-4 py-5 sm:p-6">
        <div class="flex items-center">
          <div class="flex-shrink-0 rounded-md p-3">
            <svg class="h-20 w-20" width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="6.75" y="7.63477" width="35.5" height="35.5" rx="4.91471" stroke="#138643" stroke-width="1.5"/>
            <path d="M25.0023 16.8848V32.8848" stroke="#138643" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M33 24.8852H17" stroke="#138643" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>
          <div class="ml-5 w-0 flex-1">
            <dl>
              <dt class="text-lg leading-5 font-light text-gray-600 truncate mb-2">
                Total Received
              </dt>
              <dd class="flex items-baseline">
                <div class="text-2xl leading-8 font-semibold">
                  ${{ totalRev | noDecimals }}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden rounded-xl shadow-lg border border-gray-50">
      <div class="px-4 py-5 sm:p-6">
        <div class="flex items-center">
          <div class="flex-shrink-0 rounded-md p-3">
            <svg class="h-20 w-20" width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="6.75" y="7.63477" width="35.5" height="35.5" rx="4.91471" stroke="#292546" stroke-width="1.5"/>
            <g clip-path="url(#clip0_8429:27201)">
            <path d="M25.0703 12.1865V38.8806" stroke="white" stroke-width="2.83235" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M31.14 17.04H22.0397C20.9134 17.04 19.8332 17.4875 19.0368 18.2839C18.2404 19.0803 17.793 20.1605 17.793 21.2868C17.793 22.4131 18.2404 23.4933 19.0368 24.2897C19.8332 25.0862 20.9134 25.5336 22.0397 25.5336H28.1066C29.2329 25.5336 30.3131 25.981 31.1095 26.7775C31.9059 27.5739 32.3534 28.6541 32.3534 29.7804C32.3534 30.9067 31.9059 31.9869 31.1095 32.7833C30.3131 33.5797 29.2329 34.0272 28.1066 34.0272H17.793" stroke="white" stroke-width="2.83235" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M33 25.4848H17" stroke="#292546" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_8429:27201">
            <rect width="29.1208" height="29.1208" fill="white" transform="translate(10.5117 10.9727)"/>
            </clipPath>
            </defs>
            </svg>

          </div>
          <div class="ml-5 w-0 flex-1">
            <dl>
              <dt class="text-lg leading-5 font-light text-gray-600 truncate mb-2">
                Total Paid
              </dt>
              <dd class="flex items-baseline">
                <div class="text-2xl leading-8 font-semibold">
                  ${{ totalPaid | noDecimals }}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
    <div class="overflow-hidden rounded-xl shadow-lg border border-gray-50">
      <div class="px-4 py-5 sm:p-6">
        <div class="flex items-center">
          <div class="flex-shrink-0 rounded-md p-3">
            <svg class="h-20 w-20" width="49" height="50" viewBox="0 0 49 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="6.75" y="7.63477" width="35.5" height="35.5" rx="4.91471" stroke="#B503D0" stroke-width="1.5"/>
            <g clip-path="url(#clip0_8429:27196)">
            <path d="M25 15.7598V35.0098" stroke="#B503D0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M29.5833 19.2598H22.7083C21.8574 19.2598 21.0414 19.5824 20.4397 20.1568C19.838 20.7311 19.5 21.51 19.5 22.3223C19.5 23.1345 19.838 23.9135 20.4397 24.4878C21.0414 25.0621 21.8574 25.3848 22.7083 25.3848H27.2917C28.1426 25.3848 28.9586 25.7074 29.5603 26.2818C30.162 26.8561 30.5 27.635 30.5 28.4473C30.5 29.2595 30.162 30.0385 29.5603 30.6128C28.9586 31.1871 28.1426 31.5098 27.2917 31.5098H19.5" stroke="#B503D0" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_8429:27196">
            <rect width="22" height="21" fill="white" transform="translate(14 14.8848)"/>
            </clipPath>
            </defs>
            </svg>

          </div>
          <div class="ml-5 w-0 flex-1">
            <dl>
              <dt class="text-lg leading-5 font-light text-gray-600 truncate mb-2">
                Guestio Cash
                <button type="button" v-tooltip="tooltip">
                  <InfoIcon class="h-4 w-4 text-grayDark" />
                </button>
              </dt>
              <dd class="flex items-baseline">
                <div class="text-2xl leading-8 font-semibold">
                  ${{ guestioCashBalance }}
                </div>
              </dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import api from '@/api'
  import InfoIcon from '@/components/svgs/InfoIcon'

  export default {
    name: 'PaymentStats',

    components: {
      InfoIcon,
    },


    data() {
      return {
        guestioCashBalance: 0,
        daysTillExpiration: 90,
      }
    },

    filters: {
      noDecimals(value) {
        return (value / 100).toFixed(0)
      }
    },

    computed: {
      payments() {
        return this.$store.getters['dashboard/payments/payments']
      },

      totalRev() {
        if (! this.payments.length) {
          return 0
        }

        return this.payments
          .filter((payment) => payment.type === 'incoming')
          .reduce((total, payment) => {
            return total + payment.raw_price
          }, 0);
      },

      totalPaid() {
        if (! this.payments.length) {
          return 0
        }

        return this.payments
          .filter((payment) => payment.type === 'outgoing')
          .reduce((total, payment) => {
            return total + payment.raw_price
          }, 0);
      },

      totalPending() {
        if (! this.payments.length) {
          return 0
        }

        return this.payments
          .filter((payment) => payment.type === 'outgoing' && payment.status === 'pending')
          .reduce((total, payment) => {
            return total + payment.raw_price
          }, 0);
      },

      tooltip() {
        return 'Guestio Cash expires in ' + this.daysTillExpiration + ' days.'
      },
    },

    methods: {
      fetchUserBalance() {
        api.get('/balance')
          .then(({ data }) => {
            this.guestioCashBalance = Number.isNaN(Number.parseFloat(data.data.guestio_cash))
              ? 0
              : parseFloat(data.data.guestio_cash);

              this.daysTillExpiration = Number.isNaN(Number.parseFloat(data.data.guestio_cash_expiration))
              ? 90
              : parseFloat(data.data.guestio_cash_expiration);
          })
          .catch(() => {
            //
          })
      },
    },

    mounted() {
      this.fetchUserBalance();
    }
  }
</script>
