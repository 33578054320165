<template>
  <div>
    <div>{{ activity.description }}:</div>
    <div class="bg-gray-50 mt-4 rounded-xl p-6">
      <p>{{ user.id == initiator.id ? 'Your ' : 'The ' }} interview booking request {{ booking.initiator == 'show' ? 'with' : 'on' }} <router-link class="font-bold text-violet" :to="booking.initiator == 'show' ? `/guests/${booking.guest.id}` : `/shows/${booking.show.id}`">{{ booking.initiator == 'show' ? booking.guest.name : booking.show.name }}</router-link> doesn't have an interview date yet. Has your interview been scheduled?</p>
      <div
        class="mt-6 w-full flex justify-center items-center space-x-4"
      >
        <button
          :disabled="!canAnswer"
          @click="confirmScheduledBooking()"
          class="rounded-full bg-indigo-gradiant text-white text-sm font-bold px-5 py-2 w-5/12 flex items-center justify-center disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <span>Yes</span>
        </button>
        <button
          :disabled="!canAnswer"
          @click="confirmNotScheduledYet()"
          class="font-bold py-3 text-sm w-5/12 disabled:opacity-50 disabled:cursor-not-allowed"
          >
          <span>No</span>
        </button>
      </div>
      <p
        v-if="!canAnswer"
        class="text-red-500 text-sm mt-1 font-light"
      >
        The booking status is <span class="font-medium">{{ booking.status }}</span>
      </p>
    </div>
  </div>
</template>

<script>
  import api from '@/api'

  export default {
    name: 'BookingWithoutInterviewDate',

    props: {
      activity: Object,
      booking: Object,
      user: Object,
    },

    computed: {
      initiator() {
        return this.booking.initiator == 'show' ? this.booking.show : this.booking.guest
      },

      canAnswer() {
        return this.booking.status != 'completed' && this.booking.status != 'expired' && this.booking.status != 'canceled';
      },
    },

    methods: {
      async confirmScheduledBooking() {
        try {
          await this.$store.dispatch("dashboard/bookings/confirmScheduled", this.booking.id);
          window.Event.$emit('refreshActivity')
        } catch (error) {
          this.$toast.error('There was an error. Please try again later.')
        }
      },
      async confirmNotScheduledYet() {
        try {
          await api.post(`/bookings/${this.booking.id}/confirm-not-scheduled-yet`);
          window.Event.$emit('refreshActivity')
        } catch (error) {
          this.$toast.error('There was an error. Please try again later.')
        }
      },
    },
  }
</script>
