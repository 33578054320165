<template>
    <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M14.9051 2.00639L13.0504 0.100013C12.9881 0.0359843 12.9035 0 12.8153 0C12.727 0 12.6424 0.0359843 12.5801 0.100013L4.7471 8.13404C4.68479 8.19807 4.60019 8.23405 4.51195 8.23405C4.42372 8.23405 4.33912 8.19807 4.2768 8.13404L2.42217 6.22766C2.35986 6.16363 2.27526 6.12765 2.18702 6.12765C2.09879 6.12765 2.01419 6.16363 1.95187 6.22766L0.097244 8.13404C-0.0324147 8.26697 -0.0324147 8.48246 0.097244 8.6154L4.2768 12.9C4.33912 12.964 4.42372 13 4.51195 13C4.60019 13 4.68479 12.964 4.7471 12.9L14.9051 2.48299C15.0316 2.35061 15.0316 2.13876 14.9051 2.00639Z" fill="white"/>
    </svg>
</template>

<script>
export default {
    name:'CheckSmall',
}
</script>