<template>
  <div class="flex flex-col md:flex-row md:justify-between">
    <div class="w-64 flex-shrink-0">
      <span class="text-gray-600">Your Image</span>
    </div>
    <div class="md:ml-8 mt-1 md:mt-0 flex-1 ">
      <div v-if="! userProp.imageUrl" class="mb-4 flex items-center">
        <img class="rounded-full w-12 h-12 shadow-sm" :src="gravatarSrc">
        <span class="ml-4">
          We're using your <a href="https://gravatar.com" target="_blank" rel="noopener noreferer" class="text-pink-500 hover:underline">gravatar.com</a> image
        </span>
      </div>
      <div class="flex items-center justify-between">
        <div class="w-full flex items-center">
          <MediaUploadSingle
            entity="user"
            :working="working"
            :modelId="userProp.id"
            :imageUrl="form.imageUrl"
            :imageUuid="userProp.imageUuid"
            :error="uploadingError"
            @uploaded="uploaded"
            @deleted="deleted"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import MediaUploadSingle from '@/components/shared/MediaUploadSingle';

  export default {
    props: {
      userProp: Object,
      admin: {
        type: Boolean,
        default: false,
      },
    },

    components: {
      MediaUploadSingle
    },

    data() {
      return {
        working: false,
        uploadingError: false,
        form: {
          imageUrl: this.userProp.imageUrl
        }
      }
    },

    computed: {
      gravatarSrc() {
        return `https://www.gravatar.com/avatar/${this.userProp.email_hash}?s=100&r=g`
      },

      updateAction() {
        if (this.admin) {
          return `auth/updateAdminUser`
        }

        return `auth/updateUser`
      }
    },

    methods: {
      update() {
        this.working = true
        this.uploadingError = false

        this.$store.dispatch(this.updateAction, this.form)
          .then(() => {
            this.working = false
          })
          .catch(() => {
            this.working = false
            this.uploadingError = true
          })
      },

      uploaded(url) {
        this.form.imageUrl = url
        this.update()
      },

      deleted() {
        this.form.imageUrl = null
      },
    }
  }
</script>
