<template>
  <div class="mt-12">
    <template v-if="! members.length && ! invitations.length">
      <div class="mb-2 py-6 flex flex-col justify-center items-center bg-white rounded-xl border-2 border-dashed opacity-75">
        <span class="text-sm font-medium text-grayDark">No team members added</span>

        <div class="mt-4">
          <AddTeamMemberDialog @invited="addInvitation" class="text-pink-500 underline">
            Add member +
          </AddTeamMemberDialog>
        </div>
      </div>
    </template>

    <template v-else>
      <div>
        <template v-if="members.length">
          <div v-for="(user, userIndex) in members" :key="`member-${userIndex}`" class="bg-white shadow-4xl rounded-xl mb-12">
            <div class="border-b px-8 py-6 flex justify-between items-center">
              <div class="rounded-lg bg-violet bg-opacity-10 px-4 py-3 text-violet">
                {{ user.email }}
              </div>
              <ConfirmDialog
                @confirm="removeMember(userIndex, $event)"
                confirm-action="Yes, Remove"
                :dialog-title="`Remove ${user.first_name} ${user.last_name}?`"
                dialog-text="Are you sure you want to remove this member from your team?"
                class="ml-8 flex items-center text-red-500 text-sm"
              >
                <span class="ml-2 font-light">Remove</span>
              </ConfirmDialog>
            </div>
            <div class="flex items-center justify-between px-8 py-6">
              <div class="flex-1 flex items-center space-x-2">
                <div class="rounded-full capitalize bg-gray-50 px-12 py-3">
                  {{ user.pivot.role === 'agency' ? 'Agency' : 'Basic' }}
                </div>
                <div>
                  <button type="button" v-tooltip="roleTooltip(user.pivot.role)">
                    <InfoIcon class="h-5 w-5 text-grayDark" />
                  </button>
                </div>

              </div>

              <div class="flex-1 space-y-6">
                <div class="flex items-center justify-between">
                  <p class="font-medium text-blue-800 leading-0">
                    Email Notifications
                  </p>
                  <ToggleButton
                    :enabled="!! user.pivot.email_notifications"
                    @updated="toggleTeamMemberEmailNotifications(user, $event)"
                  />
                </div>

                <div class="flex items-center justify-between">
                  <p class="font-medium text-blue-800 leading-0">
                    SMS Notifications
                  </p>
                  <ToggleButton
                    :enabled="!! user.pivot.sms_notifications"
                    @updated="toggleTeamMemberSmsNotifications(user, $event)"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-if="invitations.length">
          <div class="mt-8">
            <h3 class="text-xl font-semibold mb-2">
              Pending Invitations
            </h3>
            <div v-for="(invitation, invitationIndex) in invitations" :key="`invitation-${invitationIndex}`" class="md:py-6 border-b last:border-b-0 border-gray-300 flex items-center justify-between">
              <div>
                {{ invitation.email }} ({{ invitation.role === 'admin' ? 'Basic' : 'Agency' }})
              </div>
              <div>
                <ConfirmDialog
                  @confirm="cancelInvitation(invitationIndex, $event)"
                  confirm-action="Yes, Cancel"
                  :dialog-title="`Cancel Invitation?`"
                  :dialog-text="`Are you sure you want to cancel the invitation for ${invitation.email}?`"
                  class="ml-8 flex items-center text-pink-500"
                >
                  <span class="ml-2 font-light">Cancel</span>
                </ConfirmDialog>
              </div>
            </div>
          </div>
        </template>

        <div class="mt-12">
          <AddTeamMemberDialog @invited="addInvitation" class="text-pink-500 underline">
            Add member +
          </AddTeamMemberDialog>
        </div>
      </div>
    </template>


  </div>
</template>

<script>
  import api from '@/api'
  import InfoIcon from "@/components/svgs/InfoIcon"
  import ConfirmDialog from "@/components/shared/ConfirmDialog"
  import ToggleButton from "@/components/shared/ToggleButton"
  import AddTeamMemberDialog from "@/components/settings/AddTeamMemberDialog"

  export default {
    props: {
      userProp: Object,
    },

    components: {
      InfoIcon,
      ConfirmDialog,
      ToggleButton,
      AddTeamMemberDialog,
    },

    data() {
      return {
        working: false,
        showAddMember: false,
        form: {
          email: '',
          role: 'agency',
        },
        members: [],
        invitations: [],
      }
    },

    watch: {
      showAddMember(showAddMember) {
        if (! showAddMember) {
          this.form.email = ''
          return
        }

        //
      }
    },

    computed: {
      authUser() {
        return this.$store.getters['auth/getUser']
      }
    },

    methods: {
      roleTooltip(type) {
        if (type === 'agency') {
          return 'AGENCY level allows users to manage everything on your profiles, including Stripe connect setup.'
        }

        return 'BASIC level allows users to manage your profiles with basic actions that do not require primary account login.'
      },

      addInvitation(invitation) {
        this.invitations.push(invitation)
      },

      removeMember(index, callback) {
        api
          .delete(`/users/me/team/members/${this.members[index].id}`)
          .then(() => {
            this.members.splice(index, 1)
            this.$toast.success('Team member removed!')
          })
          .catch(() => {
            this.$toast.error('Error! Something went wrong.')
          })
          .finally(() => callback.call())
      },

      fetchTeamMembers() {
        api
          .get('/users/me/team/members')
          .then(({ data }) => {
            this.members = data.data
          })
          .catch(() => {
            console.log('could not fetch team members')
          })
      },

      fetchTeamInvitations() {
        api
          .get('/users/me/team/invitations')
          .then(({ data }) => {
            this.invitations = data.data
          })
          .catch(() => {
            console.log('could not fetch team invitations')
          })
      },

      cancelInvitation(index, callback) {
        api
          .delete(`/users/me/team/invitations/${this.invitations[index].id}`)
          .then(() => {
            this.invitations.splice(index, 1)
            this.$toast.success('The invitation was canceled')
          })
          .catch(() => {
            this.$toast.error('The invitation was not canceled')
          })
          .finally(() => callback.call())
      },

      toggleTeamMemberEmailNotifications(user, enabled) {
        // if enabled is false
        // check if any other user has the email notifications turned on
        // if not, stop and show error
        if (! enabled && ! this.hasOtherMembersWithEmailNotificationTurnedOn(user)) {
          this.$toast.error('At least one team member needs to have the email notifications turned on')
          return
        }

        user.pivot.email_notifications = enabled ? 1 : 0

        api
          .patch(`/users/me/team/members/${user.id}`, {
            email_notifications: enabled ? 1 : 0,
          })
          .catch(() => {
            this.$toast.error('Error! Something went wrong.')
            user.pivot.email_notifications = enabled ? 0 : 1
          })
      },

      toggleTeamMemberSmsNotifications(user, enabled) {
        user.pivot.sms_notifications = enabled ? 1 : 0

        api
          .patch(`/users/me/team/members/${user.id}`, {
            sms_notifications: enabled ? 1 : 0,
          })
          .catch(() => {
            this.$toast.error('Error! Something went wrong.')
            user.pivot.sms_notifications = enabled ? 0 : 1
          })
      },

      hasOtherMembersWithEmailNotificationTurnedOn(user) {
        if (this.authUser.email_notifications) {
          return true
        }

        return !! this.members
          .filter(u => u.id !== user.id && u.pivot.email_notifications)
          .length
      }
    },

    created() {
      this.fetchTeamMembers()
      this.fetchTeamInvitations()
    }
  }
</script>
