<template>
  <div class="w-full mb-1">
    <img class="w-6 h-6 lg:w-8 lg:h-8 rounded-full border-2 border-green-500 mb-1 lg:mb-2 object-cover" :src="bookingEntity.imageUrl || 'https://picsum.photos/200'" alt="">
    <div class="text-xs lg:text-sm flex flex-col leading-tight">
      <span class="font-bold text-pink-500">{{ bookingEntity.name || 'Unknown' }}</span>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'BookingAppointment',
    props: {
      booking: Object,

      entity: {
        type: String,
        default: 'guest',
      }
    },

    computed: {
      bookingEntity() {
        return this.booking[this.entity] || {}
      }
    }
  }
</script>
