<template>
  <div v-if="showBalances" class="dashboard-card">
    <h3>Guestio Balance</h3>
    <div class="grid xl:grid-cols-2 row-gap-3 mb-6">
      <div class="flex flex-col items-start xl:items-center">
        <template v-if="working">
          <div class="flex-1 flex items-center justify-center">
            <loading-icon class="h-1 text-pink-500" />
          </div>
        </template>
        <template v-else>
          <h5 class="text-xl xl:text-3xl">{{ totalBalance | currency }}</h5>
          <span class="text-md text-gray-500">TOTAL</span>
        </template>
      </div>
      <div class="flex flex-col items-start xl:items-center xl:border-l-2 border-gray-300">
        <template v-if="working">
          <div class="flex-1 flex items-center justify-center">
            <loading-icon class="h-1 text-pink-500" />
          </div>
        </template>

        <template v-else>
          <h5 class="text-xl xl:text-3xl">{{ guestioCash | currency }}</h5>
          <span class="text-md text-gray-500">GUESTIO CASH</span>
          <!-- <button class="mt-2 text-md text-pink-500">Apply Coupon</button> -->
        </template>
      </div>
    </div>
    <div>
      <router-link :to="{name: 'Payments'}" class="block rounded-full bg-guestio-gray-100 w-full py-2 px-2 text-sm xl:text-base flex justify-center text-center">
        View Payments and Payouts
      </router-link>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  export default {
    name: "BalanceCard",

    data() {
      return {
        showBalances: true,
        working: false,
        totalBalance: 0,
        availableBalance: 0,
        guestioCash: 0,
      }
    },

    filters: {
      currency(value) {
        return '$' + value
      }
    },

    created() {
      this.working = true

      api.get('/balance')
        .then(({ data }) => {
          this.totalBalance = data.data.total_balance
          this.availableBalance = data.data.available_balance
          this.guestioCash = data.data.guestio_cash
        })
        .catch(() => {
          this.showBalances = false
        })
        .finally(() => this.working = false)
    }
  }
</script>

<style scoped>

</style>
