<template>
  <div>
    <template>
      <div class="flex flex-wrap-reverse lg:flex-no-wrap items-center justify-between w-full mb-6 md:mb-13">
        <div class="flex items-center rounded-full shadow-xs bg-white border border-guestio-gray-300 py-2.5 pl-3 pr-5 max-w-xs w-full">
          <SearchIcon class="flex-shrink-0 mr-3" />
          <input type="text" class="bg-transparent w-full" placeholder="Search..." v-model="searchQuery" @change="search" />
        </div>
        <template v-if="user.account_type == 'standard'">
        <modal
          class="bg-indigo-gradiant text-white font-bold px-10 py-3 rounded-full flex items-center justify-center hover:opacity-90 mb-4 md:mb-0"
        >
          <span>Create MINIVIEW™</span>
          <template v-slot:content="{ close }">
            <SubscribeModal :user="user" :plans="plans" @subscribed="subscribed($event, close)" />
          </template>
        </modal>
      </template>
        <router-link
          v-else
          class="bg-indigo-gradiant text-white font-bold px-10 py-3 rounded-full flex items-center justify-center hover:opacity-90 mb-4 md:mb-0"
          :to="{ name: 'MiniviewCreate1' }"
        >
          Create MINIVIEW™
        </router-link>
      </div>

      <div class="grid grid-cols-1 row-gap-10" ref="miniviews">
        <div v-if="loading" class="max-w-md mx-auto flex justify-center">
          <loading-icon class="h-10 w-10 text-pink-500"/>
        </div>

        <template v-else-if="miniviews.length">
          <!--Table header-->
          <TableHeader/>

          <!--Rows-->
          <MiniviewRow
            :miniview="miniview"
            v-for="(miniview, index) in miniviews"
            :key="`miniview-row-${index}`"
          />

          <div v-if="pagination" class="pagination my-12 flex justify-end">
            <nav class="h-10 flex items-center text-gray-500" role="pagination">
              <router-link :to="prevPageRoute" class="opacity-40 w-10 h-full flex items-center justify-center border border-gray-500 rounded-l-xl hover:opacity-60 transition ease-in-out duration-150">
                <ChevronLeftAltIcon class="h-5" />
              </router-link>

              <router-link
                v-for="page in paginationRange"
                :key="page"
                :to="pageRoute(page)"
                :class="{'opacity-40': page !== pagination.current_page}"
                class="w-10 h-full flex items-center justify-center border border-gray-500"
              >
                {{ page }}
              </router-link>

              <router-link v-if="pagination.links.next" :to="nextPageRoute" class="opacity-40 w-10 h-full flex items-center justify-center border border-gray-500 rounded-r-xl hover:opacity-60 transition ease-in-out duration-150">
                <ChevronRightAltIcon class="h-5" />
              </router-link>
            </nav>
          </div>
        </template>

        <div v-else class="max-w-md mx-auto flex flex-col items-center">
          <h2 class="text-3xl font-semibold">
            No MINIVIEWs™ found.
          </h2>
          <div class="mt-12">
            <button
              @click.prevent="reload"
              :disbled="loading"
              :class="{'opacity-50': loading}"
              class="flex items-center shadow border border-gray-50 rounded-full py-3 px-6 bg-gray-50 hover:bg-gray-100"
            >
              Reload
            </button>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
  import { range } from 'lodash'
  import MiniviewRow from "@/components/miniviews/MiniviewRow";
  import TableHeader from "@/components/miniviews/TableHeader";
  import ChevronLeftAltIcon from "@/components/svgs/ChevronLeftAltIcon"
  import ChevronRightAltIcon from "@/components/svgs/ChevronRightAltIcon";
  import SearchIcon from "@/components/svgs/SearchIcon";
  import {mapActions, mapState} from "vuex";
  import { debounce } from 'lodash'
  import SubscribeModal from '@/components/settings/SubscribeModal'
  import Modal from '@/components/shared/Modal'

  export default {
    name: "MiniviewsIndex",

    components: {
      TableHeader,
      MiniviewRow,
      ChevronLeftAltIcon,
      ChevronRightAltIcon,
      SearchIcon,
      SubscribeModal,
      Modal,
    },

    data() {
      return {
        loading: true,
        currentPage: 1,
        searchQuery: '',
        plans: {
          monthly: process.env.VUE_APP_PRO_MONTHLY_PRICE || 97,
          yearly: process.env.VUE_APP_PRO_YEARLY_PRICE || 997,
        },
      }
    },

    watch: {
      $route(to, from) {
        if (to.query.page === from.query.page) {
          return
        }

        this.currentPage = to.query.page
        this.$refs.miniviews.scrollIntoView({behavior: 'smooth'})
        this.fetchMiniviews()
      }
    },

    computed: {
      ...mapState({
        miniviews: state => state.miniviews.items,
        pagination: state => state.miniviews.pagination,
      }),

      urlQuery() {
        return this.$route.query
      },

      prevPageRoute() {
        if (! this.pagination.links.previous) {
          return this.pageRoute(1)
        }

        return this.pageRoute(this.pagination.current_page - 1)
      },

      nextPageRoute() {
        if (! this.pagination.links.next) {
          return this.pageRoute(this.pagination.total_pages)
        }

        return this.pageRoute(this.pagination.current_page + 1)
      },

      paginationRange() {
        if (this.pagination.total_pages <= 5) {
          return this.pagination.total_pages
        }

        if (this.pagination.current_page > 3) {
          return range(this.pagination.current_page - 2, this.pagination.current_page + 3)
        }

        return 5
      },

      user() {
        return this.$store.getters['auth/getUser']
      },
    },

    created() {
      this.fetchMiniviews();
    },

    methods: {
      ...mapActions({
        getMiniviews: 'miniviews/getAll'
      }),

      async fetchMiniviews() {
        this.loading = true;

        const params = { page: this.currentPage, limit: 10 };
        if (this.searchQuery != '') {
          params.title = this.searchQuery;
        }

        try {
          await this.getMiniviews(params);
        } catch (error) {
          console.log(error);
        } finally {
          this.loading = false;
        }
      },

      reload() {
        this.currentPage = 1;
        this.fetchMiniviews();
      },

      pageRoute(pageNo) {
        return {
          name: 'Miniviews',
          query: {
            ...this.urlQuery,
            page: pageNo,
          },
        }
      },

      search: debounce(function () {
        this.fetchMiniviews();
      }, 750),

      subscribed(subscription, callback) {
        callback.call()
        this.$router.push({ name: 'MiniviewCreate1' })
      },
    },
  }
</script>

