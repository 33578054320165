<template>
  <div id="app">
    <Header/>
    <div class="flex overflow-hidden">
      <Sidebar/>
      <main v-if="hideBody" class="bg-gray-25 flex-1 overflow-hidden relative">
        <div v-if="adminUser && adminUser.team_role === 'agency'" class="bg-indigo-gradiant z-10">
          <div class="container mx-auto h-14 flex items-center justify-between">
            <span class="text-white text-sm">
              You are impersonating <span class="font-bold">{{ user.first_name }} {{ user.last_name }}</span>
            </span>

            <span>
              <modal
                :with-close-button="false"
                class="rounded-full bg-white text-pink-500 text-sm py-2 px-6 font-semibold"
                @opened="stopUserImpersonation"
              >
                Stop Impersonating
                <template #content>
                  <ImpersonationModal>
                    Please wait...
                  </ImpersonationModal>
                </template>
              </modal>
            </span>
          </div>
        </div>
        <router-view/>
      </main>
    </div>
    <Footer v-show="!hideFooter"/>
    <portal-target name="modal" multiple></portal-target>

     <transition
      enter-active-class="transition ease-in-out duration-300 transform"
      enter-class="translate-x-16 opacity-0"
      enter-to-class="translate-x-0 opacity-100"
      leave-active-class="transition ease-in-out duration-300 transform absolute inset-0"
      leave-class="translate-x-0 opacity-100"
      leave-to-class="translate-x-16 opacity-0"
    >
      <template v-if="isTermsOpen">
        <TermsAndConditionsModal @hide="isTermsOpen = false"/>
      </template>
    </transition>
  </div>
</template>

<script>
import Header from "./components/shared/Header";
import Footer from "./components/shared/Footer";
import Sidebar from "./components/shared/Sidebar";
import TermsAndConditionsModal from "./components/modals/TermsAndConditionsModal";
import Modal from '@/components/shared/Modal'
import ImpersonationModal from '@/components/auth/ImpersonationModal'

import {mapState, mapActions} from "vuex";
import isMobile from "is-mobile";

export default {
  name: "App",

  metaInfo: {
    title: 'Guestio.com | Making Interviews Better'
  },

  components: {
    Footer,
    Header,
    Sidebar,
    TermsAndConditionsModal,
    Modal,
    ImpersonationModal,
  },

  async mounted() {
    window.Event.$on("toast", options => {
      this.showNotification(options);
    });

    window.Event.$on("login", () => {
      this.checkTermsAccepted();
    });

    this.startIntercom();

    if (this.authenticated) {
      await this.$store.dispatch("auth/getUser");

      this.startPendo();

      this.checkTermsAccepted();

      this.checkEmailVerification();

      return
    }

    this.$store.commit('auth/logout')
  },
  data() {
    return {
      mobile: isMobile(),
      isTermsOpen: false
    };
  },

  methods: {
    ...mapActions({
      stopImpersonation: 'auth/stopImpersonation'
    }),

    checkTermsAccepted() {
      if(this.user && this.user.terms_accepted_at === null) {
        this.isTermsOpen = true;
      }
    },

    checkEmailVerification() {
      if (this.user.status !== 'pending') {
        return;
      }

      if (
        ['/verify-email', '/check-email'].includes(this.$route.path)
        || this.$route.path.startsWith('/register')
      ) {
        return;
      }

      return this.$router.push({
        path: '/check-email',
        params: {
          redirect: this.$route.path,
        },
      });
    },

    startIntercom(){
      window.intercomSettings = {
        app_id: "zgtyml9m",
        custom_launcher_selector: '.open-intercom',
      };
      // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/zgtyml9m'
      (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/zgtyml9m';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
    },

    startPendo() {
      if (! window.pendo || process.env.VUE_APP_ENV !== 'production') {
        return;
      }

      window.pendo.initialize({
        visitor: {
          id: this.user.id,
          email: this.user.email,
          full_name: `${this.user.first_name} ${this.user.last_name}`,
        },

        account: {
          id: this.user.id,
          name: `${this.user.first_name} ${this.user.last_name}`,
          is_paying: this.user.account_type === 'pro',
        }
      });
    },

    showNotification(options) {
      this.$toast.open(options);
    },

    stopUserImpersonation() {
      this.stopImpersonation()
        .then(() => {
          setTimeout(() => {
            window.location.href = '/dashboard'
          }, 1000)
        })
        .catch(error => {
          if (error.response) {
            this.$toast.error(error.response.data.message)
            return
          }

          this.$toast.error('Cannot stop impersination at this time.')
        })
    }
  },
  computed: {
    ...mapState({
      isOpen: state => state.isOpen,
      guest: state => state.guest.guest,
      show: state => state.shows.show,
      user: state => state.auth.user
    }),

    adminUser() {
      return this.$store.getters["auth/getAdminUser"];
    },

    hideBody() {
      if (this.mobile) {
        if (this.isOpen) {
          return false;
        }
        return true;
      }
      return true;
    },

    authenticated() {
      return this.$store.getters["auth/authenticated"];
    },
    hideFooter: function () {
      return this.$route.name == 'GuestProfile' || this.$route.name == 'Guest' ||this.$route.name == 'Show'
    },
  }
};
</script>
