<template>
  <div class="grid grid-cols-12 row-gap-2 lg:row-gap-0 col-gap-4 bg-white rounded-xl shadow-lg border border-gray-50">
    <!--Date-->
    <div
      class="col-span-2 lg:col-span-1 lg:row-span-1  flex flex-col items-center justify-center border-r py-4">
      <span>{{ moment(payment.payment_date).format('MMM') }}</span>
      <span class="font-semibold text-xl">{{ moment(payment.payment_date).format('D') }}</span>
    </div>
    <!--Total-->
    <div v-if="payment.type == 'incoming'" class="col-span-4 lg:col-span-2 col-start-3 row-start-1 lg:row-start-auto flex items-center">
      <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-down w-7 h-7 text-green-600">
      <g clip-path="url(#clip0_8429:26629)">
      <path d="M11.0103 2.21429C11.4501 1.45238 12.5499 1.45238 12.9897 2.21429L21.4026 16.7857C21.8424 17.5476 21.2926 18.5 20.4128 18.5H3.58718C2.70741 18.5 2.15755 17.5476 2.59744 16.7857L11.0103 2.21429Z" stroke="#138643" stroke-width="1.14286"/>
      <path d="M11.9982 8.5V15.3571" stroke="#138643" stroke-width="1.14286" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M15.4275 11.9289H8.57031" stroke="#138643" stroke-width="1.14286" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_8429:26629">
      <rect width="24" height="21" fill="white"/>
      </clipPath>
      </defs>
      </svg>

      <h4 class="ml-2 text-xl text-green-600">${{payment.raw_price | noDecimals }}</h4>
    </div>
    <div v-else class="col-span-4 lg:col-span-2 col-start-3 row-start-1 lg:row-start-auto flex items-center">
      <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow-down w-7 h-7 text-black">
      <g clip-path="url(#clip0_8429:26609)">
      <path d="M11.0103 18.2857C11.4501 19.0476 12.5499 19.0476 12.9897 18.2857L21.4026 3.71429C21.8424 2.95238 21.2926 2 20.4128 2H3.58718C2.70741 2 2.15755 2.95238 2.59744 3.71429L11.0103 18.2857Z" stroke="#292546" stroke-width="1.14286"/>
      <path d="M15.4275 7.42888H8.57031" stroke="#292546" stroke-width="1.14286" stroke-linecap="round" stroke-linejoin="round"/>
      </g>
      <defs>
      <clipPath id="clip0_8429:26609">
      <rect width="24" height="20" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
      </defs>
      </svg>

      <h4 class="ml-2 text-xl">${{payment.raw_price | noDecimals }}</h4>
    </div>
    <!--To/From-->
    <div class="col-span-3 lg:col-span-7 col-start-6 row-start-1 lg:row-start-auto  flex items-center ">
      {{payment.name}}
    </div>
    <!--Button-->
    <div
      class="col-span-3 lg:col-span-2 col-start-9 row-start-1 lg:row-start-auto lg:justify-end flex items-center lg:pr-5">
      <a v-if="payment.status === 'paid' && payment.id" :href="downloadPdfUrl(payment)" target="_blank" rel="noopener" class="rounded-full bg-gray-200 px-5 py-3 flex items-center hover:bg-gray-300 transition duration-150 ease-in-out">
        <span class="mr-2">PDF</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
            stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M7 10L12 15L17 10" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M12 15V3" stroke="#6B6B6B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </a>

      <span v-else-if="payment.status === 'pending'" class="flex items-center">
        <StatusCircleIcon status-class="text-orange-500" />
        <span class="capitalize">Payment pending</span>
      </span>
    </div>
  </div>
</template>

<script>
  import StatusCircleIcon from '@/components/svgs/StatusCircleIcon';

  import moment from 'moment'
  export default {
    name: "PaymentRow",

    components: {
      StatusCircleIcon
    },

    props: {
      payment: Object
    },

    filters: {
      noDecimals(value) {
        return (value / 100).toFixed(0)
      }
    },

    methods: {
      moment(date) {
        return moment(date)
      },

      downloadPdfUrl(payment) {
        if (payment.payout_id) {
          return `${process.env.VUE_APP_API_ENDPOINT}/payments/${payment.id}/download?payout_id=${payment.payout_id}`
        }

        return `${process.env.VUE_APP_API_ENDPOINT}/payments/${payment.id}/download`
      },
    }

  }
</script>

<style scoped>

</style>
