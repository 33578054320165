<template>
  <div
    @click="openModal"
    :class="{
      'border-l':index % 7 == 0,
      'border-t':index < 7,
      'cursor-pointer': bookings.length
    }"
    class="pb-full relative border-b border-r hover:bg-gray-50 overflow-hidden transition duration-300"
  >
    <div class="absolute inset-0 p-1 md:p-3 xl:p-4">
      <!--Day-->
      <span class="mb-1 xl:mb-2 block leading-none" :class="{'opacity-40': ! day.isSame(currentMonth, 'month')}">
        <span class="text-xs md:text-sm xl:text-base whitespace-no-wrap">
          <span class="uppercase md:font-semibold">{{ day.date() == 1 ? day.format('MMM') : '' }}</span>  {{ day.format('D') }}
        </span>
      </span>
      <!--Appointment-->
      <div class="hidden md:flex md:items-end xl:flex-col xl:items-start">
        <div v-if="bookings.length">
          <BookingAppointment :booking="bookings[0]" :entity="user.id == bookings[0].guest.user_id ? 'show' : 'guest'"/>
        </div>
        <!--More than one -->
        <span v-if="bookings.length > 1" class="font-bold text-white bg-pink-500 rounded px-1 text-xs ml-2 mb-1 xl:mb-0 xl:ml-0">
          +{{bookings.length - 1}}
        </span>
      </div>

      <div class="absolute inset-0 md:hidden flex items-end justify-end p-1">
        <span v-if="bookings.length" class="text-white bg-pink-500 rounded px-1" style="font-size: 9px">
          {{ bookings.length }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
  import BookingAppointment from "./BookingAppointment";
  import {mapMutations} from "vuex";

  export default {
    name: 'Day',

    components: {BookingAppointment},

    props: {
      index: Number,
      day: Object,
      bookings: Array,
      currentMonth: Object,
    },

    computed: {
      user() {
        return this.$store.getters['auth/getUser']
      }
    },

    methods: {
      ...mapMutations({
        setCalendarModal: "dashboard/bookings/setCalendarModal",
        setCalendarModalData: "dashboard/bookings/setCalendarModalData",
      }),
      openModal() {
        if (! this.bookings.length) {
          return
        }

        this.setCalendarModal({showCalendarModal: true})
        this.setCalendarModalData({
          date: this.day,
          bookings: this.bookings,
        })
        document.body.classList.add('overflow-hidden')
      }
    }

  }
</script>
