<template>
  <div class="flex items-center w-full my-5">
    <template v-if="showSeparator">
      <span class="border-t border-gray-400 w-4/5 h-1"></span>
      <span class="font-bold uppercase flex-shrink-0 mx-5 text-xs">{{ currentDate.format('MMM D') }}</span>
      <span class="border-t border-gray-400 w-4/5 h-1"></span>
    </template>
  </div>
</template>
<script>
import moment from 'moment'
export default {
  name: 'Separator',
  props: {
    date: String,
    prevDate: String,
  },

  computed: {
    currentDate() {
      return moment.utc(this.date).local()
    },

    previousDate() {
      if (! this.prevDate) {
        return
      }

      return moment.utc(this.prevDate).local()
    },

    showSeparator() {
      if (! this.prevDate) {
        return true
      }

      if (! this.previousDate.isSame(this.currentDate, 'day')) {
        return true
      }

      return false
    }
  },
}
</script>
