<template>
  <div>
    <div class="">
      <h3 class="text-black font-semibold text-2xl text-center">Select a date</h3>
      <div class="mt-2 flex justify-center -mx-3 h-32">
        <label v-for="(slot, index) in booking.proposed_dates" class="cursor-pointer h-full" :key="`slot-${index}`">
          <div :class="{'border-pink-500': slot == confirmedDate}" class="mx-3 px-2 py-4 border rounded-lg shadow-lg bg-white flex flex-col items-center justify-center leading-none h-full">
            <div class="sm:text-lg text-gray-500">{{ moment(slot).format('MMMM D') }}</div>
            <div class="mt-4 sm:mt-4 font-semibold sm:text-xl">{{ moment(slot).format('h:mm a') }}</div>
          </div>
          <input type="radio" class="hidden" v-model="confirmedDate" :value="slot">
        </label>
      </div>
    </div>
    <div class="px-6 pt-6 sm:px-12 sm:pt-10">
      <button @click="confirmBooking" class="rounded-full h-16 bg-indigo-gradiant text-white text-lg sm:text-xl w-full flex justify-center items-center" :class="{'opacity-50': loading || ! confirmedDate}">
        <span v-if="loading">
          <loading-icon class="h-5 w-5"/>
        </span>
        <span v-else>Confirm Booking</span>
      </button>

      <p v-if="confirmError" class="text-center mt-4 text-red-500">{{ confirmError }}</p>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  export default {
    name: 'ConfirmBooking',

    data() {
      return {
        confirmedDate: null,
        loading: false,
        confirmError: false
      }
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      proposedDates() {
        return Object.values(this.booking.proposed_dates)
      }
    },

    methods: {
      moment(date) {
        return moment.utc(date).local()
      },

      confirmBooking() {
        this.loading = true

        this.$store.dispatch('dashboard/bookings/confirmBooking', {
          id: this.booking.id,
          confirmed_date: this.confirmedDate
        }).then(() => {
          this.$emit('close')
          window.Event.$emit('refreshActivity')
        }).catch(error => {
          if (error.response) {
            this.confirmError = error.response.data.errors.confirmed_date[0]
          }
          this.confirmError = true
        }).finally(() => this.loading = false)
      }
    },

    mounted() {
      if (this.proposedDates.length == 1) {
        this.confirmedDate = this.proposedDates[0]
      }
    }
  }
</script>
