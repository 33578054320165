<template>
  <div v-if="userOwnsBookingGuest && !booking.is_confirmed && !booking.canceled_at && !booking.is_expired && !booking.is_completed">
    <alert-dialog
      class="w-full rounded-full py-3 text-center text-violet"
      confirm="Reject Booking"
      confirming="Rejecting"
      :confirm-delay="3"
      @confirm="rejectBooking"
      @cancel="cancelationReason = null"
    >
      <span>Reject Booking</span>
      <span slot="title">Reject Booking?</span>
      <template v-slot:content>
        <div>Are you sure you want to reject this booking? This action cannot be undone.</div>
        <div class="mt-6">
          <div>
            <div class="flex justify-between items-center">
              <label for="cancelation_reason" class="font-semibold text-black">Reason</label>
              <span class="text-xs text-gray-500">optional</span>
            </div>
            <div class="mt-2">
              <textarea v-model="cancelationReason" name="cancelation_reason" id="cancelation" cols="10" class="form-textarea text-sm w-full"></textarea>
            </div>
          </div>
        </div>
      </template>
    </alert-dialog>
  </div>
</template>

<script>
  import AlertDialog from '@/components/shared/AlertDialog';

  export default {
    name: 'ConfirmBooking',
    components: {AlertDialog},

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      userOwnsBookingGuest() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingGuest']
      },
    },

    methods: {
      rejectBooking(callback) {
        this.$store.dispatch('dashboard/bookings/rejectBooking', {
          id: this.$route.params.bookingId,
          reason: this.cancelationReason,
        }).then(() => {
          callback.call()
          window.Event.$emit('refreshActivity')
        }).finally(() => {
          this.cancelationReason = null
        })
      },
    },
  }
</script>
