<template>
  <div  v-if="profile.imageUrl !== null && imgError==false || currentRoute!=='Home'" class="flex flex-col itemCard flex-shrink-0">
    <router-link :to="profileRoute"
      class="rounded-lg pb-full mb-4 relative overflow-hidden w-full">
      <div  class="absolute hover:opacity-70 ease-in transition-opacity inset-0 bg-center bg-no-repeat bg-cover" style="background-image: url('/images/test_image.png')">
        <template v-if="!isVideo">
          <template v-if="profile.smallThumbUrl">
            <img :src="profile.smallThumbUrl" class="w-full h-full object-cover" :alt="profile.name" @error="imageUrlError">
          </template>
          <template v-else-if="profile.thumbUrl">
            <img :src="profile.thumbUrl" class="w-full h-full object-cover" @error="imageUrlError" :alt="profile.name">
          </template>
          <template v-else-if="profile.imageUrl">
            <img class="w-full h-full object-cover" :src="profile.imageUrl"  @error="imageUrlError" :alt="profile.name">
          </template>
        </template>
        <template v-else>
          <video :src="profile.imageUrl" loop="loop" muted="true" autoplay="true" @error="imageUrlError" class="object-cover h-full rounded-t-lg"></video>
        </template>
        <span v-if="profile.type == 'guest' && visiblePrice !== null" class="price-tag z-10" @click.prevent="">
          <span v-if="visiblePrice != 0 && authenticated">$ {{ visiblePrice }}</span>
          <span v-else-if="authenticated">FREE</span>
          <template v-else>
            <modal class="flex items-start">
              <span>See Price</span>
              <div slot="content">
                <LoginModal :redirect="redirectAfter"/>
              </div>
            </modal>
          </template>
        </span>
      </div>
    </router-link>
    <div class="grid grid-cols-6 gap-2">
      <router-link :to="profileRoute" class="leading-5 w-full col-span-5">
        <h4 class="md:text-lg mb-1 capitalize truncate" :class="darkText ? 'text-blue-800' : 'text-gray-200'">{{ profile.name }}</h4>
        <p class="text-pink-500 text-sm truncate">{{ profile.type == 'guest' ? title : platformTypes }}</p>
      </router-link>
      <template v-if="authenticated">
        <button @click.stop="favorite" class="flex items-start">
          <HeartIcon class="w-5 h-5" :fill="favorited" :color="darkText ? '#292546' : '#FFF'" />
        </button>
      </template>
      <template v-else>
        <modal class="flex items-start">
          <HeartIcon class="w-5 h-5" :fill="false" color="#FFF" />
          <div slot="content">
            <LoginModal :info="{id: this.profile.id}"/>
          </div>
        </modal>
      </template>
    </div>
  </div>
</template>

<script>
  import { uniq, last } from "lodash";
  import HeartIcon from "@/components/svgs/HeartIcon";
  import Modal from '@/components/shared/Modal';
  import LoginModal from '@/components/shared/LoginModal';
import { mapState } from 'vuex';

  export default {
    name: "Item",
    components: { HeartIcon, Modal , LoginModal},
    props: {
      profile: Object,
      darkText: { type: Boolean, required: false, default: false}
    },

    data() {
      return {
        imgError:false,
        favorited: false,
      }
    },

    methods:{
      async favorite() {
        var type = this.profile.type == 'guest' ? 'guests' : 'shows'
        const { is_favorited } = await this.$store.dispatch('dashboard/smartlist/toggleVip', { profileId: this.profile.id, type} )

        this.favorited = is_favorited;
      },

      imageUrlError(){
        this.imgError=true;
      }
    },

    computed: {
      ...mapState({
        activePrices: state => state.filters.activePrices,
        activeBookingOptions: state => state.filters.activeBookingOptions,
        query: state => state.filters.query,
        bookingOptions: state => state.filters.bookingOptions,
      }),

      activeBookingOptionIds() {
        if (! this.activeBookingOptions.length) {
          return [];
        }

        return this.bookingOptions
          .filter(option => this.activeBookingOptions.includes(option.slug))
          .map(option => option.id);
      },

      currentRoute(){
        return this.$route.name
      },
      profileRoute() {
        if (this.profile.type == 'guest') {
          return {name: 'Guest', params: {guestId: this.profile.slug || this.profile.id}}
        }

        return {name: 'Show', params: {showId: this.profile.slug || this.profile.id}}
      },

      authenticated() {
        return this.$store.getters["auth/authenticated"];
      },

      title() {
        return this.profile.title ? this.profile.title : this.profile.bio
      },

      isVideo() {

        if(!this.profile.imageUrl) {
          return false;
        }

        const videoTypes = ['mp4', 'ogg', 'mov', 'qt']

        let extension = this.profile.imageUrl.split(".").pop();
        return videoTypes.includes(extension);
      },

      redirectAfter() {
        return `/guests/${this.profile.id}/book`;
      },

      lastFilterPrice() {
        const filterPrices = this.activePrices
          .map(price => {
            if (price.includes('-')) {
              return price.split('-').sort((a, b) => {
                return this.query.sortBy == 'price' ? a - b : b - a
              })[0]
            }

            return price
          })
          .sort((a, b) => {
            return this.query.sortBy == 'price' ? a - b : b - a
          })

        return parseInt(filterPrices[0])
      },

      visiblePrice() {
        if (this.$route.name !== 'Browse') {
          return this.profile.lowest_price
        }

        if (! this.activePrices.length && this.query.sortBy !== '-price') {
          return this.profile.lowest_price
        }

        if (! this.activePrices.length && this.query.sortBy === '-price') {
          return this.profile.type === 'guest'
            ? last(this.guestPrices).price
            : last(this.showPrices).price;
        }

        const profilePrices = this.profile.type === 'guest'
          ? uniq(this.guestPrices.map(option => option.price))
          : uniq(this.showPrices.map(option => option.price))

        return profilePrices
          .sort((a, b) => {
            return this.query.sortBy == 'price' ? a - b : b - a
          })
          .filter(price => {
            return this.query.sortBy == 'price'
              ? parseInt(price) >= parseInt(this.lastFilterPrice)
              : parseInt(price) <= parseInt(this.lastFilterPrice)
          })[0]
      },

      virtualInterviewOption() {
        if (! this.bookingOptions.length) {
          return;
        }

        return this.bookingOptions.find(option => option.slug === 'virtual-interview');
      },

      guestAppearanceOption() {
        if (! this.bookingOptions.length) {
          return;
        }

        return this.bookingOptions.find(option => option.slug === 'guest-appearance');
      },

      hasVirtualInterviewPrices() {
        if (this.profile.type == 'show') {
          return false;
        }

        if (! this.virtualInterviewOption) {
          return false;
        }

        return !! this.profile.option_prices
          .filter(price => price.booking_option_id == this.virtualInterviewOption.id)
          .length;
      },

      guestPrices() {
        if (this.activeBookingOptionIds.length) {
          return this.profile.option_prices
            .filter(price => this.activeBookingOptionIds.includes(price.booking_option_id));
        }

        if (this.hasVirtualInterviewPrices) {
          return this.profile.option_prices
            .filter(price => price.booking_option_id == this.virtualInterviewOption.id);
        }

        return this.profile.option_prices;
      },

      hasGuestAppearancePrices() {
        if (this.profile.type == 'guest') {
          return false;
        }

        if (! this.guestAppearanceOption) {
          return false;
        }

        return !! this.profile.prices
          .filter(price => price.booking_option_id == this.guestAppearanceOption.id)
          .length;
      },

      showPrices() {
        if (this.hasGuestAppearancePrices) {
          return this.profile.prices
            .filter(price => price.booking_option_id == this.guestAppearanceOption.id);
        }

        return this.profile.prices;
      },

      guestAppearancePrices() {
        if (this.profile.type == 'guest') {
          return [];
        }

        if (! this.guestAppearanceOption) {
          return [];
        }

        return this.profile.prices
          .filter(price => price.booking_option_id == this.guestAppearanceOption.id);
      },

      platformTypes() {
        if (this.profile.type === 'guest') {
          return
        }

        return this.profile.showTypes.map(type => type.name).join(', ')
      }
    },

    watch: {
      'profile.is_favourited'(is_favourited) {
        this.favorited = is_favourited;
      }
    },

    mounted() {
      this.favorited = this.profile.is_favourited;
    },
  };
</script>
