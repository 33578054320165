<template>
  <div>
    <slot name="header" :preview-recording="previewRecording" :redo="redoRecording" :saving="saving" />
    <div>
      <div class="h-64 overflow-hidden relative pb-2/3 rounded-lg">
        <div class="absolute inset-0 z-10">
          <video
            v-show="previewRecording"
            ref="videoRecording"
            class="w-full h-full object-cover"
            @durationchange="fixDuration"
            @play="playing = true"
            @pause="playing = false"
            playsinline
            looping="false"
          ></video>
          <video v-show="! previewRecording" ref="video" class="video-js vjs-default-skin w-full h-full object-cover" playsinline></video>
        </div>
        <div v-if="isRecording" class="absolute z-20 bottom-0 left-0 ml-6 mb-6 px-2 py-1 rounded-lg bg-blue-800 text-white bg-opacity-50">
          <span class="text-lg font-bold font-poppins">
            {{ recordedSeconds | time }}
          </span>
        </div>
      </div>

      <div v-if="deviceError" class="mt-4 bg-red-100 p-4 rounded-lg shadow-md flex items-center">
        <span>
          <CircleAlertIcon class="w-4" color="#e53e3e" />
        </span>
        <span class="ml-2 text-red-700 text-sm">
          Please allow access to your camera and mic to record pitch.
        </span>
      </div>

      <div v-if="isSafari" class="mt-4 bg-yellow-100 p-4 rounded-lg shadow-md flex items-center">
        <span>
          <CircleAlertIcon class="w-4" color="#ecc94b" />
        </span>
        <span class="ml-2 text-yellow-700 text-sm">
          If you are having trouble with audio, try using Chrome as your browser.
        </span>
      </div>

      <div v-if="savingErrorMessage" class="mt-4 bg-red-100 p-4 rounded-lg shadow-md flex items-center">
        <span>
          <CircleAlertIcon class="w-4" color="#e53e3e" />
        </span>
        <span class="ml-2 text-red-700 text-sm">
          {{ savingErrorMessage }}
        </span>
      </div>

      <template v-if="previewRecording">
        <div class="mt-4 bg-black bg-opacity-30 h-14 rounded-lg flex items-center px-6 space-x-5">
          <div class="flex items-center">
            <button v-if="! playing" @click.prevent="play" type="button" class="p-2 focus:outline-none rounded focus:shadow-outline">
              <PlayAltIcon class="h-4 w-4 text-white" />
            </button>
            <button v-else @click.prevent="$refs.videoRecording.pause(); $refs.videoRecording.currentTime = 0" type="button" class="p-2 focus:outline-none rounded focus:shadow-outline">
              <StopIcon class="h-4 w-4 text-white" />
            </button>
          </div>
          <div @click="seek" class="progress relative flex-1 h-2 bg-white rounded-full flex items-center">
            <div
              :style="{ width: this.playProgress + '%' }"
              class="absolute inset-y-0 rounded-full bg-pink-500 pointer-events-none">
            </div>
            <div
              :style="{ left: this.playProgress + '%' }"
              class="absolute w-5 h-5 rounded-full bg-white shadow -ml-2.5 pointer-events-none"></div>
          </div>
          <div class="text-sm font-poppins font-bold text-white whitespace-nowrap">
            {{ recordedSeconds | time }}
          </div>
        </div>
      </template>

      <template v-if="! previewRecording">
        <div v-if="! isRecording" class="grid md:grid-cols-1 lg:grid-cols-2 col-gap-4 row-gap-4 mt-4">
          <div v-if="! showConfigureInput">
            <button @click.prevent="showConfigureInput = true" type="button" class="flex items-center space-x-2 text-pink-500">
              <EditIcon class="h-4 w-4" />
              <span>Configure input devices</span>
            </button>
          </div>

          <template v-if="showConfigureInput">
            <div v-if="videoDevices.length">
              <label for="video-input" class="text-black font-medium">
                Video Input
              </label>
              <div class="mt-1">
                <select v-model="videoInput" id="video-input" class="form-select w-full px-5 py-3 rounded-full focus:bg-white" @change="setVideoInputDevice">
                  <option v-for="device in videoDevices" :value="device.id" :key="`video-input-${device.id}`">
                    {{ device.name }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="audioDevices.length">
              <label for="audio-input" class="text-black font-medium">
                Audio Input
              </label>
              <div class="mt-1">
                <select v-model="audioInput" id="audio-input" class="form-select w-full px-5 py-3 rounded-full focus:bg-white">
                  <option v-for="device in audioDevices" :value="device.id" :key="`audio-input-${device.id}`">
                    {{ device.name }}
                  </option>
                </select>
              </div>
            </div>
          </template>
        </div>

        <div v-if="isReadyToRecord" class="flex justify-center mt-8">
          <button v-if="! isRecording" @click="startRecording" type="button" class="btn hover:opacity-90">
            Start Recording
          </button>

          <button v-else @click="stopRecording" type="button" class="bg-indigo-gradiant p-6 rounded-full flex items-center justify-center ml-2 lg:ml-4 hover:opacity-90">
            <StopIcon class="h-6 w-6 text-white" />
          </button>
        </div>
      </template>
    </div>

    <slot name="footer" v-if="previewRecording" :preview-recording="previewRecording" :saving="saving" :redo="redoRecording" :save="saveRecording">
      <div class="flex items-center justify-around pt-4" :class="{'opacity-75': saving}">
        <template v-if="saving">
          <div class="flex items-center justify-center">
            <loading-icon class="h-2 text-pink-500"/>
          </div>
        </template>

        <template v-else>
          <button @click.prevent="redoRecording" type="button" class="flex items-center space-x-2 text-pink-500">
            <RedoIcon class="w-4 h-4" />
            <span>Redo</span>
          </button>

          <button @click="saveRecording" type="button" class="bg-indigo-gradiant text-white font-bold px-10 py-3 rounded-full flex items-center justify-center hover:opacity-90">
            Save Recording
          </button>
        </template>
      </div>
    </slot>
  </div>
</template>

<script>
  import 'video.js/dist/video-js.css'
  import 'videojs-record/dist/css/videojs.record.css'
  import videojs from 'video.js'
  // eslint-disable-next-line
  import RecordRTC from 'recordrtc'
  // eslint-disable-next-line
  import Record from 'videojs-record/dist/videojs.record.js'
  import StopIcon from '@/components/svgs/StopIcon'
  import CircleAlertIcon from '@/components/svgs/CircleAlertIcon'
  import PlayAltIcon from '@/components/svgs/PlayAltIcon'
  import RedoIcon from '@/components/svgs/RedoIcon'
  import EditIcon from '@/components/svgs/EditIcon'
  import api from '@/api'

  import { isSafari } from '@/utils/helpers';

  export default {
    name: 'RecordVideo',

    props: {
      mediaProp: Object,
      isMiniviewAnswer: { type: Boolean, default: false },
    },

    components: {
      StopIcon, CircleAlertIcon, RedoIcon, PlayAltIcon, EditIcon
    },

    data() {
      return {
        media: null,
        showConfigureInput: false,
        previewRecording: false,
        playing: false,
        isReadyToRecord: false,
        isRecording: false,
        deviceError: false,
        videoDevices: [],
        videoInput: null,
        audioDevices: [],
        audioInput: null,
        currentSeconds: 0,
        recordedSeconds: 0,
        saving: false,
        savingErrorMessage: null,
        maxChars: 300,
        body: '',
        player: null,
        options: {
          controls: false,
          bigPlayButton: false,
          pip: false,
          fluid: false,
          width: 640,
          height: 480,
          controlBar: {
            fullscreenToggle: false,
          },
          plugins: {
            record: {
              videoRecorderType: 'WhammyRecorder',
              audio: true,
              video: {
                // video media constraints: set resolution of camera
                width: { min: 640, ideal: 1280, max: 1280 },
                height: { min: 480, ideal: 720, max: 720 }
              },
              // dimensions of captured video frames
              frameWidth: 1280,
              frameHeight: 720,
              maxLength: 60,
              displayMilliseconds: false,
              debug: false,
              timeSlice: 1000
            }
          }
        }
      }
    },

    watch: {
      playing(playing) {
        if (! this.previewRecording) {
          if (this.interval) {
            clearInterval(this.interval)
          }

          return
        }

        if (playing) {
          this.interval = setInterval(() => {
            this.currentSeconds = this.$refs.videoRecording.currentTime
          }, 100)
        } else {
          if (this.interval) {
            clearInterval(this.interval)
          }
        }
      }
    },

    computed: {
      charCount() {
        return this.body.length
      },

      playProgress() {
        return this.currentSeconds / this.recordedSeconds * 100;
      },

      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      userIsGuest() {
        return this.user.guest && this.user.guest.id == this.booking.guest_profile_id
      },

      isSafari() {
        return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      },
    },

    filters: {
      time(value) {
        value = Math.round(value)

        if (value < 60) {
          let seconds = value.toString().padStart(2, '0')

          return `0:${seconds}`
        }

        let minutes = Math.floor(value / 60)
        let seconds = Math.round(value % 60).toString().padStart(2, '0')

        return `${minutes}:${seconds}`
      }
    },

    methods: {
      redoRecording() {
        this.playing = false
        this.previewRecording = false
        this.$refs.videoRecording.src = null
        this.media = null
        this.currentSeconds = 0
        this.recordedSeconds = 0
        this.savingErrorMessage = null

        this.$nextTick(() => {
          if (! this.player) {
            this.initPlayer()
          } else {
            this.player.record().reset()
            this.player.record().getDevice()
          }
        })
      },

      seek(e) {
        const el = e.target.getBoundingClientRect();
        const seekPos = (e.clientX - el.left) / el.width;

        this.currentSeconds = this.recordedSeconds * seekPos;
        this.$refs.videoRecording.currentTime = this.currentSeconds

        if (! this.playing) {
          this.$refs.videoRecording.play()
        }
      },

      play() {
        this.$refs.videoRecording.play().catch(() => {
          this.savingErrorMessage = 'There was an error. Press Redo to try again or refresh the page.'
        });
      },

      setAudioInputDevice() {
        this.player.record().setAudioInput(this.audioInput)
      },

      setVideoInputDevice() {
        this.player.record().setVideoInput(this.videoInput)
      },

      startRecording() {
        if (! this.player) {
          return
        }

        this.player.record().start()
      },

      stopRecording() {
        if (! this.player) {
          return
        }

        this.player.record().stop()
        this.player.record().stopDevice();
        this.playing = false;
      },

      closeVideoPitch() {
        this.$emit('close')
      },

      saveRecording() {
        this.saving = true
        this.savingErrorMessage = null

        if (this.media) {
          return this.$emit('saved', this.media)
        }

        let form = new FormData

        form.append('type', 'video');

        if(!isSafari()) {
          form.append('media', this.player.recordedData);
        } else {
          let fullRecordedData;
          
          if(this.player.recordedData.length) {
            fullRecordedData = new Blob(this.player.recordedData, { type: 'video/mp4' });
          } else {
            fullRecordedData = this.player.recordedData;
          }

          form.append('media', fullRecordedData);
        }

        const url = this.isMiniviewAnswer ? '/miniviews/upload-file' : '/users/me/recordings';
        api.post(url, form)
          .then(({ data }) => {
            this.$emit('saved', data.data)
          })
          .catch(error => {
            if (! error.response) {
              this.savingErrorMessage = 'The video recording was not saved.'
              return
            }

            if (error.response.status == 426) {
              this.savingErrorMessage = 'Only PRO users have access to this feature.'
              return
            }

            if (error.response.data.message) {
              this.savingErrorMessage = error.response.data.message
              return
            }

            this.savingErrorMessage = 'The video recording was not saved.'
          })
          .finally(() => this.saving = false)
      },

      applyVideoWorkaround() {
        if (!!window.opera || navigator.userAgent.indexOf('OPR/') !== -1) {
          this.options.plugins.record.videoMimeType = 'video/webm;codecs=vp8'; // or vp9
        }
      },

      initPlayer() {
        this.player = videojs(this.$refs.video, this.options);

        this.player.record().getDevice()

        this.addEventListeners()
      },

      addEventListeners() {
        // device is ready
        this.player.on('deviceReady', () => {
          this.deviceError = false
          this.isReadyToRecord = true
          this.player.record().enumerateDevices()
        })

        this.player.on('enumerateReady', () => {
          this.fetchDevices()
        })

        // user clicked the record button and started recording
        this.player.on('startRecord', () => {
          this.isRecording = true
          this.recordedSeconds = 0
        })

        this.player.on('stopRecord', () => {
          this.isRecording = false

          if (this.recordedSeconds > 0) {
            this.previewRecording = true
          }
        })

        this.player.on('timestamp', () => {
          this.recordedSeconds = Math.round(this.player.record().getDuration())
        })

        // user completed recording and stream is available
        this.player.on('finishRecord', () => {
          // eslint-disable-next-line
          this.$refs.videoRecording.src = URL.createObjectURL(this.player.recordedData)
          this.isRecording = false
          this.previewRecording = true
        })

        // error handling
        this.player.on('error', (element, error) => {
          console.warn(error);
        })

        this.player.on('deviceError', () => {
          this.deviceError = true
        })

        this.player.on('play', () => {
          this.playing = true
        })
        this.player.on('pause', () => {
          this.playing = false
        })
      },

      fetchDevices() {
        this.videoDevices = this.player.record().devices
          .filter((d) => d.kind === 'videoinput' && d.deviceId.length)
          .map((d) => {
            return {
              name: d.label,
              id: d.deviceId
            }
          })
        this.audioDevices = this.player.record().devices
          .filter((d) => d.kind === 'audioinput' && d.deviceId.length)
          .map((d) => {
            return {
              name: d.label,
              id: d.deviceId
            }
          })
      },

      fixDuration(event) {
        if (! this.previewRecording) {
          return
        }

        let duration = event.target.duration

        if (duration != Infinity) {
          event.target.currentTime = 0
          this.recordedSeconds = duration
          this.currentSeconds = 0
          return
        }

        event.target.currentTime = 99999
      },

      enablePreview() {
        this.media = {
          ...this.mediaProp
        }

        this.$refs.videoRecording.src = this.media.url
        this.previewRecording = true
      }
    },

    mounted() {
      this.applyVideoWorkaround()

      if (this.mediaProp) {
        this.enablePreview()
      } else {
        this.initPlayer()
      }
    },

    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }

      if (this.interval) {
        clearInterval(this.interval)
      }
    }
  }
</script>
