<template>
  <svg width="24" height="21" viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M21.1814 2.76345C18.7569 0.412801 14.8112 0.412801 12.3862 2.76345C12.2518 2.89378 12.1231 3.03108 12.0002 3.17486C11.8772 3.03061 11.7485 2.89378 11.6136 2.76299C9.18918 0.412338 5.24346 0.412338 2.81852 2.76299C0.393586 5.11364 0.394065 8.93924 2.81852 11.2904L11.6615 19.8641C11.7548 19.9545 11.8772 20 11.9997 20C12.1222 20 12.2446 19.9545 12.3379 19.8641L21.1809 11.2904C23.6063 8.9397 23.6063 5.1141 21.1814 2.76345Z"
          :stroke="color"
          :fill="fill ? color: 'none'"
          stroke-width="2"/>
  </svg>
</template>

<script>
export default {
  name: 'HeartIcon',
  props: {
    fill: { type: Boolean, required: false, default: false},
    color: { type: String, required: false, default: 'white'},
  }
}
</script>
