<template>
  <router-link :to="{name: 'MiniviewQuestions', params: { miniviewId: miniview.id }}"
               class="grid grid-cols-12 col-gap-4 bg-white rounded-xl shadow-lg cursor-pointer border border-gray-50 hover:border-pink-500 transition duration-500">
    <!--Date-->
    <div
      class="col-span-2 flex flex-col items-center justify-center border-r py-4 md:py-8">
      <template v-if="date">
        <span>{{ date.format('MMM') }}</span>
        <span class="font-semibold text-xl">{{ date.format('D') }}</span>
      </template>
      <span v-else class="font-semibold">-</span>
    </div>

    <!--Title-->
    <div class="col-start-3 col-span-6 flex items-center">{{ miniview.title }}</div>

    <!--Answers-->
    <div class="col-span-2 flex items-center">{{ miniview.answer_groups.length }}</div>

    <!--Details-->
    <div class="col-span-1 items-center flex justify-end">
      <button class="flex items-center text-gray-500 text-sm">
        <span class="hidden md:block">Details</span>
        <svg class="h-6 ml-1" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M7.50016 12.2093L13.3335 17.5L7.50016 22.7907" stroke="currentColor" stroke-width="2"
                stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>
    </div>
  </router-link>
</template>

<script>
import moment from 'moment'

export default {
  name: "MiniviewRow",

  props: {
    miniview: { type: Object}
  },

  computed: {
    date() {
      if (this.miniview.created_at) {
        return moment(this.miniview.created_at);
      }
      return null;
    },
  },
}
</script>
