<template>
  <div>
    <!-- guest actions -->
    <div v-if="userOwnsBookingGuest && ! booking.canceled_at && ! booking.is_expired && ! booking.is_completed" class="mt-8 grid grid-cols-1 gap-5">
      <template v-if="booking.is_confirmed">
        <alert-dialog
          class="w-full rounded-full py-3 text-center text-gray-500"
          confirm="Cancel Booking"
          confirming="Canceling"
          :confirm-delay="3"
          @confirm="cancelBooking"
          @cancel="cancelationReason = null"
        >
          <span>Cancel Booking</span>
          <span slot="title">Cancel Booking?</span>
          <template v-slot:content>
            <div>Are you sure you want to cancel this booking? This action cannot be undone.</div>
            <div class="mt-6">
              <div>
                <div class="flex justify-between items-center">
                  <label for="cancelation_reason" class="font-semibold text-black">Reason</label>
                  <span class="text-xs text-gray-500">optional</span>
                </div>
                <div class="mt-2">
                  <textarea v-model="cancelationReason" name="cancelation_reason" id="cancelation" cols="10" class="form-textarea text-sm w-full"></textarea>
                </div>
              </div>
            </div>
          </template>
        </alert-dialog>
      </template>

      <template v-else>
        <modal class="w-full rounded-full py-3 text-center border border-gray-500 hover:bg-gray-50 focus:bg-gray-50">
          <span>Confirm Booking</span>
          <template v-slot:content="{ close }">
            <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden p-6 sm:px-16 sm:py-12">
              <ConfirmBooking @close="close" />
            </div>
          </template>
        </modal>

        <alert-dialog
          class="w-full rounded-full py-3 text-center text-gray-500"
          confirm="Reject Booking"
          confirming="Rejecting"
          :confirm-delay="3"
          @confirm="rejectBooking"
          @cancel="cancelationReason = null"
        >
          <span>Reject Booking</span>
          <span slot="title">Reject Booking?</span>
          <template v-slot:content>
            <div>Are you sure you want to reject this booking? This action cannot be undone.</div>
            <div class="mt-6">
              <div>
                <div class="flex justify-between items-center">
                  <label for="cancelation_reason" class="font-semibold text-black">Reason</label>
                  <span class="text-xs text-gray-500">optional</span>
                </div>
                <div class="mt-2">
                  <textarea v-model="cancelationReason" name="cancelation_reason" id="cancelation" cols="10" class="form-textarea text-sm w-full"></textarea>
                </div>
              </div>
            </div>
          </template>
        </alert-dialog>
      </template>
    </div>

    <!-- show actions -->
    <div v-if="userOwnsBookingShow && ! booking.canceled_at && ! booking.is_expired && ! booking.is_completed" class="mt-8 grid grid-cols-1 gap-5">
      <template v-if="! booking.confirmed_date">
        <modal class="w-full rounded-full py-3 text-center border border-gray-500 hover:bg-gray-50 focus:bg-gray-50">
          <span>Reschedule Booking</span>
          <template v-slot:content="{ close }">
            <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden">
              <BookingSlots @close="close" />
            </div>
          </template>
        </modal>
      </template>

      <alert-dialog
        class="w-full rounded-full py-3 text-center text-gray-500"
        confirm="Cancel Booking"
        confirming="Canceling"
        :confirm-delay="3"
        @confirm="cancelBooking"
        @cancel="cancelationReason = null"
      >
        <span>Cancel Booking</span>
        <span slot="title">Cancel Booking?</span>
        <template v-slot:content>
          <div>Are you sure you want to cancel this booking? This action cannot be undone.</div>
          <div class="mt-6">
            <div>
              <div class="flex justify-between items-center">
                <label for="cancelation_reason" class="font-semibold text-black">Reason</label>
                <span class="text-xs text-gray-500">optional</span>
              </div>
              <div class="mt-2">
                <textarea v-model="cancelationReason" name="cancelation_reason" id="cancelation" cols="10" class="form-textarea text-sm w-full"></textarea>
              </div>
            </div>
          </div>
        </template>
      </alert-dialog>
    </div>
  </div>
</template>

<script>
  import Modal from '@/components/shared/Modal'
  import BookingSlots from '@/components/bookings/individual/BookingSlots'
  import AlertDialog from '@/components/shared/AlertDialog'
  import ConfirmBooking from '@/components/bookings/individual/ConfirmBooking'
  export default {
    components: { Modal, BookingSlots, AlertDialog, ConfirmBooking },

    data() {
      return {
        cancelationReason: null,
      }
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      bookingStatus() {
        return this.$store.getters['dashboard/bookings/bookingStatus']
      },

      proposedTimes() {
        return this.booking.proposed_dates
      },

      userOwnsBookingGuest() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingGuest']
      },

      userOwnsBookingShow() {
        return this.$store.getters['dashboard/bookings/userOwnsBookingShow']
      },
    },

    methods: {
      cancelBooking(callback) {
        this.$store.dispatch('dashboard/bookings/cancelBooking', {
          id: this.$route.params.bookingId,
          reason: this.cancelationReason,
        }).then(() => {
          callback.call()
          window.Event.$emit('refreshActivity')
        }).finally(() => {
          this.cancelationReason = null
        })
      },

      rejectBooking(callback) {
        this.$store.dispatch('dashboard/bookings/rejectBooking', {
          id: this.$route.params.bookingId,
          reason: this.cancelationReason,
        }).then(() => {
          callback.call()
          window.Event.$emit('refreshActivity')
        }).finally(() => {
          this.cancelationReason = null
        })
      }
    }
  }
</script>
