<template>
  <div>
      <div class="pulse w-30 h-8 mb-4 rounded-lg"/>
    <div class="flex overflow-x-scroll w-full slider">
      <slot name="item"></slot>
    </div>
  </div>
  
</template>

<script>
export default {
  name: "SliderSkeleton",
}
</script>

<style scoped>
@keyframes pulse-bg {
    0% { background-color: #b9babb; }
    50% { background-color: #e3e4e5; }
    100% { background-color: #b9babb; }
  }

  
.slider::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.slider {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
@keyframes loading {  
  to {
    background-position: 315px 0, 0 0, 0 190px, 50px 195px;
  }
}
.pulse{
   background: 
    linear-gradient(0.25turn, transparent, #fff, transparent),
    linear-gradient(rgb(211, 210, 210), rgb(211, 210, 210)),
    radial-gradient(38px circle at 19px 19px, #eee 50%, transparent 51%),
    linear-gradient(rgb(211, 210, 210), rgb(211, 210, 210));  
  background-position: -315px 0, 0 0, 0px 190px, 50px 195px; 
  animation: loading 1.5s infinite;
}
</style>

