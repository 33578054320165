<template>
  <div :class="dark ? 'bg-lightBlack text-grayHome' : 'c22 text-black'">
    <div>
      <p class="c12 c15"><span class="c10 c7"></span></p>
    </div>
    <p class="c15 c20 title">
      <span class="c14 c3">GUESTIO<br />TERMS OF SERVICE</span>
    </p>
    <p class="c20 c15"><span class="c0">Last updated June 14, 2021</span></p>
    <ol class="c2 lst-kix_list_1-0 start" start="1">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Acceptance of Terms</span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span
        >These Terms of Service (the “Terms of Service”) apply to your (“You” or “your”)
         use of Guestio application, available as a mobile app download or as an online 
         application located at “www.guestio.com” and to any successor website, subdomain,
         subsite, or mobile application which links to or references these Terms of Service 
         (the “Services”), which are owned and operated by Guestio, Inc., (“Guestio” or “we”).
      </span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >The Services provide a way for audio, video, and podcasting show hosts (“Shows”)
         to hire guests (“Guests”) to appear on their content. Collectively, Shows and Guests 
         are “Users”.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        >You understand that the Services are only a communication channel, and that Guestio is 
        not liable for the content of any such communication.  Any information that you provide 
        is purely voluntary, and Guestio is not liable for the use by any User of our community 
        of information that you provide.  Guestio does not perform background checks or consumer 
        credit checks on any User in connection with the Services.</span
      >
    </p>
    <p class="c1">
      <span class="c0"
        ><b>PLEASE NOTE:</b> Your access to and use of the Services is subject to these Terms of
        Service, as well as all applicable laws and regulations. Please read these Terms of Service
        carefully. When you provide your consent by clicking the “accept” button, or otherwise 
        through the account registration process which references these terms during the process of
        connecting to and using the Services, you are agreeing to be bound by these terms to the 
        same extent as if a paper copy of these terms had been manually executed, and you understand
        and intend to enter into these terms electronically. If you do not agree with these terms, 
        you should not give your consent, and you are not permitted to access or use the Services. 
        These Terms of Service may be changed, modified, supplemented or updated by Guestio from time
        to time without advance notice, and the updated terms may be posted on Guestio website or 
        otherwise within the Services, and you will be bound by any such changed, modified, supplemented
        or updated Terms of Service if you continue to use the Services after such changes are posted;
        provided, however, that Guestio may provide notice by means of conspicuous alert or 
        notification displayed on the Services in the case of substantial revisions. Unless otherwise
        indicated, any new Guestio Content or services added to the Services will also be subject 
        to these Terms of Service effective upon the date of any such addition. You are encouraged 
        to review the Services and these Terms of Service periodically for updates and changes.  
        If you are using the Services on behalf of a legal entity or another individual, you represent
        and warrant that you are authorized to enter into and agree to these Terms of Service on behalf
        of that legal entity or individual. 
      </span>
    </p>
    <p class="c1">
      <span class="c0"
        >If you have any questions about these Terms of Service, please contact us as described in
         the Contacting Us section below. 
      </span>
    </p>
    <ol class="c2 lst-kix_list_1-0" start="2">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3"
            >Limited License and Site Access; All Rights Reserved 
          </span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >Use of the Services is limited to persons 18 year of age or older.  Subject to 
        your compliance with these Terms of Service, Guestio hereby grants you a limited 
        license to access and make personal use of these Services, but not to download 
        (other than page caching) or modify it, or any portion of it, except with express 
        written consent of Guestio (e.g., downloading of PDF forms, applications, etc.). 
        The Services also contain text, pictures, graphics, logos, button items, images, 
        works of authorship, and other content (collectively, the “Guestio Content”).  
        Guestio Content does not include User Content (as defined below). The license granted
        in this Section does not include any resale use of the Services or Guestio Content; 
        any derivative use of the Services or Guestio Content; or any use of data mining, 
        robots, or similar data gathering and extraction tools. The Services or any portion 
        thereof may not be reproduced, duplicated, copied, sold, resold, visited, or otherwise
        exploited for any commercial purpose without the express written consent of Guestio. 
        You may not frame or utilize framing techniques to enclose any trademark, logo, or 
        other proprietary information (including images, text, page layout, or form) of Guestio 
        without Guestio's express written consent. You may not use any meta-tags or any other 
        “hidden text” utilizing any of Guestio's name(s) or service marks without the express 
        written consent of their owners. We (or the respective third party owners of Guestio Content)
        retain all right, title, and interest in the Services and any Guestio Content offered on 
        these Services, including any and all intellectual property rights.  Any software applications
        available on or through the Services are licensed, not sold, to you. Guestio may assign 
        these Terms of Service or any part of them without restrictions. You may not assign 
        these Terms of Service or any part of them, nor transfer or sub-license your rights under
        this License, to any third party.  We (or the respective third party owners of Guestio Content)
        reserve all rights not expressly granted. Any unauthorized use terminates the permission or 
        license granted by Guestio.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="3">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline"><span class="c14 c3">Services</span></h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c7"
          >Users may use the Services to offer and accept Guest bookings to appear (an “Appearance”)
           on Show content. Guests may control which Users or Shows are allowed to see and/or offer 
           an Appearance. Guests set a fee (an “Appearance Fee”) for each Appearance.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >When two Users decide to book an Appearance with each other, we will share information with
          each User as necessary or requested, such as (i) the first and last name, social media account
          handles, and contact information, (ii) links to User profiles, or (iii) details of the Show, 
          Guest, and/or Show's and Guest’s activities.
        </span>
      </li>
      <li class="c1 c5">
        <span class="c7"
          >The Services include providing notifications to Users upon the occurrence of certain events,
          such as when a Guest has accepted an offer for an Appearance. You agree to allow such notifications,
          which may be sent by in-application push message, posting to your account, email, and/or text message.</span
        >
      </li>
      <li class="c1 c5" id="h.gjdgxs">
        <span class="c7"
          >In consideration of the Services, we receive from a Guest a fee (the “Service Fee”) in the amount of 
          20% of the Appearance Fee paid by Show to Guest in exchange for providing access to the Services.
          The Service Fees are non-refundable. We do not currently charge Shows any service fee for use of the Service.</span
        >
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="4">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c3 c14">Payment Terms </span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c3">Appearances</span
        ><span class="c7"
          >.Once you become a User of the Services, you will have the opportunity to perform Appearances for other Users
          (if you are a Guest) and to request other Users to make Appearances on your Shows (if you are a Show), in 
          exchange for consideration set by Guest and agreed to by Show. If you are a Show, you agree to pay, using
          a valid credit card (or other form of payment that the third party payment processor may accept from time to time),
          the applicable fees and taxes (if any) set forth in the offer that you accepted. If you are a Guest, you agree, 
          to pay the Service as set forth in Section 3.4.  If you are a Guest, you understand that you are making Appearances
          for the Show, and not Guestio. If you are a Show, you understand that the Guest, not Guestio, is responsible for 
          making Appearances. You understand that Guestio is not a party to and will be in no way responsible for the 
          performance of either you or the applicable User for an Appearance. You understand and agree that you shall be 
          solely liable for any damages arising out of or related to your breach of these obligations, and you agree to 
          indemnify Guestio for any liabilities or damages arising out of or related to any breach of your obligations 
          arising out of or in connection with any Appearances.
</span
        >
      </li>
      <li class="c1 c5" id="h.30j0zll">
        <span class="c3">Third Party Payment Processors</span
        ><span class="c7"
          >.You will be required to connect a bank account or register with a third-party payment service to make or 
          receive any payments for Appearances. The Services do not directly accept or process payments. Instead, 
          the Services may provide an interface to or content from third-party payment processors and may allow payments
          through these third-party payment processors. These third-party payment processors collect, process and 
          store payment information at the direction of the user and provide only credits and notifications to us. 
          You are responsible for complying with any terms and conditions set by our third party payment processors 
          and we expressly disclaim all liability for your use of such third party payment processors. If you are Guest,
          you must connect your bank account or payment service within 90 days following an Appearance. If you do not 
          connect a bank account or payment service within 90 days following an Appearance, you hereby forfeit and waive 
          any and all claims to your Appearance Fee for such Appearance, and the Appearance Fee will become the sole and
          exclusive property of Guestio.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c3">Taxes</span
        ><span class="c7"
          >. It is possible that if you are making any Appearances for other Users that such may be subject to taxes in
          the jurisdiction where the Appearance is completed.  You are encouraged to talk with your financial and tax
          advisors to determine whether or not any Tasks that you elect to complete would obligation you to collect
          and remit any taxes.</span
        ><span class="c3"
          >YOU ARE SOLELY RESPONSIBLE FOR COLLECTING AND REMITTING ANY TAX WHICH MAY BE APPLICABLE TO THE SERVICES THAT
           YOU AGREE TO PROVIDE TO ANY PARTY AND FOR REMITTING ANY TAX TO THE APPROPRIATE TAXING AUTHORITY.</span
        ><span class="c7">GUESTIO SHALL HAVE NO LIABILITY OR RESPONSIBILITY FOR COLLECTING, REMITTING OR ADVISING
           YOU ON ANY TAX OBLIGATIONS.</span>
      </li>
      <li class="c1 c5">
        <span class="c3">DISCLAIMER</span
        ><span class="c7"
          >.ALL PAYMENTS RELATED TO THE SERVICES MUST BE PROCESSED THROUGH THE PAYMENT METHODS PROVIDED WITHIN THE SERVICES. </span
        ><span class="c3"
          >USERS SHOULD NOT DIRECTLY EXCHANGE PAYMENT OR REQUEST PAYMENT OF ANY AMOUNTS OUTSIDE OF THE PAYMENT METHODS
           PROVIDED THROUGH THE SERVICES.</span
        ><span class="c7"
          >GUESTIO IS NOT RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGES RESULTING FROM ANY EXCHANGE OF PAYMENT OR FOR 
          ANY DISPUTE RELATED TO PAYMENTS MADE OUTSIDE OF THE SERVICES.  GUESTIO MAY TERMINATE YOUR ACCESS TO THE 
          SERVICES IF YOU REQUEST OR EXCHANGE ANY PAYMENT OUTSIDE OF THE METHODS PROVIDED THROUGH THE SERVICES.</span
        >
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="5">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Additional Terms </span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >Certain areas of the Services (and your access to or use of certain aspects of the Services) may have
        different terms and conditions posted or may require you to agree with and accept additional terms 
        and conditions in order to obtain access to or use of Services. Any additional terms will be made 
        available to you at the time you access that applicable Guestio Service. If there is a conflict between
        these Terms and terms and conditions posted for a specific area or a particular Service, the latter 
        terms and conditions will take precedence with respect to your use of or access to that area or particular
        Services, as applicable.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="6">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Ownership of Guestio Content and Services</span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c7"
          >Except as otherwise expressly stated, all Guestio Content appearing on the Services is the copyrighted
          work of Guestio or its third party content suppliers and is protected by U.S. and international copyright
          laws. The compilation (meaning the collection, arrangement and assembly) of all Guestio Content is also
          the exclusive property of Guestio and is protected by U.S. and international copyright laws.
        </span>
      </li>
      <li class="c1 c5">
        <span class="c7"
          >You may download information from the Services and print out a hard copy for your personal use provided
          that you keep intact and do not remove or alter any copyright or other notice (e.g., trademark, patent, etc.)
          contained in the information. Except as otherwise expressly stated herein, you may not alter, modify, copy,
          distribute (for compensation or otherwise), transmit, display, perform, reproduce, reuse, post, publish,
          license, frame, download, store for subsequent use, create derivative works from, transfer, or sell any
          information or Guestio Content obtained from these Services, in whole or in part, including any text,
          images, audio, and video in any manner, without the prior written authorization of Guestio or any
          applicable third party suppliers. The use of Guestio Content, including images, by you, or anyone
          else authorized by you, is prohibited unless specifically permitted by Guestio. Any unauthorized
          use of text or images may violate copyright laws, trademark laws, the laws of privacy and publicity,
          and applicable regulations and statutes. Guestio does not warrant or represent that your use of
          Guestio Content or the Services will not infringe rights of third parties.
        </span>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="7">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3"
            >Ownership of Information submitted via the Services 
          </span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c7">Except as set forth in our</span><span>&nbsp;</span
        ><span class="c16"
          ><a
            class="c17"
            href="https://www.google.com/url?q=https://www.iubenda.com/privacy-policy/52449323&amp;sa=D&amp;source=editors&amp;ust=1616618820854000&amp;usg=AOvVaw2lU_aQwkFDdOZa6awTB-b7"
            >Privacy Policy,</a
          ></span
        ><span class="c7">&nbsp;</span
        ><sup><a href="#cmnt1" id="cmnt_ref1">[a]</a></sup
        ><sup><a href="#cmnt2" id="cmnt_ref2">[b]</a></sup
        ><sup><a href="#cmnt3" id="cmnt_ref3">[c]</a></sup
        ><span class="c7"
          >any communication or User Content will be considered
          non-confidential. Unless otherwise expressly stated in writing by
          Guestio, no compensation will be paid with respect to Guestio’s use of
          any User Content. Guestio shall have no obligation to preserve, return
          or otherwise make available to you or others any User Content.
          &nbsp;</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >Except as set forth in our Privacy Policy, Guestio does not claim ownership
          of your User Content in its original form. However, by making any of your 
          User Content available on or through the Services, you hereby grant to Guestio
          a worldwide, irrevocable, perpetual, non-exclusive, transferable, royalty-free
          license, with the right to create derivative works, to use, edit, view, copy,
          adapt, modify, distribute, host, market, transfer, display, perform, transmit,
          stream, broadcast, access, and otherwise fully utilize such User Content on 
          the Services as currently exist or may be developed in the future. For the 
          avoidance of doubt, the license granted in this paragraph applies to your 
          User Content on the Service.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >Without limiting the foregoing, you hereby grant to Guestio the worldwide,
          irrevocable, perpetual, non-exclusive, transferable, royalty-free right to
          use, reuse, and to grant others the right to use and reuse, without any
          compensation, notice, review or approval, the User Content, your name,
          photograph, likeness (including caricature), voice, and biographical information,
          and any reproduction or simulation thereof, (collectively “Licensed Material”).
          You further authorize Guestio to incorporate and license others to incorporate
          any part or all of an Appearance in any form.  You agree that the rights granted
          hereunder shall include the perpetual, irrevocable, worldwide right of Guestio
          to edit, telecast, cablecast, rerun, record, publish, reproduce, use, license,
          print, transmit, display distribute, create derivative works from, or otherwise
          exploit, in any manner and in any medium or forum whether now known or hereafter
          devised said Appearances in whole or in part without any further compensation 
          to you. You agree that Guestio shall be without liability to you for any distortion 
          or illusionary effect resulting from the publication of your picture, portrait,
          likeness, photographic image, artwork or reproduction of video footage or audio
          recording. You hereby waive all rights and release Guestio and its employees,
          directors, officers, employees, volunteers, representatives, and agents, 
          from any and all liability which may arise from any and/or all claims by 
          you or any third party in connection with the use of your name, picture,
          portrait or likeness and you agree that you shall not sue nor bring any
          proceeding against any of the foregoing parties, or their successors and
          assigns for, any claim or cause of action, whether now known or unknown,
          for defamation, invasion of right to privacy, publicity or personality 
          or any similar matter, or based upon or relating to the use and exploitation
          of the Licensed Material or an Appearance.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >Subject to our Privacy Policy, and limits under applicable law with regard to
          identifiable information, you understand and intend that by providing your User
          Content to Guestio, you hereby waive any privacy expectations that you may have
          with respect to any such User Content. You hereby agree, subject to the Privacy
          Policy and applicable law, that Guestio is the full owner of all right, title,
          and interest in any content or information extracted, derived, or otherwise 
          created from such User Content, and you agree to hold Guestio and its affiliates,
          subsidiaries, licensees, sponsors and assigns harmless from and against, and hereby
          waive any right to pursue, any claims of any nature arising in connection with the
          inclusion in, publication via or display on any Guestio site, or any other use
          authorized under these Terms, of your User Content.  You further hereby agree 
          that any User Content by you will be accurate and will not be intended to mislead,
          harm or cause damage to Guestio, any User, any third party or any other party, and
          you agree to indemnify, defend and hold harmless Guestio against any and all claims,
          liabilities and damages caused by any User Content from you or any third party.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          > You understand and agree that, if any other third-party or User shares or provides
          you with access to their User Content, or if you otherwise access any User Content
          through the Services, you will not obtain, as a result of your use of the Services,
          any right, title, or interest in or to such User Content. You agree to maintain
          the confidentiality and integrity of such User Content and/or to any shared Content
          and to use or disclose such User Content and/or Guestio Content only as authorized by
          their owner. You understand and agree that you shall be solely liable for any damages
          arising out of or related to your breach of these obligations, and you agree to indemnify
          Guestio for any liabilities or damages arising out of or related to any breach of your duty
          to maintain the confidentiality and integrity of such User Content and/or any shared Guestio
          Content and to use or disclose such User Content and/or Guestio Content only as authorized
          by their owner.</span
        >
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="8">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Digital Millennium Copyright Act</span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c7"
          >If you are a copyright owner or an agent thereof and believe that any User Content infringes upon
          your copyrights, you may submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA")
          by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for
          further detail):</span
        >
      </li>
    </ol>
    <ul class="c2 lst-kix_list_2-0 start">
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive
           right that is allegedly infringed;</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at
          a single website location are covered by a single notification, a representative list of such works at that
          website;</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >Identification of the material that is claimed to be infringing or to be the subject of infringing activity and
          that is to be removed or access to which is to be disabled and information reasonably sufficient to permit the service
          provider to locate the material;</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number,
           and, if available, an electronic mail;</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by
          the copyright owner, its agent, or the law; and</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</span
        >
      </li>
    </ul>
    <p class="c1">
      <span>Guestio’s</span
      ><span
        >&nbsp;designated Copyright Agent to receive notifications of claimed
        infringement is Joanna Hale, telephone (702) 551-4269 </span
      ><span>email: team@guestio.com, </span
      ><sup><a href="#cmnt4" id="cmnt_ref4">[d]</a></sup
      ><span
        >address:6232 Hambledon Hill Rd., Las Vegas, NV 89113. Guestio’s DMCA
        registration number is DMCA-1037092. </span
      ><sup><a href="#cmnt5" id="cmnt_ref5">[e]</a></sup
      ><sup><a href="#cmnt6" id="cmnt_ref6">[f]</a></sup
      ><sup><a href="#cmnt7" id="cmnt_ref7">[g]</a></sup
      ><sup><a href="#cmnt8" id="cmnt_ref8">[h]</a></sup
      ><span class="c0"
        >You acknowledge that if you fail to comply with all of these
        requirements, your DMCA notice may not be valid.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-1" start="2">
      <li class="c1 c5">
        <span class="c3">Counter-Notice</span><span class="c7 c26">.&nbsp;</span
        ><span class="c7"
          >If you believe that your User Content that was removed (or to which access was disabled) is not infringing,
          or that you have the authorization from the copyright owner, the copyright owner's agent, or pursuant to the
          law, to post and use the material in your User Submission, you may send a counter-notice containing the following information to the Copyright Agent:</span
        >
      </li>
    </ol>
    <ul class="c2 lst-kix_list_3-0 start">
      <li class="c1 c4 li-bullet-0">
        <span class="c0">Your physical or electronic signature;</span>
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >Identification of the User Submission that has been removed or to which access has been disabled and the location
           at which the User Submission appeared before it was removed or disabled;</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >A statement that you have a good faith belief that the User Submission was removed or disabled as a result of mistake
          or a misidentification of the User Submission; and</span
        >
      </li>
      <li class="c1 c4 li-bullet-0">
        <span class="c0"
          >Your name, address, telephone number, and e-mail address, a statement that you consent to the jurisdiction of the federal
          court in San Francisco, California, and a statement that you will accept service of process from the person who provided
          notification of the alleged infringement.</span
        >
      </li>
    </ul>
    <p class="c1">
      <span class="c0"
        >If a counter-notice is received by the Copyright Agent, we may send a copy of the counter-notice to the original complaining
        party informing that person that it may replace the removed Content or cease disabling it in 10 business days. Unless the
        copyright owner files an action seeking a court order against the Content provider, the removed Content may be replaced,
        or access to it restored, in 10 to 14 business days or more after receipt of the counter-notice, at our sole discretion.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="9">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline"><span class="c14 c3">&nbsp;Links</span></h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >The Services may contain links to third-party websites or resources. You acknowledge and agree that Guestio is not
        responsible or liable for: (i) the availability or accuracy of such websites or resources; or (ii) the content, products,
        or services on or available from such websites or resources. Links to such websites or resources do not imply any endorsement
        by Guestio. You acknowledge sole responsibility for and assume all risk arising from your use of any such websites or resources
        or the content, products or services on or available from such websites or resources.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="10">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Advertisements</span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >Guestio may include advertisements on its own behalf or paid advertisements on behalf of interested companies and/or
        individuals on the Service. By clicking on the advertisements, you may be shifted to a website of the advertiser or
        receive other messages, information, or offers from the advertiser. You acknowledge and agree that Guestio is not liable
        for the privacy practices of advertisers or the content of their websites, information, messages or offers. Users are wholly
        liable for all communications with advertisers and for all transactions subsequently executed.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="11">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline"><span class="c14 c3">Feedback</span></h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >Please be advised that if you send or submit to Guestio creative ideas, suggestions, inventions, or materials (“Feedback”),
        Guestio shall: (i) own, exclusively, all now known or later discovered rights to the Feedback; (ii) not be subject to any
        obligation of confidentiality and shall not be liable for any use or disclosure of any Feedback; and (iii) be entitled to
        unrestricted use of the Feedback for any purpose whatsoever, commercial or otherwise, without compensation to you or any
        other person.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="12">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline"><span class="c14 c3">Privacy </span></h1>
      </li>
    </ol>
    <p class="c1">
      <span
        >Your privacy is important to us. &nbsp;We maintain our Privacy </span
      ><span>Policy</span><sup><a href="#cmnt9" id="cmnt_ref9">[i]</a></sup
      ><span class="c0"
        >&nbsp;at https://www.iubenda.com/privacy-policy/52449323. &nbsp;We
        agree to use your information in accordance with our Privacy Policy, and
        you consent and acknowledge that your information may be used in
        accordance with our Privacy Policy. &nbsp;We reserve the right to store
        all information transmitted via our Services and to use it in accordance
        with our Privacy Policy. &nbsp;We reserve the right to modify our
        Privacy Policy from time to time, effective upon posting the revised
        version on our Site. &nbsp;We encourage you to periodically check the
        Site for updates. &nbsp;If you disagree with anything in our Privacy
        Policy, you should cancel your subscription and discontinue using our
        Services.
      </span>
    </p>
    <ol class="c2 lst-kix_list_1-0" start="13">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Connection Requirements </span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >You are responsible for providing and maintaining, at your own risk, option and expense, any hardware, software and
        communication lines required to access and use these Services, and Guestio reserves the right to change the access
        configuration of the Services at any time without prior notice. 
      </span>
    </p>
    <ol class="c2 lst-kix_list_1-0" start="14">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Profiles and Accounts</span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c3">Profiles</span
        ><span class="c7"
          >.When you register with us for access to the Services and successfully complete the account registration 
          process you will become a “User” of the Services. As a User, you will have the opportunity to create a profile
          (“Profile”) and to add information, data, and images to your Profile) (“Profile Content.”) All Users are responsible
          for monitoring the Profile Content uploaded or added to their Profile.  Please note that you, and not Guestio, are
          responsible for maintaining and protecting all Profile Content. Guestio will not be liable to you for any loss or
          corruption of your Profile Content, or for any costs or expenses associated with backing up or restoring any of your
          Profile Content.  You understand and intend that allowing access to your Profile or otherwise sharing or disclosing
          any information with a Third Party pursuant to this Section is authorized by you and will not be considered a
          “disclosure,” access, or use of information by Guestio for any purposes. You are responsible for using the Services
          in a manner that complies with the law..</span
        >
      </li>
      <li class="c1 c5"><span class="c3">Accounts </span></li>
    </ol>
    <ol class="c2 lst-kix_list_1-2 start" start="1">
      <li class="c1 c6">
        <span class="c7"
          >In order to access the Services, including to (i) create and manage a Profile or (ii) post any Profile Content
          through the Services, you must register to create an account (“Account”) and become a User.  Your Account is subject
          to verification and approval by Guestio. You agree to provide accurate, current and complete information during the 
          registration process and to update such information to keep it accurate, current and complete. Guestio reserves the 
          right to suspend or terminate your Account if any information provided during the registration process or thereafter
          proves to be inaccurate, not current or incomplete. You will be asked to create a password when you create your 
          Account. Guestio does not have access to your passwords and if you forget your password you will be asked to create
          a new one. You are responsible for safeguarding your password. You agree that you will not disclose your password 
          to any third party and that you will take sole responsibility for any activities or actions under your Account, 
          whether or not you have authorized such activities or actions. You will immediately notify Guestio if you become 
          aware of any unauthorized use of your Account.</span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >You are responsible for any activity that occurs through your Account and you agree you will not sell, transfer,
          license or assign your account, followers, username, or any account rights. With the exception of third parties
          that are expressly authorized by a User to assist in the creation of the User’s account, Guestio prohibits the
          creation of and you agree that you will not create an account for anyone other than yourself. You also represent
          that all information you provide or provided to Guestio upon registration and at all other times will be true,
          accurate, current and complete and you agree to update your information as necessary to maintain its truth and
          accuracy. Do not provide your password to any other person or use any other person’s username and password. 
          You must notify Guestio immediately of any breach of security or unauthorized use of your account. Guestio will
          not be liable for any losses caused by any unauthorized use of your account.</span
        >
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="15">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">User Responsibilities</span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c3">Responsibility for Equipment</span
        ><span class="c7"
          >. Use of the Services may require a computer, smartphone, or tablet.  Unless otherwise agreed in writing between 
          the parties, all equipment is provided solely by the Users of the Service.  Guestio has no responsibility for the
          operation or support, maintenance or repair of any equipment, software or services that you elect to use in 
          connection with the Services.  
        </span>
      </li>
      <li class="c1 c5">
        <span class="c3">Third Party Carriers. </span
        ><span class="c7"
          >The Services communicate via internet and/or cellular data service provided by independent carriers.
          The internet or cellular data service provided by the independent carrier may fail or go off-line from
          time to time, and during any such outage our Services will be unable to transmit and receive information.
          We may not receive timely notice of the communications outage from the independent carriers. We are not
          obligated to provide Services during any such outages.  Cellular networks and internet providers may be
          regulated by federal and state agencies and changes in rules and regulations may require us to modify or 
          terminate our Services.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c3">Prohibited Use.</span
        ><span class="c7"
          >Any use or attempted use of the Services(i) for any unlawful, unauthorized, fraudulent or malicious purpose, 
          or (ii) that could damage, disable, overburden, or impair any server, or the network(s) connected to any server, 
          or (iii) interfere with any other party's use and enjoyment of the Services, or (iv) to gain unauthorized access 
          to any other accounts, computer systems or networks connected to any server or systems through hacking, password 
          mining or any other means, or (v) to access systems, data or information not intended by Guestio to be made accessible 
          to a user, or (vi) attempt to obtain any materials or information through any means not intentionally made available 
          by Guestio, or (vii) any use other than the business purpose for which it was intended, is prohibited.
        </span>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >In addition, in connection with your use of the Services, you agree you
        will not:
      </span>
    </p>
    <ol class="c2 lst-kix_list_1-2" start="3">
      <li class="c1 c6">
        <span class="c7"
          >Upload or transmit any message, information, data, text, software or images, or other content that is unlawful, 
          harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, or otherwise objectionable, 
          or that may invade another's right of privacy or publicity;</span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Create a false identity for the purpose of misleading others or impersonate any person or entity, including, without 
          limitation, any Guestio representative, or falsely state or otherwise misrepresent your affiliation with a person or entity; 
        </span>
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Upload or transmit any material that you do not have a right to reproduce, display or transmit under any law or under 
          contractual or fiduciary relationships (such as nondisclosure agreements); 
        </span>
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Upload files that contain viruses, trojan horses, worms, time bombs, cancel-bots, corrupted files, or any other 
          similar software or programs that may damage the operation of another's computer or property of another;</span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Delete any author attributions, legal notices or proprietary designations or labels that you upload to any 
          communication feature; 
        </span>
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Use the Services’ communication features in a manner that adversely affects the availability of its resources 
          to other users (e.g., flooding continuous posting of repetitive text or denial of service attacks); 
        </span>
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Upload or transmit any unsolicited advertising, promotional materials, “junk mail,” “spam,” “chain letters,” 
          
          “pyramid schemes” or any other form of solicitation, commercial or otherwise;</span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Violate any applicable local, state, national or international law;
        </span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Upload or transmit any material that infringes any patent, trademark, service mark, trade secret, copyright 
          or other proprietary rights of any party;</span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Delete or revise any material posted by any other person or
          entity;</span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Manipulate or otherwise display the Services by using framing,
          mirroring or similar navigational technology or directly link to any
          portion of the Services other than the main homepage in accordance
          with the Limited License and Site Access outlined above;
        </span>
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Probe, scan, test the vulnerability of or breach the authentication
          measures of, the Services or any related networks or systems;</span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Register, subscribe, attempt to register, attempt to subscribe,
          unsubscribe, or attempt to unsubscribe, any party for any Services if
          you are not expressly authorized by such party to do so;</span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Harvest or otherwise collect information about others, including
          e-mail addresses;
        </span>
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Use any robot, spider, scraper, or other automated or manual means to
          access the Services, or copy any content or information on these
          Services; or</span
        >
      </li>
      <li class="c1 c6">
        <span class="c7"
          >Sell or use any Guestio Content or information from Services for any
          commercial purpose or personal pecuniary gain.</span
        >
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >Guestio reserves the right to take whatever lawful actions it may deem appropriate in response to actual or 
        suspected violations of the foregoing, including, without limitation, the suspension or termination of the user's 
        access and/or account. Guestio may cooperate with legal authorities and/or third parties in the investigation of any 
        suspected or alleged crime or civil wrong. Except as may be expressly limited by the Privacy Policy, Guestio reserves 
        the right at all times to disclose any information as Guestio deems necessary to satisfy any applicable law, 
        regulation, legal process or governmental request, or to edit, refuse to post or to remove any information or 
        materials, in whole or in part, in Guestio's sole discretion. 
      </span>
    </p>
    <ol class="c2 lst-kix_list_1-0" start="16">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline"><span class="c14 c3">Termination</span></h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >Guestio may, in its discretion and without liability to you, with or without cause, with or without prior notice, 
        and at any time terminate these Terms or your access to the Services. Upon termination we will promptly remit to 
        you any funds in our control that we reasonably determine are owed to you. You may cancel your Account at any time 
        by contacting us or following the prompts on the site. Please note that if your Account is cancelled, we do not have 
        an obligation to delete or return to you any User Content you have posted to the Services, including, but not limited 
        to, any reviews or feedback.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="17">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Internet Risks</span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >You understand that applications, systems, and devices connected to the Internet are subject to inherent risks 
        common to the Internet, such as malicious code, viruses, hackers, and other similar parties, code, or systems. 
        Guestio has no liability for any applications accessed or downloaded using the Services or for the acts of any 
        third party that may affect the use of the Services.  You understand and agree that no data transmitted over the I
        nternet is or can be guaranteed to be completely secure.  Guestio does not guarantee that data submitted or 
        transmitted to Guestio or through the Services will be free from unauthorized disclosure, access, misappropriation, 
        or intrusion.  </span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="18">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Right to Monitor</span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >Guestio neither actively monitors general use of the Services under normal circumstances nor exercises editorial 
        control over the content of any third party's website, e-mail transmission, news group, or other material created 
        or accessible over or through these Services. However, Guestio does reserve the right to monitor such use at any 
        time as it deems appropriate and to remove any materials that, in Guestio's sole discretion, may be illegal, may 
        subject Guestio to liability, may violate these Terms of Service, or are, in the sole discretion of Guestio, 
        inconsistent with Guestio's purpose for these Services. 
      </span>
    </p>
    <ol class="c2 lst-kix_list_1-0" start="19">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline"><span class="c14 c3">Disclaimer</span></h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c7"
          >Guestio Content and other information contained in the Services has been prepared by Guestio as a convenience 
          to its users and is not intended to constitute advice or recommendations upon which a user may rely. Guestio 
          has used reasonable efforts in collecting, preparing and providing quality information and material, but makes 
          no warranty or guarantee about the accuracy, completeness, or adequacy of Guestio Content or other information 
          contained in or linked to the Services or any other Website maintained by Guestio. Users relying on Guestio 
          Content or other information from the Services do so at their own risk. 
        </span>
      </li>
      <li class="c1 c5">
        <span class="c7"
          >YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. ALL GUESTIO CONTENT, AND SERVICES ARE PROVIDED ON AN “AS IS” 
          OR “AS AVAILABLE” BASIS, AND GUESTIO EXPRESSLY DISCLAIMS ALL WARRANTIES AND CONDITIONS OF ANY KIND WHETHER 
          EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A 
          PARTICULAR PURPOSE, NON-INFRINGEMENT, SATISFACTORY QUALITY, TIMELY, ACCURATE, OR ERROR-FREE OPERATION, OR 
          FREEDOM FROM COMPUTER VIRUS OR MALICIOUS CODE. GUESTIO MAKES NO WARRANTY THAT GUESTIO CONTENT IS ACCURATE, 
          TIMELY, UNINTERRUPTED, VIRUS-FREE OR ERROR-FREE, OR THAT ANY SUCH PROBLEMS WILL BE CORRECTED.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >PLEASE NOTE THAT, GUESTIO CANNOT AND DOES NOT CONTROL THE INFORMATION CONTAINED IN ANY USER CONTENT. 
          GUESTIO IS NOT RESPONSIBLE FOR AND DISCLAIMS ANY AND ALL LIABILITY RELATED TO ANY AND ALL USER CONTENT, 
          INCLUDING COMPLIANCE WITH APPLICABLE LAWS, RULES AND GUIDES (SUCH AS FTC GUIDES CONCERNING SPONSORED 
          ENDORSEMENTS ). ANY AGREEMENTS BETWEEN A SHOW AND GUEST WILL BE MADE OR ACCEPTED AT EACH PARTY’S OWN RISK. </span
        >
      </li>
      <li class="c1 c5">
        <span class="c7">
          YOU EXPRESSLY ACKNOWLEDGE THAT YOU, AND NOT GUESTIO, ARE RESPONSIBLE FOR COMPLYING WITH THE FEDERAL TRADE 
          COMMISSION’S GUIDES CONCERNING THE USE OF ENDORSEMENTS AND TESTIMONIALS IN ADVERTISING, 16 C.F.R. Part 255 
          (THE “FTC GUIDES”), AND GUESTIO DISCLAIMS ANY AND ALL LIABILITY FOR ANY YOUR FAILURE TO COMPLY WITH THE FTC GUIDES. 
        </span>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="20">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Limitation of Liability </span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c7"
          >YOU UNDERSTAND AND AGREE THAT GUESTIO WILL NOT BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, 
          CONSEQUENTIAL OR EXEMPLARY DAMAGES THAT ARE DIRECTLY OR INDIRECTLY RELATED TO: (A) THE SERVICES; (B) ANY 
          ACTION TAKEN IN CONNECTION WITH AN INVESTIGATION BY LAW ENFORCEMENT AUTHORITIES REGARDING YOUR OR ANY OTHER 
          PARTY'S USE OF THE SERVICES; (C) ANY ACTION TAKEN IN CONNECTION WITH COPYRIGHT OR OTHER INTELLECTUAL PROPERTY 
          OWNERS; (D) ANY ERRORS OR OMISSIONS IN THE SERVICES’ OPERATION; OR (E) ANY DAMAGE TO ANY USER'S COMPUTER, 
          MOBILE DEVICE, OR OTHER EQUIPMENT OR TECHNOLOGY INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF PRODUCTS, 
          USE, DATA OR OTHER INTANGIBLE LOSSES, EVEN IF GUESTIO HAS BEEN ADVISED OF THE POSSIBILITIES OF THOSE DAMAGES, 
          RESULTING FROM YOUR USE OR INABILITY TO USE THE SERVICES, PRODUCTS AND SERVICES OR CONTENT, THE COST OF 
          OBTAINING SUBSTITUTE PRODUCTS AND SERVICES RESULTING FROM ANY LOSS OF DATA, INFORMATION, PRODUCTS AND 
          SERVICES OBTAINED FROM PURCHASES OR TRANSACTIONS, OR STATEMENTS OR CONDUCT OF ANY THIRD PARTY, OR ANY 
          OTHER MATTER RELATED TO THE SERVICES, PRODUCTS AND SERVICES OR CONTENT, EVEN IF A REMEDY SET FORTH HEREIN 
          IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE. YOU UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES 
          IS PREDICATED UPON YOUR WAIVER OF ANY RIGHT TO SUE GUESTIO AND ITS AFFILIATES DIRECTLY OR TO PARTICIPATE 
          IN A CLASS ACTION SUIT FOR ANY LOSSES OR DAMAGES RESULTING FROM YOUR USE OF THE SERVICES.</span
        >
      </li>
      <li class="c1 c5" id="h.1fob9te">
        <span class="c7"
          >YOU UNDERSTAND AND AGREE THAT GUESTIO WILL HAVE NO LIABILITY TO YOU OR TO A THIRD PARTY FOR ANY CAUSE OF 
          ACTION RELATED TO THE SERVICES OR TO THESE TERMS OF SERVICE UNDER ANY THEORY.  IF, HOWEVER, A COURT OR 
          JUDICIAL OR ADMINISTRATIVE AUTHORITY OF APPROPRIATE JURISDICTION, IN FINAL RULING, DETERMINES THAT THIS 
          PROVISION IS UNENFORCEABLE, OUR TOTAL LIABILITY TO YOU FROM ALL CAUSES OF ACTION AND UNDER ALL THEORIES 
          WILL BE LIMITED TO THE AMOUNT YOU HAVE PAID FOR THE SERVICES, IF ANY, AND IF YOU HAVE PAID NO AMOUNT, 
          THEN $50.  YOU ALSO ACKNOWLEDGE AND AGREE THAT YOU HAVE SELECTED THE SERVICES WITH A FULL UNDERSTANDING 
          OF THE LIMITATION OF OUR LIABILITY IN THIS AGREEMENT.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >YOU EXPRESSLY AGREE AND ACKNOWLEDGE THAT THE FOREGOING LIMITATIONS OF LIABILITY FORM AN ESSENTIAL BASIS 
          OF THE BARGAIN BETWEEN THE PARTIES AND SHALL APPLY EVEN IF A LIMITED REMEDY FAILS OF ITS ESSENTIAL PURPOSE 
          OR IS DEEMED UNCONSCIONABLE.  CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE 
          EXCLUSION OR LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS, 
          EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS. 
        </span>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="21">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Indemnification </span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >You agree to defend, indemnify, and hold harmless Guestio, its affiliates, its contractors, and all of their 
        respective directors, officers, employees, representatives, proprietors, partners, shareholders, servants, 
        principals, agents, predecessors, successors, assigns, accountants, and attorneys from and against any and all 
        suits, actions, claims, proceedings, damages, settlements, judgments, injuries, liabilities, obligations, 
        losses, risks, costs, and expenses (including, without limitation, attorneys’ fees and litigation expenses) 
        relating to or arising from these Services, your use of these Services, your fraud, violation of law, or willful 
        misconduct, including without limitation your failure to comply with the FTC Guides, and any breach by you of 
        these Terms of Service.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="22">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Confirming Appearances and Disputes</span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c7"
          >The Services will request that you confirm whether the Show and Guest
          performed the Appearance within 7 days after the scheduled date. If
          you are a Show, you agree that if you confirm the Appearance occurred
          or if you do not </span
        ><span>respond to</span
        ><span class="c7"
          >&nbsp;confirmation requests, you authorize Guestio to disburse
          payment to the Guest and you waive any claim against Guestio for
          non-performance or refund, whether the Appearance actually occurred or
          not. If you and another user have a dispute about whether an
          Appearance occurred, please contact support at </span
        ><span class="c21">support@guestio.com</span>
      </li>
      <li class="c1 c5">
        <span class="c7"
          >Guestio does not make any representation or warranty about the character or quality of any Appearance, 
          Guest or Show. You acknowledge that you shall have no recourse from Guestio for any dispute with another User.</span
        >
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="23">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline"><span class="c14 c3">Notice</span></h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >Any notices to you from Guestio regarding the Services or these Terms
        of Service will be posted on the Services or made by e-mail or regular
        mail.</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-0" start="24">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Social Media Shows and Websites </span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c7"
          >Any information, communications, or material of any type or nature that you submit to our Services 
          through the use of social media integration (including, but not limited to any Guestio website 
          contained on a social media show or website such as Facebook or Twitter) by email, posting, 
          messaging, uploading, downloading, or otherwise (collectively, a “Social Media Submission”), is 
          done at your own risk and without any expectation of privacy. Guestio cannot control the actions 
          of other users of any social media show or website and Guestio is therefore not responsible for 
          any content or Social Media Submissions contained on such sites and shows. By visiting any 
          Guestio Services that are contained on a social media show or website, you are representing 
          and warranting to Guestio that you have reviewed the applicable privacy policy and terms of use 
          of such show or website and that you will abide by all such provisions contained therein.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >Additionally, in the event that any one of the Services offers a message board or any other 
          interactive or social-type feature on a website administered directly by Guestio, please be 
          aware that these areas may allow you to publicly post, and share with other users, certain 
          messages, content, or other information (e.g., stories, pictures, ingredients, tips, etc.). 
          Although Guestio may take certain precautions to protect those who use these areas of 
          Guestio's Services, we encourage you to be wary of giving out any personal information 
          in such public forums. The information you post can be collected and used by people you 
          don't know. We cannot guarantee the privacy and safety of these areas and are therefore 
          not responsible for any information you choose to post. Your use of these features is 
          fully at your own risk.</span
        >
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="25">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">Electronic Communications</span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >When you visit the Services or send e-mails to us, you are communicating with us electronically. 
        You consent to receive communications from us electronically. We will communicate with you by 
        e-mail or by posting notices on the Services. You agree that all agreements, notices, disclosures 
        and other communications that we provide to you electronically satisfy any legal requirement that 
        such communications be in writing. You further agree that any notices provided by us electronically 
        are deemed to be given and received on the date we transmit any such electronic communication as 
        described in these Terms of Service.
      </span>
    </p>
    <ol class="c2 lst-kix_list_1-0" start="26">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">General Provisions </span>
        </h1>
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-1 start" start="1">
      <li class="c1 c5">
        <span class="c3">Entire Agreement</span
        ><span class="c0"
          >. These Terms of Service, the Privacy Statement, and other policies Guestio may post on the Services 
          constitutes the entire agreement between Guestio and you in connection with your use of the Services 
          and Guestio Content, and supersedes any prior agreements between Guestio and you regarding use of 
          the Services, including prior versions of these Terms of Service.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c3"
          >Governing Law; Jurisdiction; Venue; Severability of Provisions.</span
        ><span class="c0"
          >The Terms of Service are governed by the laws of the State of Delaware, USA and controlling United 
          States Federal Law without regard to any conflicts of law provisions. Any legal proceedings arising 
          from or relating to these Terms of Service shall be brought exclusively in the federal or state courts 
          located in Las Vegas, Nevada and the parties hereby consent to the personal jurisdiction and venue 
          of such courts. All parts of these Terms of Service apply to the maximum extent permitted by law. We 
          both agree that if any provision of these Terms of Service is found by a court of competent jurisdiction 
          to be unenforceable as written, then that part will be replaced with terms that most closely match the 
          intent of the unenforceable provision to the extent permitted by law. The invalidity of part of these 
          Terms of Service will not affect the validity and enforceability of the remaining provisions. The 
          section headings are for convenience and do not have any force or effect.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c3">No Agency Relationship.</span
        ><span class="c0"
          >Neither these Terms of Service, nor any Guestio Content, materials, or features of the Services 
          create any partnership, joint venture, employment, or other agency relationship between us. You may 
          not enter into any contract on our behalf or bind us in any way. 
        </span>
      </li>
      <li class="c1 c5">
        <span class="c3">Time Limitation on Claims. </span
        ><span class="c0"
          >You agree that any claim you may have arising out of or related to your use of the Services or 
          your relationship with Guestio must be filed within one year after such claim arose; otherwise, 
          your claim is permanently barred.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c3">Remedies. </span
        ><span class="c0"
          >You agree that any violation, or threatened violation, by you of these Terms of Service constitutes
           an unlawful and unfair business practice that will cause us irreparable and unquantifiable harm. 
           You also agree that monetary damages would be inadequate for such harm and consent to our obtaining 
           any injunctive or equitable relief that we deem necessary or appropriate. These remedies are in addition 
           to any other remedies we may have at law or in equity.</span
        >
      </li>
    </ol>
    <ol class="c2 lst-kix_list_1-0" start="27">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline"><span>Contacting Us. </span></h1>
      </li>
    </ol>
    <p class="c1">
      <span
        >If you have any questions or concerns about these Terms of Service,
        please contact us at </span
      ><span class="c16"
        ><a class="c17" href="mailto:support@guestio.com"
          >support@guestio.com</a
        ></span
      ><span
        >&nbsp;or at the mailing address provided below. We will attempt to
        respond to your questions or concerns promptly after we receive
        them.</span
      >
    </p>
    <p class="c25 c15"><span class="c0">Guestio, Inc.</span></p>
    <p class="c25 c15"><span class="c0">6232 Hambledon Hill Rd.</span></p>
    <p class="c15 c25"><span class="c0">Las Vegas, NV 89113</span></p>
    <ol class="c2 lst-kix_list_1-0" start="28">
      <li class="c8 li-bullet-0">
        <h1 style="display: inline">
          <span class="c14 c3">App Store Terms of Use. &nbsp; </span>
        </h1>
      </li>
    </ol>
    <p class="c1">
      <span class="c0"
        >The following terms apply to any App accessed through or downloaded
        from any app store or distribution show (like a Guestio portal, the
        Apple App Store or Google Play) where the App may now or in the future
        be made available (each an “App Provider”). &nbsp;You acknowledge and
        agree that:</span
      >
    </p>
    <ol class="c2 lst-kix_list_1-1" start="6">
      <li class="c1 c5">
        <span class="c7"
          >These Terms are concluded between you and Guestio, and not with the
          App Provider, and that Guestio (not the App Provider, to the extent
          the App provider is not Guestio), is solely responsible for the
          App.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >To the extent you obtain an App from the Apple App Store, any
          licenses granted hereunder for the use of the App are limited to a
          license to use the App on any Apple-Platformed Products that you own
          or control and as permitted by the Usage Rules set forth in the App
          Store Terms of Service, except that the App may be accessed and used
          by other accounts associated with you via family sharing or volume
          purchasing.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >Unless expressly stated otherwise in writing by the App Provider, the
          App Provider has no obligation to furnish any maintenance and support
          services with respect to the App.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >To the extent the App Provider is not Guestio, you may notify the App Provider in the event of any 
          failure of the App to conform to any applicable warranty, and the App Provider will refund the purchase 
          price for the App to you (if applicable) and to the maximum extent permitted by applicable law, the App 
          Provider will have no other warranty obligation whatsoever with respect to the App.  To the extent that 
          Guestio is not the App Provider, any claims, losses, liabilities, damages, costs or expenses other than 
          the purchase price attributable to any failure to conform to any warranty will be the sole responsibility 
          of Guestio in accordance with these Terms.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >To the extent the App Provider is not Guestio, the App Provider is
          not responsible for addressing any claims you have or any claims of
          any third party relating to the App or your possession and use of the
          App, including, but not limited to: (A) product liability claims; (B)
          any claim that the App fails to conform to any applicable legal or
          regulatory requirement; or &nbsp;(C) claims arising under consumer
          protection or similar legislation.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >To the extent the App Provider is not Guestio, in the event of any
          third party claim that the App or your possession and use of that App
          infringes that third party’s intellectual property rights, Guestio
          will be solely responsible for the investigation, defense, settlement,
          and discharge of any such intellectual property infringement claim to
          the extent required by these Terms.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >To the extent the App Provider is not Guestio, the App Provider, and
          its subsidiaries, are third party beneficiaries of these Terms as
          related to your license of the App, and that, upon your acceptance of
          the terms and conditions of these Terms, the App Provider will have
          the right (and will be deemed to have accepted the right) to enforce
          these Terms as related to your license of the App against you as a
          third party beneficiary thereof.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >You must also comply with all applicable third party terms of service
          when using the App.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >You represent and warrant that (i) you are not located in a country
          that is subject to a U.S. Government embargo, or that has been
          designated by the U.S. Government as a “terrorist supporting” country;
          and (ii) you are not listed on any U.S. Government list of prohibited
          or restricted parties.</span
        >
      </li>
      <li class="c1 c5">
        <span class="c7"
          >To the extent you obtain an App from the Apple App Store, you agree
          that Apple, and Apple’s subsidiaries, are third party beneficiaries of
          the sections of these Terms applicable to the App, and that, upon the
          your acceptance of these Terms, Apple will have the right (and will be
          deemed to have accepted the right) to enforce the applicable End User
          License Agreement against you as a third party beneficiary
          thereof.</span
        >
      </li>
    </ol>
    <div>
      <p class="c12 c15"><span class="c7 c10"></span></p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    dark: {
      default: false,
      type: Boolean
    }
  }
}
</script>

<style scoped>

ol.lst-kix_list_1-3 {
  list-style-type: none;
}
ol.lst-kix_list_1-4 {
  list-style-type: none;
}
.lst-kix_list_2-6 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_2-7 > li:before {
  content: "\0025aa  ";
}
ol.lst-kix_list_1-5 {
  list-style-type: none;
}
ol.lst-kix_list_1-6 {
  list-style-type: none;
}
ol.lst-kix_list_1-0 {
  list-style-type: none;
}
.lst-kix_list_2-4 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_2-5 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_2-8 > li:before {
  content: "\0025aa  ";
}
ol.lst-kix_list_1-1 {
  list-style-type: none;
}
ol.lst-kix_list_1-2 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li {
  counter-increment: lst-ctn-kix_list_1-1;
}
.lst-kix_list_3-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_3-1 > li:before {
  content: "o  ";
}
.lst-kix_list_3-2 > li:before {
  content: "\0025aa  ";
}
ul.lst-kix_list_3-7 {
  list-style-type: none;
}
ul.lst-kix_list_3-8 {
  list-style-type: none;
}
ol.lst-kix_list_1-8.start {
  counter-reset: lst-ctn-kix_list_1-8 0;
}
ul.lst-kix_list_3-1 {
  list-style-type: none;
}
.lst-kix_list_3-5 > li:before {
  content: "\0025aa  ";
}
ul.lst-kix_list_3-2 {
  list-style-type: none;
}
.lst-kix_list_3-4 > li:before {
  content: "\0025aa  ";
}
ul.lst-kix_list_3-0 {
  list-style-type: none;
}
ol.lst-kix_list_1-5.start {
  counter-reset: lst-ctn-kix_list_1-5 0;
}
ol.lst-kix_list_1-7 {
  list-style-type: none;
}
.lst-kix_list_3-3 > li:before {
  content: "\0025aa  ";
}
ul.lst-kix_list_3-5 {
  list-style-type: none;
}
.lst-kix_list_1-7 > li {
  counter-increment: lst-ctn-kix_list_1-7;
}
ol.lst-kix_list_1-8 {
  list-style-type: none;
}
ul.lst-kix_list_3-6 {
  list-style-type: none;
}
ul.lst-kix_list_3-3 {
  list-style-type: none;
}
ul.lst-kix_list_3-4 {
  list-style-type: none;
}
.lst-kix_list_3-8 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_3-6 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_3-7 > li:before {
  content: "\0025aa  ";
}
ol.lst-kix_list_1-7.start {
  counter-reset: lst-ctn-kix_list_1-7 0;
}
.lst-kix_list_1-2 > li {
  counter-increment: lst-ctn-kix_list_1-2;
}
.lst-kix_list_1-5 > li {
  counter-increment: lst-ctn-kix_list_1-5;
}
.lst-kix_list_1-8 > li {
  counter-increment: lst-ctn-kix_list_1-8;
}
ol.lst-kix_list_1-4.start {
  counter-reset: lst-ctn-kix_list_1-4 0;
}
ol.lst-kix_list_1-1.start {
  counter-reset: lst-ctn-kix_list_1-1 0;
}
.lst-kix_list_1-4 > li {
  counter-increment: lst-ctn-kix_list_1-4;
}
ol.lst-kix_list_1-6.start {
  counter-reset: lst-ctn-kix_list_1-6 0;
}
ol.lst-kix_list_1-3.start {
  counter-reset: lst-ctn-kix_list_1-3 0;
}
ul.lst-kix_list_2-8 {
  list-style-type: none;
}
ol.lst-kix_list_1-2.start {
  counter-reset: lst-ctn-kix_list_1-2 0;
}
ul.lst-kix_list_2-2 {
  list-style-type: none;
}
.lst-kix_list_1-0 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) ". ";
}
ul.lst-kix_list_2-3 {
  list-style-type: none;
}
ul.lst-kix_list_2-0 {
  list-style-type: none;
}
ul.lst-kix_list_2-1 {
  list-style-type: none;
}
ul.lst-kix_list_2-6 {
  list-style-type: none;
}
.lst-kix_list_1-1 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) ". ";
}
.lst-kix_list_1-2 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) ". ";
}
ul.lst-kix_list_2-7 {
  list-style-type: none;
}
ul.lst-kix_list_2-4 {
  list-style-type: none;
}
ul.lst-kix_list_2-5 {
  list-style-type: none;
}
.lst-kix_list_1-3 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) ". ";
}
.lst-kix_list_1-4 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) ". ";
}
ol.lst-kix_list_1-0.start {
  counter-reset: lst-ctn-kix_list_1-0 0;
}
.lst-kix_list_1-0 > li {
  counter-increment: lst-ctn-kix_list_1-0;
}
.lst-kix_list_1-6 > li {
  counter-increment: lst-ctn-kix_list_1-6;
}
.lst-kix_list_1-7 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) "."
    counter(lst-ctn-kix_list_1-6, decimal) "."
    counter(lst-ctn-kix_list_1-7, decimal) ". ";
}
.lst-kix_list_1-3 > li {
  counter-increment: lst-ctn-kix_list_1-3;
}
.lst-kix_list_1-5 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) ". ";
}
.lst-kix_list_1-6 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) "."
    counter(lst-ctn-kix_list_1-6, decimal) ". ";
}
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}
.lst-kix_list_2-0 > li:before {
  content: "\0025cf  ";
}
.lst-kix_list_2-1 > li:before {
  content: "o  ";
}
.lst-kix_list_1-8 > li:before {
  content: "" counter(lst-ctn-kix_list_1-0, decimal) "."
    counter(lst-ctn-kix_list_1-1, decimal) "."
    counter(lst-ctn-kix_list_1-2, decimal) "."
    counter(lst-ctn-kix_list_1-3, decimal) "."
    counter(lst-ctn-kix_list_1-4, decimal) "."
    counter(lst-ctn-kix_list_1-5, decimal) "."
    counter(lst-ctn-kix_list_1-6, decimal) "."
    counter(lst-ctn-kix_list_1-7, decimal) "."
    counter(lst-ctn-kix_list_1-8, decimal) ". ";
}
.lst-kix_list_2-2 > li:before {
  content: "\0025aa  ";
}
.lst-kix_list_2-3 > li:before {
  content: "\0025aa  ";
}
ol {
  margin: 0;
  padding: 0;
}
table td,
table th {
  padding: 0;
}
.c8 {
  margin-left: 18pt;
  padding-top: 24pt;
  padding-left: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c18 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 10pt;
  font-family: "Arial";
  font-style: normal;
}
.c0 {
  color: inherit;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Arial";
  font-style: normal;
}
.c24 {
  -webkit-text-decoration-skip: none;
  text-decoration: underline;
  vertical-align: baseline;
  text-decoration-skip-ink: none;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}
.c9 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Arial";
  font-style: normal;
}
.c1 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
.c10 {
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: "Calibri";
  font-style: normal;
}
.c12 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
  height: 10pt;
}
.c14 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: "Arial";
  font-style: normal;
}
.c19 {
  text-decoration: none;
  vertical-align: baseline;
  font-size: 10pt;
  font-family: "Arial";
  font-style: normal;
}
.c13 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c25 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: justify;
}
.c20 {
  padding-top: 0pt;
  padding-bottom: 12pt;
  line-height: 1;
  text-align: center;
}
.c16 {
  text-decoration-skip-ink: none;
  -webkit-text-decoration-skip: none;
  color: #1155cc;
  text-decoration: underline;
}
.c5 {
  margin-left: 0pt;
  list-style-position: inside;
  text-indent: 45pt;
}
.c22 {
  background-color: #ffffff;
  padding: 72pt 72pt 72pt 72pt;
}
.c6 {
  margin-left: 36pt;
  list-style-position: inside;
  text-indent: 90pt;
}
.c11 {
  border: 1px solid black;
  margin: 5px;
}
.c4 {
  margin-left: 36pt;
  padding-left: 0pt;
}
.c17 {
  color: inherit;
  text-decoration: inherit;
}
.c2 {
  padding: 0;
  margin: 0;
}
.c3 {
  color: inherit;
  font-weight: 700;
}
.c15 {
  orphans: 2;
  widows: 2;
}
.c7 {
  color: inherit;
}
.c26 {
  font-style: italic;
}
.c21 {
  background-color: #ffff00;
}
.c23 {
  font-weight: 700;
}
.title {
  padding-top: 0pt;
  color: inherit;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 12pt;
  font-family: "Arial";
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.subtitle {
  padding-top: 18pt;
  color: #666666;
  font-size: 24pt;
  padding-bottom: 4pt;
  font-family: "Georgia";
  line-height: 1;
  page-break-after: avoid;
  font-style: italic;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
li {
  color: inherit;
  font-size: 10pt;
  font-family: "Arial";
}
p {
  margin: 0;
  color: inherit;
  font-size: 10pt;
  font-family: "Arial";
}
h1 {
  padding-top: 24pt;
  color: inherit;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 12pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h2 {
  padding-top: 2pt;
  color: #2f5496;
  font-size: 13pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h3 {
  padding-top: 2pt;
  color: #1f3863;
  font-size: 12pt;
  padding-bottom: 0pt;
  font-family: "Calibri";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h4 {
  padding-top: 12pt;
  color: inherit;
  font-weight: 700;
  font-size: 12pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h5 {
  padding-top: 11pt;
  color: inherit;
  font-weight: 700;
  font-size: 11pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
h6 {
  padding-top: 10pt;
  color: inherit;
  font-weight: 700;
  font-size: 10pt;
  padding-bottom: 2pt;
  font-family: "Arial";
  line-height: 1;
  page-break-after: avoid;
  orphans: 2;
  widows: 2;
  text-align: justify;
}
</style>