<template>
<svg width="16" height="19" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.5456 17.357L8.182 12.9452L1.81836 17.357V3.23932C1.81836 2.2647 2.63239 1.47461 3.63654 1.47461H12.7275C13.7316 1.47461 14.5456 2.2647 14.5456 3.23932V17.357Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
</template>

<script>
    export default {
        name: "BookMarkIcon"
    }
</script>

<style lang="scss" scoped>

</style>