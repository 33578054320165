import Vue from 'vue'
import VueRouter from 'vue-router'
import VueAnalytics from 'vue-ua'
import Home from '../views/Home.vue';
import store from '../store/index';

import dashboardRoutes from './dashboard';
import authRoutes from './auth';
import guestsRoutes from './guests';
import showsRoutes from './shows';
import miniviewsRoutes from './miniviews';

Vue.use(VueRouter)

const isAuthenticated = () => {
  return localStorage.getItem('authenticated')
}

const hasVerifiedEmail = () => {
  const user = store.getters['auth/getUser'];

  if (user.status !== 'pending') {
    return true;
  }

  return false;
}

const verifyEmail = (from) => {
  store.dispatch('auth/verifyEmail', from.query.queryURL)
    .finally(() => {
      store.dispatch('auth/getUser');
      router.push({ name: 'ThankYou' });
    });
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('@/views/About'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact'),
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import('@/views/TermsAndConditions'),
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('@/views/PrivacyPolicy'),
  },
  {
    path: '/thanks',
    name: 'ThankYou',
    component: () => import('@/views/ThankYou'),
  },
  {
    path: '/browse',
    name: 'Browse',
    component: () => import('@/views/Browse'),
  },
  {
    path: '/guestio-talent',
    name: 'GuestioTalent',
    component: () => import('@/views/GuestioTalent'),
  },
  {
    path: '/booking/success',
    name: 'BookingSuccess',
    props: true,
    component: () => import('@/views/BookingSuccess.vue')
  },
  {
    path: '/verify-email',
    beforeEnter: verifyEmail,
  },
  {
    path: '/check-email',
    meta: {
      requiresAuth: true,
    },
    name: 'CheckEmail',
    component: () => import('@/views/CheckEmail'),
  },
  // {
  //   path: '/profile-setup/availability',
  //   name: 'availability',
  //   component: () => import('../views/Availability'),
  //   beforeEnter: withAuth,
  // },
  {
    path: '/guest-availability/:guestId',
    name: 'guest-availability',
    component: () => import('../views/GuestAvailability.vue')
  },
  {
    path: '/pages/:pageSlug',
    name: 'pages.show',
    component: () => import('../views/Page.vue')
  },
  {
    path: '/stripe/authorization',
    name: 'stripe.authorization',
    component: () => import('../views/StripeAuthorization.vue')
  },
  {
    path: '/google/authorization',
    name: 'google.authorization',
    component: () => import('../views/GoogleAuthorization.vue')
  },
  {
    path: '/microsoft/authorization',
    name: 'microsoft.authorization',
    component: () => import('../views/MicrosoftAuthorization.vue')
  },
  {
    path: '/instagram/authorization',
    name: 'instagram.authorization',
    component: () => import('../views/InstagramAuthorization.vue')
  },
  {
    path: '/youtube/authorization',
    name: 'youtube.authorization',
    component: () => import('../views/YoutubeAuthorization.vue')
  },
  {
    path: '/twitter/authorization',
    name: 'twitter.authorization',
    component: () => import('../views/TwitterAuthorization.vue')
  },
  {
    path: '/tiktok/authorization',
    name: 'tiktok.authorization',
    component: () => import('../views/TiktokAuthorization.vue')
  },
  {
    path: '/team-invitations/:invitationId',
    name: 'team-invitations',
    component: () => import('../views/TeamInvitations'),
  },
  ...dashboardRoutes,
  ...authRoutes,
  ...guestsRoutes,
  ...showsRoutes,
  ...miniviewsRoutes,
  {
    path: '/404-not-found',
    name: 'NotFound',
    component: () => import('@/views/NotFound'),
  },
  {
    path: '/403-forbidden',
    name: 'Forbidden',
    component: () => import('@/views/Forbidden'),
  },
  // Will first try to resolve and load a guest profile
  // or redirect to 404 page if not found
  {
    path: '/:guestId',
    name: 'GuestProfile',
    component: () => import('@/views/Guest.vue')
  },
  {
    path: '*',
    redirect: { name: 'NotFound' }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.meta.scrollToTop === false) {
      return false;
    } else if (savedPosition) {
      return savedPosition
    } else if (from.path == to.path) {
      return false
    } else {
      return { x: 0, y: 0 }
    }
  }
})

if (process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  Vue.use(VueAnalytics, {
    appName: 'Guestio',
    appVersion: 'v1.0.0',
    trackingId: process.env.VUE_APP_GOOGLE_ANALYTICS_TRACKING_ID,
    debug: process.env.VUE_APP_GOOGLE_ANALYTICS_DEBUG || false,
    vueRouter: router,
  })
}

router.beforeEach((to, from, next) => {
  const isImpersonate = to.matched.some(record => record.meta.isImpersonate);

  if (isImpersonate) {
    store.dispatch('auth/impersonate', { ...to.query, device: 'spa' }).finally(() => {
      router.push({ name: 'Home' });
      // window.location.reload();
    })
  }
  const reqAuth = to.matched.some(record => record.meta.requiresAuth);
  const reqGuest = to.matched.some(record => record.meta.guest);

  if (reqAuth) {
    if (!isAuthenticated()) {
      localStorage.setItem('redirect_path', to.fullPath)
      return next({ name: 'Login' })
    }

    if (
      !['/check-email', '/verify-email'].includes(to.path)
      && !to.path.startsWith('/register')
      && !hasVerifiedEmail()
    ) {
      return next({ name: 'CheckEmail' });
    }

    return next()
  } else if (reqGuest) {
    if (isAuthenticated()) {
      return next({ name: 'Home' });
    }
    return next()
  } else {
    return next();
  }
});

export default router
