<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    :stroke="stroke"
    :stroke-width="strokeWidth"
    stroke-linecap="round"
    stroke-linejoin="round"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.994 2.4671C17.529 1.81433 17.0646 0.833191 16.2206 0.833191H1.77915C0.935115 0.833191 0.470703 1.81433 1.00575 2.46711L6.90659 9.66636C7.05311 9.84513 7.13319 10.0691 7.13319 10.3003V15.6312C7.13319 16.0055 7.34214 16.3484 7.67472 16.52L9.40806 17.4141C10.0736 17.7575 10.8665 17.2743 10.8665 16.5254V10.3003C10.8665 10.0691 10.9466 9.84513 11.0931 9.66636L16.994 2.4671Z"
      :fill="fill"/>
  </svg>
</template>

<script>
export default {
  name: 'FilterIcon',
  props:{
    fill: {
      type: String,
      default: 'none',
      required: false
    },
    stroke: {
      type: String,
      default: '#000',
      required: false
    },
    strokeWidth: {
      type: Number,
      default: 2,
      required: false
    }
  }
}
</script>

<style lang="css" scoped></style>