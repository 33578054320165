<template>
  <div>
    <div class="mt-6 bg-guestio-gray-100 p-6 lg:px-8 rounded-lg">
      <div class="header flex justify-between items-center">
        <div class="flex">
          <button @click="closeVideoPitch" type="button">
            <XIcon class="h-6 text-blue-800" />
          </button>

          <h4 class="ml-4 font-poppins text-lg font-bold text-blue-800">Record Video</h4>
        </div>

        <div v-if="previewRecording">
          <button @click.prevent="redoRecording" type="button" class="flex items-center space-x-2 text-pink-500">
            <RedoIcon class="w-4 h-4" />
            <span>Redo</span>
          </button>
        </div>
      </div>

      <div class="mt-6">
        <div class="h-64 overflow-hidden relative pb-2/3 rounded-lg">
          <div class="absolute inset-0 z-10">
            <video
              v-show="previewRecording"
              ref="videoRecording"
              class="w-full h-full object-cover"
              @durationchange="fixDuration"
              @play="playing = true"
              @pause="playing = false"
              playsinline
              looping="false"
            ></video>
            <video v-show="! previewRecording" ref="video" id="myVideo" class="video-js vjs-default-skin w-full h-full object-cover" playsinline></video>
          </div>
          <div v-if="isRecording && recordedSeconds" class="absolute z-20 bottom-0 left-0 ml-6 mb-6 px-2 py-1 rounded-lg bg-blue-800 text-white bg-opacity-50">
            <span class="text-lg font-bold font-poppins">
              {{ recordedSeconds | time }}
            </span>
          </div>
        </div>

        <div v-if="deviceError" class="mt-4 bg-red-100 p-4 rounded-lg shadow-md flex items-center">
          <span>
            <CircleAlertIcon class="w-4" color="#e53e3e" />
          </span>
          <span class="ml-2 text-red-700 text-sm">
            Please allow access to your camera and mic to record pitch.
          </span>
        </div>

        <div v-if="sendingErrorMessage" class="mt-4 bg-red-100 p-4 rounded-lg shadow-md flex items-center">
          <span>
            <CircleAlertIcon class="w-4" color="#e53e3e" />
          </span>
          <span class="ml-2 text-red-700 text-sm">
            {{ sendingErrorMessage }}
          </span>
        </div>

        <template v-if="previewRecording">
          <div class="mt-4 bg-black bg-opacity-30 h-14 rounded-lg flex items-center px-6 space-x-5">
            <div class="flex items-center">
              <button v-if="! playing" @click.prevent="$refs.videoRecording.play()" type="button" class="p-2 focus:outline-none rounded focus:shadow-outline">
                <PlayAltIcon class="h-4 w-4 text-white" />
              </button>
              <button v-else @click.prevent="$refs.videoRecording.pause(); $refs.videoRecording.currentTime = 0" type="button" class="p-2 focus:outline-none rounded focus:shadow-outline">
                <StopIcon class="h-4 w-4 text-white" />
              </button>
            </div>
            <div @click="seek" class="progress relative flex-1 h-2 bg-white rounded-full flex items-center">
              <div
                :style="{ width: this.playProgress + '%' }"
                class="absolute inset-y-0 rounded-full bg-pink-500 pointer-events-none">
              </div>
              <div
                :style="{ left: this.playProgress + '%' }"
                class="absolute w-5 h-5 rounded-full bg-white shadow -ml-2.5 pointer-events-none"></div>
            </div>
            <div class="text-sm font-poppins font-bold text-white whitespace-nowrap">
              {{ recordedSeconds | time }}
            </div>
          </div>
        </template>

        <template v-if="! previewRecording">
          <div v-if="! isRecording" class="grid md:grid-cols-1 lg:grid-cols-2 col-gap-4 row-gap-4 mt-4">
            <div v-if="videoDevices.length">
              <label for="video-input" class="text-black font-medium">
                Video Input
              </label>
              <div class="mt-1">
                <select v-model="videoInput" id="video-input" class="form-select w-full px-5 py-3 rounded-full focus:bg-white" @change="setVideoInputDevice">
                  <option v-for="device in videoDevices" :value="device.id" :key="`video-input-${device.id}`">
                    {{ device.name }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="audioDevices.length">
              <label for="audio-input" class="text-black font-medium">
                Audio Input
              </label>
              <div class="mt-1">
                <select v-model="audioInput" id="audio-input" class="form-select w-full px-5 py-3 rounded-full focus:bg-white">
                  <option v-for="device in audioDevices" :value="device.id" :key="`audio-input-${device.id}`">
                    {{ device.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div v-if="isReadyToRecord" class="flex justify-center mt-8">
            <button v-if="! isRecording" @click="startRecording" type="button" class="btn hover:opacity-90">
              Start Recording
            </button>

            <button v-else @click="stopRecording" type="button" class="bg-indigo-gradiant p-6 rounded-full flex items-center justify-center ml-2 lg:ml-4 hover:opacity-90">
              <StopIcon class="h-6 w-6" />
            </button>
          </div>
        </template>
      </div>
    </div>

    <div v-if="previewRecording" class="flex pt-4" :class="{'opacity-75': sending}">
      <div class="rounded-full px-6 py-4 bg-gray-50 flex items-center flex-grow" :class="{'bg-red-100': sendingErrorMessage}">
        <input
          v-model="body"
          type="text"
          class="bg-transparent w-10 flex-grow text-sm"
          :maxlength="maxChars"
          placeholder="Type a Message..."
          ref="message"
          @keydown.enter="sendPitch">
        <span class="text-gray-500 ml-2 block flex-shrink-0 text-xs">{{ charCount }}/{{ maxChars }}</span>
      </div>
      <!--Button-->
      <template v-if="sending">
        <div class="ml-4 flex items-center justify-center">
          <loading-icon class="h-2 text-pink-500"/>
        </div>
      </template>

      <template v-else>
        <button @click="sendPitch" type="button" class="bg-indigo-gradiant p-4 rounded-full flex items-center justify-center ml-4 hover:opacity-90">
          <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M15.2673 8.42992C15.424 8.23071 15.4041 7.94515 15.2208 7.76985C15.0389 7.59454 14.7533 7.58524 14.5594 7.74993L5.71941 15.2711L1.05121 13.4648C0.636817 13.3068 0.352611 12.9203 0.324731 12.4767C0.295512 12.0344 0.527935 11.6147 0.919731 11.4049L20.2997 0.548727C20.6092 0.381383 20.9864 0.398648 21.2799 0.592557C21.5747 0.787792 21.7368 1.12911 21.7036 1.47975L20.0036 19.2009C19.969 19.5688 19.7618 19.8995 19.4471 20.0921C19.131 20.2846 18.7419 20.3178 18.3992 20.1797L12.7175 17.9803L9.15018 21.3219C8.8819 21.5729 8.48878 21.6406 8.15143 21.4932C7.81409 21.3445 7.5976 21.0098 7.60026 20.6419V18.0175L15.2673 8.42992Z"
              fill="white"/>
          </svg>
        </button>
      </template>
    </div>
  </div>
</template>

<script>
  import 'video.js/dist/video-js.css'
  import 'videojs-record/dist/css/videojs.record.css'
  import videojs from 'video.js'
  // eslint-disable-next-line
  import RecordRTC from 'recordrtc'
  // eslint-disable-next-line
  import Record from 'videojs-record/dist/videojs.record.js'
  import XIcon from '@/components/svgs/XIcon'
  import StopIcon from '@/components/svgs/StopIcon'
  import CircleAlertIcon from '@/components/svgs/CircleAlertIcon'
  import PlayAltIcon from '@/components/svgs/PlayAltIcon'
  import RedoIcon from '@/components/svgs/RedoIcon'
  import api from '@/api'
  export default {
    name: 'CreateVideoPitch',

    components: {
      XIcon, StopIcon, CircleAlertIcon, RedoIcon, PlayAltIcon
    },

    data() {
      return {
        previewRecording: false,
        playing: false,
        isReadyToRecord: false,
        isRecording: false,
        deviceError: false,
        videoDevices: [],
        videoInput: null,
        audioDevices: [],
        audioInput: null,
        currentSeconds: 0,
        recordedSeconds: 0,
        sending: false,
        sendingErrorMessage: null,
        maxChars: 300,
        body: '',
        player: null,
        options: {
          controls: false,
          bigPlayButton: false,
          pip: false,
          fluid: false,
          width: 640,
          height: 480,
          controlBar: {
            fullscreenToggle: false,
          },
          plugins: {
            record: {
              videoRecorderType: 'WhammyRecorder',
              audio: true,
              video: {
                // video media constraints: set resolution of camera
                width: { min: 640, ideal: 640, max: 1280 },
                height: { min: 480, ideal: 480, max: 920 }
              },
              // dimensions of captured video frames
              frameWidth: 640,
              frameHeight: 480,
              maxLength: 60,
              displayMilliseconds: false,
              debug: false,
              timeSlice: 1000
            }
          }
        }
      }
    },

    watch: {
      playing(playing) {
        if (! this.previewRecording) {
          return
        }

        if (playing) {
          this.interval = setInterval(() => {
            this.currentSeconds = this.$refs.videoRecording.currentTime
          }, 100)
        } else {
          if (this.interval) {
            clearInterval(this.interval)
          }
        }
      }
    },

    computed: {
      charCount() {
        return this.body.length
      },

      playProgress() {
        return this.currentSeconds / this.recordedSeconds * 100;
      },

      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },

      user() {
        return this.$store.getters['auth/getUser']
      },

      userIsGuest() {
        return this.user.guest && this.user.guest.id == this.booking.guest_profile_id
      }
    },

    filters: {
      time(value) {
        if (value < 60) {
          let seconds = Math.round(value).toString().padStart(2, '0')

          return `0:${seconds}`
        }

        let minutes = Math.floor(value / 60)
        let seconds = Math.round(value % 60).toString().padStart(2, '0')

        return `${minutes}:${seconds}`
      }
    },

    methods: {
      redoRecording() {
        this.$refs.videoRecording.src = null
        this.player.record().reset()
        this.player.record().getDevice()
        this.currentSeconds = 0
        this.recordedSeconds = 0
        this.previewRecording = false
        this.sendingErrorMessage = null
      },

      seek(e) {
        const el = e.target.getBoundingClientRect();
        const seekPos = (e.clientX - el.left) / el.width;

        this.currentSeconds = this.recordedSeconds * seekPos;
        this.$refs.videoRecording.currentTime = this.currentSeconds

        if (! this.playing) {
          this.$refs.videoRecording.play()
        }
      },

      setAudioInputDevice() {
        this.player.record().setAudioInput(this.audioInput)
      },

      setVideoInputDevice() {
        this.player.record().setVideoInput(this.videoInput)
      },

      startRecording() {
        if (! this.player) {
          return
        }

        this.player.record().start()
      },

      stopRecording() {
        if (! this.player) {
          return
        }

        this.player.record().stop()
        this.player.record().stopDevice()
      },

      closeVideoPitch() {
        this.$emit('close')
      },

      sendPitch() {
        this.sending = true
        this.sendingErrorMessage = null
        let form = new FormData

        // console.log(this.player.recordedData)

        form.append('video', this.player.recordedData)
        form.append('message', this.body)
        form.append('sender', this.userIsGuest ? 'guest' : 'show')

        api.post(`/bookings/${this.booking.id}/video-pitch`, form)
          .then(() => {
            window.Event.$emit('refreshActivity')
            this.$emit('close')
          })
          .catch(error => {
            if (! error.response) {
              this.sendingErrorMessage = 'The video pitch was not saved.'
              return
            }

            if (error.response.status == 426) {
              this.sendingErrorMessage = 'Only PRO users have access to this feature.'
              return
            }

            if (error.response.data.message) {
              this.sendingErrorMessage = error.response.data.message
              return
            }

            this.sendingErrorMessage = 'The video pitch was not saved.'
          }).finally(() => this.sending = false)
      },

      applyVideoWorkaround() {
        if (!!window.opera || navigator.userAgent.indexOf('OPR/') !== -1) {
          this.options.plugins.record.videoMimeType = 'video/webm;codecs=vp8'; // or vp9
        }
      },

      initPlayer() {
        this.player = videojs(this.$refs.video, this.options);

        this.player.record().getDevice()

        this.addEventListeners()
      },

      addEventListeners() {
        // device is ready
        this.player.on('deviceReady', () => {
          this.deviceError = false
          this.isReadyToRecord = true
          this.player.record().enumerateDevices()
        })

        this.player.on('enumerateReady', () => {
          this.fetchDevices()
        })

        // user clicked the record button and started recording
        this.player.on('startRecord', () => {
          this.isRecording = true
        })

        this.player.on('stopRecord', () => {
          this.isRecording = false
          this.previewRecording = true
        })

        this.player.on('timestamp', () => {
          this.recordedSeconds = Math.round(this.player.record().getDuration())
        })

        // user completed recording and stream is available
        this.player.on('finishRecord', () => {
          // eslint-disable-next-line
          let blob = this.player.recordedData
          this.$refs.videoRecording.src = URL.createObjectURL(this.player.recordedData)
          this.isRecording = false
          this.previewRecording = true
        })

        // error handling
        this.player.on('error', (element, error) => {
          console.warn(error);
        })

        this.player.on('deviceError', () => {
          this.deviceError = true
        })

        this.player.on('play', () => {
          this.playing = true
        })
        this.player.on('pause', () => {
          this.playing = false
        })
      },

      fetchDevices() {
        this.videoDevices = this.player.record().devices
          .filter((d) => d.kind === 'videoinput' && d.deviceId.length)
          .map((d) => {
            return {
              name: d.label,
              id: d.deviceId
            }
          })
        this.audioDevices = this.player.record().devices
          .filter((d) => d.kind === 'audioinput' && d.deviceId.length)
          .map((d) => {
            return {
              name: d.label,
              id: d.deviceId
            }
          })
      },

      fixDuration(event) {
        if (! this.previewRecording) {
          return
        }

        let duration = event.target.duration

        console.log('fix duration', duration)

        if (duration != Infinity) {
          event.target.currentTime = 0
          this.recordedSeconds = duration
          this.currentSeconds = 0
          return
        }

        event.target.currentTime = 99999
      }
    },

    mounted() {
      this.applyVideoWorkaround()
      this.initPlayer()
    },

    beforeDestroy() {
      if (this.player) {
        this.player.dispose();
      }
    }
  }
</script>
