<template>
  <div class="w-full flex flex-col xl:flex-row space-y-2 xl:space-y-0 items-center justify-between mb-10">
    <div class="flex flex-wrap lg:flex-no-wrap items-center justify-between w-full">
      <div class="w-full flex xl:block rounded-full bg-white border border-guestio-gray-300 shadow-xs overflow-hidden">
        <button @click="toggleBookedBy('me')"
                :class="filters.bookedBy === 'me' ? 'bg-indigo-gradiant text-white font-semibold' : 'text-blue-800'"
                class="flex-1 rounded-full text-center w-6/12 py-3 text-xs">
          I booked
        </button>
        <button @click="toggleBookedBy('others')"
                :class="filters.bookedBy === 'others' ? 'bg-indigo-gradiant text-white font-semibold' : 'text-blue-800'"
                class="flex-1 rounded-full text-center py-3 w-6/12 text-xs">
          They booked me
        </button>
      </div>
      <!--Search input-->
      <div class="lg:ml-3 flex items-center rounded-full shadow-xs bg-white border border-guestio-gray-300 py-2.5 pl-3 pr-5 my-5 lg:my-0 w-full md:mr-6">
        <SearchIcon class="flex-shrink-0 mr-3" />
        <input type="text" class="bg-transparent w-full" placeholder="Search..." v-model="filters.search" @change="search" />
      </div>
    </div>
    <div class="flex flex-col md:flex-row justify-start xl:justify-end w-full">
      <!--Filters and export-->
      <div class="flex items-center mb-4 sm:mb-0">
        <button @click="changeView('row')" class="w-12 h-12 hover:bg-black hover:text-white focus:bg-black active:bg-black rounded-full flex items-center justify-center mr-3" :class="activeView === 'row' ? 'bg-black text-white' : 'bg-white border border-guestio-gray-300 shadow-md'">
          <ListIcon />
        </button>
        <button @click="changeView('calendar')" class="w-12 h-12 hover:bg-black hover:text-white focus:bg-black active:bg-black rounded-full flex items-center justify-center mr-3" :class="activeView === 'calendar' ? 'bg-black text-white' : 'bg-white border border-guestio-gray-300 shadow-md'">
          <CalendarIcon />
        </button>
      </div>
      <!--Filters-->
      <div class="flex items-center">
        <span class="relative">
          <button type="button"
                  class="flex items-center rounded-full shadow-xs bg-white border border-guestio-gray-300 py-3 px-6"
                  :class="{'border border-pink-500':filtersIsOpen}"
                  @click.prevent="toggleFilters">
              <span class="mr-3">Filters</span>
              <FilterIcon :stroke="hasActiveFilters ? '#B503D0' : '#000'"/>
          </button>
          <FiltersDropdownBookings v-if="filtersIsOpen" @filter-statuses="filterByStatus" @filter-amounts="filterByAmount" @close-dropdown="toggleFilters" />
        </span>
        <span class="w-1 border-l-2 border-gray-600 h-8 mx-4"></span>
        <button @click.prevent="exportBookings" type="button" class="flex items-center rounded-full shadow-xs bg-white border border-guestio-gray-300 py-3 px-6">
          <span class="mr-3" ref="export">Export</span>
          <DownloadIcon />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { mapMutations } from "vuex";
import FilterIcon from "@/components/svgs/FilterIcon";
import ListIcon from "@/components/svgs/ListIcon";
import CalendarIcon from "@/components/svgs/CalendarIcon";
import SearchIcon from "@/components/svgs/SearchIcon";
import DownloadIcon from "@/components/svgs/DownloadIcon";
import FiltersDropdownBookings from "@/components/shared/FiltersDropdownBookings"

export default {
  name: "BookingFilters",

  components: {
    FilterIcon,
    FiltersDropdownBookings,
    ListIcon,
    CalendarIcon,
    SearchIcon,
    DownloadIcon,
  },

  data() {
    return {
      activeView: "row",
      filtersIsOpen: false,
      hasActiveFilters: false,
    };
  },

  computed: {
    filters() {
      return this.$store.getters["dashboard/bookings/filters"];
    },
  },

  watch: {
    filters: {
      deep: true,

      handler(filters) {
        this.$store.commit("dashboard/bookings/setFilters", filters);
        let active = false;
        for (const key in filters) {
          const element = filters[key];
          if (key == "statuses" || key == "amounts") {
            if (element.length) {
              active = true;
            }
          }
        }
        this.hasActiveFilters = active;
      },
    },
  },

  methods: {
    ...mapMutations({
      setCalendarView: "dashboard/bookings/setCalendarView",
    }),

    changeView(view) {
      this.activeView = view;
      switch (view) {
        case "row":
          this.setCalendarView({ calendarView: false });
          break;
        case "calendar":
          this.setCalendarView({ calendarView: true });
          break;

        default:
          this.setCalendarView({ calendarView: false });
          break;
      }
    },

    search() {
      setTimeout(() => {
        this.$emit("filterBookings");
      }, 500);
    },

    exportBookings() {
      this.$refs.export.innerText = "Exporting...";

      api
        .get("/bookings/export")
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "bookings.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          alert("An error occured while trying to export your bookings.");
        })
        .finally(() => (this.$refs.export.innerText = "Export"));
    },

    toggleFilters() {
      this.filtersIsOpen = !this.filtersIsOpen;
    },

    filterByStatus(statuses) {
      this.filters.statuses = statuses;
      this.$emit("filterBookings");
    },

    filterByAmount(amounts) {
      this.filters.amounts = amounts;
      this.$emit("filterBookings");
    },

    toggleBookedBy(entity) {
      this.filters.bookedBy = entity
      this.$emit("filterBookings");
    }
  },
};
</script>

<style scoped>
button {
  transition: all ease 0.2s;
}

.month-selector {
  width: 100%;
}

@media (min-width: 1024px) {
  .month-selector {
    width: 300px;
  }
}
</style>
