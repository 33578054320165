module.exports = {
  methods: {
    confirmLogin() {
      window.Event.$emit('open-reconfirm-login')

      return new Promise((resolve) => {
        window.Event.$on('reconfirmed-login', confirm => resolve(confirm))
      })
    }
  }
}
