<template>
  <div>
    <div class="h-16 border-b border-gray-100 flex items-center px-6 xl:px-12">
      <button @click.prevent="closePicker" class="text-gray-500 flex items-center hover:text-pink-500 transition-colors duration-200 ease-in-out">
        <svg class="h-4 w-4 opacity-75" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M15 19l-7-7 7-7"></path></svg>
        <span class="ml-4">Return to Calendar</span>
      </button>
    </div>
    <div class="px-6 xl:px-12 mt-4 xl:mt-6">
      <h3 class="text-black font-semibold text-2xl text-center">{{ day.format('MMMM DD') }}</h3>

      <div class="mt-6 xl:mt-12 grid grid-cols-2 gap-6 max-h-lg overflow-y-scroll p-1">
        <div v-for="(slot, index) in availableSlots" :key="`slot-${index}`" class="flex">
          <button
            @click.prevent="pickSlot(slot)"
            class="text-base xl:text-lg transition-colors duration-300 ease-in-out h-13 w-full bg-gray-200 rounded-full flex items-center justify-center hover:bg-pink-100 focus:outline-none focus:shadow-outline"
            :class="slotIsSelected(slot) ? 'bg-pink-500 text-white hover:bg-pink-500' : 'text-gray-500'">
            {{ parseTime(slot.datetime) }}
          </button>
        </div>
      </div>

      <div class="my-12">
       <button
          class="w-full bg-transparent hover:bg-pink-100 h-14 rounded-full border flex items-center justify-center transition-colors duration-300 ease-in-out"
          type="submit"
          :class="! selectedSlots.length ? 'border-gray-500 border-gray-500 opacity-50' : 'text-pink-500 border-pink-500'"
          :disabled="! selectedSlots.length"
          @click.prevent="selectSlots"
        >
          <span v-if="selectedSlots.length">
            Add {{ slotsText }}
          </span>
          <span v-else>Select some times</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import pluralize from 'pluralize'
  export default {
    props: {
      day: Object,
      slots: Array,
      selectedSlotsProp: Array,
    },

    data() {
      return {
        selectedSlots: [],
      }
    },

    computed: {
      slotsText() {
        return `${this.selectedSlots.length} ${pluralize('Time', this.selectedSlots.length)}`
      },

      availableSlots() {
        return this.slots.filter(slot => slot.status == 'available')
      }
    },

    methods: {
      closePicker() {
        this.$emit('close')
      },

      parseTime(datetime) {
        return moment(datetime, 'YYYY-MM-DD HH:mm:ss').format('h:mm a')
      },

      selectSlots() {
        this.$emit('updated-slots', this.selectedSlots)
        this.closePicker()
      },

      slotIsSelected(slot) {
        return this.selectedSlots.find(s => s.datetime == slot.datetime)
      },

      pickSlot(slot) {
        if (this.slotIsSelected(slot)) {
          let index = this.selectedSlots.indexOf(slot)
          this.selectedSlots.splice(index, 1)
          return
        }

        if (this.selectedSlots.length == 3) {
          this.selectedSlots.shift()
        }

        this.selectedSlots.push(slot)
      },

      parseSelectedSlots() {
        if (! this.selectedSlotsProp.length) {
          return
        }

        this.selectedSlotsProp.forEach(slot => {
          if (this.slots.includes(slot)) {
            this.pickSlot(slot)
          }
        })
      }
    },

    mounted() {
      this.parseSelectedSlots()
    }
  }
</script>
