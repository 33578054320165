import { render, staticRenderFns } from "./TermsAndConditionsModal.vue?vue&type=template&id=28ad6480&scoped=true"
import script from "./TermsAndConditionsModal.vue?vue&type=script&lang=js"
export * from "./TermsAndConditionsModal.vue?vue&type=script&lang=js"
import style0 from "./TermsAndConditionsModal.vue?vue&type=style&index=0&id=28ad6480&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ad6480",
  null
  
)

export default component.exports