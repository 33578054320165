<template>
  <portal to="modal">
    <div
      class="modal-container fixed bottom-0 inset-x-0 px-4 inset-0 pb-6 xl:p-0 sm:flex sm:items-center sm:justify-center pointer-events-none z-60"
      @click="close()">
      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="ease-in duration-100"
        leave-class="opacity-100"
        leave-to-class="opacity-0">
        <div v-if="modalOpened" class="fixed inset-0 transition-opacity pointer-events-auto">
          <div class="absolute inset-0 bg-gray-900 opacity-75"></div>
        </div>
      </transition>

      <transition
        enter-active-class="ease-out duration-300"
        enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        enter-to-class="opacity-100 translate-y-0 sm:scale-100"
        leave-active-class="ease-in duration-100"
        leave-class="opacity-100 translate-y-0 sm:scale-100"
        leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
        <div
          v-if="modalOpened" @click.stop
          class="transition-all transform flex items-center justify-center w-full h-full pointer-events-auto overflow-y-auto"
          role="dialog" aria-modal="true" aria-labelledby="modal-headline">
          <div class="max-w-lg w-full max-h-screen bg-white rounded-xl px-12 py-10" @click.stop>
            <template v-if="! confirmingSocial">
              <div>
                <h3 class="font-bold text-2xl text-blue-800">
                  Confirm password
                </h3>
                <p class="mt-4">
                  For your security, please confirm your password to continue.
                </p>
              </div>

              <div class="mt-4 sm:mt-6">
                <ValidationObserver ref="confirmForm" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(confirmPassword)" method="post">
                    <div>
                      <label class="font-medium text-black">Password</label>
                      <ValidationProvider mode="lazy" rules="required" name="Password" vid="password" v-slot="{ errors }">
                        <div class="mt-2">
                          <input type="password" class="form-input text-black sm:text-lg bg-gray-50 w-full px-5 sm:py-3 rounded-full border-transparent focus:bg-white focus:shadow-3xl disabled:opacity-75" v-model="form.password" ref="passwordField" :disabled="loading">
                        </div>
                        <p class="text-red-500 text-sm mt-1 font-light" v-if="errors.length">{{ errors[0] }}</p>
                      </ValidationProvider>
                    </div>

                    <div class="mt-4">
                      <button class="btn text-base bg-gray-600 flex justify-center w-full items-center disabled:opacity-50 h-12" type="submit" :disabled="loading">
                        <span v-if="loading">
                          <loading-icon class="h-2 px-3"/>
                        </span>
                        <span class="text-sm sm:text-xl" v-else>Confirm password</span>
                      </button>
                    </div>
                  </form>
                </ValidationObserver>

                <div v-if="socialLoginEnabled" class="mt-6">
                  <!-- disabled until credentials are provided -->
                  <div class="relative">
                    <div class="absolute inset-0 flex items-center">
                      <div class="w-full border-t border-gray-300"></div>
                    </div>
                    <div class="relative flex justify-center text-sm">
                      <span class="px-2 bg-white text-gray-500">
                        Or continue with
                      </span>
                    </div>
                  </div>

                  <div class="mt-6 grid grid-cols-2 gap-3">
                    <div>
                      <button type="button" @click="confirmSocial('facebook')" class="w-full inline-flex justify-center py-4 px-4 border border-gray-300 rounded-full shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Sign in with Facebook</span>
                        <FacebookLoginIcon class="w-5 h-5" />
                      </button>
                    </div>

                    <div>
                      <button type="button" @click="confirmSocial('google')" class="w-full inline-flex justify-center py-4 px-4 border border-gray-300 rounded-full shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
                        <span class="sr-only">Sign in with Google</span>
                        <GoogleLoginIcon class="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>

            <template v-if="confirmingSocial">
              <div>
                <h3 class="font-bold text-2xl text-blue-800">
                  Confirming social login
                </h3>
                <p class="mt-4">
                  We're waiting for the social login confirmation.
                </p>
              </div>

              <div class="mt-4 sm:mt-6">
                <div class="bg-gray-50 h-20 rounded-lg border-2 border-dashed flex items-center justify-center">
                  <span v-if="seconds > 0" class="text-4xl font-bold text-blue-800">{{ seconds }}</span>
                  <span v-if="socialConfirmError" class="text-red-500">
                    The social login confirmation failed.
                  </span>
                </div>
              </div>

              <div class="mt-6">
                <button class="mx-auto text-base flex justify-center text-pink-500" type="button" @click="cancelSocialConfirm">
                  Cancel
                </button>
              </div>
            </template>
          </div>

          <div class="absolute top-0 right-0 mt-4 mr-4 z-50">
            <button @click.prevent="close()" type="button"
                    class="p-2 rounded-full text-white opacity-75 hover:opacity-100 focus:bg-gray-500 bg-gray-600 bg-opacity-50">
              <svg class="h-8 lg:h-12 xl:h-14" fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                   viewBox="0 0 24 24" stroke="currentColor">
                <path d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
        </div>
      </transition>
    </div>
  </portal>
</template>

<script>
  import api from '@/api'
  import Cookies from 'js-cookie'
  import FacebookLoginIcon from '@/components/svgs/FacebookLoginIcon'
  import GoogleLoginIcon from '@/components/svgs/GoogleLoginIcon'

  export default {
    components: {
      FacebookLoginIcon,
      GoogleLoginIcon,
    },

    data() {
      return {
        loading: false,
        modalOpened: false,
        checkInterval: null,
        seconds: 120,
        confirmingSocial: false,
        socialConfirmError: false,
        form: {
          password: '',
        },
        passwordConfirmError: false,
      }
    },

    watch: {
      modalOpened(modalOpened) {
        document.body.classList.toggle('overflow-hidden', modalOpened)

        this.$emit(modalOpened ? 'opened' : 'closed')

        if (modalOpened) {
          this.$nextTick().then(() => {
            this.$refs.passwordField.focus()
          })
        }
      }
    },

    computed: {
      socialLoginEnabled() {
        return process.env.VUE_APP_SOCIAL_LOGIN_ENABLED
      },
      socialLoginUrl() {
        return `${process.env.VUE_APP_API_ENDPOINT}/auth/login`
      },
    },

    methods: {
      close(confirmed) {
        this.modalOpened = false
        this.loading = false
        this.form.password = ''

        if (this.confirmingSocial) {
          this.cancelSocialConfirm()
        }

        if (! confirmed) {
          window.Event.$emit('reconfirmed-login', false)
        }
      },

      handleEscape(e) {
        if (e.keyCode === 27) {
          this.close()
        }
      },

      confirmPassword() {
        this.loading = true

        api
          .post('/auth/confirm-login', this.form)
          .then(() => {
            window.Event.$emit('reconfirmed-login', true)
            this.close(true)
          })
          .catch(error => {
            this.loading = false

            if (error.response) {
              return this.$refs.confirmForm.setErrors(error.response.data.errors)
            }

            this.$toast.error('Sorry. An error ocured.')
          })
      },

      confirmSocial(provider) {
        window.open(`${this.socialLoginUrl}/${provider}`, provider, 'noopener')
        this.confirmingSocial = true
        this.checkForConfirmationCookie()
      },

      hasSocialConfirmationCookie() {
        let cookie = Cookies.get('login-confirmed')

        return typeof cookie != 'undefined' ? true : false
      },

      checkForConfirmationCookie() {
        this.seconds = 120

        this.checkInterval = setInterval(() => {
          this.seconds--
          const confirmed = this.hasSocialConfirmationCookie()
          if (confirmed || this.seconds == 0) {
            clearInterval(this.checkInterval)

            if (! confirmed) {
              return this.socialConfirmError = true
            }

            window.Event.$emit('reconfirmed-login', true)
            this.close(true)
          }
        }, 1000)
      },
      cancelSocialConfirm() {
        this.confirmingSocial = false
        this.socialConfirmError = false
        this.seconds = 120

        if (this.checkInterval) {
          clearInterval(this.checkInterval)
        }
      }
    },

    mounted() {
      document.addEventListener('keyup', this.handleEscape)
      window.Event.$on('open-reconfirm-login', () => this.modalOpened = true)
    },

    beforeDestroy() {
      document.removeEventListener('keyup', this.handleEscape)

      if (this.checkInterval) {
        clearInterval(this.checkInterval)
      }
    }
  }
</script>
