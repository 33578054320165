<template>
  <div>
    <RecordAudio @saved="addAnswer" :media-prop="miniviewMedia" :isMiniviewAnswer="isMiniviewAnswer">
      <template #header>
        <div class="flex items-center justify-between mb-4">
          <h4 class="font-poppins text-lg font-bold text-blue-800">
            {{ miniview.body }}
          </h4>

          <div>
            <button @click="$emit('close')" type="button">
              <XIcon class="h-5 text-gray-500" />
            </button>
          </div>
        </div>
      </template>

      <template #footer="{ redo, saving, save }">
        <div class="flex items-center justify-around pt-4" :class="{'opacity-75': saving}">
          <template v-if="saving">
            <div class="flex items-center justify-center py-3">
              <loading-icon class="h-2 text-pink-500"/>
            </div>
          </template>

          <template v-else>
            <button @click.prevent="redo" type="button" class="flex items-center space-x-2 text-pink-500">
              <RedoIcon class="w-4 h-4" />
              <span>Redo</span>
            </button>

            <button
              @click="save"
              type="button" class="bg-indigo-gradiant text-white font-bold px-10 py-3 rounded-full flex items-center justify-center hover:opacity-90 disabled:opacity-50"
            >
              Save Answer
            </button>
          </template>
        </div>
      </template>
    </RecordAudio>
  </div>
</template>

<script>
  import RedoIcon from '@/components/svgs/RedoIcon'
  import XIcon from '@/components/svgs/XIcon'
  import RecordAudio from '@/components/shared/RecordAudio'

  export default {
    props: {
      miniview: Object,
      isMiniviewAnswer: { type: Boolean, default: false },
    },

    components: {
      RedoIcon,
      XIcon,
      RecordAudio,
    },

    computed: {
      miniviewMedia() {
        if (! this.miniview.answer_media_url) {
          return null
        }

        return {
          url: this.miniview.answer_media_url,
        }
      }
    },

    methods: {
      addAnswer(media) {
        this.$emit('update-miniview', media)

        this.$emit('close')
      }
    },
  }
</script>
