<template>
  <div>
    <button v-if="canAddItem" class="flex items-center justify-center text-violet" @click.prevent="openForm()">
      <CirclePlusIcon fill="white" class="mr-2 h-9 w-9" stroke="2" strokeColor="transparent" backgroundColor="#B503D0"/>
      <span class="text-sm font-medium">Add {{visibleType}}</span>
    </button>
    <modal v-else class="flex items-start">
      <button v-if="!showForm" class="flex items-center justify-center text-violet">
        <CirclePlusIcon fill="white" class="mr-2 h-9 w-9" stroke="2" strokeColor="transparent" backgroundColor="#B503D0"/>
        <span class="text-sm font-medium">Add {{visibleType}}</span>
      </button>

      <div slot="content">
        <div class="bg-white flex flex-col p-6 rounded-lg max-w-md">
          <span class="font-bold mb-5">You reached the limit of 10 people</span>
          <span>To have <b>unlimited people</b> on your wishlist, upradge to Pro now</span>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
  import {mapState, mapMutations} from "vuex";
  import CirclePlusIcon from "@/components/svgs/CirclePlusIcon";
  import Modal from '@/components/shared/Modal';

  export default {
    name: 'AddItemButton',
    components: {CirclePlusIcon, Modal},
    props: {
      type: String
    },

    methods: {
      ...mapMutations({
        setShowForm: "dashboard/smartlist/setShowForm",
      }),

      openForm() {
        this.setShowForm(true);
      },
    },

    computed: {
      ...mapState({
        user: state => state.auth.user,
        smartlist: state => state.dashboard.smartlist.smartlist,
        showForm: state => state.dashboard.smartlist.showForm,
      }),

      canAddItem() {
        return !this.showForm && (this.user.account_type == 'pro' || this.smartlist.length < 10)
      },

      visibleType() {
        if (this.type === 'show') {
          return 'show';
        }

        return this.type;
      },
    },
  }
</script>
