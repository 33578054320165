<template>
  <div v-if="imageUrl" class="h-48 relative overflow-hidden rounded-xl border-2 border-dashed border-pink-500" @dragover.prevent="dragOverInput" @dragleave="dragLeaveOrEnd" @dragend="dragLeaveOrEnd" @drop.prevent="dropFile">
    <img v-if="!isVideo(imageUrl)" :src="imageUrl" alt="Image" class="h-full w-auto">
    <video v-else :src="imageUrl" loop="loop" muted="true" autoplay="true" class="object-cover h-full"></video>
    <div v-if="!working && !uploading" :class="['absolute z-10 inset-x-0 bg-gray-800 bg-opacity-75 h-10 flex items-center justify-center bottom-0 transition duration-150 ease-in-out hover:bg-opacity-100', highlight ? 'bg-opacity-100' : '']">
      <label
        class="text-sm text-white px-2 py-2 rounded-lg focus:outline-none focus:shadow-ouline-gray cursor-pointer"
      >
        Change image
        <input
          type="file"
          class="hidden"
          @change="changeInput"
        >
      </label>
    </div>

    <div class="absolute z-10 top-0 right-0 flex items-center justify-center transition duration-150 ease-in-out">
      <modal>
        <div class="p-1 bg-blue-400 bg-opacity-75 rounded-lg mt-2 mr-2 text-white hover:bg-opacity-100">
          <CropIcon/>
        </div>
        <template v-slot:content="{ close }">
          <CropImage :imageUrl="imageUrl" @cropped="saveImage" @cancel="close" :loading="uploading" :aspectRatio="cropAspectRatio" :minHeight="minHeight" :minWidth="minWidth"/>
        </template>
      </modal>
      
      <alert-dialog confirm="Delete" @confirm="deleteMedia($event)" type="button" class="p-1 bg-red-400 bg-opacity-75 rounded-lg mt-2 mr-2 text-white hover:bg-opacity-100">
        <TrashIcon/>

        <template #title>
          Delete image
        </template>

        <template #content>
          Are you sure you want to delete this image?
        </template>
      </alert-dialog>
    </div>
    <div v-if="working || uploading" class="absolute z-10 inset-x-0 bg-gray-800 bg-opacity-75 text-white h-10 flex items-center justify-center bottom-0 transition duration-150 ease-in-out text-sm">
      Saving...
    </div>
    <div v-if="uploadingError || error" class="absolute z-10 inset-x-0 bg-red-600 bg-opacity-75 text-white h-10 flex items-center justify-center bottom-0 transition duration-150 ease-in-out text-sm">
      {{errorText.length ? errorText: 'Image not saved'}}
    </div>
  </div>
  <div v-else class="w-full"  @dragover.prevent="dragOverInput" @dragend="dragLeaveOrEnd" @dragleave="dragLeaveOrEnd" @drop.prevent="dropFile">
    <EmptyImageUpload
      class="w-full bg-gray-200"
      :uploading="uploading"
      :uploadingError="uploadingError || error"
      @change="changeInput"
      :allowedSize="allowedSize"
      :suggestedDimensions="suggestedDimensions"
    />
  </div>
</template>

<script>
  import api from '@/api'
  import AlertDialog from '@/components/shared/AlertDialog'
  import EmptyImageUpload from '@/components/shared/EmptyImageUpload'
  import Modal from '@/components/shared/Modal'
  import TrashIcon from '@/components/svgs/TrashIcon'
  import CropIcon from '@/components/svgs/CropIcon'
  import CropImage from '@/components/shared/CropImage'

  export default {
    name: 'MediaUploadSingle',

    props: {
      entity: String,
      working: { type: Boolean, defualt: false},
      modelId: Number,
      imageUrl: String,
      imageUuid: String,
      error: { type: Boolean, defualt: false},
      cropAspectRatio: { type: Number, required: false, default: 10 / 8 },
      minHeight: {type: Number, required: false, default: 400},
      minWidth: {type: Number, required: false, default: 400},
      allowedSize: {type: String, required: false, default: '20MB'},
      suggestedDimensions: {type: String, required: false, default: ''}
    },

    components: {
      AlertDialog,
      TrashIcon,
      CropIcon,
      Modal,
      CropImage,
      EmptyImageUpload
    },

    data() {
      return {
        uploading: false,
        uploadingError: false,
        errorText: '',
        highlight: false,
      }
    },

    methods: {
      changeInput(event) {
        this.uploadImage(event);
      },
      dragOverInput() {
        this.highlight = true;
      },
      dragLeaveOrEnd() {
        this.highlight = false;
      },
      dropFile(event) {
        this.uploadImage(event);
      },

      saveImage(canvas){
        this.uploading = true
        this.uploadingError = false
        if (canvas) {
          let uploadForm = new FormData()
          canvas.toBlob(blob => {
            uploadForm.append('image', blob);
            api.post('/uploads', uploadForm)
            .then(response => {
              this.$emit('uploaded', response.data.uploads[0])
            })
            .catch(() => {
              this.uploadingError = true
            })
            .finally(() => {
              this.uploading = false
            })
          }, 'image/jpeg');
        }
      },

      uploadImage(e) {
        this.errorText = '';

        let files = [];

        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length != 0) {
          files = e.dataTransfer.files;
        } else if (e.target.files.length != 0) {
          files = e.target.files;
        } else {
          return;
        }

        this.uploading = true
        this.uploadingError = false

        let uploadForm = new FormData()

        const videoTypes = ['video/mp4', 'video/ogg', 'video/mov', 'video/qt'] 

        if(videoTypes.includes(files[0]['type'])) {
          if(this.$props.entity == "guest" || this.$props.entity == "new-guest") {
            uploadForm.append('video', files[0])
          } else {
            this.errorText = 'Video only available for Guest profile';

            this.uploading = false;
            this.uploadingError = true;
            return;
          }
        } else {
          uploadForm.append('image', files[0])
        }

        api.post('/uploads', uploadForm)
          .then(response => {
            if(response.data.uploads !== null) {
              this.$emit('uploaded', response.data.uploads[0])
            } else {
              this.uploading = false
              this.uploadingError = true;
              this.errorText = response.data.message;
            }
          })
          .catch(() => {
              this.uploadingError = true
          })
          .finally(() => {
              this.uploading = false
              e.target.value = null
          })
      },

      async deleteMedia(callback) {
        if (!this.entity.includes('new')) {
          await this.$store.dispatch(this.deleteMediaAction, {
            model_id: this.modelId,
            uuid: this.imageUuid,
          })
  
          callback.call()
  
          await this.$store.dispatch(this.fetchAction, {id: this.modelId})
        }
        this.$emit('deleted')
      },

      isVideo(url) {
        const videoTypes = ['mp4', 'ogg', 'mov', 'qt']

        let extension = url.split(".").pop();
        return videoTypes.includes(extension);
      },
    },

    computed: {
      fetchAction() {
        switch (this.entity) {
          case 'guest':
            return 'guest/fetchGuest';
          case 'show':
            return 'shows/fetchShow';
          default:
            return 'auth/getUser';
        }
      },

      deleteMediaAction() {
        switch (this.entity) {
          case 'guest':
            return 'guest/deleteMedia';
          case 'show':
            return 'shows/deleteMedia';
          default:
            return 'auth/deleteMedia';
        }
      },
    },
  }
</script>