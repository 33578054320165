<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" :class="classes">
    <path d="M18 6L6 18" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M6 6L18 18" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'CrossIcon',
  props: {
    classes: {
      type: String,
      default: '',
      required: false
    },
    color: {
      type: String,
      default: 'black',
      required: false
    }
  }
}
</script>

<style lang="css" scoped></style>