<template>
  <div :class="{'opacity-50': discarding}">
    <div>
      If you scheduled a time for your inverview using <span class="font-bold text-pink-500">{{ activity.properties.value }}</span>, please add it to the booking.
    </div>
    <div class="mt-2 space-x-2">
      <modal class="bg-purple-50 rounded-full text-white text-sm font-bold px-5 py-2">
        <span>
          Schedule Date/Time
        </span>
        <template v-slot:content="{ close }">
          <div class="max-w-lg w-full bg-white rounded-xl overflow-hidden self-start my-4">
            <calendar @close="close" :single="true" @save="changeBookingDate($event, close)" />
          </div>
        </template>
      </modal>

      <button type="button" @click="discardActivity" class="text-purple-50 text-sm px-2 py-2 hover:underline">
        Discard this
      </button>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import Modal from '@/components/shared/Modal'
  import Calendar from '@/components/bookings/individual/Calendar'

  export default {
    props: {
      activity: Object,
    },

    data() {
      return {
        discarding: false,
      }
    },

    components: {
      Modal, Calendar
    },

    computed: {
      booking() {
        return this.$store.getters['dashboard/bookings/booking']
      },
    },

    methods: {
      changeBookingDate(date, callback) {
        this.$store.dispatch('dashboard/bookings/changeBookingDate', {
          id: this.$route.params.bookingId,
          confirmed_date: date.toISOString(),
        }).then(() => {
          callback.call()
          this.$store.dispatch('dashboard/bookings/fetchBooking', {
            id: this.$route.params.bookingId,
          })
        })
      },

      discardActivity() {
        this.discarding = true

        api.delete(`/bookings/${this.$route.params.bookingId}/activities/${this.activity.id}`)
          .then(() => this.$emit('action-taken'))
      }
    }
  }
</script>
