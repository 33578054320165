<template>
  <modal :with-close-button="false">
    <slot />

    <template #content="{ close }">
      <div class="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all sm:max-w-md w-full" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
        <form method="post" @submit.prevent="sendReport(close)">
          <article class="bg-white px-4 pt-6 pb-5 sm:px-8 sm:py-6 sm:pb-6">
            <header class="flex justify-between items-center">
              <div class="flex items-center space-x-2">
                <span class="rounded-full h-10 w-10 bg-pink-500 bg-opacity-10 flex items-center justify-center">
                  <ExclamationIcon class="h-5 w-5 text-pink-500" />
                </span>
                <h3 class="font-bold font-poppins text-blue-800">
                  Report No-Show
                </h3>
              </div>
              <div>
                <button @click="close" type="button" class="text-gray-500 p-1 rounded focus:shadow-outline">
                  <XIcon class="w-6 h-6" />
                </button>
              </div>
            </header>

            <main class="text-sm text-blue-800 font-medium mt-6">
              <p>
                If the interview didn't happen, please let us know and our support team will investigate and get back to you as soon as possible.
              </p>

              <div class="mt-4">
                <div class="flex justify-between items-center">
                  <label for="details" class="font-semibold text-blue-800 text-sm">
                    Tell us what happened <span class="text-gray-500 font-normal">(optional)</span>
                  </label>
                </div>
                <div class="mt-1">
                  <textarea v-model="form.details" name="details" id="details" rows="10" class="form-textarea rounded-lg text-sm w-full focus:shadow-3xl focus:border-pink-500"></textarea>

                  <p class="text-xs text-gray-500">
                    Be as detailed as you can.
                  </p>
                </div>
              </div>
            </main>

            <footer class="flex items-center justify-between mt-6">
              <button type="submit" class="bg-indigo-gradiant text-sm font-bold text-white rounded-full px-8 h-11 flex items-center justify-center disabled:opacity-60 relative" :disabled="working">
                <span v-if="working" class="absolute">
                  <loading-icon class="text-white h-2" />
                </span>
                <span :class="{'text-transparent': working}">
                  Send Report
                </span>
              </button>

              <button @click.prevent="close" type="button" class="text-pink-500 font-bold text-sm disabled:opacity-60" :disabled="working">
                Cancel
              </button>
            </footer>
          </article>
        </form>
      </div>
    </template>
  </modal>
</template>

<script>
  import api from '@/api'
  import Modal from '@/components/shared/Modal'
  import ExclamationIcon from '@/components/svgs/ExclamationIcon'
  import XIcon from '@/components/svgs/XIcon'

  export default {
    name: 'ReportNoShow',

    props: {
      booking: Object,
    },

    components: {
      Modal,
      ExclamationIcon,
      XIcon,
    },

    data() {
      return {
        working: false,
        form: {
          details: '',
        },
      }
    },

    computed: {
      validBookingState() {
        return this.booking.status === 'completed' && this.booking.confirmed_by_invitee
      },
    },

    methods: {
      sendReport(callback) {
        this.working = true

        api.post(`/bookings/${this.booking.id}/no-show`, this.form)
          .then(() => {
            this.$toast.success('The no-show report was sent!')
          })
          .catch(error => {
            if (error.response) {
              return this.$toast.error(error.response.data.message)
            }

            this.$toast.error('Error! Something went wrong.')
          })
          .finally(() => {
            this.working = false
            this.form.details = ''
            callback()
          })
      },
    },
  }
</script>
